<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="events"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col>
      <v-row v-if="isFailureGetEventsListState">
        <v-col>
          <Alert type="error">
            {{ errorMessageGetEventsListState }}
          </Alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="auto">
          <v-menu>
            <template #activator="{ props }">
              <v-btn
                color="primary"
                v-bind="props"
                :loading="isPendingReportPasses"
                :disabled="isPendingReportPasses"
              >
                <v-icon start> mdi-file-chart </v-icon>
                {{ $t("advanced.allEvents") }}&nbsp;
                <v-icon end> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list density="comfortable">
              <v-list-item @click="getEventsReportPasses('report/worktime')">
                <v-list-item-title>{{
                  $t("advanced.worktime")
                }}</v-list-item-title>
                <template #append>
                  <v-tooltip location="left" max-width="250">
                    <template #activator="{ props }">
                      <v-icon v-bind="props" color="grey-lighten-1"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>{{ $t("advanced.onlyZoneWithWorktime") }}</span>
                  </v-tooltip>
                </template>
              </v-list-item>

              <v-list-item @click="getEventsReportPasses('report/iotime')">
                <v-list-item-title>{{
                  $t("advanced.iotime")
                }}</v-list-item-title>
                <template #append>
                  <v-tooltip location="left" max-width="250">
                    <template #activator="{ props }">
                      <v-icon v-bind="props" color="grey-lighten-1"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>{{ $t("advanced.onlyZoneWithWorktime") }}</span>
                  </v-tooltip>
                </template>
              </v-list-item>
              <v-list-item @click="getEventsReportPasses('report/passusers')">
                <v-list-item-title>{{
                  $t("advanced.passUsers")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="getEventsReportPasses('report/passed')">
                <v-list-item-title>{{
                  $t("advanced.passed")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="getEventsReportPasses('report/passed', true)"
              >
                <v-list-item-title>{{
                  $t("advanced.passedTemp")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="getEventsReportPasses('report/stat')">
                <v-list-item-title>{{
                  $t("advanced.analytic")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="isAllEventsOpen = true">
                <v-list-item-title>{{
                  $t("advanced.customReport")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :loading="isPendingGetEventsListState"
            :headers="headers"
            :items="eventsList"
            :items-per-page="pagination.limit"
            hide-default-footer
            :row-props="rowProps"
          >
            <template #[`item.pass_type`]="{ item }">
              <EventPassType :item="item" />
              <EventActions :item="item" />
            </template>

            <template #[`item.identity_types`]="{ item }">
              <EventPurposeType :item="item" />
              <EventIdentityTypes :item="item" />
            </template>

            <template #[`item.avatar`]="{ item }">
              <EventAvatar :item="item" />
            </template>

            <template #[`item.full_name`]="{ item }">
              <EventContent :item="item" />
              <EventContentChips :item="item" />
            </template>

            <template #[`item.identity_value`]="{ item }">
              <v-chip
                v-if="item.event?.identity_value"
                color="primary"
                variant="outlined"
                class="my-1"
                size="small"
              >
                <v-icon start size="small">
                  {{ getIdentityValueIconName(item.event.identity_value) }}
                </v-icon>
                {{ getIdentityValue(item.event.identity_value) }}
              </v-chip>
            </template>

            <template #[`item.created_at`]="{ item }">
              <EventContent
                :item="item"
                :name="{
                  show: false,
                }"
                :purpose="{ show: false }"
                :temperature="{ show: false }"
                :formatCreatedAt="{
                  show: true,
                }"
              />
            </template>

            <template #bottom
              ><DataTableFooterV2
                v-model="pagination"
                @update:modelValue="onPagination"
            /></template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

  <ModalAllEventsSettings
    v-if="isAllEventsOpen"
    :loading="isPendingReport"
    @close="isAllEventsOpen = false"
    @submit="getEventsReport"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";

import Filter from "@/components/Filter";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Alert from "@/components/Alert";
import {
  EventPurposeType,
  EventIdentityTypes,
  EventAvatar,
  EventContent,
  EventContentChips,
  EventPassType,
  EventActions,
  EventIndicator,
} from "@/components/EventsTools";

import correctVisit from "@/mixins/correctVisit";
import permission from "@/mixins/permissions";

import ModalAllEventsSettings from "./ModalAllEventsSettings";

export default {
  name: "EventList",

  mixins: [correctVisit, permission, EventIndicator],

  components: {
    EventPurposeType,
    EventIdentityTypes,
    EventAvatar,
    EventContent,
    EventContentChips,
    EventPassType,
    EventActions,
    ModalAllEventsSettings,
    DataTableFooterV2,
    Filter,
    Alert,
  },

  data() {
    return {
      eventsList: [],

      headers: [
        {
          value: "pass_type",
          width: "16px",
          sortable: false,
        },
        {
          value: "identity_types",
          width: "8px",
          cellProps: {
            class: "pl-2 pr-0 py-2",
          },
          sortable: false,
        },
        {
          value: "avatar",
          width: 50,
          cellProps: {
            class: "py-2",
          },
          sortable: false,
        },
        {
          title: this.$t("users.visitor"),
          value: "full_name",
          cellProps: {
            class: "py-2",
          },
        },
        {
          title: this.$t("advanced.identityValue"),
          value: "identity_value",
          align: "center",
          sortable: false,
        },
        {
          title: this.$t("users.visit_time"),
          value: "formatCreatedAt",
          width: 50,
          align: "center",
        },
      ],

      pageData: {},
      filter: {},
      pagination: {},

      isAllEventsOpen: false,
    };
  },

  methods: {
    ...mapActions({
      resetGetEventsListAction: "events/resetGetEventsList",
      getEventsListAction: "events/getEventsList",

      report: "events/report",
      reportPasses: "passes/report",

      setStatePageDataAction: "stateEvents/setPageData",
      setStateFilterAction: "stateEvents/setFilter",
      setStatePaginationAction: "stateEvents/setPagination",
    }),

    getEventsList() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getEventsListAction(params).then((response) => {
        const curList = [];

        this.eventsListState.forEach((event) => {
          curList.push(this.correctVisit(event));
        });

        this.eventsList = curList;

        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;

          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }
      });
    },

    getEventsReportPasses(url, istemp = false) {
      const params = {
        istemp,
        ...this.filter,
      };

      this.reportPasses({ params, url });
    },

    getIdentityValueType(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);
      return type;
    },

    getIdentityValue(identityValue) {
      const separator = identityValue.indexOf(":");
      return identityValue.substring(identityValue.length, separator + 1);
    },

    getEventsReport(list) {
      const params = {
        ...this.filter,
      };

      const data = {
        header: list
          .filter((e) => e.checked)
          .map((e) => {
            const result = {
              title: e.name,
              field: e.value,
              width: e.width,
            };

            if (e.type) {
              result.type = e.type;
            }

            return result;
          }),
      };

      this.report({ params, data });
    },

    getIdentityValueIconName(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);

      switch (type) {
        case "qr":
          return "mdi-qrcode-scan";
        case "card":
          return "mdi-card-bulleted-outline";
        default:
          break;
      }
    },

    rowProps(item) {
      const props = {};
      if (
        this.$vuetify.display.mobile &&
        item.item.person_id &&
        this.can(this.$const.RULES.OBJECT_VIEW)
      ) {
        props.onClick = () => this.goToUser(item);
      }

      props.class = this.indicator(item.item);

      return props;
    },

    goToUser(item) {
      this.$router.push({
        name: this.$const.ROUTES.SHOW_PERSON,
        params: {
          id: item.item.person_id,
        },
        state: {
          id: item.item.person_id,
        },
      });
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния таблицы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },

    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };

      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getEventsList();
    },
  },

  computed: {
    ...mapState({
      isPendingGetEventsListState: (state) =>
        state.events.isPendingGetEventsList,
      isFailureGetEventsListState: (state) =>
        state.events.isFailureGetEventsList,
      errorMessageGetEventsListState: (state) =>
        state.events.errorMessageGetEventsList,

      eventsListState: (state) => state.events.eventsList,

      isPendingReport: (state) => state.events.isPendingReport,
      isPendingReportPasses: (state) => state.passes.isPendingReport,

      statePageData: (state) => state.stateEvents.data,
      stateFilter: (state) => state.stateEvents.filter,
      statePagination: (state) => state.stateEvents.pagination,
    }),
  },

  created() {
    this.getState();
    this.getEventsList();
  },

  unmounted() {
    this.resetGetEventsListAction();
  },
};
</script>
