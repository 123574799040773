export default {
  methods: {
    walk(root, callback) {
      if (root) {
        callback(root);
        if (root.children?.length) {
          root.children.forEach((child) => {
            this.walk(child, callback);
          });
        }
      }
    },

    walkArray(root, callback) {
      root.forEach((rootItem) => {
        this.walk(rootItem, callback);
      });
    },
  },
};
