import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      parseUserIdLangMessage: null,
    };
  },

  methods: {
    ...mapActions({
      validateFaceAction: "person/validateFace",
    }),
    validateFace(photo) {
      const params = {
        photo_encrypted: photo,
      };

      this.validateFaceAction(params);
    },

    getLangMessage(message) {
      this.parseUserIdLangMessage = null;
      let maxChar;

      if (message.indexOf("face match found User") !== -1) {
        this.parseUserIdLangMessage = message
          .replace("face match found User ", "")
          .trim();

        message = "Face match found";
      }

      if (message.indexOf("face match found User") !== -1) {
        this.parseUserIdLangMessage = message
          .replace("face match found User ", "")
          .trim();

        message = "Face match found";
      }

      if (message.indexOf("duplicate access card") !== -1) {
        const l = message.split("#");
        this.parseUserIdLangMessage = l[1].trim();

        message = "Duplicate access card";
      }

      if (message.indexOf("duplicate access CARD") !== -1) {
        const l = message.split("#");
        this.parseUserIdLangMessage = l[1].trim();

        message = "Duplicate access CARD";
      }

      if (message.indexOf("duplicate access QR") !== -1) {
        const l = message.split("#");
        this.parseUserIdLangMessage = l[1].trim();

        message = "Duplicate access QR";
      }

      if (message.indexOf("Image size is incorrect") !== -1) {
        message = "Image size is incorrect";
      }

      if (
        message.indexOf("pq: value too long for type character varying") !== -1
      ) {
        maxChar = message
          .replace("pq: value too long for type character varying", "")
          .trim();

        message = "pq: value too long for type character varying";
      }

      switch (message) {
        case "cant extract features for masks. SMALL FACE":
          return this.$t("message.smallFace");

        case "Cant find face of the image":
        case "bad photo":
          return this.$t("message.CantFaceImage");

        case "Unavailable to device":
        case "Device Unavailable":
          return this.$t("message.UnavailableToDevice");

        case "face rotated":
          return this.$t("message.faceRotated");

        case "Fields filled in incorrectly":
          return this.$t("message.FieldsIncorrectly");

        case "Face match found":
          return `${this.$t("message.FaceMatchFound")}`;

        case "No subdivision selected":
          return this.$t("message.NoSubdivisionSelected");

        case 'pq: duplicate key value violates unique constraint "admins_username_key"':
          return this.$t("message.duplicateKeyLogin");

        case "Duplicate access card":
        case "Duplicate access CARD":
          return `${this.$t("message.DuplicateAccessCard")}`;

        case "Duplicate access QR":
          return `${this.$t("message.DuplicateAccessQR")}`;

        case "Wrong landmarks":
          return this.$t("message.wrongLandmarks");

        case "Image size is incorrect":
          return this.$t("message.imageSizeIsIncorrect");

        case "pq: value too long for type character varying":
          return `${this.$t("message.maxCharSize")} ${maxChar}`;

        case "Not found device":
          return this.$t("message.notFoundDevice");

        case "duplicate phone":
          return this.$t("requests.phoneExist");

        default:
          return message;
      }
    },
  },

  computed: {
    ...mapState({
      isFaceValidPending: (state) => state.person.isPendingValidateFace,
      isFaceValidSuccess: (state) => state.person.isSuccessValidateFace,
      isFaceValidFailure: (state) => state.person.isFailureValidateFace,
      faceValidErrorMessage: (state) => state.person.errorMessageValidateFace,
    }),
  },
};
