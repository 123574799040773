<template>
  <Alert v-if="isFailureUpdateAccessModeState" type="error">
    {{ errorMessageUpdateAccessModeState }}
  </Alert>

  <Form
    :disabled="isPendingUpdateAccessModeState"
    :id="id"
    @setData="setData"
    @cancel="goBack"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  components: { Form, Alert },

  data() {
    return {
      id: undefined,
    };
  },

  methods: {
    ...mapActions({
      resetUpdateAccessModeAction: "accessModes/resetUpdateAccessMode",
      updateAccessModeAction: "accessModes/updateAccessMode",
    }),
    getData() {
      this.id = this.$route.params.id;
    },

    goBack() {
      if (this.$route?.query?.close) {
        return window.close();
      }

      this.$router.go(-1);
    },

    setData(data) {
      const params = {
        id: this.id,
        data: data,
      };
      this.updateAccessModeAction(params).then(this.goBack);
    },
  },

  computed: {
    ...mapState({
      isPendingUpdateAccessModeState: (state) =>
        state.accessModes.isPendingUpdateAccessMode,
      isFailureUpdateAccessModeState: (state) =>
        state.accessModes.isFailureUpdateAccessMode,
      errorMessageUpdateAccessModeState: (state) =>
        state.accessModes.errorMessageUpdateAccessMode,
    }),
  },

  created() {
    this.getData();
  },

  unmounted() {
    this.resetUpdateAccessModeAction();
  },
};
</script>
