<template>
  <v-col class="pa-0">
    <v-data-table
      :headers="fields"
      :items="filterIndoors"
      disable-sort
      :items-per-page="pagination.limit"
      :row-props="
        (row) => {
          return { class: indicator(row.item) };
        }
      "
      item-key="id"
    >
      <template #[`item.avatar`]="{ item }">
        <EventAvatar :item="item" />
      </template>

      <template #[`header.button`]="{ item }">
        <v-btn
          v-if="!!filterIndoors.length"
          variant="outlined"
          rounded
          color="primary"
          size="small"
          @click="closeVisitAll(item)"
        >
          {{ $t("users.eventCloseAll") }}
        </v-btn>
      </template>
      <template #[`item.button`]="{ item }">
        <v-btn
          variant="outlined"
          rounded
          color="primary"
          size="small"
          @click="closeVisit(item)"
        >
          {{ $t("users.eventClose") }}
        </v-btn>
      </template>

      <template #[`item.content`]="{ item }">
        <EventContent :item="item" />
      </template>

      <template #[`item.formatCreatedAt`]="{ item }">
        <EventContent
          :item="item"
          :name="{
            show: false,
          }"
          :purpose="{ show: false }"
          :temperature="{ show: false }"
          :formatCreatedAt="{
            show: true,
          }"
        />
      </template>

      <template #bottom>
        <DataTableFooterV2
          v-model="pagination"
          @update:modelValue="onPagination"
        />
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  EventAvatar,
  EventContent,
  EventIndicator,
} from "@/components/EventsTools";

import DataTableFooterV2 from "@/components/DataTableFooterV2";

import corrVisit from "@/mixins/correctVisit";

export default {
  components: {
    DataTableFooterV2,
    EventAvatar,
    EventContent,
  },

  mixins: [corrVisit, EventIndicator],

  data() {
    return {
      fields: [
        {
          value: "avatar",
          width: 50,
          cellProps: {
            class: "py-2",
          },
        },
        {
          value: "content",
          title: this.$t("users.visitor").toUpperCase(),
        },
        {
          value: "button",
          width: 160,
          align: "center",
        },
        {
          value: "formatCreatedAt",
          title: this.$t("users.visit_time").toUpperCase(),
          width: 50,
          align: "center",
        },
      ],
      indoors: [],
      indoorsInterval: null,

      pagination: {},

      closedItems: [],
    };
  },

  methods: {
    ...mapActions({
      getIndoorsAction: "visits/getIndoors",
      closeVisitsAction: "visits/closeVisits",
      setStatePaginationAction: "stateIndoorTable/setPagination",
    }),

    //Получение состояния страницы
    getState() {
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния страницы
    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };
      this.setStatePaginationAction(this.pagination);
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getIndoors();
    },

    //Получение и обновление данных таблицы
    getIndoors() {
      let params = {
        ...this.pagination,
      };

      if (this.monitoringStateFilter.typeUser) {
        params = {
          ...params,
          type_user: this.monitoringStateFilter.typeUser,
        };
      }

      if (this.monitoringStateFilter.zoneId) {
        params = {
          ...params,
          zone_id: this.monitoringStateFilter.zoneId,
        };
      }

      if (this.monitoringStateFilter.temp_mode) {
        params = {
          ...params,
          temp: this.monitoringStateFilter.temp_mode,
        };
      }

      this.getIndoorsAction(params).then((response) => {
        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;
          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            totalItems: pagination.total_items,
          };
        }

        const list = [];
        this.indoorsState.forEach((visit) => {
          list.push(this.correctVisit(visit, false));
        });

        this.indoors = list;
        this.closedItems = [];
      });
    },
    setIndoorInterval() {
      this.indoorsInterval = setInterval(this.getIndoors, 5000);
    },
    deleteIndoorInterval() {
      clearInterval(this.indoorsInterval);
    },

    //Закрытие визитов
    closeVisit(item) {
      const params = {
        ids: [item.user_id],
      };

      this.closedItems = [...this.closedItems, item.user_id];

      this.closeVisitsAction(params).then(() => this.$emit("closeVisits"));
    },
    closeVisitAll() {
      if (!this.indoors.length) return;

      this.closedItems = this.indoors.map((indoor) => indoor.user_id);

      const params = {
        isAll: true,
      };

      this.closeVisitsAction(params).then(() => this.$emit("closeVisits"));
    },
  },

  computed: {
    ...mapState({
      indoorsState: (state) => state.visits.indoors,
      monitoringStateFilter: (state) => state.saveState.monitoring.filter,
      statePagination: (state) => state.stateIndoorTable.pagination,
    }),

    filterIndoors() {
      return this.indoors.filter(
        (indoor) => !this.closedItems.includes(indoor.user_id)
      );
    },
  },

  watch: {
    monitoringStateFilter() {
      this.pagination.page = 1;
      this.onPagination();
    },
  },

  created() {
    this.getState();
    this.getIndoors();
    this.setIndoorInterval();
  },

  unmounted() {
    this.deleteIndoorInterval();
  },
};
</script>
