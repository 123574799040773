<template>
  <div>
    <v-row>
      <v-col
        v-for="(channel, index) of channels"
        :key="channel.id || channel.key"
        cols="12"
        :md="getChannelCol(index)"
      >
        <SmartCameras
          :title="$t('devices.camera')"
          :channel="channel"
          :index="index"
          :isShowRemoveBtn="channels.length > 1"
          @changeState="updateChannels($event, index)"
          @remove-channel="removeChannel($event)"
          :deviceType="deviceType"
        />
      </v-col>

      <v-col cols="12">
        <v-btn color="primary" link @click="addChannel">{{
          $t("devices.addCamera")
        }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SmartCameras from "../SmartCameras.vue";

export default {
  props: {
    channels: {
      type: Array,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SmartCameras,
  },

  data() {
    return {
      deviceType: "AUTOMARSHAL",
    };
  },
  methods: {
    removeChannel(channel) {
      this.$emit("remove-channel", channel);
    },

    store() {
      let data = {};

      data.device_type = this.deviceType;

      this.$emit("update:modelValue", data);
    },

    updateChannels(e, index) {
      const channels = this.channels;
      channels[index] = e;

      this.$emit("change-channels", channels);
    },
    addChannel() {
      this.$emit("add-channel");
    },

    getChannelCol(index) {
      if (
        this.channels.length === index + 1 &&
        this.channels.length % 2 === 1
      ) {
        return 12;
      } else {
        return 6;
      }
    },
  },

  created() {
    if (!this.isEdit) {
      this.store();
    }
  },
};
</script>
