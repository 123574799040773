<template>
  <v-card elevation="2">
    <v-tabs show-arrows color="primary">
      <v-tab v-for="(tab, i) in tabByPermission" :key="i" :to="tab.to">
        <v-icon start>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-card-text>
      <router-view />
    </v-card-text>
  </v-card>
</template>

<script>
import permissions from "@/mixins/permissions";

export default {
  name: "DocumentsPage",

  mixins: [permissions],

  data() {
    return {
      tabs: [
        {
          name: this.$t("menu.permitRequests"),
          to: { name: this.$const.ROUTES.PERMIT_REQUESTS },
          icon: "mdi-lock-check",
          permission: this.$const.RULES.REQUEST_VIEW,
        },
      ],
    };
  },

  watch: {
    $route: {
      handler() {
        if (this.tabByPermission.length) {
          this.redirect();
        }
      },
      immediate: true,
    },
  },

  methods: {
    redirect() {
      if (this.$route.name === this.$const.ROUTES.DOCUMENTS) {
        this.$router.replace(this.tabByPermission[0].to);
      }
    },
  },

  computed: {
    tabByPermission() {
      return this.tabs.filter((tab) => this.can(tab.permission));
    },
  },
};
</script>
