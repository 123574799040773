export default {
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  set(state, { data, pagination }) {
    state.data = data;
    state.pagination = pagination;
  },

  lazySet(state, { data, pagination }) {
    state.data = [...state.data, ...data];
    state.pagination = pagination;
  },

  setLimit(state, limit) {
    localStorage.setItem("personsLimit", limit);
    state.limit = limit;
  },

  setLoadingCheckPhone(state, { type, payload = "" }) {
    state.isPendingCheckPhone = type === "IS_PENDING";
    state.isSuccessCheckPhone = type === "IS_SUCCESS";
    state.isFailureCheckPhone = type === "IS_FAILURE";
    state.errorMessageCheckPhone = payload;
  },

  setCheckPhone(state, checkPhoneData) {
    state.checkPhoneData = checkPhoneData;
  },

  setLoadingCheckEmail(state, { type, payload = "" }) {
    state.isPendingCheckEmail = type === "IS_PENDING";
    state.isSuccessCheckEmail = type === "IS_SUCCESS";
    state.isFailureCheckEmail = type === "IS_FAILURE";
    state.errorMessageCheckEmail = payload;
  },

  setCheckEmail(state, checkEmailData) {
    state.checkEmailData = checkEmailData;
  },

  setLoadingMultiSetDepartment(state, { type, payload = "" }) {
    state.isPendingMultiSetDepartment = type === "IS_PENDING";
    state.isSuccessMultiSetDepartment = type === "IS_SUCCESS";
    state.isFailureMultiSetDepartment = type === "IS_FAILURE";
    state.errorMessageMultiSetDepartment = payload;
  },

  setLoadingMultiSetAccessLevel(state, { type, payload = "" }) {
    state.isPendingMultiSetAccessLevel = type === "IS_PENDING";
    state.isSuccessMultiSetAccessLevel = type === "IS_SUCCESS";
    state.isFailureMultiSetAccessLevel = type === "IS_FAILURE";
    state.errorMessageMultiSetAccessLevel = payload;
  },

  setLoadingDelAllPersons(state, { type, payload = "" }) {
    state.isPendingDelAllPersons = type === "IS_PENDING";
    state.isSuccessDelAllPersons = type === "IS_SUCCESS";
    state.isFailureDelAllPersons = type === "IS_FAILURE";
    state.errorMessageDelAllPersons = payload;
  },

  setLoadingPersonsPhoto(state, { type, payload = "" }) {
    state.isPendingPersonPhoto = type === "IS_PENDING";
    state.isSuccessPersonPhoto = type === "IS_SUCCESS";
    state.isFailurePersonPhoto = type === "IS_FAILURE";
    state.errorMessagePersonPhoto = payload;
  },
};
