<template>
  <v-sheet>
    <template v-if="modelValue.length">
      <v-row v-for="field in modelValue" :key="field.key">
        <v-col>
          <Card
            v-model:isEdit="field.isEdit"
            :fieldValue="field.value"
            :allFields="modelValue"
            :customFieldsType="customFieldsType"
            @cancelCard="cancelEdit(field.key)"
            @deleteCard="deleteCard(field.key)"
            @saveCard="editCard(field.key, $event)"
          />
        </v-col>
      </v-row>
    </template>
    <v-row v-if="isAdd">
      <v-col>
        <Card
          v-model:isEdit="isAddComputed"
          :isNew="true"
          :fieldValue="{}"
          :allFields="modelValue"
          :customFieldsType="customFieldsType"
          @cancelCard="cancelAdd"
          @saveCard="addCard($event)"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-btn block color="success" variant="tonal" @click="showAddCard">
          {{ $t("object-type.add-field") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import Card from "./components/Card.vue";

export default {
  components: { Card },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    customFieldsType: {
      type: String,
      default: "object",
    },

    isAdd: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    //Добавление поля
    showAddCard() {
      this.$emit("update:isAdd", true);
    },
    addCard(data) {
      const curObject = {
        isEdit: false,
        key: data.name,
        value: data,
      };
      const curArray = [...this.modelValue, curObject];

      this.$emit("update:modelValue", curArray);
      this.cancelAdd();
    },
    cancelAdd() {
      this.$emit("update:isAdd", false);
    },

    //Редактирование поля
    cancelEdit(key) {
      const curArray = this.modelValue.map((object) => {
        if (object.key == key) {
          const curObject = {
            ...object,
            isEdit: false,
          };
          return curObject;
        }

        return object;
      });

      this.$emit("update:modelValue", curArray);
    },
    editCard(key, data) {
      const curArray = this.modelValue.map((object) => {
        if (object.key == key) {
          const curObject = {
            ...object,
            key: data.name,
            isEdit: false,
            value: data,
          };
          return curObject;
        }

        return object;
      });

      this.$emit("update:modelValue", curArray);
    },

    //Удаление
    deleteCard(key) {
      const curArray = this.modelValue.filter((object) => object.key != key);

      this.$emit("update:modelValue", curArray);
    },
  },

  computed: {
    isAddComputed: {
      get() {
        return this.isAdd;
      },
      set(newValue) {
        this.$emit("update:isAdd", newValue);
      },
    },
  },
};
</script>
