export default {
  //Старое хранилище api
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  setList(state, { data, pagination }) {
    state.list = data;
    state.pagination = pagination;
  },

  setTree(state, payload) {
    state.tree = payload || [];
  },

  setLimit(state, limit) {
    localStorage.setItem("accessLevelsLimit", limit);
    state.limit = limit;
  },

  //Новое хранилище api
  setLoadingGetAccessLevelsList(state, { type, payload = "" }) {
    state.isPendingGetAccessLevelsList = type === "IS_PENDING";
    state.isSuccessGetAccessLevelsList = type === "IS_SUCCESS";
    state.isFailureGetAccessLevelsList = type === "IS_FAILURE";
    state.errorMessageGetAccessLevelsList = payload;
  },

  setAccessLevelsList(state, payload) {
    state.accessLevelsList = payload;
  },

  setLoadingGetAccessLevelsTree(state, { type, payload = "" }) {
    state.isPendingGetAccessLevelsTree = type === "IS_PENDING";
    state.isSuccessGetAccessLevelsTree = type === "IS_SUCCESS";
    state.isFailureGetAccessLevelsTree = type === "IS_FAILURE";
    state.errorMessageGetAccessLevelsTree = payload;
  },

  setAccessLevelsTree(state, payload) {
    state.accessLevelsTree = payload;
  },
};
