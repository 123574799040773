import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useVuelidate(),
      rules: [],
    };
  },

  validations() {
    return {
      rules: {
        required,
        minLength: minLength(1),
      },
    };
  },
};
