<template>
  <v-col>
    <v-tabs v-model="settingTab" background-color="grey lighten-3 mb-5">
      <v-tab>{{ $t("devices.mainParameters") }}</v-tab>
      <v-tab>{{ $t("devices.recognitionParameters") }}</v-tab>
      <v-tab>{{ $t("devices.recognitionSettings") }}</v-tab>
      <v-tab>{{ $t("devices.other") }}</v-tab>
      <v-tab>{{ $t("devices.hard") }}</v-tab>
    </v-tabs>

    <v-window v-model="settingTab">
      <!-- Main -->
      <v-window-item>
        <v-row>
          <v-col cols="12">
            <v-subheader>{{ $t("devices.delayTimeForCloseDoor") }}</v-subheader>
            <v-slider
              v-model="delayTimeForCloseDoorComputed"
              class="align-center"
              max="25"
              min="0"
              step="1"
              hide-details
              density="comfortable"
              color="primary"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="delayTimeForCloseDoorComputed"
                  hide-details
                  max="25"
                  min="0"
                  step="1"
                  type="number"
                  style="width: 100px"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="identifyDistance"
              :items="identifyDistanceOptions"
              :label="$t('devices.identifyDistance')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              hide-details
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="recDisplayImageMode"
              :items="recDisplayImageModeOptions"
              :label="$t('devices.recDisplayImageMode')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              hide-details
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-switch
              v-model="recRank"
              :label="$t('devices.recRank')"
              hide-details
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-switch
              v-model="sensorMode"
              :label="$t('devices.sensorMode')"
              hide-details
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-switch
              v-model="isMaskOpen"
              :label="$t('devices.isMaskOpen')"
              hide-details
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col v-if="sensorMode" cols="12" md="6">
            <v-select
              v-model="sensorModeType"
              :items="sensorModeTypeOptions"
              :label="$t('devices.sensorModeType')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              hide-details
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-switch
              v-model="isVoiceOpen"
              :label="$t('devices.isVoiceOpen')"
              hide-details
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-switch
              v-model="asReader"
              :label="$t('devices.readerMode')"
              hide-details
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>
        </v-row>

        <!--h4 class="mt-5">{{ $t("devices.savePhoto") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <v-switch
                  v-model="recSucSaveSpotImage"
                  :label="$t('devices.recSucSaveSpotImage')"
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-switch
                  v-model="recFailSaveSpotImage"
                  :label="$t('devices.recFailSaveSpotImage')"
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-switch
                  v-model="recNoPerSaveSpotImage"
                  :label="$t('devices.recNoPerSaveSpotImage')"
                  @change="inputValue"
                />
              </v-col>
            </v-row-->
      </v-window-item>

      <!-- Recognition -->
      <v-window-item>
        <v-row>
          <v-col cols="12" md="4">
            <v-switch
              v-model="recModeFaceEnable"
              :label="$t('devices.recModeFaceEnable')"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-switch
              v-model="recModeCardEnable"
              :label="$t('devices.recModeCardEnable')"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-switch
              v-model="recModeCardFaceEnable"
              :label="$t('devices.recModeCardFaceEnable')"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-slider
              v-model="identifyScores"
              class="align-center"
              max="100"
              min="50"
              :label="$t('devices.identifyScores')"
              hide-details
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="identifyScores"
                  hide-details
                  max="100"
                  min="50"
                  step="1"
                  type="number"
                  style="width: 100px"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  @update:modelValue="inputValue"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="multiplayerDetection"
              :items="multiplayerDetectionOptions"
              :label="$t('devices.multiplayerDetection')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>
        </v-row>
      </v-window-item>

      <!-- Settings -->
      <v-window-item>
        <v-row>
          <v-col cols="12" md="6">
            <h4 class="mb-5">{{ $t("devices.onSucRec") }}</h4>
            <v-select
              v-model="voiceType"
              :items="voiceTypeOptions"
              :label="$t('devices.voiceType')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-select
              v-model="comModType"
              :items="comModTypeOptions"
              :label="$t('devices.comModType')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-select
              v-model="recSucWiegandType"
              :items="recWiegandTypeOptions"
              :label="$t('devices.recSucWiegandType')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
            <div v-if="recSucWiegandType != 1">
              <v-select
                v-model="recSucWiegandContentType"
                :items="recSucWiegandContentTypeOptions"
                :label="$t('devices.recSucWiegandContent')"
                item-title="text"
                item-value="value"
                variant="outlined"
                density="comfortable"
                color="primary"
                @update:modelValue="onChangeRecSucWiegandContentType"
              />
              <v-text-field
                v-if="recSucWiegandContentType === 'custom'"
                v-model="recSucWiegandContent"
                :label="$t('devices.custom')"
                variant="outlined"
                density="comfortable"
                color="primary"
                :rules="recSucWiegandContentRules"
                @update:modelValue="inputValue"
              />
            </div>
            <v-select
              v-model="isOpenRelay"
              :items="relayOptions"
              :label="$t('devices.isOpenRelay')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <h4 class="mb-5">{{ $t("devices.onFailRec") }}</h4>
            <!--v-switch
                  v-model="recStrangerType"
                  :label="$t('devices.recStrangerType')"
                  @change="inputValue"
                /-->

            <v-select
              v-model="relaySwitch"
              :items="relayOptions"
              :label="$t('devices.relaySwitch')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            {{ $t("devices.recStrangerTimesThreshold") }}
            <v-slider
              v-model="recStrangerTimesThreshold"
              class="align-center"
              max="20"
              min="1"
              hide-details
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="recStrangerTimesThreshold"
                  hide-details
                  max="20"
                  min="1"
                  step="1"
                  type="number"
                  style="width: 100px"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  @update:modelValue="inputValue"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>
      </v-window-item>

      <!-- Other -->
      <v-window-item>
        <v-row>
          <v-col>
            <v-text-field
              v-model="companyName"
              :label="$t('devices.companyName')"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-select
              v-model="wgInputType"
              :items="wgInputTypeOptions"
              :label="$t('devices.wgInputType')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-select
              v-model="serialOutMode"
              :items="serialOutModeOptions"
              :label="$t('devices.serialOutMode')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-select
              v-model="recModeCardIntf"
              :items="recModeCardIntfOptions"
              :label="$t('devices.recModeCardIntf')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-select
              v-model="recModeCardFaceIntf"
              :items="recModeCardIntfOptions"
              :label="$t('devices.recModeCardFaceIntf')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-select
              v-model="recFailWiegandType"
              :items="recWiegandTypeOptions"
              :label="$t('devices.recFailWiegandType')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-select
              v-model="recNoPerWiegandType"
              :items="recWiegandTypeOptions"
              :label="$t('devices.recNoPerWiegandType')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-switch
              v-model="recModeCardPhoto"
              :label="$t('devices.recModeCardPhoto')"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />

            <v-switch
              v-model="uniquenessRegImage"
              :label="$t('devices.uniquenessRegImage')"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>
        </v-row>
      </v-window-item>

      <!-- System -->
      <v-window-item>
        <v-row>
          <v-col cols="12" md="6">
            {{ $t("devices.audioVolume") }}
            <v-slider
              v-model="audioVolume"
              class="align-center"
              max="100"
              min="1"
              hide-details
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="audioVolume"
                  hide-details
                  max="100"
                  min="1"
                  step="1"
                  type="number"
                  style="width: 100px"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  @update:modelValue="inputValue"
                />
              </template>
            </v-slider>
          </v-col>

          <v-col cols="12" md="6">
            {{ $t("devices.lightBrightness") }}
            <v-slider
              v-model="lightBrightness"
              class="align-center"
              max="100"
              min="1"
              hide-detailsdensity="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="lightBrightness"
                  hide-details
                  max="100"
                  min="1"
                  step="1"
                  type="number"
                  style="width: 100px"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  @update:modelValue="inputValue"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-switch
              v-model="antiTamper"
              :label="$t('devices.antiTamper')"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="language"
              :items="languageOptions"
              :label="$t('setting.lang')"
              item-title="text"
              item-value="value"
              variant="outlined"
              density="comfortable"
              color="primary"
              hide-details
              @update:modelValue="inputValue"
            />
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
  </v-col>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      settingTab: null,

      language: this.modelValue?.language || "ru",

      // Main
      asReader: this.modelValue?.as_reader,
      recRank: this.modelValue?.config?.recRank === 1 ? false : true,
      identifyDistance: this.modelValue?.config?.identifyDistance || 2,
      delayTimeForCloseDoor: this.modelValue?.config?.delayTimeForCloseDoor
        ? parseInt(this.modelValue.config.delayTimeForCloseDoor)
        : 5000,
      recDisplayImageMode: this.modelValue?.config?.recDisplayImageMode || 1,
      isMaskOpen: this.modelValue?.config?.mask?.isMaskOpen || false,
      isVoiceOpen: this.modelValue?.config?.mask?.isVoiceOpen || false,
      sensorMode: this.modelValue?.sensor_mode || false,
      sensorModeType: this.modelValue?.sensor_mode_type || "normalOpen",
      // recFailSaveSpotImage: this.formatToBoolean(
      //   this.value?.config?.recFailSaveSpotImage
      // ),
      // recNoPerSaveSpotImage: this.formatToBoolean(
      //   this.value?.config?.recNoPerSaveSpotImage
      // ),
      // recSucSaveSpotImage: this.formatToBoolean(
      //   this.value?.config?.recSucSaveSpotImage
      // ),

      // Recognition
      recModeFaceEnable: this.formatToBoolean(
        this.modelValue?.config?.recModeFaceEnable
      ),
      recModeCardEnable: this.formatToBoolean(
        this.modelValue?.config?.recModeCardEnable
      ),
      recModeCardFaceEnable: this.formatToBoolean(
        this.modelValue?.config?.recModeCardFaceEnable
      ),

      identifyScores: this.modelValue?.config?.identifyScores || 80,
      multiplayerDetection: this.modelValue?.config?.multiplayerDetection || 1,

      // Settings
      voiceType: this.modelValue?.config?.voiceType || 1,
      recSucDisplayText2Content:
        this.modelValue?.config?.recSucDisplayText2Content || "",
      isOpenRelay: this.modelValue?.config?.isOpenRelay || 1,
      recSucWiegandType: this.modelValue?.config?.recSucWiegandType || 1,
      comModType: this.modelValue?.config?.comModType || 1,
      //recStrangerType: this.formatToBoolean(this.value?.config?.recStrangerType),
      recStrangerTimesThreshold:
        this.modelValue?.config?.recStrangerTimesThreshold || 3,
      relaySwitch: this.modelValue.config?.relaySwitch || 2,
      recSucWiegandContentType:
        this.modelValue?.config?.recSucWiegandContent === "{idcardNum}"
          ? "device"
          : "custom",
      recSucWiegandContentTypeOptions: [
        {
          text: this.$t("devices.valueFromDevice"),
          value: "device",
        },
        {
          text: this.$t("devices.custom"),
          value: "custom",
        },
      ],
      recSucWiegandContent: this.modelValue?.config?.recSucWiegandContent,

      // Other
      companyName: this.modelValue?.config?.companyName || "",
      wgInputType: this.modelValue?.config?.wgInputType || 1,
      serialOutMode: this.modelValue?.config?.serialOutMode || 1,
      recModeCardIntf: this.modelValue?.config?.recModeCardIntf || 1,
      recModeCardFaceIntf: this.modelValue?.config?.recModeCardFaceIntf || 1,
      recModeCardPhoto: this.formatToBoolean(
        this.modelValue?.config?.recModeCardPhoto
      ),
      recNoPerRelayType: 2,
      recFailWiegandType: this.modelValue?.config?.recFailWiegandType || 1,
      uniquenessRegImage: this.formatToBoolean(
        this.modelValue?.config?.uniquenessRegImage
      ),
      recNoPerWiegandType: this.modelValue?.config?.recNoPerWiegandType || 1,

      // System
      audioVolume: this.modelValue?.hard_config?.audioVolume || 1,
      lightBrightness: this.modelValue?.hard_config?.lightBrightness || 100,
      antiTamper: this.formatToBoolean(
        this.modelValue?.hard_config?.antiTamper || 2
      ),

      // Options
      // Main
      languageOptions: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Русский",
          value: "ru",
        },
      ],

      identifyDistanceOptions: [
        {
          text: this.$t("devices.noLimit"),
          value: 0,
        },
        {
          text: this.$t("devices.within05m"),
          value: 1,
        },
        {
          text: this.$t("devices.within1m"),
          value: 2,
        },
        {
          text: this.$t("devices.within15m"),
          value: 3,
        },
        {
          text: this.$t("devices.within2m"),
          value: 4,
        },
        {
          text: this.$t("devices.within3m"),
          value: 5,
        },
        {
          text: this.$t("devices.within4m"),
          value: 6,
        },
      ],
      recDisplayImageModeOptions: [
        {
          text: this.$t("devices.displayTakePhoto"),
          value: 1,
        },
        {
          text: this.$t("devices.displaySavePhoto"),
          value: 2,
        },
        {
          text: this.$t("devices.notDisplayPhoto"),
          value: 3,
        },
      ],

      // Recognition
      multiplayerDetectionOptions: [
        {
          text: this.$t("devices.multiPeople"),
          value: 1,
        },
        {
          text: this.$t("devices.singlePerson"),
          value: 2,
        },
      ],

      // Settings
      voiceTypeOptions: [
        {
          text: this.$t("devices.maleVoice"),
          value: 1,
        },
        {
          text: this.$t("devices.femaleVoice"),
          value: 2,
        },
      ],
      recWiegandTypeOptions: [
        {
          text: this.$t("devices.notOutput"),
          value: 1,
        },
        {
          text: "Wiegand 26",
          value: 2,
        },
        {
          text: "Wiegand 34",
          value: 3,
        },
        {
          text: "Wiegand 50",
          value: 4,
        },
        {
          text: "Wiegand 66",
          value: 5,
        },
      ],
      comModTypeOptions: [
        {
          text: this.$t("devices.openTheDoor"),
          value: 1,
        },
        {
          text: this.$t("devices.notOutput"),
          value: 2,
        },
        {
          text: this.$t("devices.outputPersonId"),
          value: 3,
        },
        {
          text: this.$t("devices.outputIdCardNum"),
          value: 4,
        },
        {
          text: this.$t("devices.outputPhone"),
          value: 5,
        },
      ],

      // Other
      relayOptions: [
        {
          text: this.$t("devices.output"),
          value: 1,
        },
        {
          text: this.$t("devices.notOutput"),
          value: 2,
        },
      ],
      wgInputTypeOptions: [
        {
          text: "wg26 format 1",
          value: 1,
        },
        {
          text: "wg26 format 2",
          value: 2,
        },
        {
          text: "wg34",
          value: 3,
        },
        {
          text: "wg50",
          value: 4,
        },
        {
          text: "wg66",
          value: 5,
        },
      ],
      serialOutModeOptions: [
        {
          text: "Open the door",
          value: 1,
        },
        {
          text: "Custom",
          value: 2,
        },
        {
          text: "Close",
          value: 3,
        },
      ],
      recModeCardIntfOptions: [
        {
          text: this.$t("devices.ttlSerialPort"),
          value: 1,
        },
        {
          text: this.$t("devices.serialPort232"),
          value: 2,
        },
        {
          text: this.$t("devices.usbSerialPort"),
          value: 3,
        },
        {
          text: this.$t("devices.i2cSerialPort"),
          value: 4,
        },
        {
          text: this.$t("devices.wiegandInput"),
          value: 5,
        },
      ],
      recSucWiegandContentRules: [
        (v) => {
          if (v && v.length <= 12) {
            return /^\d+$/.test(v) || this.$t("message.invalidValue");
          } else {
            return /^[a-fA-F0-9]*$/.test(v) || this.$t("message.invalidValue");
          }
        },
      ],

      sensorModeTypeOptions: [
        {
          text: this.$t("devices.normalOpen"),
          value: "normalOpen",
        },
        {
          text: this.$t("devices.normalClose"),
          value: "normalClose",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),

    delayTimeForCloseDoorComputed: {
      get() {
        const curValue = parseInt(this.delayTimeForCloseDoor);
        if (curValue < 1000) {
          return 0;
        }

        return curValue / 1000;
      },
      set(newValue) {
        const curNewValue = parseInt(newValue);

        if (curNewValue < 1) {
          this.delayTimeForCloseDoor = 500;
          this.inputValue();
          return;
        }

        this.delayTimeForCloseDoor = curNewValue * 1000;
        this.inputValue();
      },
    },
  },

  methods: {
    inputValue() {
      const {
        language,
        // Main
        asReader,
        recRank,
        sensorMode,
        sensorModeType,
        identifyDistance,
        delayTimeForCloseDoor,
        recDisplayImageMode,
        isMaskOpen,
        isVoiceOpen,
        //recFailSaveSpotImage,
        //recSucSaveSpotImage,
        //recNoPerSaveSpotImage,

        // Recognition
        recModeFaceEnable,
        recModeCardEnable,
        recModeCardFaceEnable,

        identifyScores,
        multiplayerDetection,

        // Settings
        voiceType,
        recSucDisplayText2Content,
        isOpenRelay,
        recSucWiegandType,
        recSucWiegandContent,
        comModType,
        //recStrangerType,
        recStrangerTimesThreshold,
        relaySwitch,

        // Other
        companyName,
        wgInputType,
        serialOutMode,
        recModeCardIntf,
        recModeCardFaceIntf,
        recModeCardPhoto,
        recNoPerRelayType,
        recFailWiegandType,
        uniquenessRegImage,
        recNoPerWiegandType,

        // System
        audioVolume,
        lightBrightness,
        antiTamper,
      } = this;

      const data = {
        language,
        as_reader: asReader,
        sensor_mode: sensorMode,
        sensor_mode_type: sensorModeType,
        hard_config: {
          audioVolume,
          lightBrightness,
          antiTamper: this.formatFromNumToBoolean(antiTamper),
        },
        config: {
          // Main
          recRank: this.formatFromNumToBoolean(recRank),
          identifyDistance,
          delayTimeForCloseDoor: parseInt(delayTimeForCloseDoor),
          recDisplayImageMode,
          mask: {
            isMaskOpen: isMaskOpen,
            isVoiceOpen: isVoiceOpen,
          },
          //recFailSaveSpotImage:
          //  this.formatFromNumToBoolean(recFailSaveSpotImage),
          //recSucSaveSpotImage: this.formatFromNumToBoolean(recSucSaveSpotImage),
          // recNoPerSaveSpotImage: this.formatFromNumToBoolean(
          //   recNoPerSaveSpotImage
          // ),

          // Recognition
          recModeFaceEnable: this.formatFromNumToBoolean(recModeFaceEnable),
          recModeCardEnable: this.formatFromNumToBoolean(recModeCardEnable),
          recModeCardFaceEnable: this.formatFromNumToBoolean(
            recModeCardFaceEnable
          ),
          identifyScores,
          multiplayerDetection,

          // Settings
          voiceType,
          recSucDisplayText2Content,
          isOpenRelay,
          recSucWiegandType,
          recSucWiegandContent,
          comModType,
          recStrangerType: 2,
          recStrangerTimesThreshold,
          relaySwitch,

          // Other
          companyName,
          wgInputType,
          serialOutMode,
          recModeCardIntf,
          recModeCardFaceIntf,
          recModeCardPhoto: this.formatFromNumToBoolean(recModeCardPhoto),
          recNoPerRelayType,
          recFailWiegandType,
          uniquenessRegImage: this.formatFromNumToBoolean(uniquenessRegImage),
          recNoPerWiegandType,
        },
      };

      this.$emit("update:modelValue", data);
    },

    formatToBoolean(prop) {
      switch (prop) {
        case 1:
          return false;
        case 2:
          return true;
        default:
          return false;
      }
    },

    formatFromNumToBoolean(prop) {
      switch (prop) {
        case false:
          return 1;
        case true:
          return 2;
        default:
          return 1;
      }
    },
    onChangeRecSucWiegandContentType(type) {
      switch (type) {
        case "device":
          this.recSucWiegandContent = "{idcardNum}";
          break;
        case "custom":
          this.recSucWiegandContent = "";
          break;
        default:
          this.recSucWiegandContent = "";
          break;
      }
      this.inputValue();
    },
  },
};
</script>
