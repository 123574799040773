<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col cols="auto">
          {{ curProfileType?.name }}
        </v-col>
        <v-spacer />
        <v-col v-if="showDeleteProfile" cols="auto">
          <v-btn
            :ripple="false"
            color="red"
            variant="text"
            density="comfortable"
            icon="mdi-delete-outline"
            location="top"
            @click.stop="onRemoveProfile(modelValue.profile_types_id)"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <label>{{ $t("user.department") }}</label>
          <el-tree-select
            v-model="departmentComputed"
            :data="departments"
            check-on-click-node
            node-key="id"
            size="large"
            :props="{ label: 'name', children: 'children' }"
            placeholder=""
            show-checkbox
            check-strictly
            filterable
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CustomFields
            v-model="customFieldsComputed"
            :fields="curProfileType?.fields"
            :show="false"
          /> </v-col
      ></v-row>
      <v-row>
        <v-col>
          <v-switch
            v-model="useDateRangeComputed"
            density="compact"
            color="primary"
            hide-details
          >
            <template #label>
              {{ this.$t("profile.durationTime") }}
              <v-tooltip location="top">
                <template #activator="{ props }">
                  <v-icon class="ml-2" v-bind="props" size="small"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span>{{ $t("profile.mesTimeZoneWarn") }}</span>
              </v-tooltip>
            </template>
          </v-switch>
        </v-col>
      </v-row>
      <v-row v-if="useDateRangeComputed">
        <v-col>
          <DateRange
            v-model="dateRangeComputed"
            :settings="{ size: 'large', clearable: true, md: 6 }"
          />
        </v-col>
      </v-row>

      <v-row v-if="showPermitList">
        <v-col>
          <PermitList
            :data="modelValue.permits"
            :showAddPermit="showAddPermit"
            :showEdit="showEditPermit"
            :showDelete="showDeletePermit"
            @updatePermits="onChangePermits"
            @editedStatus="onChangePermitsEdited"
          />
          <v-checkbox
            v-model="isPermitsEdited"
            style="display: none !important"
            :rules="[(v) => !v || 'Error']"
          >
          </v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { CustomFields } from "@/components/ObjectsTools";
import DateRange from "@/components/DateRange";
import PermitList from "@/components/Permits";

export default {
  emits: ["removeProfile", "update:modelValue", "changePermitsEdited"],

  components: { CustomFields, DateRange, PermitList },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    profileTypes: {
      type: Array,
      default: () => [],
    },

    //Настройки департаментов
    departments: {
      type: Array,
      default: () => [],
    },

    /* <-- Profile --> */

    showDeleteProfile: {
      type: Boolean,
      default: true,
    },

    /* <-- Permits --> */

    showPermitList: {
      type: Boolean,
      default: true,
    },

    showAddPermit: {
      type: Boolean,
      default: true,
    },

    showEditPermit: {
      type: Boolean,
      default: true,
    },

    showDeletePermit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      activeDateRange: undefined,
      isPermitsEdited: false,
    };
  },

  methods: {
    onChangePermits(permits) {
      const params = {
        ...this.modelValue,
        permits: permits,
      };

      this.$emit("update:modelValue", params);
    },

    onRemoveProfile(profileTypeId) {
      this.$emit("removeProfile", profileTypeId);
    },

    onChangePermitsEdited(event) {
      this.isPermitsEdited = event;
      this.$emit("changePermitsEdited", event);
    },
  },

  computed: {
    departmentComputed: {
      get() {
        return this.modelValue.departments_id;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          departments_id: newValue,
        };

        this.$emit("update:modelValue", params);
      },
    },

    customFieldsComputed: {
      get() {
        return this.modelValue.values;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          values: newValue,
        };

        this.$emit("update:modelValue", params);
      },
    },

    useDateRangeComputed: {
      get() {
        return (
          this.activeDateRange ??
          (!!this.modelValue.start_time || !!this.modelValue.expire_time)
        );
      },
      set(newValue) {
        this.activeDateRange = newValue;

        if (!newValue) {
          const params = {
            ...this.modelValue,
            start_time: null,
            expire_time: null,
          };
          this.$emit("update:modelValue", params);
        }
      },
    },

    dateRangeComputed: {
      get() {
        return [this.modelValue.start_time, this.modelValue.expire_time];
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          start_time: newValue[0] || "",
          expire_time: newValue[1] || "",
        };

        this.$emit("update:modelValue", params);
      },
    },

    curProfileType() {
      return this.profileTypes.find(
        (type) => type.id == this.modelValue.profile_types_id
      );
    },
  },
};
</script>
