export default {
  setPageData({ commit }, data = {}) {
    localStorage.setItem("zonesStatePageData", JSON.stringify(data));
    commit("setPageData", data);
  },

  setFilter({ commit }, data = {}) {
    localStorage.setItem("zonesStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },

  setPagination({ commit }, data = {}) {
    localStorage.setItem("zonesStatePagination", JSON.stringify(data));
    commit("setPagination", data);
  },
};
