<template>
  <Alert v-if="isFailureUpdatePermitState" type="error">
    {{ errorMessageUpdatePermitState }}
  </Alert>

  <Form
    :disabled="isPendingUpdatePermitState"
    :id="id"
    @setData="setData"
    @cancel="goBack"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  components: { Form, Alert },

  data() {
    return {
      id: undefined,
    };
  },

  methods: {
    ...mapActions({
      updatePermitAction: "permits/updatePermit",
    }),

    getData() {
      this.id = this.$route.params.id;
    },

    goBack() {
      this.$router.go(-1);
    },

    setData(data) {
      const params = {
        id: this.id,
        data: data,
      };
      this.updatePermitAction(params).then(this.goBack);
    },
  },

  computed: {
    ...mapState({
      isPendingUpdatePermitState: (state) =>
        state.permits.isPendingUpdatePermit,
      isFailureUpdatePermitState: (state) =>
        state.permits.isFailureUpdatePermit,
      errorMessageUpdatePermitState: (state) =>
        state.permits.errorMessageUpdatePermit,
    }),
  },

  created() {
    this.getData();
  },
};
</script>
