<template>
  <div>
    <v-text-field
      v-model="decimalNumberComputed"
      :label="`${$t('permits.number')} *`"
      color="primary"
      density="comfortable"
      variant="outlined"
      type="number"
      append-icon="mdi-cog"
      :error-messages="errorMessages"
      hide-details="auto"
      @click:append="openGenerateNumberDialog"
    >
      <template #message="{ message }">
        <span v-html="message" />
      </template>
    </v-text-field>

    <v-dialog v-model="isOpenGenerateNumberDialog" max-width="400">
      <v-card :title="$t('permits.generateCardNumber')">
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mb-0">
              <v-col cols="6">
                <v-text-field
                  v-model="seriesComputed"
                  :label="$t('card.series')"
                  color="primary"
                  density="comfortable"
                  variant="outlined"
                  type="number"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="numberComputed"
                  :label="$t('card.number')"
                  color="primary"
                  density="comfortable"
                  variant="outlined"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="bolHexComputed"
              class="mb-3"
              :label="$t('card.hex_code') + ' ORION'"
              type="text"
              color="primary"
              density="comfortable"
              variant="outlined"
            />
            <v-text-field
              v-model="hexComputed"
              class="mb-3"
              :label="$t('card.hex_code')"
              type="text"
              color="primary"
              density="comfortable"
              variant="outlined"
              autofocus
            />
            <v-switch
              color="primary"
              v-model="isCardReaderMode"
              :label="$t('card.cardReaderMode')"
              :disabled="!devices.length"
              :hint="!devices.length ? $t('card.noCardReaderDevices') : ''"
              persistent-hint
            />

            <template v-if="isCardReaderMode">
              <v-select
                color="primary"
                density="comfortable"
                variant="outlined"
                v-model="selectedDevice"
                :items="devices"
                :item-title="getItemText"
                item-value="id"
                :label="$t('card.cardReaderDevice')"
              />
            </template>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red"
            variant="text"
            @click="isOpenGenerateNumberDialog = false"
          >
            <v-icon start>mdi-close</v-icon>
            {{ $t("button.cancel") }}
          </v-btn>

          <v-btn color="success" variant="text" @click="accept">
            <v-icon start>mdi-check</v-icon>
            {{ $t("button.apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import crc8 from "@/plugins/crc8";
import { isHexadecimal, isInt } from "validator";
import { mapActions, mapState } from "vuex";

export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      valid: true,

      isOpenGenerateNumberDialog: false,

      cardSeries: "",
      cardNumber: "",
      cardHexCodeBol: "",
      cardHexCode: "",

      isCardReaderMode: false,
      devices: [],
      selectedDevice: null,
    };
  },

  methods: {
    ...mapActions({
      getDevicesAction: "devices/get",
    }),

    accept() {
      this.isOpenGenerateNumberDialog = false;
    },

    createWebSockets() {
      this.$emitter.on("cardreader", (item) => {
        if (this.isCardReaderMode && this.selectedDevice == item.id) {
          const curNewValue = this.translateHex(item.cardNum);

          this.cardHexCode = curNewValue;
          this.cardHexCodeBol = this.genHexCodeBol(curNewValue);
          this.cardSeries = this.genSeries(curNewValue);
          this.cardNumber = this.genNumber(curNewValue);

          this.$emit("update:modelValue", this.hexToDecimal(curNewValue));

          this.isCardReaderMode = false;
          this.selectedDevice = null;
        }
      });
    },

    destroyWebSockets() {
      this.$emitter.off("cardreader");
    },

    getItemText(item) {
      let text = item.ip_address;

      if (item.name) {
        text += ` (${item.name})`;
      }

      return text;
    },
    getDevices() {
      this.getDevicesAction().then(() => {
        const devices = this.getDevicesWithCardReader(this.devicesState);
        this.devices = devices;
        if (devices.length) {
          this.selectedDevice = devices[0]?.id;
        }
      });
    },

    getDevicesWithCardReader(devices) {
      return devices.filter((device) => {
        const data = JSON.parse(device.data);

        if (data.card_reader) {
          return true;
        }

        return false;
      });
    },

    translateHex(hex) {
      let newHex = hex.toUpperCase();

      const regex = new RegExp(/^[A-F0-9\\-]+$/);

      newHex = newHex
        .split("")
        .map((char) => {
          switch (char) {
            case "Ф":
              return "A";
            case "И":
              return "B";
            case "С":
              return "C";
            case "В":
              return "D";
            case "У":
              return "E";
            case "А":
              return "F";
            default:
              if (regex.test(char)) {
                return char;
              }
              return;
          }
        })
        .join("");

      return newHex;
    },

    openGenerateNumberDialog() {
      const curNewValue = this.modelValue; // ? this.modelValue : "0";

      const curHex = this.genHexCode(curNewValue);

      this.cardHexCode = curHex;
      this.cardHexCodeBol = this.genHexCodeBol(curHex);
      this.cardSeries = this.genSeries(curHex);
      this.cardNumber = this.genNumber(curHex);

      this.isOpenGenerateNumberDialog = true;
    },

    genHexCode(value) {
      if (!value) return "";

      let hex = BigInt(value, 10).toString(16);

      return hex.toUpperCase();
    },

    genHexCodeBol(hex) {
      if (!hex) return "";
      let curHex = hex;

      if (hex.length > 12) {
        curHex = hex.substr(hex.length - 12, 12);
      }

      let hh = curHex.padStart(12, "0");
      let b = [];
      let j = 0;
      hh = hh + "01";
      for (var i = 6; i >= 0; i--) {
        b[j] = parseInt(hh.substr(i * 2, 2), 16);
        j++;
      }
      hh = crc8(b).toString(16).padStart(2, "0") + hh;
      return hh.toUpperCase();
    },

    genNumber(hex) {
      if (!hex) return "";

      const curNumber = hex.substr(hex.length - 4, 4);
      return parseInt(curNumber, 16).toString();
    },

    genSeries(hex) {
      if (!hex) return "";
      const curNumber = hex.substr(0, hex.length - 4);

      return parseInt(curNumber, 16).toString();
    },

    hexToDecimal(hex) {
      return parseInt(hex, 16).toString();
    },

    bolHexToDecimal(bolHex) {
      let hx = bolHex.substr(2, 12);

      if (!hx) return "";

      return BigInt("0x" + hx).toString(10);
    },

    serialNumberToHex(serial, number) {
      const hexSerial = this.genHexCode(serial);
      let hexNumber = this.genHexCode(number);
      hexNumber = hexNumber.substr(hexNumber.length - 4, 4);
      hexNumber = hexNumber.padStart(4, "0");

      return hexSerial + hexNumber;
    },
  },

  computed: {
    ...mapState({
      devicesState: (state) => state.devices.data,
    }),

    decimalNumberComputed: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        const curNewValue = newValue;

        const curHex = this.genHexCode(curNewValue);

        this.cardHexCode = curHex;
        this.cardHexCodeBol = this.genHexCodeBol(curHex);
        this.cardSeries = this.genSeries(curHex);
        this.cardNumber = this.genNumber(curHex);

        this.$emit("update:modelValue", curNewValue);
      },
    },

    hexComputed: {
      get() {
        return this.cardHexCode;
      },
      set(newValue) {
        const curNewValue = this.translateHex(newValue);

        this.cardHexCode = curNewValue;
        this.cardHexCodeBol = this.genHexCodeBol(curNewValue);
        this.cardSeries = this.genSeries(curNewValue);
        this.cardNumber = this.genNumber(curNewValue);

        this.$emit("update:modelValue", this.hexToDecimal(curNewValue));
      },
    },

    bolHexComputed: {
      get() {
        return this.cardHexCodeBol;
      },
      set(newValue) {
        const curNewValue = this.translateHex(newValue);

        const curDecimal = this.bolHexToDecimal(curNewValue);
        const curHex = this.genHexCode(curDecimal);

        this.cardHexCodeBol = curNewValue;
        this.cardHexCode = curHex;
        this.cardSeries = this.genSeries(curHex);
        this.cardNumber = this.genNumber(curHex);

        this.$emit("update:modelValue", curDecimal);
      },
    },

    seriesComputed: {
      get() {
        return this.cardSeries;
      },
      set(newValue) {
        const curNewValue = newValue;

        const curHex = this.serialNumberToHex(curNewValue, this.cardNumber);
        const curDecimal = this.hexToDecimal(curHex);

        this.cardSeries = curNewValue;
        this.cardHexCode = curHex;
        this.cardHexCodeBol = this.genHexCodeBol(curHex);

        this.$emit("update:modelValue", curDecimal);
      },
    },

    numberComputed: {
      get() {
        return this.cardNumber;
      },
      set(newValue) {
        const curNewValue = newValue;

        const curHex = this.serialNumberToHex(this.cardSeries, curNewValue);
        const curDecimal = this.hexToDecimal(curHex);

        this.cardNumber = curNewValue;
        this.cardHexCode = curHex;
        this.cardHexCodeBol = this.genHexCodeBol(curHex);

        this.$emit("update:modelValue", curDecimal);
      },
    },
  },

  created() {
    this.createWebSockets();
    this.getDevices();
  },
  unmounted() {
    this.destroyWebSockets();
  },
};
</script>
