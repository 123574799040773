<template>
  <v-chip
    v-if="item.purposeType === 'entrance' || item.purposeType === 'duplex'"
    prepend-icon="mdi-location-enter"
    color="success"
  >
    {{ $t("device.purpose_entrance") }}
  </v-chip>

  <v-chip
    v-if="item.purposeType === 'exit'"
    prepend-icon="mdi-location-exit"
    color="error"
  >
    {{ $t("device.purpose_exit") }}
  </v-chip>

  <v-chip
    v-if="item.purposeType === 'basiclock'"
    prepend-icon="mdi-key"
    color="warning"
  >
    {{ $t("device.purpose_basiclock") }}
  </v-chip>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
