<template>
  <v-card class="monitoring-fullscreen">
    <v-dialog
      v-if="isShowAlarmDialog"
      :model-value="true"
      max-width="700px"
      persistent
    >
      <ModalAlarmDialog :data="deviceAlarm" @close="closeAlarmDialog" />
    </v-dialog>

    <v-row v-if="curEvent" class="monitoring-fullscreen__container">
      <v-col>
        <v-row class="monitoring-fullscreen__content ma-0">
          <v-col
            cols="12"
            md="6"
            class="monitoring-fullscreen__content-img d-flex align-start align-center flex-shrink-1"
          >
            <EventCardAvatar :item="curEvent" />
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="monitoring-fullscreen__content-text d-flex flex-column justify-center align-center flex-shrink-0"
          >
            <v-row class="ma-0 flex-grow-0">
              <v-col class="pa-0 d-flex flex-column align-center">
                <EventContentFullScreen :item="curEvent" />
                <EventContentChips :item="curEvent" />
              </v-col>
            </v-row>

            <v-row class="flex-grow-0">
              <v-col
                class="monitoring-fullscreen__content-chips d-flex justify-center align-center flex-wrap"
              >
                <EventPurposeTypeFullScreen :item="curEvent" />

                <EventPassTypeFullScreen :item="curEvent" />

                <EventIdentityTypesFullScreen :item="curEvent" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row
          v-if="typeText"
          :class="[
            'monitoring-fullscreen__bottom ma-0',
            {
              'monitoring-fullscreen__bottom--error':
                this.curEvent?.pass_type === 'error',
            },
            {
              'monitoring-fullscreen__bottom--warning':
                this.curEvent?.pass_type === 'alarm',
            },
            {
              'monitoring-fullscreen__bottom--success':
                this.curEvent?.pass_type === 'pass' ||
                this.curEvent?.pass_type === 'before',
            },
            {
              'monitoring-fullscreen__bottom--black': this.curEvent?.Blocked,
            },
          ]"
        >
          <v-col>
            {{ typeText }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div v-else class="w-100 h-100 d-flex justify-center align-center">
      <v-alert
        color="blue"
        max-height="100"
        max-width="400"
        :title="$t('monitoring.nextEvent')"
      />
    </div>
  </v-card>
</template>

<script>
import correctVisit from "@/mixins/correctVisit";
import {
  EventPassTypeFullScreen,
  EventIdentityTypesFullScreen,
  EventCardAvatar,
  EventContentFullScreen,
  EventContentChips,
  EventPurposeTypeFullScreen,
} from "@/components/EventsTools";

import { mapState, mapGetters, mapActions } from "vuex";
import ModalAlarmDialog from "@/components/ModalAlarmDialog";

import permissions from "@/mixins/permissions";

export default {
  mixins: [permissions, correctVisit],

  components: {
    ModalAlarmDialog,
    EventCardAvatar,
    EventIdentityTypesFullScreen,
    EventContentFullScreen,
    EventPassTypeFullScreen,
    EventContentChips,
    EventPurposeTypeFullScreen,
  },

  data() {
    return {
      curEvent: {},

      isShowAlarmDialog: false,
      deviceAlarm: null,
    };
  },

  methods: {
    ...mapActions({
      getEventLogsAction: "visits/getEventLogs",
    }),

    onGetVisit(visit, isFirst = false) {
      const data = visit.event;

      if (
        (!this.role.allowed_zones.length ||
          this.role.allowed_zones.includes(parseInt(data.zone_id))) &&
        (isFirst ||
          this.$moment(visit.created_at).isAfter(
            this.$moment(this.curEvent.created_at)
          ))
      ) {
        this.curEvent = visit.event;
      }
    },

    onGetNewVisit(visit) {
      const correctVisit = this.correctVisit(visit, false);

      const isZonesIncludeUser =
        !this.$auth.user().zones ||
        this.$auth.user().zones.includes(parseInt(correctVisit.zone_id));

      const isRoleAllowed =
        !this.role.allowed_zones.length ||
        this.role.allowed_zones.includes(parseInt(correctVisit.zone_id));

      const isAfter =
        this.$moment(visit.created_at).isAfter(
          this.$moment(this.curEvent.created_at)
        ) ||
        (this.$moment(visit.created_at).isSame(this.curEvent.created_at) &&
          visit.id > this.curEvent.id);

      if (isZonesIncludeUser && isRoleAllowed && isAfter) {
        this.curEvent = this.correctVisit(visit);
      }
    },

    connectWebSockets() {
      this.$emitter.on(this.$const.WS_CHANNELS.NEW_EVENT, this.onGetNewVisit);
    },

    disconnectedWebSockets() {
      this.$emitter.off(this.$const.WS_CHANNELS.NEW_EVENT);
    },

    async getEventLog() {
      const params = {
        limit: 1,
      };

      await this.getEventLogsAction(params).then(() => {
        this.curEvent = this.correctVisit(this.eventLogsState[0]) || {};
      });
    },

    closeAlarmDialog() {
      this.isShowAlarmDialog = false;
      this.deviceAlarm = null;
    },

    showAlarmDialog(alarm) {
      this.deviceAlarm = alarm;
      this.isShowAlarmDialog = true;
    },
  },

  computed: {
    ...mapGetters({
      role: "user/getRole",
    }),

    ...mapState({
      settings: (state) => state.settings.data,
      eventLogsState: (state) => state.visits.eventLogs,
    }),

    typeText() {
      let text = "";

      switch (this.curEvent?.pass_type) {
        case "pass":
          text = this.$t("users.recognizedAndPass");
          break;
        case "before":
          text = this.$t("users.recognized");
          break;
        case "error":
          text = this.$t("users.error");
          break;
        case "alarm":
        case "event":
          text = this.$t("users.notRecognized");
          break;
        default:
          text = "";
          break;
      }

      if (this.curEvent?.Blocked) {
        text += ` (${this.$t("users.blocked")})`;
      } else if (this.curEvent?.Blacked) {
        text += ` (${this.$t("users.blacklist")})`;
      }

      return text;
    },
  },

  unmounted() {
    this.disconnectedWebSockets();
  },

  created() {
    this.connectWebSockets();
    this.getEventLog();
  },
};
</script>
