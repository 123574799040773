<template>
  <v-row>
    <v-col cols="12">
      <full-year-calendar-vue3
        class="custom-v-calendar"
        :initial-page="{ month: 1, year: new Date().getFullYear() }"
        :locale="$vuetify.locale.current == 'en' ? 'en-EN' : 'ru-RU'"
        :columns="4"
        :rows="3"
        :attributes="attributes"
        mode="date"
        @dayclick="onDayClick"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dayFormat: "YYYY-MM-DD",
    };
  },

  methods: {
    onDayClick(day) {
      const curDates = this.modelValue.map((day) => {
        return {
          id: this.$moment(day).format(this.dayFormat),
          date: day,
        };
      });

      const idx = curDates.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        curDates.splice(idx, 1);
      } else {
        curDates.push({
          id: day.id,
          date: day.date,
        });
      }

      this.$emit(
        "update:modelValue",
        curDates.map((curDate) => curDate.date)
      );
    },
  },

  computed: {
    attributes() {
      let hasNow = false;

      const curDates = this.modelValue.map((date) => {
        if (this.$moment(date).isSame(this.$moment().format(), "day"))
          hasNow = true;

        return {
          highlight: true,
          dates: date,
        };
      });

      if (!hasNow) {
        curDates.push({
          highlight: {
            fillMode: "outline",
          },
          dates: this.$moment().format(),
        });
      }

      return curDates;
    },
  },
};
</script>
