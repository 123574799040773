<template>
  <v-row v-if="isFailureAddObject">
    <v-col>
      <Alert type="error">
        {{ errorMessageAddObject }}
      </Alert>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card
          class="overflow-visible"
          :loading="isPendingAddObject"
          elevation="2"
        >
          <template #loader="{ isActive }">
            <v-progress-linear
              v-if="isActive"
              color="primary"
              height="10"
              indeterminate
            />
          </template>

          <v-card-text>
            <v-row>
              <v-col>
                <ObjectCard
                  v-model="object"
                  :objectTypeId="objctTypeId"
                  :showAddProfile="isAddProfile"
                  :showAddPermit="isAddPermit"
                  @changePermitsEdited="isPermitsEdited = $event"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn @click="onCancel">
              <v-icon start>mdi-cancel</v-icon>
              {{ $t("button.cancel") }}
            </v-btn>
            <v-spacer />

            <v-btn
              :disabled="isPermitsEdited"
              variant="flat"
              color="green"
              @click="onSave"
            >
              <v-icon start>mdi-content-save-outline</v-icon>
              {{ $t("button.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import ObjectCard from "@/components/ObjectsTools/ObjectCard";

import faceValidate from "@/mixins/faceValidate";
import permissions from "@/mixins/permissions";

export default {
  name: "AddPerson",

  mixins: [faceValidate, permissions],

  components: { Alert, ObjectCard },

  data() {
    return {
      valid: true,

      isPermitsEdited: false,

      object: {},
      objctTypeId: 1,
    };
  },

  methods: {
    ...mapActions({
      addObjectAction: "object/addObject",

      resetAddObjectAction: "object/resetAddObject",
    }),

    async onSave() {
      let valid = false;
      await this.$refs.form.validate().then((result) => {
        valid = result.valid;
      });
      if (!valid) return;

      const curData = {
        ...this.object,
        object_types_id: this.objctTypeId,
      };

      this.addObjectAction(curData).then(this.onCancel);
    },

    onCancel() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      isPendingAddObject: (state) => state.object.isPendingAddObject,
      isFailureAddObject: (state) => state.object.isFailureAddObject,
      errorMessageAddObject: (state) => state.object.errorMessageAddObject,
    }),

    isAddProfile() {
      return this.can(this.$const.RULES.PROFILE_ADD);
    },

    isAddPermit() {
      return this.can(this.$const.RULES.PERMIT_ADD);
    },
  },

  unmounted() {
    this.resetAddObjectAction();
  },
};
</script>
