<template>
  <v-row>
    <v-col cols="12" md="auto">
      <v-btn-toggle v-model="view" mandatory density="comfortable">
        <v-btn value="list">
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>

        <v-btn value="tree">
          <v-icon>mdi-file-tree</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-col>

    <v-spacer />

    <v-col v-if="can($const.RULES.ZONE_ADD)" cols="12" md="auto">
      <v-btn color="primary" @click="goToAdd">
        <v-icon start> mdi-plus </v-icon>
        {{ $t("departments.addNew") }}
      </v-btn>
    </v-col>
  </v-row>

  <List v-if="view == 'list'" />
  <Tree v-if="view == 'tree'" />
</template>

<script>
import permissions from "@/mixins/permissions";

import List from "./components/List";
import Tree from "./components/Tree";

export default {
  name: "Zones",

  mixins: [permissions],

  components: {
    List,
    Tree,
  },

  data() {
    return {
      view: "list",
    };
  },

  methods: {
    goToAdd() {
      this.$router.push({ name: this.$const.ROUTES.ADD_ZONE });
    },
  },
};
</script>
