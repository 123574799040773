<template>
  <v-row align="center" justify="center">
    <v-col cols="auto">
      <v-card
        color="primary"
        variant="outlined"
        class="mx-auto"
        max-width="344"
      >
        <v-card-item>
          <div>
            <div class="text-h6 mb-1 text-center">
              {{ this.$t("user.notValidForRout") }} <br />
              <div class="text-subtitle-2 text-grey-darken-4 mt-2">
                {{ this.$t("user.autoRedirect") }}
              </div>
            </div>
          </div>
        </v-card-item>

        <v-card-actions v-if="redirects.length" class="d-flex justify-center">
          <v-btn variant="outlined" color="primary" @click="startRedirect">
            {{ this.$t("button.comeBack") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import userRedirect from "../mixins/userRedirect";
export default {
  mixins: [userRedirect],
  name: "AccessDenied",

  data() {
    return {
      redirects: [],
      redirectTimeOut: null,
    };
  },

  methods: {
    startRedirect() {
      this.$router.push(this.redirects[0]);
      this.clearRedirectTimeOut();
    },
    setRedirect() {
      this.redirects = this.getRedirects();
      if (this.redirects.length) {
        this.redirectTimeOut = setTimeout(this.startRedirect, 5000);
      }
    },
    clearRedirectTimeOut() {
      if (this.redirectTimeOut) clearTimeout(this.redirectTimeOut);
    },
  },

  unmounted() {
    this.clearRedirectTimeOut();
  },

  created() {
    this.setRedirect();
  },
};
</script>
