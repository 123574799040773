import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  data: [],
};

export default {
  state,
  mutations,
  actions,
};
