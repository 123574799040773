import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  checkPass({ commit }, data) {
    commit("setLoadingCheckPass", { type: "IS_PENDING_STORE" });

    return $axios({
      method: "POST",
      url: "/auth/checkPassword",
      baseURL: "",
      data: data,
    })
      .then(() => {
        commit("setLoadingCheckPass", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          commit("setLoadingCheckPass", {
            type: "IS_FAILURE_STORE",
            payload: error?.response?.data?.status?.message || "",
          });

          $toast.error(i18n.global.t("login.passError"));
        } else {
          commit("setLoadingCheckPass", {
            type: "IS_FAILURE_STORE",
            payload: error?.response?.data?.status?.message || "",
          });

          $toast.error(i18n.global.t("message.error"));
        }
        throw error;
      });
  },
};
