<template>
  <v-row v-if="item.purpose || item.utype">
    <v-col
      class="text-center"
      :class="
        this.$vuetify.display.mdAndUp ? 'text-subtitle-1' : 'text-subtitle-2'
      "
    >
      {{ item.purpose }} {{ item.utype }}
    </v-col>
  </v-row>

  <v-row class="mt-0">
    <v-col>
      <router-link
        v-if="item.objects_id && can(this.$const.RULES.OBJECT_VIEW)"
        class="text-primary text-center"
        :class="this.$vuetify.display.mdAndUp ? 'text-h2' : 'text-h4'"
        :to="{
          name: $const.ROUTES.OBJECT_SHOW,
          params: { id: item.objects_id },
        }"
        >{{ item.title }}</router-link
      >
      <div
        v-else
        class="text-primary text-center"
        :class="this.$vuetify.display.mdAndUp ? 'text-h2' : 'text-h4'"
      >
        {{ item.title }}
      </div>
    </v-col>
  </v-row>

  <v-row>
    <v-col
      class="text-center"
      :class="this.$vuetify.display.mdAndUp ? 'text-h4' : 'text-h6'"
    >
      {{ item.formatCreatedAt }}
    </v-col>
  </v-row>
</template>
<script>
import permission from "@/mixins/permissions";
export default {
  mixins: [permission],
  props: {
    item: {
      type: Object,
      default: () => {},
    },

    name: {
      type: Object,
      default: () => {
        return {
          show: true,
          class: "text-subtitle-2",
        };
      },
    },
    purpose: {
      type: Object,
      default: () => {
        return {
          show: true,
          class: "",
          direction: {
            show: false,
            class: "",
          },
        };
      },
    },
    temperature: {
      type: Object,
      default: () => {
        return {
          show: true,
          class: "",
        };
      },
    },
    organization: {
      type: Object,
      default: () => {
        return {
          show: false,
          class: "",
        };
      },
    },
    typeUser: {
      type: Object,
      default: () => {
        return {
          show: false,
          class: "",
        };
      },
    },
    info: {
      type: Object,
      default: () => {
        return {
          show: false,
          class: "",
        };
      },
    },
    formatCreatedAt: {
      type: Object,
      default: () => {
        return {
          show: false,
          class: "",
        };
      },
    },
  },
};
</script>
