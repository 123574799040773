import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const selectedRole = window.localStorage.getItem("selectedRole") || 0;

const state = {
  isSuccess: false,

  selectedRole: parseInt(selectedRole),

  token: null,

  data: null,

  isPendingCheckPass: false,
  isSuccessCheckPass: false,
  isFailureCheckPass: false,
  errorMessageCheckPass: "",
};

export default {
  state,
  mutations,
  actions,
  getters,
};
