import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPendingAddObjectType: false,
  isSuccessAddObjectType: false,
  isFailureAddObjectType: false,
  errorMessageAddObjectType: "",

  isPendingGetObjectType: false,
  isSuccessGetObjectType: false,
  isFailureGetObjectType: false,
  errorMessageGetObjectType: "",

  objectType: {},

  isPendingUpdateObjectType: false,
  isSuccessUpdateObjectType: false,
  isFailureUpdateObjectType: false,
  errorMessageUpdateObjectType: "",
};

export default {
  state,
  mutations,
  actions,
};
