<template>
  <div class="d-flex flex-wrap ma-n1">
    <v-chip
      v-if="item.blacklisted && item.trackable_comment"
      class="ma-1"
      prepend-icon="mdi-eye-outline"
      color="primary"
    >
      {{ item.trackable_comment }}
    </v-chip>
    <v-chip
      v-if="item.Blocked && item.BlockedComment"
      class="ma-1"
      prepend-icon="mdi-cancel"
      color="error"
    >
      {{ item.BlockedComment }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
