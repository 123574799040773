export default {
  setLoadingGetPermits(state, { type, payload = "" }) {
    state.isPendingGetPermits = type === "IS_PENDING";
    state.isSuccessGetPermits = type === "IS_SUCCESS";
    state.isFailureGetPermits = type === "IS_FAILURE";
    state.errorMessageGetPermits = payload;
  },

  setPermits(state, payload) {
    state.permitsList = payload;
  },

  setLoadingGetPermit(state, { type, payload = "" }) {
    state.isPendingGetPermit = type === "IS_PENDING";
    state.isSuccessGetPermit = type === "IS_SUCCESS";
    state.isFailureGetPermit = type === "IS_FAILURE";
    state.errorMessageGetPermit = payload;
  },

  setPermit(state, payload) {
    state.permit = payload;
  },

  setLoadingUpdatePermit(state, { type, payload = "" }) {
    state.isPendingUpdatePermit = type === "IS_PENDING";
    state.isSuccessUpdatePermit = type === "IS_SUCCESS";
    state.isFailureUpdatePermit = type === "IS_FAILURE";
    state.errorMessageUpdatePermit = payload;
  },
};
