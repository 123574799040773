<template>
  <v-card class="overflow-visible" elevation="2">
    <v-tabs show-arrows color="primary">
      <v-tab v-for="(tab, i) in tabByPermission" :key="i" :to="tab.to">
        <v-icon start>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-card-text>
      <router-view />
    </v-card-text>
  </v-card>
</template>

<script>
import permissions from "@/mixins/permissions";

export default {
  name: "AdmissionPage",

  mixins: [permissions],

  data() {
    return {
      tabs: [
        {
          name: this.$t("menu.access-levels"),
          to: { name: this.$const.ROUTES.ACCESS_LEVELS },
          icon: "mdi-account-arrow-right",
          permission: this.$const.RULES.ACCESS_LEVELS_VIEW,
        },
        {
          name: this.$t("menu.access-modes"),
          to: { name: this.$const.ROUTES.ACCESS_MODES },
          icon: "mdi-lock-clock",
          permissions: this.$const.RULES.ACCESS_MODES_VIEW,
        },
      ],
    };
  },

  watch: {
    $route: {
      handler() {
        if (this.tabByPermission.length) {
          this.redirect();
        }
      },
      immediate: true,
    },
  },

  methods: {
    redirect() {
      if (this.$route.name === this.$const.ROUTES.ACCESS) {
        this.$router.replace(this.tabByPermission[0].to);
      }
    },
  },

  computed: {
    tabByPermission() {
      return this.tabs.filter((tab) => this.can(tab.permission));
    },
  },
};
</script>
