export default {
  set(state, payload) {
    state.data = payload;
  },

  setServerUrl(state, payload) {
    state.serverUrl = payload.url;
  },

  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  setLoadingServerUrl(state, { type, payload = "" }) {
    state.isPendingServerUrl = type === "IS_PENDING";
    state.isSuccessServerUrl = type === "IS_SUCCESS";
    state.isFailureServerUrl = type === "IS_FAILURE";
    state.errorMessageServerUrl = payload;
  },

  setUrl(state, url) {
    state.serverUrl = url;
  },

  setLoadingStore(state, { type, payload = "" }) {
    state.isPendingStore = type === "IS_PENDING";
    state.isSuccessStore = type === "IS_SUCCESS";
    state.isFailureStore = type === "IS_FAILURE";
    state.errorMessageStore = payload;
  },

  setLoadingResetTerminal(state, { type, payload = "" }) {
    state.isPendingResetTerminal = type === "IS_PENDING";
    state.isSuccessResetTerminal = type === "IS_SUCCESS";
    state.isFailureResetTerminal = type === "IS_FAILURE";
    state.errorMessageResetTerminal = payload;
  },

  setLoadingSettingsData(state, { type, payload = "" }) {
    state.isPendingSettingsData = type === "IS_PENDING";
    state.isSuccessSettingsData = type === "IS_SUCCESS";
    state.isFailureSettingsData = type === "IS_FAILURE";
    state.errorMessageSettingsData = payload;
  },
  setSettingsData(state, data) {
    state.settingsData = data;
  },

  setLoadingSaveSettingsData(state, { type, payload = "" }) {
    state.isPendingSaveSettingsData = type === "IS_PENDING";
    state.isSuccessSaveSettingsData = type === "IS_SUCCESS";
    state.isFailureSaveSettingsData = type === "IS_FAILURE";
    state.errorMessageSaveSettingsData = payload;
  },

  setLoadingBackup(state, { type, payload = "" }) {
    state.isPendingBackup = type === "IS_PENDING";
    state.isSuccessBackup = type === "IS_SUCCESS";
    state.isFailureBackup = type === "IS_FAILURE";
    state.errorMessageBackup = payload;
  },

  setLoadingRestore(state, { type, payload = "" }) {
    state.isPendingRestore = type === "IS_PENDING";
    state.isSuccessRestore = type === "IS_SUCCESS";
    state.isFailureRestore = type === "IS_FAILURE";
    state.errorMessageRestore = payload;
  },

  setLoadingRestoreBackup(state, { type, payload = "" }) {
    state.isPendingRestoreBackup = type === "IS_PENDING";
    state.isSuccessRestoreBackup = type === "IS_SUCCESS";
    state.isFailureRestoreBackup = type === "IS_FAILURE";
    state.errorMessageRestoreBackup = payload;
  },

  setLoadingBackupStop(state, { type, payload = "" }) {
    state.isPendingBackupStop = type === "IS_PENDING";
    state.isSuccessBackupStop = type === "IS_SUCCESS";
    state.isFailureBackupStop = type === "IS_FAILURE";
    state.errorMessageBackupStop = payload;
  },
  setLoadingDeleteBackup(state, { type, payload = "" }) {
    state.isPendingDeleteBackup = type === "IS_PENDING";
    state.isSuccessDeleteBackup = type === "IS_SUCCESS";
    state.isFailureDeleteBackup = type === "IS_FAILURE";
    state.errorMessageDeleteBackup = payload;
  },
  setLoadingDownloadBackup(state, { type, payload = "" }) {
    state.isPendingDownloadBackup = type === "IS_PENDING";
    state.isSuccessDownloadBackup = type === "IS_SUCCESS";
    state.isFailureDownloadBackup = type === "IS_FAILURE";
    state.errorMessageDownloadBackup = payload;
  },
  setLoadingBackupList(state, { type, payload = "" }) {
    state.isPendingBackupList = type === "IS_PENDING";
    state.isSuccessBackupList = type === "IS_SUCCESS";
    state.isFailureBackupList = type === "IS_FAILURE";
    state.errorMessageBackupList = payload;
  },
  setBackupList(state, payload) {
    state.backupList = payload;
  },
  setLoadingBackupStatus(state, { type, payload = "" }) {
    state.isPendingStatus = type === "IS_PENDING";
    state.isSuccessStatus = type === "IS_SUCCESS";
    state.isFailureStatus = type === "IS_FAILURE";
    state.errorMessageStatus = payload;
  },
  setLoadingCheckTerminalStatus(state, { type, payload = "" }) {
    state.isPendingCheckTerminalStatus = type === "IS_PENDING";
    state.isSuccessCheckTerminalStatus = type === "IS_SUCCESS";
    state.isFailureCheckTerminalStatus = type === "IS_FAILURE";
    state.errorMessageCheckTerminalStatus = payload;
  },
};
