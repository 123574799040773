import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  getVisits({ commit }, params) {
    commit("setLoadingVisits", { type: "IS_PENDING" });
    params.type = ["pass"];
    //params.limit=50;

    return $axios("events", { params })
      .then((response) => {
        commit("setVisits", response.data.data);
        commit("setLoadingVisits", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingVisits", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  getIndoors({ commit }, params) {
    commit("setLoadingIndoors", { type: "IS_PENDING" });
    params.type = ["visit"];

    return $axios("events", { params })
      .then((response) => {
        commit("setIndoors", response.data.data);
        commit("setIndoorsPagination", response.data.meta.pagination);
        commit("setLoadingIndoors", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingIndoors", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },

  getEventLogs({ commit }, params) {
    commit("setLoadingEventLogs", { type: "IS_PENDING" });

    return $axios("events", { params })
      .then((response) => {
        commit("setEventLogs", response.data.data);
        commit("setEventLogsPagination", response.data.meta.pagination);
        commit("setLoadingEventLogs", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingEventLogs", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },

  closeVisits({ commit }, params) {
    commit("setLoadingCloseVisits", { type: "IS_PENDING" });

    return $axios
      .put("visit/close", params)
      .then((response) => {
        commit("setLoadingCloseVisits", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingCloseVisits", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        let message = error?.response?.data?.status?.message;
        switch (message) {
          case "no permission for close visits":
            message = this.$t("users.noRightsToCloseVisitMessage");
            break;
          default:
            message = i18n.t("message.errorLoading");
            break;
        }

        $toast.error(message);
      });
  },
};
