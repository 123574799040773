export default {
  MONITORING: "monitoring",
  VISITOR_TABLE: "visitor-table",
  INDOOR_TABLE: "indoor-table",
  EVENT_LOG_TABLE: "event-log-table",
  FULLSCREEN_MONITORING: "fullscreenMonitoring",

  ANALYTICS: "analytics",
  EVENTS_JOURNAL: "events-journal",
  PASSES_JOURNAL: "passes-journal",

  SETTINGS: "settings",
  GENERAL_SETTINGS: "general-settings",
  IMPORT: "import",
  NOTIFICATIONS: "notifications",
  LOG: "log",

  EDIT_PERSONAL: "editPersonal",

  ADMISSION: "admission",

  PERSONS: "persons",
  ADD_PERSON: "person-add",
  EDIT_PERSON: "person-edit",
  SHOW_PERSON: "person-show",

  ADD_PROFILE: "profile-add",
  EDIT_PROFILE: "profile-edit",
  PROFILE_SHOW: "profile-show",
  PROFILE_DATA: "profile-data",

  DEPARTMENTS: "departments",
  ADD_DEPARTMENT: "department-add",
  EDIT_DEPARTMENT: "department-edit",

  ACCESS_LEVELS: "access-levels",
  ADD_ACCESS_LEVEL: "access-level-add",
  EDIT_ACCESS_LEVEL: "access-level-edit",

  ZONES_AND_DEVICES: "zones-and-devices",

  ACCESS_LINKS: "access-links",

  ACCESS: "access",

  OBJECT: "zones-and-devices",

  OBJECTS: "objects",

  OBJECT_LIST: "object-list",
  OBJECT_SHOW: "object-show",
  OBJECT_DATA: "object-data",
  OBJECT_STATISTICS: "object-statistics",
  OBJECT_HISTORY: "object-history",
  EDIT_OBJECT: "object-edit",
  ADD_OBJECT: "object-add",

  OBJECT_TYPE: "object-type",
  ADD_OBJECT_TYPE: "object-type-add",
  EDIT_OBJECT_TYPE: "object-type-edit",

  PROFILE_TYPE: "profile-type",
  ADD_PROFILE_TYPE: "profile-type-add",
  EDIT_PROFILE_TYPE: "profile-type-edit",

  ZONES: "zones",
  ADD_ZONE: "zone-add",
  EDIT_ZONE: "zone-edit",

  PERMITS: "permits",
  EDIT_PERMIT: "permits-edit",

  DEVICES: "devices",
  ADD_DEVICE: "addDevice",
  EDIT_DEVICE: "editDevice",

  DOCUMENTS: "documents",

  PERMIT_REQUESTS: "permitRequests",
  ADD_PERMIT_REQUEST: "addPermitRequest",
  ADD_MULTI_PERMIT_REQUEST: "addMultiPermitRequest",
  EDIT_PERMIT_REQUEST: "editPermitRequest",

  ACCESS_MODES: "access-modes",
  EDIT_ACCESS_MODES: "access-modes-edit",
  ADD_ACCESS_MODES: "access-modes-add",

  OPERATORS: "operators",
  ADD_OPERATOR: "operator-add",
  EDIT_OPERATOR: "operator-edit",

  ACCESS_DENIED: "access-denied",

  REDIRECT_PAGE: "redirect-page",
};
