<template>
  <span v-for="type in item.identityTypes" :key="type">
    <v-tooltip v-if="type === 'face'" :text="$t('users.face')" location="top">
      <template #activator="{ props }">
        <v-icon v-bind="props" size="small" color="primary">
          mdi-emoticon-happy-outline
        </v-icon>
      </template>
    </v-tooltip>

    <v-tooltip v-if="type === 'qr'" :text="$t('users.qr')" location="top">
      <template #activator="{ props }">
        <v-icon v-bind="props" size="small" color="primary">
          mdi-qrcode-scan
        </v-icon>
      </template>
    </v-tooltip>

    <v-tooltip v-if="type === 'card'" location="top">
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          size="small"
          color="primary"
          v-clipboard:copy="getIdentityValue(item.event?.identity_value || '')"
          v-clipboard:success="onCopy"
        >
          mdi-card-bulleted-outline
        </v-icon>
      </template>
      {{ $t("users.card") }} <br />
      HEX - {{ getIdentityValue(item.event.identity_value) }} <br />
      DEC -
      {{ parseInt(getIdentityValue(item.event.identity_value), 16) }}
    </v-tooltip>
  </span>

  <span v-if="item.event?.accompany_id">
    <v-tooltip max-width="250" location="top">
      <template #activator="{ props }">
        <v-icon v-bind="props" size="small" color="primary"
          >mdi-account-supervisor-circle-outline</v-icon
        >
      </template>
      <span>
        {{ $t("permits.accompany") }} <br />

        <template v-if="item.event.accompany_name">
          {{ item.event.accompany_name }}
        </template>
      </span>
    </v-tooltip>
  </span>

  <span v-if="item.event?.message">
    <v-tooltip max-width="250" location="top">
      <template #activator="{ props }">
        <v-icon v-bind="props" size="small" color="primary">
          mdi-message-reply-text-outline
        </v-icon>
      </template>
      <span>
        {{ item.event.message }}
      </span>
    </v-tooltip>
  </span>
</template>
<script>
import identityValue from "@/mixins/identityValue";

export default {
  mixins: [identityValue],

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    onCopy() {
      this.$toast.success(this.$t("message.copied"));
    },
  },
};
</script>
