export default {
  getListFormatted: (state) => {
    return state.data.map((device) => {
      const data = JSON.parse(device.data);

      const deviceType = data.device_type || "OGATE";
      let deviceTypeName = deviceType.toLowerCase();
      if (deviceType && deviceType.indexOf(".") != -1) {
        deviceTypeName = deviceType.split(".")[0].toLowerCase();
      }
      const temperatureEnable = data.temperature_enable === "on" || false;
      let avatar = require("@/assets/img/devices/unknown.png");

      if (deviceTypeName) {
        if (temperatureEnable) {
          avatar = require(`@/assets/img/devices/${deviceTypeName}-t.png`);
        } else {
          avatar = require(`@/assets/img/devices/${deviceTypeName}.png`);
        }
      }

      const result = {
        id: device.id,
        name: device.name,
        ipAddress: device.ip_address,
        isEnabled: device.enabled,
        avatar,
        type: device.device_type,
        deviceType: data.device_type,
        controlMode: data.control_mode || undefined,
        isErrorLogs: device.is_error_logs,
        channels: device.channels,
        monitoring: device.monitoring,
      };

      if (data.doors_number) {
        result.doorsNumber = data.doors_number;
      }

      return result;
    });
  },
};
