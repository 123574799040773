<template>
  <v-card class="overflow-visible" elevation="2">
    <!-- <v-tabs show-arrows color="primary">
      <v-tab v-for="(tab, i) in tabByPermission" :key="i" :to="tab.to">
        <v-icon left>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs> -->

    <v-card-text>
      <router-view />
    </v-card-text>
  </v-card>
</template>

<script>
import permissions from "@/mixins/permissions";

export default {
  name: "AnalyticsPage",

  mixins: [permissions],

  data() {
    return {
      tabs: [
        // {
        //   name: this.$t("menu.passes-journal"),
        //   to: { name: this.$const.ROUTES.PASSES_JOURNAL },
        //   icon: "mdi-account-check",
        //   permission: this.$const.RULES.JOURNAL_VIEW,
        // },
        {
          name: this.$t("menu.events-journal"),
          to: { name: this.$const.ROUTES.EVENTS_JOURNAL },
          icon: "mdi-account-details",
          permission: this.$const.RULES.JOURNAL_VIEW,
        },
      ],
    };
  },

  watch: {
    $route: {
      handler() {
        if (this.tabByPermission.length) {
          this.redirect();
        }
      },
      immediate: true,
    },
  },

  methods: {
    redirect() {
      if (this.$route.name === this.$const.ROUTES.ANALYTICS) {
        this.$router.replace(this.tabByPermission[0].to);
      }
    },
  },

  computed: {
    tabByPermission() {
      return this.tabs.filter((tab) => this.can(tab.permission));
    },
  },
};
</script>
