import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  getPermits({ commit }, params) {
    commit("setLoadingGetPermits", { type: "IS_PENDING" });

    return $axios("permits", params)
      .then((response) => {
        const { data } = response.data;

        commit("setPermits", data);
        commit("setLoadingGetPermits", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetPermits", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },

  getPermit({ commit }, id) {
    commit("setLoadingGetPermit", { type: "IS_PENDING" });

    return $axios(`permits/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("setPermit", data);
        commit("setLoadingGetPermit", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetPermit", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },

  updatePermit({ commit }, { id, params }) {
    commit("setLoadingUpdatePermit", { type: "IS_PENDING" });

    return $axios
      .put(`permits/${id}`, params)
      .then((response) => {
        commit("setLoadingUpdatePermit", { type: "IS_SUCCESS" });

        $toast.success(i18n.global.t("message.saveSuccess"));
        return response;
      })
      .catch((error) => {
        commit("setLoadingUpdatePermit", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.saveError"));
        throw error;
      });
  },
};
