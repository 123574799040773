<template>
  <ToggleArrayItems :items="item.values" :minLength="3">
    <template #default="{ items }">
      <v-checkbox
        v-for="value in items"
        :key="value.value"
        v-model="treeValue"
        :multiple="item.multiple"
        density="compact"
        hide-details
        color="primary"
        :value="value.value"
        :label="value.name"
      />
    </template>
    <template #button="{ toggleFull, isFull, splicedItems }">
      <v-col cols="auto" class="pa-1">
        <v-btn
          block
          variant="tonal"
          density="comfortable"
          :ripple="false"
          @click.stop="toggleFull"
          >{{ isFull ? $t("user.collapse") : $t("user.expand") }}
          {{
            splicedItems.length
              ? `(${countActiveHidden(splicedItems)}/${splicedItems.length})`
              : ""
          }}</v-btn
        >
      </v-col>
    </template>
  </ToggleArrayItems>
</template>

<script>
import ToggleArrayItems from "@/components/ToggleArrayItems.vue";

export default {
  components: { ToggleArrayItems },

  props: {
    modelValue: {
      type: [Array, Number, String],
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    countActiveHidden(splicedItems) {
      let activeValuesCount = 0;

      if (!splicedItems?.length || !this.modelValue?.length)
        return activeValuesCount;

      splicedItems.forEach((item) => {
        if (this.modelValue.includes(item.value)) ++activeValuesCount;
      });

      return activeValuesCount;
    },
  },

  computed: {
    treeValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        const event = {};
        event[this.item.param] = newValue;
        this.$emit("update:modelValue", event);
      },
    },
  },
};
</script>
