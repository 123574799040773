import { Buffer } from "buffer";
import { imgHttp } from "@/plugins/axios";

export default {
  data() {
    return {
      imgProtected: null,
    };
  },

  methods: {
    getImage(src) {
      return imgHttp
        .get(src, {
          responseType: "arraybuffer",
        })
        .then(
          (response) =>
            (this.imgProtected = `data:image/png;base64,${Buffer.from(
              response.data,
              "binary"
            ).toString("base64")}`)
        );
    },
  },
};
