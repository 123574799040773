import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPendingFilter: false,
  isSuccessFilter: false,
  isFailureFilter: false,
  errorMessageFilter: "",
  filterTemplate: {},
};

export default {
  state,
  mutations,
  actions,
};
