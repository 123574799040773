<template>
  <v-row>
    <v-col class="d-flex flex-wrap smart-filter__search" style="gap: 10px">
      <el-select
        v-if="item.selectable"
        v-model="selectValue"
        class="flex-grow-1"
        style="min-width: 120px; width: calc(40% - 5px); max-width: 100%"
      >
        <el-option
          v-for="itemList in item.values"
          :key="itemList.value"
          :label="itemList.name"
          :value="itemList.value"
        />
      </el-select>

      <el-autocomplete
        v-model="searchValue"
        :fetch-suggestions="filterHistory"
        :clearable="true"
        class="flex-grow-1"
        style="width: calc(60% - 5px); max-width: 100%"
        name="value"
        @blur="setHistory"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      historyItems: [],
    };
  },

  computed: {
    selectValue: {
      get() {
        if (this.item.selectable) {
          const arrValue = this.modelValue.split(":");
          return arrValue[0] || this.item.defaultSelect;
        }
        return null;
      },
      set(newValue) {
        const event = {};
        event[this.item.param] = `${newValue}:${
          this.modelValue.split(":")[1] ?? ""
        }`;
        this.$emit("update:modelValue", event);
      },
    },
    searchValue: {
      get() {
        if (this.item.selectable) {
          const arrValue = this.modelValue.split(":");
          return arrValue[1];
        }
        return this.modelValue;
      },
      set(newValue) {
        const event = {};
        if (this.item.selectable) {
          event[this.item.param] = `${
            this.modelValue.split(":")[0] || this.item.defaultSelect
          }:${newValue}`;
        } else {
          event[this.item.param] = newValue;
        }
        this.$emit("update:modelValue", event);
      },
    },
  },

  methods: {
    setHistory() {
      let searchValue = "";

      if (this.item.selectable) {
        const arrValue = this.modelValue.split(":");
        searchValue = arrValue[1];
      } else {
        searchValue = this.modelValue;
      }

      if (!this.item.historyNamespace || !searchValue) return;

      const searchHistory = localStorage.getItem(
        `searchHistory-${this.item.historyNamespace}`
      );

      if (searchHistory) {
        const searchHistoryArr = searchHistory.split("&*$");

        if (searchHistoryArr.length >= 5) {
          searchHistoryArr.shift();
        }

        if (!searchHistoryArr.includes(searchValue)) {
          searchHistoryArr.push(searchValue);
        }

        this.historyItems = searchHistoryArr;

        localStorage.setItem(
          `searchHistory-${this.item.historyNamespace}`,
          searchHistoryArr.join("&*$")
        );
      } else {
        this.historyItems = [searchValue];

        localStorage.setItem(
          `searchHistory-${this.item.historyNamespace}`,
          searchValue
        );
      }
    },

    getHistory() {
      let curHistory = [];

      if (!this.item.historyNamespace) return;

      const searchHistory = localStorage.getItem(
        `searchHistory-${this.item.historyNamespace}`
      );

      if (searchHistory) {
        curHistory = searchHistory.split("&*$");
      }

      this.historyItems = curHistory;
    },

    filterHistory(queryString, cb) {
      let results = queryString
        ? this.historyItems.filter((item) => {
            return item.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
          })
        : this.historyItems;

      results = results.map((item) => {
        return { value: item };
      });

      cb(results);
    },
  },

  created() {
    this.getHistory();
  },
};
</script>
