import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const limit = window.localStorage.getItem("passesLimit") || 30;

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  data: [],
  limit: parseInt(limit),
  pagination: {
    current_page: 1,
    per_page: 30,
    total: 0,
    total_items: 0,
  },

  // Report
  isPendingReport: false,
  isSuccessReport: false,
  isFailureReport: false,
  errorMessageReport: "",
};

export default {
  state,
  mutations,
  actions,
  getters,
};
