import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  isPendingStore: false,
  isSuccessStore: false,
  isFailureStore: false,
  errorMessageStore: "",

  isPendingEdit: false,
  isSuccessEdit: false,
  isFailureEdit: false,
  errorMessageEdit: "",

  isPendingRemove: false,
  isSuccessRemove: false,
  isFailureRemove: false,
  errorMessageRemove: "",

  isPendingRoles: false,
  isSuccessRoles: false,
  isFailureRoles: false,
  errorMessageRoles: "",

  data: null,

  allRoles: [],
  roles: [],
};

export default {
  state,
  mutations,
  actions,
};
