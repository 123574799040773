import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
import { moment as $moment } from "@/plugins/vue-moment";
const $toast = useToast();

export default {
  //Получить типы объектов
  resetGetObjectTypes({ commit }) {
    commit("setLoadingGetObjectTypes", { type: "" });
    commit("setObjectTypes", []);
  },

  getObjectTypes({ commit }) {
    commit("setLoadingGetObjectTypes", { type: "IS_PENDING" });

    return $axios("/objects/types")
      .then((response) => {
        commit("setObjectTypes", response.data.data);
        commit("setLoadingGetObjectTypes", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetObjectTypes", {
          type: "IS_FAILURE",
          payload: error?.response?.data || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );

        throw error;
      });
  },

  //Получить объект
  resetGetObject({ commit }) {
    commit("setLoadingGetObject", { type: "" });
    commit("setObject", {});
  },

  getObject({ commit }, id) {
    commit("setLoadingGetObject", { type: "IS_PENDING" });

    return $axios(`/object/${id}`)
      .then((response) => {
        commit("setObject", response.data.data);
        commit("setLoadingGetObject", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );

        throw error;
      });
  },

  //Добавить объект
  resetAddObject({ commit }) {
    commit("setLoadingAddObject", { type: "" });
  },

  addObject({ commit }, params) {
    commit("setLoadingAddObject", { type: "IS_PENDING" });

    return $axios
      .post(`objects/add`, params)
      .then((response) => {
        commit("setLoadingAddObject", { type: "IS_SUCCESS" });
        $toast.success(i18n.global.t("message.saveSuccess"));
        return response;
      })
      .catch((error) => {
        commit("setLoadingAddObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.saveError"));
        throw error;
      });
  },

  //Редактировать объекты
  resetUpdateObject({ commit }) {
    commit("setLoadingUpdateObject", { type: "" });
  },

  updateObject({ commit }, { id, params }) {
    commit("setLoadingUpdateObject", { type: "IS_PENDING" });

    return $axios
      .put(`object/${id}`, params)
      .then((response) => {
        commit("setLoadingUpdateObject", { type: "IS_SUCCESS" });
        $toast.success(i18n.global.t("message.saveSuccess"));
        return response;
      })
      .catch((error) => {
        commit("setLoadingUpdateObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.saveError"));
        throw error;
      });
  },

  //Получить список объектов
  resetGetObjectsList({ commit }) {
    commit("setObjectsList", []);
    commit("setLoadingGetObjectsList", { type: "" });
  },

  getObjectsList({ commit }, params) {
    commit("setLoadingGetObjectsList", { type: "IS_PENDING" });

    return $axios("objects/person", { params })
      .then((response) => {
        commit("setObjectsList", response.data.data);
        commit("setLoadingGetObjectsList", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetObjectsList", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Удалить объект
  resetDeleteObject({ commit }) {
    commit("setLoadingDeleteObject", { type: "" });
  },

  deleteObject({ commit }, params) {
    commit("setLoadingDeleteObject", { type: "IS_PENDING" });

    return $axios
      .post("objects/delete", params)
      .then((response) => {
        commit("setLoadingDeleteObject", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingDeleteObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Восстановить объект
  resetRestoreObject({ commit }) {
    commit("setLoadingRestoreObject", { type: "" });
  },

  restoreObject({ commit }, params) {
    commit("setLoadingRestoreObject", { type: "IS_PENDING" });

    return $axios
      .post("objects/restore", params)
      .then((response) => {
        commit("setLoadingRestoreObject", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingRestoreObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Добавить в отслеживаемые
  resetBlackObject({ commit }) {
    commit("setLoadingBlackObject", { type: "" });
  },

  blackObject({ commit }, params) {
    commit("setLoadingBlackObject", { type: "IS_PENDING" });

    return $axios
      .post("objects/black", params)
      .then((response) => {
        commit("setLoadingBlackObject", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingBlackObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Удалить из отслеживаемых
  resetUnblackObject({ commit }) {
    commit("setLoadingUnblackObject", { type: "" });
  },

  unblackObject({ commit }, params) {
    commit("setLoadingUnblackObject", { type: "IS_PENDING" });

    return $axios
      .post("objects/unblack", params)
      .then((response) => {
        commit("setLoadingUnblackObject", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingUnblackObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Заблокировать объекты
  resetBlockObject({ commit }) {
    commit("setLoadingBlockObject", { type: "" });
  },

  blockObject({ commit }, params) {
    commit("setLoadingBlockObject", { type: "IS_PENDING" });

    return $axios
      .post("objects/block", params)
      .then((response) => {
        commit("setLoadingBlockObject", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingBlockObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Разблокировать объекты
  resetUnblockObject({ commit }) {
    commit("setLoadingUnblockObject", { type: "" });
  },

  unblockObject({ commit }, params) {
    commit("setLoadingUnblockObject", { type: "IS_PENDING" });

    return $axios
      .post("objects/unblock", params)
      .then((response) => {
        commit("setLoadingUnblockObject", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingUnblockObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Сгенерировать отчёт
  resetReportObject({ commit }) {
    commit("setLoadingReportObject", { type: "" });
  },

  reportObject({ commit }, params) {
    commit("setLoadingReportObject", { type: "IS_PENDING" });

    const requestParams = {
      method: "post",
      url: "objects/report",
      responseType: "blob",
      data: params,
    };

    return $axios
      .request(requestParams)
      .then((response) => {
        commit("setLoadingReportObject", { type: "IS_SUCCESS" });

        const filename = `events-${$moment().format("YYYYMMDDHHmmss")}.xlsx`;

        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/xlsx",
          })
        );

        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        return response;
      })
      .catch((error) => {
        commit("setLoadingReportObject", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Получить типы объектов
  resetGetObjectHistory({ commit }) {
    commit("setLoadingGetObjectHistory", { type: "" });
    commit("setObjectHistory", {});
  },

  getObjectHistory({ commit }, { id, params }) {
    commit("setLoadingGetObjectHistory", { type: "IS_PENDING" });

    return $axios(`/object/${id}/history`, { params })
      .then((response) => {
        commit("setObjectHistory", response.data.data);
        commit("setLoadingGetObjectHistory", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetObjectHistory", {
          type: "IS_FAILURE",
          payload: error?.response?.data || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );

        throw error;
      });
  },
};
