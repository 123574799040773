export default {
  //Старое хранилище api
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  setList(state, payload) {
    state.list = payload || [];
  },

  setTree(state, payload) {
    state.tree = payload || [];
  },

  //Новое хранилище api
  setLoadingGetZonesList(state, { type, payload = "" }) {
    state.isPendingGetZonesList = type === "IS_PENDING";
    state.isSuccessGetZonesList = type === "IS_SUCCESS";
    state.isFailureGetZonesList = type === "IS_FAILURE";
    state.errorMessageGetZonesList = payload;
  },

  setZonesList(state, payload) {
    state.zonesList = payload;
  },

  setLoadingGetZonesTree(state, { type, payload = "" }) {
    state.isPendingGetZonesTree = type === "IS_PENDING";
    state.isSuccessGetZonesTree = type === "IS_SUCCESS";
    state.isFailureGetZonesTree = type === "IS_FAILURE";
    state.errorMessageGetZonesTree = payload;
  },

  setZonesTree(state, payload) {
    state.zonesTree = payload;
  },
};
