import useVuelidate from "@vuelidate/core";
import { required, helpers, maxLength } from "@vuelidate/validators";

const validType = helpers.regex(/^[a-zA-Z0-9\\-]+$/);

export default {
  data() {
    return {
      v$: useVuelidate({ $scope: false }),
      fields: [],
      name: "",
      type: "",
    };
  },

  validations() {
    const data = {
      name: { required, maxLength: maxLength(15) },
      type: { required, validType, maxLength: maxLength(10) },
      fields: {
        fieldsValid: helpers.withParams({ type: "fieldsValid" }, (value) => {
          if (this.isAdd) return false;

          if (!value?.length) return true;

          return !value.filter((valueItem) => valueItem.isEdit == true).length;
        }),
      },
    };

    return data;
  },

  computed: {
    nameErrors() {
      const errors = [];

      if (this.v$.name.$dirty && this.v$.name.required.$invalid) {
        errors.push(this.$t("object-type.errSelectItemRequire"));
      }

      if (this.v$.name.$dirty && this.v$.name.maxLength.$invalid) {
        errors.push(this.$t("object-type.errMaxNameLength", { count: "15" }));
      }

      return errors;
    },
    typeErrors() {
      const errors = [];

      if (this.v$.type.$dirty && this.v$.type.required.$invalid) {
        errors.push(this.$t("object-type.errSelectItemRequire"));
      }

      if (this.v$.type.$dirty && this.v$.type.maxLength.$invalid) {
        errors.push(this.$t("object-type.errMaxNameLength", { count: "10" }));
      }

      if (this.v$.type.$dirty && this.v$.type.validType.$invalid) {
        errors.push(this.$t("object-type.errTypeRegex"));
      }

      return errors;
    },
  },
};
