<template>
  <v-row v-if="Object.keys(profile).length" type="flex">
    <v-col>
      <v-card elevation="0">
        <v-card-title>
          <v-row>
            <v-col cols="auto text-primary font-weight-medium">
              {{ profile.name }}
            </v-col>
            <v-spacer />
          </v-row>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="6" class="grey--text">ID</v-col>
            <v-col cols="6">{{ object.id }}</v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="grey--text">{{
              $t("user.createdAt")
            }}</v-col>
            <v-col cols="6">{{
              $moment(object.created_at).format("DD.MM.YYYY HH:mm")
            }}</v-col>
          </v-row>
          <v-row v-if="object.updated_at">
            <v-col cols="6" class="grey--text">{{
              $t("user.updatedAt")
            }}</v-col>
            <v-col cols="6">{{
              $moment(object.updated_at).format("DD.MM.YYYY HH:mm")
            }}</v-col>
          </v-row>
          <CustomFields v-model="object.values" />

          <v-row v-if="object.profiles?.length">
            <v-col cols="6" class="grey--text">{{
              $t("profile.permits")
            }}</v-col>
            <v-col cols="6">
              <v-chip-group>
                <v-chip v-for="profile in object.profiles" :key="profile.id">
                  {{ profile.type_name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <v-btn color="green" @click="goToEdit">
                <v-icon start>mdi-pencil</v-icon>
                {{ $t("user.edit") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";

import { CustomFields } from "@/components/ObjectsTools";

export default {
  components: { CustomFields },

  data() {
    return {
      id: null,
      object_id: null,

      profile: {},
    };
  },

  methods: {
    ...mapActions({
      getProfileAction: "profiles/getProfile",
    }),

    getIdes() {
      this.id = parseInt(this.$route.params.id);
      this.object_id = parseInt(history.state.object_id);
    },

    getProfile() {
      this.getProfileAction(this.id).then(() => {
        this.profile = this.profileState;
      });
    },

    goToEdit() {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_OBJECT,
      });
    },
  },

  computed: {
    ...mapState({
      profileState: (state) => state.profiles.profile,
    }),
  },

  created() {
    this.getIdes();
    this.getProfile();
  },
};
</script>
