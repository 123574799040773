import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPendingClearDB: false,
  isSuccessClearDB: false,
  isFailureClearDB: false,
  errorMessageClearDB: "",

  isPendingDownloadAllLog: false,
  isSuccessDownloadAllLog: false,
  isFailureDownloadAllLog: false,
  errorMessageDownloadAllLog: "",

  isPendingGetLog: false,
  isSuccessGetLog: false,
  isFailureGetLog: false,
  errorMessageGetLog: "",
  log: "",

  isPendingGetUserLog: false,
  isSuccessGetUserLog: false,
  isFailureGetUserLog: false,
  errorMessageGetUserLog: "",
  userLog: "",

  isPendingDownloadAllUserLog: false,
  isSuccessDownloadAllUserLog: false,
  isFailureDownloadAllUserLog: false,
  errorMessageDownloadAllUserLog: "",
};

export default {
  state,
  mutations,
  actions,
};
