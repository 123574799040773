<template>
  <v-dialog
    v-model="show"
    persistent
    width="375"
    :hide-overlay="isLinkGeneration"
  >
    <template #activator>
      <v-btn color="primary" @click="openDialog" flat>{{
        $t("user.invitationToTakeBiometrics")
      }}</v-btn>
    </template>
    <v-card>
      <v-card-title
        ><v-row
          ><v-col>{{ cardTitle }}</v-col
          ><v-col cols="auto"
            ><v-btn
              :label="$t('button.close')"
              variant="text"
              density="compact"
              icon="mdi-close"
              @click="closeDialog"
            />
          </v-col> </v-row
      ></v-card-title>
      <v-card-text>
        <v-progress-linear
          v-if="isLinkGeneration"
          class="mb-1"
          indeterminate
          color="primary"
        />
        <template v-else>
          <v-row class="ma-0">
            <v-col cols="12" class="px-0">
              <Countdown
                ref="telCountdown"
                :time="40 * 1000"
                :auto-start="false"
              >
                <template #default="{ resolved }">
                  <v-btn
                    :disabled="
                      (!!resolved.s && resolved.s != 40) || isPendingSendSms
                    "
                    color="primary"
                    block
                    size="small"
                    @click="sendSms"
                  >
                    {{
                      `${telTitle} ${
                        !!resolved.s && resolved.s != 40
                          ? "(" + resolved.s + ")"
                          : ""
                      }`
                    }}</v-btn
                  ></template
                ></Countdown
              ></v-col
            >
            <v-col cols="12" class="px-0">
              <Countdown
                ref="emailCountdown"
                :time="40 * 1000"
                :auto-start="false"
              >
                <template #default="{ resolved }">
                  <v-btn
                    :disabled="
                      (!!resolved.s && resolved.s != 40) || isPendingSendEmail
                    "
                    color="primary"
                    block
                    size="small"
                    @click="sendEmail"
                  >
                    {{
                      `${emailTitle} ${
                        !!resolved.s && resolved.s != 40
                          ? "(" + resolved.s + ")"
                          : ""
                      }`
                    }}</v-btn
                  ></template
                ></Countdown
              ></v-col
            >
            <v-col cols="12" class="px-0">
              <v-row class="ma-0 flex-nowrap">
                <v-col cols="auto" class="pa-0 flex-grow-1">
                  <v-btn
                    :disabled="!firstTry.qrd"
                    color="primary"
                    block
                    @click="sendOnTerminal"
                    size="small"
                    >{{ $t("user.sendLinkQRD") }}
                  </v-btn>
                </v-col>
                <v-col
                  v-if="!firstTry.qrd"
                  cols="auto"
                  class="pa-0 flex-grow-0 d-flex align-center"
                >
                  <v-tooltip location="top" max-width="250">
                    <template #activator="{ props }">
                      <v-icon class="ml-2" v-bind="props" color="grey-lighten-1"
                        >mdi-information</v-icon
                      >
                    </template>
                    {{ $t("user.messageLinkQRD") }}
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-text-field
                :label="$t('user.invitationLink')"
                :model-value="bioLink"
                color="primary"
                density="compact"
                variant="outlined"
                hide-details
                @focus="selectAll"
                ref="linkText"
                readonly
              >
                <template #append-inner>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        variant="text"
                        density="compact"
                        color="primary"
                        icon
                        v-clipboard:copy="bioLink"
                        v-clipboard:success="onCopy"
                      >
                        <v-icon>mdi-clipboard-multiple-outline</v-icon>
                      </v-btn>
                    </template>
                    {{ $t("requests.copyUrl") }}
                  </v-tooltip>
                </template>
                <template #append>
                  <v-tooltip location="top">
                    <template #activator="{ props }">
                      <v-btn
                        v-bind="props"
                        variant="text"
                        density="compact"
                        color="primary"
                        icon
                        v-print="{
                          id: '#printQrBlock',
                          previewTitle: userName,
                          popTitle: userName,
                        }"
                      >
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    {{ $t("button.print") }}
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="bioLink" colas="12" class="px-0 pb-0">
              <div id="printQrBlock">
                <QRCodeVue3
                  width="327"
                  height="327"
                  :value="bioLink"
                  :qrOptions="qrOptions"
                  :imageOptions="imageOptions"
                  :dotsOptions="dotsOptions"
                  :backgroundOptions="backgroundOptions"
                  :image="image"
                  :cornersSquareOptions="cornersSquareOptions"
                  :cornersDotOptions="cornersDotOptions"
                />
              </div>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import qrDesign from "@/mixins/qrDesign";

export default {
  mixins: [qrDesign],
  props: {
    id: {
      type: Number,
      default: null,
    },
    userName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,

      firstTry: {
        tel: true,
        email: true,
        qrd: true,
      },
    };
  },
  methods: {
    ...mapActions({
      getBioLinkAction: "person/getBioLink",
      sendSmsAction: "person/sendSms",
      sendEmailAction: "person/sendEmail",
      sendOnTerminalAction: "person/sendOnTerminal",
      offTerminalAction: "person/offTerminal",
    }),

    //Управление диалогом
    openDialog() {
      this.getBioLink();
      this.show = true;
    },
    closeDialog() {
      if (!this.firstTry.qrd) this.offTerminalAction();
      this.clearDialog();
      this.show = false;
    },
    clearDialog() {
      this.firstTry.tel = true;
      this.firstTry.email = true;
      this.firstTry.qrd = true;

      this.getBioLinkAction({ id: null, clear: true });
    },

    //Получение ссылки
    getBioLink() {
      this.getBioLinkAction({ id: this.id }).then(() => {
        if (!this.bioLink) this.closeDialog();
      });
    },

    //Установка перезарядки
    setReloadTel() {
      this.$refs.telCountdown.reset();
      this.$refs.telCountdown.start();
      this.firstTry.tel = false;
    },
    setReloadEmail() {
      this.$refs.emailCountdown.reset();
      this.$refs.emailCountdown.start();
      this.firstTry.email = false;
    },

    //Отправка ссылки
    sendSms() {
      const params = {
        sendtype: "sms",
        template: "bio_invite",
        url: this.bioLink,
      };

      this.sendSmsAction({ id: this.id, params: params }).then(() => {
        if (this.isFailureSendSms) {
          return;
        }
        this.setReloadTel();
      });
    },
    sendEmail() {
      const params = {
        sendtype: "email",
        template: "bio_invite",
        url: this.bioLink,
      };
      this.sendEmailAction({ id: this.id, params: params }).then(() => {
        if (this.isFailureSendEmail) {
          return;
        }
        this.setReloadEmail();
      });
    },
    sendOnTerminal() {
      this.firstTry.qrd = false;
      this.sendOnTerminalAction(this.bioLink);
    },

    //Утилиты
    onCopy() {
      this.$toast.success(this.$t("message.copied"));
    },
    selectAll(event) {
      event.target.select();
    },
  },
  computed: {
    ...mapState({
      isLinkGeneration: (state) => state.person.isPendingPassBio,
      bioLink: (state) => state.person.bioLink,

      isPendingSendSms: (state) => state.person.isPendingSendSms,
      isFailureSendSms: (state) => state.person.isFailureSendSms,
      errorMessageSendSms: (state) => state.person.errorMessageSendSms,

      isPendingSendEmail: (state) => state.person.isPendingSendEmail,
      isFailureSendEmail: (state) => state.person.isFailureSendEmail,
      errorMessageSendEmail: (state) => state.person.errorMessageSendEmail,
    }),

    cardTitle() {
      return this.isLinkGeneration
        ? this.$t("user.linkGeneration")
        : this.$t("user.linkDisplayMethod");
    },

    telTitle() {
      return `${this.firstTry.tel ? "" : this.$t("user.again")} ${this.$t(
        "user.sendLinkSms"
      )}`;
    },
    emailTitle() {
      return `${this.firstTry.email ? "" : this.$t("user.again")} ${this.$t(
        "user.sendLinkEmail"
      )}`;
    },
  },
  unmounted() {
    this.clearDialog();
  },
};
</script>
