import VueTelInputVuetify from "./vue-tel-input-vuetify.vue";

export function install(app) {
  app.component("vue-tel-input-vuetify", VueTelInputVuetify);
}

export { VueTelInputVuetify };

const plugin = {
  install,
};

// Auto-install

export default plugin;
