<template>
  <el-select
    v-model="selectValue"
    :multiple="item.multiple"
    style="width: 100%"
  >
    <el-option
      v-for="itemList in item.values"
      :key="itemList.value"
      :label="itemList.name"
      :value="itemList.value"
    />
  </el-select>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [Array, String, Number],
      default: undefined,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    selectValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        const event = {};
        event[this.item.param] = newValue;
        this.$emit("update:modelValue", event);
      },
    },
  },
};
</script>
