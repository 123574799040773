import { isBase64 } from "validator";
import ImgProtected from "./imgProtected";

export default {
  mixins: [ImgProtected],
  data() {
    return {
      isOpenZoomImg: false,
      zoomImgUrl: "",
    };
  },

  methods: {
    isPhotoBase64(photo) {
      if (photo) {
        const index = photo.indexOf(",");
        const photoBase64 = photo.slice(index + 1);
        return isBase64(photoBase64);
      }
      return false;
    },
    async onHoverImg(img, isProtected) {
      let image = img;

      this.isOpenZoomImg = true;

      if (isProtected) {
        await this.getImage(img);
        image = this.imgProtected;
      }

      this.zoomImgUrl = image;
    },
    closeZoomImg() {
      this.isOpenZoomImg = false;
      this.zoomImgUrl = "";
    },
  },
};
