<template>
  <Alert v-if="isFailureUpdateProfileTypeState" type="error">
    {{ errorMessageUpdateProfileTypeState }}
  </Alert>

  <Form
    :disabled="isPendingUpdateProfileTypeState"
    :id="id"
    @setData="setData"
    @cancelForm="goBack"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";

import Alert from "@/components/Alert";

export default {
  components: { Form, Alert },

  data() {
    return {
      id: undefined,
    };
  },

  methods: {
    ...mapActions({
      updateProfileTypeActions: "profileTypes/updateProfileType",
    }),

    getData() {
      this.id = this.$route.params.id;
    },

    goBack() {
      this.$router.go(-1);
    },

    setData(data) {
      const params = {
        id: this.id,
        data: data,
      };

      this.updateProfileTypeActions(params).then(this.goBack);
    },
  },

  computed: {
    ...mapState({
      isPendingUpdateProfileTypeState: (state) =>
        state.profileTypes.isPendingUpdateProfileType,
      isFailureUpdateProfileTypeState: (state) =>
        state.profileTypes.isFailureUpdateProfileType,
      errorMessageUpdateProfileTypeState: (state) =>
        state.profileTypes.errorMessageUpdateProfileType,
    }),
  },

  created() {
    this.getData();
  },
};
</script>
