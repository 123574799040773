<template>
  <v-row v-for="field in fields" :key="field.name">
    <v-col cols="12">
      <TextField
        v-if="field.type == 'text' || field.type == 'res_tab_num'"
        v-model="fieldsComputed"
        :field="field"
      />
      <Phone
        v-if="field.type == 'phone'"
        v-model="fieldsComputed"
        :settings="settings"
        :field="field"
      />
      <PhoneNumberRes
        v-if="field.type == 'res_phone'"
        v-model="fieldsComputed"
        :settings="settings"
        :field="field"
      />
      <Email
        v-if="field.type == 'email'"
        v-model="fieldsComputed"
        :field="field"
      />
      <Select
        v-if="field.type == 'select'"
        v-model="fieldsComputed"
        :field="field"
      />

      <SelectInput
        v-if="field.type == 'select_input'"
        v-model="fieldsComputed"
        :field="field"
      />

      <EmailRes
        v-if="field.type == 'res_email'"
        v-model="fieldsComputed"
        :settings="settings"
        :field="field"
      />
    </v-col>
  </v-row>
</template>

<script>
import TextField from "./components/TextField";
import Phone from "./components/PhoneNumber";
import Email from "./components/Email";
import Select from "./components/Select";
import SelectInput from "./components/SelectInput";
import PhoneNumberRes from "./components/PhoneNumberRes";
import EmailRes from "./components/EmailRes";

export default {
  components: {
    TextField,
    Phone,
    Email,
    Select,
    SelectInput,
    PhoneNumberRes,
    EmailRes,
  },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    settings: {
      type: Object,
      default: () => {},
    },

    fields: {
      type: Array,
      default: () => {},
    },
  },

  computed: {
    fieldsComputed: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>
