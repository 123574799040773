<template>
  <v-row>
    <v-col>
      <v-autocomplete
        v-model="schedulesValueComputed"
        multiple
        chips
        :label="$t('access-levels.accessModes')"
        item-title="name"
        item-value="id"
        color="primary"
        density="comfortable"
        variant="outlined"
        closable-chips
        hide-details
        :loading="isPendingGetAccessModesState"
        :items="accessModes"
      >
        <template #append-inner>
          <v-tooltip location="top">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                color="grey-lighten-1"
                variant="text"
                density="compact"
                icon
                @click.stop="getAccessModes"
              >
                <v-icon>mdi-autorenew</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("setting.dataUpdate") }}</span>
          </v-tooltip>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: {
      type: [String, Number],
      default: "",
    },

    schedules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      accessModes: [],
    };
  },

  methods: {
    ...mapActions({
      getAccessModesAction: "accessModes/getAccessModes",
    }),

    getAccessModes() {
      this.getAccessModesAction().then(() => {
        this.accessModes = this.dataAccessModesState;
      });
    },
  },

  computed: {
    ...mapState({
      isPendingGetAccessModesState: (state) =>
        state.accessModes.isPendingGetAccessModes,
      dataAccessModesState: (state) => state.accessModes.dataAccessModes,
    }),

    schedulesValueComputed: {
      get() {
        return this.schedules;
      },
      set(newValue) {
        const data = {
          id: this.id,
          schedule: newValue,
        };

        this.$emit("updateSchedules", data);
      },
    },
  },

  created() {
    this.getAccessModes();
  },
};
</script>
