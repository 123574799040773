<template>
  <v-row v-if="isFailureGetProfilesTypes">
    <v-col>
      <Alert type="error">
        {{ errorMessageGetProfilesTypes }}
      </Alert>
    </v-col>
  </v-row>
  <v-row v-if="isFailureDepartments">
    <v-col>
      <Alert type="error">
        {{ errorMessageDepartments }}
      </Alert>
    </v-col>
  </v-row>

  <v-row>
    <v-col>
      <EditCard
        v-if="isEdit"
        v-model="modelValueComputed"
        :showDeleteProfile="showDeleteProfile"
        :showPermitList="showPermitList"
        :showAddPermit="showAddPermit"
        :showEditPermit="showEditPermit"
        :showDeletePermit="showDeletePermit"
        :isEditPermits="isEditPermits"
        :profileTypes="profileTypesList"
        :departments="departmentsList"
        @removeProfile="onRemoveProfile"
        @changePermitsEdited="onChangePermitsEdited"
      />
      <ShowCard
        v-else
        v-model="modelValueComputed"
        :showDeleteProfile="showDeleteProfile"
        :showPermitList="showPermitList"
        :showAddPermit="showAddPermit"
        :showEditPermit="showEditPermit"
        :showDeletePermit="showDeletePermit"
        :isEditPermits="isEditPermits"
        :profileTypes="profileTypesList"
        :departments="departmentsList"
        @removeProfile="onRemoveProfile"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Alert from "@/components/Alert";

import EditCard from "./Edit";
import ShowCard from "./Show";

export default {
  emits: ["removeProfile", "update:modelValue", "changePermitsEdited"],

  components: { EditCard, ShowCard, Alert },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    isEdit: {
      type: Boolean,
      default: true,
    },

    isEditPermits: {
      type: Boolean,
      default: true,
    },

    //Настройки типов профиля
    getProfileTypesAuto: {
      type: Boolean,
      default: true,
    },
    profileTypes: {
      type: Array,
      default: () => [],
    },

    //Настройки департаментов
    getDeparmentsAuto: {
      type: Boolean,
      default: true,
    },
    departments: {
      type: Array,
      default: () => [],
    },

    /* <-- Profile --> */

    showDeleteProfile: {
      type: Boolean,
      default: true,
    },

    profileKey: {
      type: [Number, String],
      default: undefined,
    },

    /* <-- Permits --> */

    showPermitList: {
      type: Boolean,
      default: true,
    },

    showAddPermit: {
      type: Boolean,
      default: true,
    },

    showEditPermit: {
      type: Boolean,
      default: true,
    },

    showDeletePermit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      profileTypesList: [],
      departmentsList: [],
    };
  },

  watch: {
    profileTypes() {
      this.getProfilesTypes();
    },

    departments() {
      this.getDepartments();
    },
  },

  methods: {
    ...mapActions({
      getProfilesTypesAction: "profiles/getProfilesTypes",
      resetGetProfilesTypesAction: "profiles/resetGetProfilesTypes",

      getDepartmentsAction: "departments/getTree",
    }),

    getProfilesTypes() {
      if (this.getProfileTypesAuto) {
        this.getProfilesTypesAction().then(() => {
          this.profileTypesList = structuredClone(this.profileTypesState);
        });
      } else {
        this.profileTypesList = this.profileTypes;
      }
    },

    getDepartments() {
      if (this.getDeparmentsAuto) {
        this.getDepartmentsAction().then(() => {
          this.departmentsList = structuredClone(this.departmentsState);
        });
      } else {
        this.departmentsList = this.departments;
      }
    },

    onRemoveProfile(profileTypeId) {
      this.$emit("removeProfile", profileTypeId);
    },

    onChangePermitsEdited(event) {
      this.$emit("changePermitsEdited", { id: this.profileKey, value: event });
    },
  },

  computed: {
    ...mapState({
      isLoadingDepartments: (state) => state.departments.isLoading,
      isFailureDepartments: (state) => state.departments.isFailure,
      errorMessageDepartments: (state) => state.departments.errorMessage,

      departmentsState: (state) => state.departments.tree,

      isPendingGetProfilesTypes: (state) =>
        state.profiles.isPendingGetProfilesTypes,
      isFailureGetProfilesTypes: (state) =>
        state.profiles.isFailureGetProfilesTypes,
      errorMessageGetProfilesTypes: (state) =>
        state.profiles.errorMessageGetProfilesTypes,

      profileTypesState: (state) => state.profiles.profilesTypes,
    }),

    modelValueComputed: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },

  created() {
    this.getProfilesTypes();
    this.getDepartments();
  },
};
</script>
