import VueClipboard from "vue3-clipboard";

export default {
  install: (app) => {
    app.use(VueClipboard, {
      autoSetContainer: true,
      appendToBody: true,
    });
  },
};
