import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useVuelidate(),
      objectId: null,
      permits: [],
    };
  },
  validations() {
    return {
      objectId: { required },
      permits: { required, minLength: minLength(1) },
    };
  },

  computed: {
    objectIdErrors() {
      const errors = [];
      if (!this.v$.objectId.$dirty) return errors;

      this.v$.objectId.required.$invalid &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    permitsErrors() {
      const errors = [];
      if (!this.v$.permits.$dirty) return errors;

      (this.v$.permits.required.$invalid ||
        this.v$.permits.minLength.$invalid) &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },
  },

  methods: {
    validateForm() {
      this.v$.$touch();

      return !this.v$.$invalid;
    },
  },
};
