<template>
  <v-row>
    <v-col>
      <v-dialog v-model="isActiveComputed">
        <template #activator="{ props: activatorProps }">
          <v-hover>
            <template #default="{ props: hoverProps, isHovering }">
              <v-btn
                v-if="!getButtonStatus"
                v-bind="{ ...activatorProps, ...hoverProps }"
                :class="[
                  !getButtonStatus || isHovering ? 'opacity-100' : 'opacity-50',
                  getButtonStatus ? 'justify-start' : 'justify-center',
                ]"
                class="justify-start"
                variant="outlined"
                min-height="48"
                height="auto"
                max-height="fit-content"
                :ripple="false"
                block
                :color="getButtonColor"
              >
                {{ $t("button.add") }}
              </v-btn>
              <v-select
                v-else
                v-bind="activatorProps"
                :modelValue="modelObjects"
                :multiple="multiple"
                :item-key="itemKey"
                :label="$t('permits.accompanying')"
                variant="outlined"
                density="comfortable"
                color="primary"
                item-title="name"
                hide-details
                clearable
                readonly
                @click:clear.stop="removeObject"
              >
                <!-- TODO: Использовать removeObjects(object[itemKey]) при multiple -->
              </v-select>
            </template>
          </v-hover>
        </template>

        <template #default>
          <v-card class="h-100">
            <v-card-title>
              <v-row>
                <template v-if="$vuetify.display.mdAndUp">
                  <v-col cols="auto">
                    <v-btn variant="plain" @click="onCancel">
                      <v-icon start>mdi-cancel</v-icon>
                      {{ $t("button.cancel") }}
                    </v-btn>
                  </v-col>
                  <v-spacer />
                  <v-col v-if="multiple" cols="auto">
                    <v-btn color="green" variant="flat" @click="onSave">
                      <v-icon start>mdi-pencil</v-icon>
                      {{ $t("button.save") }}
                    </v-btn>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="auto">
                    <v-btn
                      variant="plain"
                      density="compact"
                      icon="mdi-cancel"
                      @click="onCancel"
                    />
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn color="green" variant="falt" @click="onSave">
                      <v-icon start>mdi-pencil</v-icon>
                      {{ $t("button.save") }}
                    </v-btn>
                  </v-col>
                </template>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row class="my-0">
                <Filter
                  v-model="filter"
                  :md="3"
                  :showOptions="false"
                  filterName="objects"
                  @update:modelValue="onFilter"
                />
                <v-col>
                  <v-data-table
                    v-model="selectedComputed"
                    :headers="headersComputed"
                    :items="objectsGetter"
                    :items-per-page="pagination.limit"
                    :show-select="multiple"
                    style="min-height: 60vh; max-height: 80vh"
                  >
                    <template #[`item.avatar`]="{ item }">
                      <div class="my-3">
                        <v-badge
                          :model-value="!!item.operator_id"
                          bordered
                          color="primary"
                          icon="mdi-star"
                        >
                          <v-avatar v-if="item.photo" color="primary" size="50">
                            <ImgProtected
                              :src="`avatar/small/${item.photo}`"
                              :alt="item.full_name"
                            />
                          </v-avatar>

                          <v-avatar v-else color="primary" size="50">
                            <v-icon size="35" dark> mdi-account-circle </v-icon>
                          </v-avatar>
                        </v-badge>
                      </div>
                    </template>

                    <template #[`item.name`]="{ item }">
                      <div>
                        <v-chip
                          v-if="item.blocked"
                          size="x-small"
                          class="mr-1 bg-red text-white"
                        >
                          <v-icon start size="x-small">mdi-cancel</v-icon>
                          {{ $t("users.blocked") }}
                        </v-chip>
                        <v-chip
                          v-if="item.blacklisted"
                          size="x-small"
                          color="primary"
                          class="mr-1"
                        >
                          <v-icon start size="x-small">mdi-eye</v-icon>
                          {{ $t("users.blacklist") }}
                        </v-chip>
                      </div>
                      <div class="text-primary">{{ item.name }}</div>
                      <template v-if="item.phone">
                        <br />
                        {{ item.phone }}
                      </template>
                    </template>

                    <template #[`item.actions`]="{ item }">
                      <v-btn
                        :disabled="disabledKeys(item)"
                        color="green"
                        variant="flat"
                        append-icon="mdi-arrow-right"
                        @click="selectObject(item)"
                      >
                        {{ $t("button.select") }}
                      </v-btn>
                    </template>

                    <template #bottom>
                      <DataTableFooterV2
                        v-model="pagination"
                        @update:modelValue="onPagination"
                      />
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ImgProtected from "@/components/ImgProtected";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";

export default {
  components: { DataTableFooterV2, Filter, ImgProtected },

  props: {
    modelValue: {
      type: [Array, String, Number],
      default: undefined,
    },

    modelObjects: {
      type: [Array, Object],
      default: undefined,
    },

    disabledKeys: {
      type: Function,
      default: () => {},
    },

    itemKey: {
      type: String,
      default: "id",
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,

      selected: [],
      selectedObjects: [],

      filter: {},
      pagination: {
        limit: 10,
      },
    };
  },

  methods: {
    ...mapActions({
      getObjectsAction: "object/getObjectsList",
    }),

    getObjects() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getObjectsAction(params).then((response) => {
        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;

          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };
        }
      });
    },

    initModal() {
      this.selectedObjects = this.modelObjects;
      this.selected = this.modelValue;

      this.getObjects();
    },

    cancelModal() {
      if (this.multiple) {
        this.selected = [];
        this.selectedObjects = [];
      } else {
        this.selected = "";
        this.selectedObjects = {};
      }

      this.filter = {};
      this.pagination = {
        limit: 10,
      };
    },

    removeObjects(id) {
      const curObjects = this.modelObjects.filter(
        (object) => object[this.itemKey] != id
      );
      const curObjectsIds = curObjects.map((object) => object[this.itemKey]);

      this.$emit("update:modelObjects", curObjects);
      this.$emit("update:modelValue", curObjectsIds);
    },

    removeObject() {
      this.$emit("update:modelObjects", {});
      this.$emit("update:modelValue", undefined);
    },

    onCancel() {
      this.cancelModal();

      this.isActive = false;
    },

    onSave() {
      this.$emit("update:modelObjects", this.selectedObjects);
      this.$emit("update:modelValue", this.selected);

      this.onCancel();
    },

    onFilter() {
      this.pagination.page = 1;
      this.onPagination();
    },

    onPagination() {
      this.getObjects();
    },

    selectObject(item) {
      const curObject = {
        id: item.id,
        name: item.name,
        [this.itemKey]: item[this.itemKey],
      };

      this.selectedObjects = curObject;
      this.selected = curObject[this.itemKey];

      this.onSave();
    },
  },

  computed: {
    ...mapGetters({
      objectsGetter: "object/getObjectsToShow",
    }),

    headersComputed() {
      const curHeaders = [
        {
          title: "ID",
          value: "id",
          width: 40,
        },
        {
          value: "avatar",
          sortable: false,
          width: 75,
        },
        {
          title: this.$t("users.fullname"),
          value: "name",
        },
      ];

      if (!this.multiple) {
        curHeaders.push({
          value: "actions",
          align: "end",
          width: 121,
        });
      }

      return curHeaders;
    },

    getButtonColor() {
      if (this.errorMessages.length) return "error";

      if (this.multiple && !this.modelValue.length) {
        return "success";
      } else if (!this.multiple && !this.modelValue) {
        return "success";
      }

      return undefined;
    },

    getButtonStatus() {
      if (this.multiple) {
        return this.modelObjects.length;
      } else {
        return Object.keys(this.modelObjects).length;
      }
    },

    isActiveComputed: {
      get() {
        return this.isActive;
      },
      set(newValue) {
        if (newValue) {
          this.initModal();
        } else {
          this.cancelModal();
        }

        this.isActive = newValue;
      },
    },

    selectedComputed: {
      get() {
        if (this.multiple) {
          return this.selected;
        } else {
          return [];
        }
      },
      set(newValue) {
        this.selectedObjects = newValue.map((id) => {
          let curObject = undefined;

          curObject = this.selectedObjects.find(
            (object) => object[this.itemKey] == id
          );

          if (curObject) return curObject;

          curObject = this.objectsGetter.find(
            (object) => object[this.itemKey] == id
          );

          curObject = {
            id: curObject.id,
            name: curObject.name,
            [this.itemKey]: curObject[this.itemKey],
          };

          return curObject ?? {};
        });

        this.selected = newValue;
      },
    },
  },
};
</script>
