export default {
  setFilter({ commit }, data = {}) {
    localStorage.setItem("permitRequestsStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },
  setPagination({ commit }, data = {}) {
    localStorage.setItem("permitRequestsStatePagination", JSON.stringify(data));
    commit("setPagination", data);
  },
};
