export default {
  setLoadingGetObjectTypes(state, payload) {
    state.isPendingGetObjectTypes = payload.type === "IS_PENDING";
    state.isSuccessGetObjectTypes = payload.type === "IS_SUCCESS";
    state.isFailureGetObjectTypes = payload.type === "IS_FAILURE";
    state.errorMessageGetObjectTypes = payload.payload;
  },

  setObjectTypes(state, payload) {
    state.objectTypes = payload;
  },

  setLoadingGetObject(state, { type, payload = "" }) {
    state.isPendingGetObject = type === "IS_PENDING";
    state.isSuccessGetObject = type === "IS_SUCCESS";
    state.isFailureGetObject = type === "IS_FAILURE";
    state.errorMessageGetObject = payload;
  },

  setObject(state, payload) {
    state.object = payload;
  },

  setLoadingAddObject(state, { type, payload = "" }) {
    state.isPendingAddObject = type === "IS_PENDING";
    state.isSuccessAddObject = type === "IS_SUCCESS";
    state.isFailureAddObject = type === "IS_FAILURE";
    state.errorMessageAddObject = payload;
  },

  setLoadingUpdateObject(state, { type, payload = "" }) {
    state.isPendingUpdateObject = type === "IS_PENDING";
    state.isSuccessUpdateObject = type === "IS_SUCCESS";
    state.isFailureUpdateObject = type === "IS_FAILURE";
    state.errorMessageUpdateObject = payload;
  },

  setLoadingGetObjectsList(state, { type, payload = "" }) {
    state.isPendingGetObjectsList = type === "IS_PENDING";
    state.isSuccessGetObjectsList = type === "IS_SUCCESS";
    state.isFailureGetObjectsList = type === "IS_FAILURE";
    state.errorMessageGetObjectsList = payload;
  },

  setObjectsList(state, payload) {
    state.objectsList = payload;
  },

  setLoadingDeleteObject(state, { type, payload = "" }) {
    state.isPendingDeleteObject = type === "IS_PENDING";
    state.isSuccessDeleteObject = type === "IS_SUCCESS";
    state.isFailureDeleteObject = type === "IS_FAILURE";
    state.errorMessageDeleteObject = payload;
  },

  setLoadingRestoreObject(state, { type, payload = "" }) {
    state.isPendingRestoreObject = type === "IS_PENDING";
    state.isSuccessRestoreObject = type === "IS_SUCCESS";
    state.isFailureRestoreObject = type === "IS_FAILURE";
    state.errorMessageRestoreObject = payload;
  },

  setLoadingBlackObject(state, { type, payload = "" }) {
    state.isPendingBlackObject = type === "IS_PENDING";
    state.isSuccessBlackObject = type === "IS_SUCCESS";
    state.isFailureBlackObject = type === "IS_FAILURE";
    state.errorMessageBlackObject = payload;
  },

  setLoadingUnblackObject(state, { type, payload = "" }) {
    state.isPendingUnblackObject = type === "IS_PENDING";
    state.isSuccessUnblackObject = type === "IS_SUCCESS";
    state.isFailureUnblackObject = type === "IS_FAILURE";
    state.errorMessageUnblackObject = payload;
  },

  setLoadingBlockObject(state, { type, payload = "" }) {
    state.isPendingBlockObject = type === "IS_PENDING";
    state.isSuccessBlockObject = type === "IS_SUCCESS";
    state.isFailureBlockObject = type === "IS_FAILURE";
    state.errorMessageBlockObject = payload;
  },

  setLoadingUnblockObject(state, { type, payload = "" }) {
    state.isPendingUnblockObject = type === "IS_PENDING";
    state.isSuccessUnblockObject = type === "IS_SUCCESS";
    state.isFailureUnblockObject = type === "IS_FAILURE";
    state.errorMessageUnblockObject = payload;
  },

  setLoadingReportObject(state, { type, payload = "" }) {
    state.isPendingReportObject = type === "IS_PENDING";
    state.isSuccessReportObject = type === "IS_SUCCESS";
    state.isFailureReportObject = type === "IS_FAILURE";
    state.errorMessageReportObject = payload;
  },

  setObjectHistory(state, payload) {
    state.objectHistory = payload;
  },

  setLoadingGetObjectHistory(state, { type, payload = "" }) {
    state.isPendingGetObjectHistory = type === "IS_PENDING";
    state.isSuccessGetObjectHistory = type === "IS_SUCCESS";
    state.isFailureGetObjectHistory = type === "IS_FAILURE";
    state.errorMessageGetObjectHistory = payload;
  },
};
