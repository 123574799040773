<template>
  <v-row v-if="isFailureDeleteObjectState">
    <v-col>
      <Alert type="error">
        {{ errorMessageDeleteObjectState }}
      </Alert>
    </v-col>
  </v-row>

  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" md="4">
        <AvatarCard v-model="photoComputed" />
      </v-col>

      <v-col style="z-index: 1" cols="12" md="8">
        <v-card
          :loading="isPending || isPendingDeleteObjectState"
          :disabled="isDisabled"
          class="overflow-visible"
          elevation="2"
        >
          <template #loader="{ isActive }">
            <v-progress-linear
              v-if="isActive"
              color="primary"
              height="10"
              indeterminate
            />
          </template>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <FullName
                  v-model:surname="lastNameComputed"
                  v-model:name="firstNameComputed"
                  v-model:patronymic="middleNameComputed"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <CustomFields
                  v-model="customFieldsComputed"
                  :settings="{ object_id: modelValue.id, isObject: true }"
                  :fields="curObjectType?.fields"
                  :show="false"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn @click="onCancelForm">
              <v-icon start>mdi-cancel</v-icon>
              {{ $t("button.cancel") }}
            </v-btn>

            <v-spacer />
            <v-col v-if="id && isPersonDelete" cols="auto">
              <v-btn variant="flat" color="error" @click="onRemove">
                {{ $t("button.delete") }}
              </v-btn>
            </v-col>
            <v-btn color="success" variant="flat" @click="save">
              <v-icon start>mdi-content-save-outline</v-icon>
              {{ $t("button.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";

import AvatarCard from "./AvatarCard";

import { CustomFields } from "@/components/ObjectsTools";
import FullName from "@/components/fullname";

import permissions from "@/mixins/permissions";

export default {
  name: "PersonForm",

  components: {
    FullName,
    AvatarCard,
    CustomFields,
  },

  mixins: [permissions],

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    isPending: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      BREAK_STRING_CHAR: "&*|",
      valid: false,

      id: undefined,
    };
  },

  methods: {
    ...mapActions({
      getObjectTypesAction: "object/getObjectTypes",

      resetDeleteObjectAction: "object/resetDeleteObject",
      deleteObjectAction: "object/deleteObject",
    }),

    getId() {
      this.id = parseInt(this.$route.params.id);
    },

    getObjectTypes() {
      this.getObjectTypesAction().then(() => {
        if (this.modelValue.object_types_id) return;

        const curType = structuredClone(this.objectTypesState[0]);

        const curValues = [];

        curType.fields.forEach((field) => {
          curValues.push({
            type: field.type,
            name: field.name,
            value: field.value,
            input_value: field.input_value,
          });
        });

        const params = {
          ...this.modelValue,
          values: curValues,
          object_types_id: curType.id,
        };

        this.updateModelValue(params);
      });
    },

    onRemove() {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),
        agree: this.removeObject,
      });
    },

    removeObject() {
      const params = {
        ids: [this.id],
      };

      this.deleteObjectAction(params).then(this.onCancelForm);
    },

    onCancelForm() {
      this.$emit("cancelForm");
    },

    async save() {
      let valid = false;
      await this.$refs.form.validate().then((result) => {
        valid = result.valid;
      });
      if (!valid) return;

      this.$emit("SaveForm");
    },

    updateModelValue(params) {
      this.$emit("update:modelValue", params);
    },
  },

  computed: {
    ...mapState({
      objectTypesState: (state) => state.object.objectTypes,
      isPendingDeleteObjectState: (state) => state.object.isPendingDeleteObject,
      isFailureDeleteObjectState: (state) => state.object.isFailureDeleteObject,
      errorMessageDeleteObjectState: (state) =>
        state.object.errorMessageDeleteObject,
    }),

    curObjectType() {
      return this.objectTypesState.find(
        (type) => type.id == this.modelValue.object_types_id
      );
    },

    firstNameComputed: {
      get() {
        return this.modelValue.name?.split(this.BREAK_STRING_CHAR)[1] ?? "";
      },
      set(newValue) {
        let parseName = this.modelValue.name?.split(this.BREAK_STRING_CHAR);

        if (parseName?.length) {
          parseName[1] = newValue;
        } else {
          parseName = ["", newValue, ""];
        }

        parseName = parseName.join(this.BREAK_STRING_CHAR);

        const params = {
          ...this.modelValue,
          name: parseName,
        };

        this.updateModelValue(params);
      },
    },

    lastNameComputed: {
      get() {
        return this.modelValue.name?.split(this.BREAK_STRING_CHAR)[0] ?? "";
      },
      set(newValue) {
        let parseName = this.modelValue.name?.split(this.BREAK_STRING_CHAR);

        if (parseName?.length) {
          parseName[0] = newValue;
        } else {
          parseName = [newValue, "", ""];
        }

        parseName = parseName.join(this.BREAK_STRING_CHAR);

        const params = {
          ...this.modelValue,
          name: parseName,
        };

        this.updateModelValue(params);
      },
    },

    middleNameComputed: {
      get() {
        return this.modelValue.name?.split(this.BREAK_STRING_CHAR)[2] ?? "";
      },
      set(newValue) {
        let parseName = this.modelValue.name?.split(this.BREAK_STRING_CHAR);

        if (parseName?.length) {
          parseName[2] = newValue;
        } else {
          parseName = ["", "", newValue];
        }

        parseName = parseName.join(this.BREAK_STRING_CHAR);

        const params = {
          ...this.modelValue,
          name: parseName,
        };

        this.updateModelValue(params);
      },
    },

    photoComputed: {
      get() {
        return this.modelValue.photo;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          photo: newValue,
          photo_encrypted: newValue,
        };

        this.updateModelValue(params);
      },
    },

    customFieldsComputed: {
      get() {
        return this.modelValue.values;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          values: newValue,
        };

        this.$emit("update:modelValue", params);
      },
    },

    isPersonDelete() {
      return this.can(this.$const.RULES.OBJECT_DELETE);
    },
  },

  created() {
    this.getId();
    this.getObjectTypes();
  },

  unmounted() {
    this.resetDeleteObjectAction();
  },
};
</script>
