<template>
  <template v-if="name.show">
    <router-link
      v-if="item.objects_id && can(this.$const.RULES.OBJECT_VIEW)"
      :class="name.class"
      class="text-primary"
      :to="{
        name: $const.ROUTES.OBJECT_SHOW,
        params: { id: item.objects_id },
      }"
      >{{ item.title }}</router-link
    >
    <div v-else :class="name.class" class="text-primary">
      {{ item.title }}
    </div>
  </template>

  <template v-if="purpose.direction?.show">
    <div v-if="item.purpose || item.utype" :class="purpose.class">
      <span v-if="item.purposeType == 'exit'" :class="purpose.direction.class">
        {{ $t("device.purpose_exit") }}
      </span>
      <span v-else :class="purpose.direction.class">
        {{ $t("device.purpose_entrance") }}
      </span>
      ({{ item.purpose }} {{ item.utype }})
    </div>
  </template>
  <template v-else-if="purpose.show">
    <div v-if="item.purpose || item.utype" :class="purpose.class">
      {{ item.purpose }} {{ item.utype }}
    </div>
  </template>

  <template v-if="organization.show">
    <div
      v-for="org in item.organization"
      :key="org.name"
      :class="organization.class"
    >
      {{ org.name }}
    </div>
  </template>

  <template v-if="typeUser.show">
    <div v-if="item.profile_name" :class="typeUser.class">
      {{ item.profile_name }}
    </div>
  </template>

  <template v-if="temperature.show">
    <div v-if="item.temperature" :class="temperature.class">
      {{ item.temperature }}
    </div>
  </template>

  <template v-if="info.show">
    <div v-if="item.info" :class="info.class">
      {{ item.info }}
    </div>
  </template>

  <template v-if="formatCreatedAt.show">
    <div :class="formatCreatedAt.class">
      {{ item.formatCreatedAt }}
    </div>
  </template>
</template>
<script>
import permission from "@/mixins/permissions";
export default {
  mixins: [permission],
  props: {
    item: {
      type: Object,
      default: () => {},
    },

    name: {
      type: Object,
      default: () => {
        return {
          show: true,
          class: "text-subtitle-2",
        };
      },
    },
    purpose: {
      type: Object,
      default: () => {
        return {
          show: true,
          class: "",
          direction: {
            show: false,
            class: "",
          },
        };
      },
    },
    temperature: {
      type: Object,
      default: () => {
        return {
          show: true,
          class: "",
        };
      },
    },
    organization: {
      type: Object,
      default: () => {
        return {
          show: false,
          class: "",
        };
      },
    },
    typeUser: {
      type: Object,
      default: () => {
        return {
          show: false,
          class: "",
        };
      },
    },
    info: {
      type: Object,
      default: () => {
        return {
          show: false,
          class: "",
        };
      },
    },
    formatCreatedAt: {
      type: Object,
      default: () => {
        return {
          show: false,
          class: "",
        };
      },
    },
  },
};
</script>
