export default {
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  setLoadingVisits(state, { type, payload = "" }) {
    state.isPendingVisits = type === "IS_PENDING";
    state.isSuccessVisits = type === "IS_SUCCESS";
    state.isFailureVisits = type === "IS_FAILURE";
    state.errorMessageVisits = payload;
  },
  setVisits(state, payload) {
    state.visits = payload;
  },
  setLoadingIndoors(state, { type, payload = "" }) {
    state.isPendingIndoors = type === "IS_PENDING";
    state.isSuccessIndoors = type === "IS_SUCCESS";
    state.isFailureIndoors = type === "IS_FAILURE";
    state.errorMessageIndoors = payload;
  },
  setIndoors(state, payload) {
    state.indoors = payload;
  },
  setIndoorsPagination(state, payload) {
    state.indoorsPagination = payload;
  },
  setIndoorsLimit(state, payload) {
    state.limitIndoors = payload;
  },
  setLoadingEventLogs(state, { type, payload = "" }) {
    state.isPendingEventLogs = type === "IS_PENDING";
    state.isSuccessEventLogs = type === "IS_SUCCESS";
    state.isFailureEventLogs = type === "IS_FAILURE";
    state.errorMessageEventLogs = payload;
  },
  setEventLogs(state, payload) {
    state.eventLogs = payload;
  },
  setEventLogsPagination(state, payload) {
    state.eventLogsPagination = payload;
  },
  setEventLogsLimit(state, payload) {
    state.limitEventLogs = payload;
  },
  setLoadingCloseVisits(state, { type, payload = "" }) {
    state.isPendingCloseVisits = type === "IS_PENDING";
    state.isSuccessCloseVisits = type === "IS_SUCCESS";
    state.isFailureCloseVisits = type === "IS_FAILURE";
    state.errorMessageCloseVisits = payload;
  },
};
