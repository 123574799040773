import walkTree from "@/mixins/walkTree";

export default {
  mixins: [walkTree],

  methods: {
    clearModel(values, filterTemplate) {
      const activeKeys = filterTemplate.map((item) => item.param);
      let activeValues = {};
      let clearedValue = {};
      let isUpdate = false;

      for (let key in values) {
        if (activeKeys.includes(key)) {
          activeValues[key] = values[key];
        } else {
          isUpdate = true;
        }
      }

      clearedValue = this.clearValues(activeValues, filterTemplate);

      activeValues = clearedValue.data;

      return { data: activeValues, needUpdate: clearedValue.needUpdate };
    },

    clearValues(values, filterTemplate) {
      const curValues = {};
      let clearedValue = undefined;
      let needUpdate = false;

      filterTemplate.map((item) => {
        switch (item.type) {
          case "tree":
            clearedValue = this.clearTreeValues(values[item.param], item);
            curValues[item.param] = clearedValue.data;
            needUpdate = clearedValue.needUpdate
              ? clearedValue.needUpdate
              : needUpdate;
            break;

          case "checkboxes":
          case "select":
            clearedValue = this.clearListValues(values[item.param], item);
            curValues[item.param] = clearedValue.data;
            needUpdate = clearedValue.needUpdate
              ? clearedValue.needUpdate
              : needUpdate;
            break;

          default:
            curValues[item.param] = values[item.param];
            break;
        }
      });

      return { data: curValues, needUpdate };
    },

    clearTreeValues(value, item) {
      if (typeof value == "undefined")
        return { data: undefined, needUpdate: false };

      const activeKeys = [];
      const curValues = [];
      let needUpdate = false;

      this.walkArray(item.values, (valueItem) => {
        activeKeys.push(valueItem.id);
      });

      value.forEach((valueItem) => {
        if (activeKeys.includes(valueItem)) {
          curValues.push(valueItem);
        } else {
          needUpdate = true;
        }
      });

      return { data: curValues, needUpdate };
    },

    clearListValues(value, item) {
      if (typeof value == "undefined")
        return { data: undefined, needUpdate: false };

      const activeKeys = [];
      let curValue = value;
      let needUpdate = false;

      item.values.forEach((value) => {
        activeKeys.push(value.id);
      });

      if (typeof value == Array) {
        curValue = [];
        value.forEach((valueItem) => {
          if (activeKeys.includes(valueItem)) {
            curValue.push(valueItem);
          } else {
            needUpdate = true;
          }
        });
      } else if (typeof value == String) {
        if (activeKeys.includes(value)) {
          curValue = value;
        } else {
          needUpdate = true;
        }
      }

      return { data: curValue, needUpdate };
    },
  },
};
