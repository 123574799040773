export default {
  setPageData({ commit }, data = {}) {
    localStorage.setItem("operatorsStatePageData", JSON.stringify(data));
    commit("setPageData", data);
  },
  setFilter({ commit }, data = {}) {
    localStorage.setItem("operatorsStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },
  setPagination({ commit }, data = {}) {
    localStorage.setItem("operatorsStatePagination", JSON.stringify(data));

    commit("setPagination", data);
  },
};
