import i18n from "@/plugins/i18n";
import { useToast } from "vue-toastification";
const $toast = useToast();

export const errorHandler = (error, commit, type) => {
  const message =
    error?.response?.data?.status?.message || i18n.t("message.deleteError");

  commit("setLoading", {
    type,
    payload: message,
  });

  $toast.error(message);
};

export const jsonParse = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    $toast.error(i18n.global.t("message.errorLoading"));
    return null;
  }
};
