export default {
  getListExcludeById: (state) => (id) => {
    return state.list.filter((item) => item.id !== id);
  },

  getZoneById: (state) => (id) => {
    let result = {
      name: "(unknown)",
    };

    if (id) {
      const filteredZone = state.list.filter((item) => item.id === id);

      if (filteredZone.length) {
        result = filteredZone[0];
      }
    }

    return result;
  },
};
