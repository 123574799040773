export default {
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  set(state, payload) {
    state.data = payload;
  },

  setLimit(state, limit) {
    localStorage.setItem("devicesLimit", limit);
    state.limit = limit;
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },

  setLoadingScan(state, payload) {
    state.isPendingScan = payload.type === "IS_PENDING";
    state.isSuccessScan = payload.type === "IS_SUCCESS";
    state.isFailureScan = payload.type === "IS_FAILURE";
    state.errorMessageScan = payload.payload;
  },

  setLoadingSort(state, payload) {
    state.isPendingSort = payload.type === "IS_PENDING";
    state.isSuccessSort = payload.type === "IS_SUCCESS";
    state.isFailureSort = payload.type === "IS_FAILURE";
    state.errorMessageSort = payload.payload;
  },

  setLoadingMonitoring(state, payload) {
    state.isPendingMonitoring = payload.type === "IS_PENDING";
    state.isSuccessMonitoring = payload.type === "IS_SUCCESS";
    state.isFailureMonitoring = payload.type === "IS_FAILURE";
    state.errorMessageMonitoring = payload.payload;
  },

  setMonitoring(state, payload) {
    state.dataMonitoring = payload;
  },

  setScannedDevices(state, payload) {
    state.scannedDevices = payload;
  },

  setAllDevicesIp(state, payload) {
    state.allDevicesIp = payload;
  },

  setFilterMinify(state, payload) {
    state.isMinifyFilter = payload;
    localStorage.setItem("minifyDevicesFilter", payload);
  },

  setLoadingStatistics(state, payload) {
    state.isPendingStatistics = payload.type === "IS_PENDING";
    state.isSuccessStatistics = payload.type === "IS_SUCCESS";
    state.isFailureStatistics = payload.type === "IS_FAILURE";
    state.errorMessageStatistics = payload.payload;
  },

  setStatistics(state, payload) {
    state.dataStatistics = payload;
  },
};
