export default {
  //Старое хранилище api
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  set(state, { data, pagination }) {
    state.data = data;
    state.pagination = pagination;
  },

  setLimit(state, limit) {
    localStorage.setItem("operatorsLimit", limit);
    state.limit = limit;
  },

  //Новое хранилище api
  setLoadingGetOperatorsList(state, { type, payload = "" }) {
    state.isPendingGetOperatorsList = type === "IS_PENDING";
    state.isSuccessGetOperatorsList = type === "IS_SUCCESS";
    state.isFailureGetOperatorsList = type === "IS_FAILURE";
    state.errorMessageGetOperatorsList = payload;
  },

  setOperatorsList(state, payload) {
    state.operatorsList = payload;
  },
};
