export default {
  setPageData(state, data) {
    state.data = data;
  },
  setFilter(state, data) {
    state.filter = data;
  },
  setPagination(state, data) {
    state.pagination = { ...state.pagination, ...data };
  },
};
