export default {
  setPageData({ commit }, data = {}) {
    localStorage.setItem("accessModesStatePageData", JSON.stringify(data));
    commit("setPageData", data);
  },
  setFilter({ commit }, data = {}) {
    localStorage.setItem("accessModesStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },
  setPagination({ commit }, data = {}) {
    localStorage.setItem("accessModesStatePagination", JSON.stringify(data));
    commit("setPagination", data);
  },
};
