<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <v-app>
        <Vue3ExtendLayouts v-if="$auth.check()" />
        <Login v-else-if="$auth.ready()" />

        <div>
          <ReloadApp :show="isNewVersion" />
        </div>

        <PromptDialog ref="prompt" />
      </v-app>
    </el-config-provider>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import routerHooks from "./router-hooks";

import Vue3ExtendLayouts from "@/components/vue3-extend-layout";
import Login from "@/components/Login";
import ReloadApp from "@/components/ReloadApp";
import PromptDialog from "@/components/PromptDialog";

import switchLang from "@/mixins/switchLang";
import { ElConfigProvider } from "element-plus";

export default {
  mixins: [switchLang, routerHooks],

  components: {
    ReloadApp,
    ElConfigProvider,
    Login,
    Vue3ExtendLayouts,
    PromptDialog,
  },

  data() {
    return {
      isNewVersion: false,
    };
  },

  watch: {
    token: {
      handler: function (token) {
        if (token) {
          if (window.$ws) window.$ws.close();
          this.websocketCreate(token);
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      setCurVersionAction: "stateSystem/setCurVersion",
    }),

    async getSettings(isFirstLoad) {
      const version = this.settings?.version;

      await this.$store.dispatch("settings/get");

      this.switchLang(this.settings.lang);

      if (!isFirstLoad) {
        if (this.settings.version !== version) {
          this.isNewVersion = true;
        } else {
          this.isNewVersion = false;
        }
      }
      this.checkNewVersion(this.settings.version);
    },

    checkNewVersion(newVersion) {
      if (this.curVersionState != newVersion) {
        this.$auth.logout();
        localStorage.clear();
        this.setCurVersionAction(this.settings.version);
        this.$router.go();
      }
    },

    websocketCreate(token) {
      let protocol = "ws://";
      if (window.location.protocol == "https:") {
        protocol = "wss://";
      }
      window.$ws = new WebSocket(
        `${protocol}${window.location.host}/websocket?token=${token}`
      );
      window.$ws.onmessage = (event) => {
        try {
          const msg = JSON.parse(event.data);
          //Первый поток
          this.$emitter.emit(msg.Event, msg.Args);
          //Второй поток
          this.$emitter.emit(`ws2-${msg.Event}`, msg.Args);
        } catch (error) {
          return;
        }
      };
    },

    onMenuSizeToggle() {
      this.isMenuMinimize = !this.isMenuMinimize;

      localStorage.setItem("isMenuMinimize", this.isMenuMinimize);
    },
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
      token: (state) => state.user.token,
      curVersionState: (state) => state.stateSystem.curVersion,
    }),
  },

  created() {
    this.getSettings(true);

    setInterval(() => {
      this.getSettings();
    }, 600000); // получаем настройки каждые 10 минут чтобы проверить актуальность версии
  },

  mounted() {
    this.$root.$prompt = this.$refs.prompt.open;
  },
};
</script>

<style lang="scss">
@import "assets/sass/app.scss";
</style>
