import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  //Старое хранилище api
  getList({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("zones", { params })
      .then((response) => {
        const { data } = response.data;

        commit("setList", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  getTree({ commit }) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("zones/tree")
      .then((response) => {
        const { data } = response.data;

        commit("setTree", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  //Новое хранилище api

  resetGetZonesList({ commit }) {
    commit("setLoadingGetZonesList", { type: "" });
    commit("setZonesList", []);
  },

  getZonesList({ commit }, params) {
    commit("setLoadingGetZonesList", { type: "IS_PENDING" });

    return $axios("zones", { params })
      .then((response) => {
        const data = response.data;

        commit("setZonesList", data.data);
        commit("setLoadingGetZonesList", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetZonesList", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  resetGetZonesTree({ commit }) {
    commit("setLoadingGetZonesTree", { type: "" });
    commit("setZonesTree", []);
  },

  getZonesTree({ commit }, params) {
    commit("setLoadingGetZonesTree", { type: "IS_PENDING" });

    return $axios("zones/tree", { params })
      .then((response) => {
        const data = response.data;

        commit("setZonesTree", data.data);
        commit("setLoadingGetZonesTree", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetZonesTree", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },
};
