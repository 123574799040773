<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="accessmodes"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col cols="12" :lg="!pageData.isMini ? 9 : 12">
      <v-row
        v-if="isFailureDeleteAccessModeState || isFailureGetAccessModesState"
      >
        <v-col v-if="isFailureDeleteAccessModeState" cols="12">
          <Alert type="error">
            {{ errorMessageDeleteAccessModesState }}
          </Alert>
        </v-col>
        <v-col v-if="isFailureGetAccessModesState" cols="12">
          <Alert type="error">
            {{ errorMessageGetAccessModesState }}
          </Alert>
        </v-col>
      </v-row>
      <v-row v-if="can($const.RULES.ACCESS_MODES_ADD)">
        <v-spacer />
        <v-col cols="auto">
          <v-btn color="primary" @click="goToAdd">
            <v-icon start> mdi-plus </v-icon>
            {{ $t("departments.addNew") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :loading="
              isPendingAccessModesState || isPendingDeleteAccessModeState
            "
            :headers="headers"
            :items="dataAccessModes"
            :items-per-page="pagination.limit"
            disable-sort
          >
            <template #loader="{ isActive }">
              <v-progress-linear
                v-if="isActive"
                color="primary"
                height="10"
                indeterminate
              />
            </template>
            <template #[`item.name`]="{ item }">
              <router-link
                v-if="can($const.RULES.ACCESS_MODES_EDIT)"
                class="text-primary"
                :to="{
                  name: $const.ROUTES.EDIT_ACCESS_MODES,
                  params: {
                    id: item.id,
                  },
                }"
              >
                {{ item.name }}
              </router-link>
              <div v-else class="text-primary">
                {{ item.name }}
              </div>
            </template>

            <template #[`item.access-levels`]="{ item }">
              <v-row class="ma-n1 justify-center">
                <ToggleArrayItems :items="item.accesslevels" :minLength="3">
                  <template #default="{ items }">
                    <v-col
                      v-for="accessLevel in items"
                      :key="accessLevel.id"
                      cols="auto"
                      class="pa-1"
                    >
                      <v-chip
                        size="small"
                        @click="
                          can($const.RULES.ACCESS_LEVELS_EDIT)
                            ? goToEditAccessLevel(accessLevel.id)
                            : undefined
                        "
                      >
                        {{ accessLevel.name }}
                      </v-chip>
                    </v-col>
                  </template>
                  <template #button="{ toggleFull, isFull }">
                    <v-col cols="auto" class="pa-1">
                      <v-btn
                        v-tooltip="
                          isFull ? $t('user.collapse') : $t('user.expand')
                        "
                        size="small"
                        color="primary"
                        variant="tonal"
                        density="comfortable"
                        :ripple="false"
                        :icon="
                          isFull ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'
                        "
                        @click.stop="toggleFull"
                      />
                    </v-col>
                  </template>
                </ToggleArrayItems>
              </v-row>
            </template>

            <template #[`item.updated_at`]="{ item }">
              {{ $moment(item.updated_at).format("DD.MM.YYYY HH:mm") }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip
                v-if="can($const.RULES.ACCESS_MODES_EDIT)"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="primary"
                    @click="goToEdit(item.id)"
                  >
                    <v-icon size="x-small">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("departments.change") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="can($const.RULES.ACCESS_MODES_DELETE)"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="red"
                    @click="onRemove(item.id)"
                  >
                    <v-icon size="small">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("departments.delete") }}</span>
              </v-tooltip>
            </template>

            <template #bottom
              ><DataTableFooterV2
                v-model="pagination"
                @update:modelValue="onPagination"
            /></template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";

import Alert from "@/components/Alert";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";
import ToggleArrayItems from "@/components/ToggleArrayItems.vue";

import permissions from "@/mixins/permissions";

export default {
  components: { Alert, DataTableFooterV2, Filter, ToggleArrayItems },

  mixins: [permissions],

  data() {
    return {
      dataAccessModes: [],

      headers: [
        {
          title: "ID",
          value: "id",
        },
        {
          title: this.$t("access-levels.name"),
          value: "name",
        },
        {
          title: this.$t("access-levels.access-level"),
          value: "access-levels",
          align: "center",
          cellProps: {
            class: "pa-2",
          },
        },
        {
          title: this.$t("access-modes.updated_at"),
          value: "updated_at",
        },
        {
          value: "actions",
          align: "right",
          width: 100,
        },
      ],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      getAccessModesActions: "accessModes/getAccessModes",
      deleteAccessModeAction: "accessModes/deleteAccessMode",

      setStatePageDataAction: "stateAccessModes/setPageData",
      setStateFilterAction: "stateAccessModes/setFilter",
      setStatePaginationAction: "stateAccessModes/setPagination",
    }),

    getAccessModes() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getAccessModesActions(params).then((response) => {
        this.dataAccessModes = this.dataAccessModesState;

        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;

          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.deleteAccessModeAction(id);
          this.getAccessModes();
        },
      });
    },

    goToEditAccessLevel(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ACCESS_LEVEL,
        params: {
          id,
        },
      });
    },

    goToAdd() {
      this.$router.push({ name: this.$const.ROUTES.ADD_ACCESS_MODES });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ACCESS_MODES,
        params: {
          id,
        },
      });
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния таблицы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },

    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };

      this.setStatePaginationAction(this.pagination);
    },
    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getAccessModes();
    },
  },

  computed: {
    ...mapState({
      isPendingAccessModesState: (state) =>
        state.accessModes.isPendingGetAccessModes,
      isFailureGetAccessModesState: (state) =>
        state.accessModes.isFailureGetAccessModes,
      errorMessageGetAccessModesState: (state) =>
        state.accessModes.errorMessageGetAccessModes,
      dataAccessModesState: (state) => state.accessModes.dataAccessModes,

      isPendingDeleteAccessModeState: (state) =>
        state.accessModes.isPendingDeleteAccessMode,
      isFailureDeleteAccessModeState: (state) =>
        state.accessModes.isFailureDeleteAccessMode,
      errorMessageDeleteAccessModesState: (state) =>
        state.accessModes.errorMessageDeleteAccessMode,

      statePageData: (state) => state.stateAccessModes.data,
      stateFilter: (state) => state.stateAccessModes.filter,
      statePagination: (state) => state.stateAccessModes.pagination,
    }),
  },

  created() {
    this.getState();
    this.getAccessModes();
  },
};
</script>
