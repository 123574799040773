<template>
  <v-row
    v-if="entrance && Object.keys(entrance).length"
    :class="indicator(entrance)"
    class="ma-0 pos-relative"
  >
    <EventContent
      :item="entrance"
      :name="{
        show: false,
      }"
      :temperature="{
        show: false,
      }"
      :purpose="{
        show: false,
        class:
          'user-card__badge py-1 px-3 text-center bg-grey-lighten-3 rounded-md rounded-t-0',
        direction: { show: true, class: 'text-uppercase font-weight-bold' },
      }"
    />
    <v-col cols="12" md="6" class="pa-0">
      <EventCardAvatar :item="entrance" />
    </v-col>
    <v-col cols="12" md="6" align-self="center">
      <div class="flex-grow-1 d-flex pa-3 ml-4 flex-column justify-center">
        <EventActions :item="entrance" :isIcons="false" @unblock="unblock" />

        <div class="mb-2 text-center">
          <EventPurposeType :item="entrance" />
          <EventIdentityTypes :item="entrance" />
        </div>

        <EventContent
          :item="entrance"
          :name="{
            show: true,
            class: 'text-center font-weight-bold text-h6',
          }"
          :purpose="{ show: false }"
          :organization="{
            show: true,
            class: 'text-center mt-1 text-caption font-weight-bold',
          }"
          :typeUser="{
            show: true,
            class: 'text-center mt-1 text-caption',
          }"
          :info="{ show: true, class: 'text-center mt-1 text-caption' }"
          :formatCreatedAt="{
            show: true,
            class: 'text-center mt-1 text-caption',
          }"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  EventCardAvatar,
  EventIdentityTypes,
  EventPurposeType,
  EventActions,
  EventContent,
  EventIndicator,
} from "@/components/EventsTools";

export default {
  components: {
    EventPurposeType,
    EventCardAvatar,
    EventIdentityTypes,
    EventActions,
    EventContent,
  },

  mixins: [EventIndicator],

  props: {
    entrance: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    unblock(data) {
      this.$emit("unblock", data);
    },
  },
};
</script>
