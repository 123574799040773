export default {
  setLoadingGetAccessLinks(state, { type, payload = "" }) {
    state.isPendingGetAccessLinks = type === "IS_PENDING";
    state.isSuccessGetAccessLinks = type === "IS_SUCCESS";
    state.isFailureGetAccessLinks = type === "IS_FAILURE";
    state.errorMessageGetAccessLinks = payload;
  },

  setAccessLinks(state, payload) {
    state.accessLinks = payload;
  },
};
