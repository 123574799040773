<template>
  <!--Show-->
  <v-card v-if="!isEdit" :ripple="false" @click="setIsEdit">
    <v-card-title>
      <v-row>
        <v-col class="text-h6">{{ name }}</v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            :ripple="false"
            density="comfortable"
            icon="mdi-close"
            color="error"
            variant="plain"
            @click.stop="deleteField"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">{{ $t("object-type.select-item-value") }}</v-col>
        <v-col cols="6">{{ value }}</v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <!--Edit-->
  <v-card
    v-else
    :variant="isNew ? 'outlined' : undefined"
    :color="isNew ? 'success' : undefined"
  >
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="nameComputed"
            :label="`${$t('object-type.select-item-name')}*`"
            :color="isNew ? 'success' : 'primary'"
            :error-messages="nameErrors"
            variant="outlined"
            density="comfortable"
            hide-details="auto"
            @update:modelValue="v$.name.$touch()"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="valueComputed"
            :label="`${$t('object-type.select-item-value')}*`"
            :color="isNew ? 'success' : 'primary'"
            :error-messages="valueErrors"
            variant="outlined"
            density="comfortable"
            hide-details="auto"
            @update:modelValue="v$.value.$touch()"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="auto">
          <v-btn variant="plain" color="black" @click.stop="goBack">{{
            $t("button.cancel")
          }}</v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            :disabled="v$.$invalid"
            variant="tonal"
            flat
            color="success"
            @click.stop="saveData"
            >{{ $t("button.save") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import SelectField from "../validation/SelectField";

export default {
  mixins: [SelectField],

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },

    isNew: {
      type: Boolean,
      default: false,
    },

    fieldValue: {
      type: Object,
      default: () => {},
    },

    allSelectItems: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    fieldValue(newValue) {
      this.getData(newValue);
    },
  },

  methods: {
    getData(newValue) {
      const curData = structuredClone(newValue);
      this.name = curData.label;
      this.value = curData.value;
    },
    deleteField() {
      this.$emit("deleteField");
    },

    setIsEdit() {
      if (!this.isEdit) {
        this.$emit("update:isEdit", true);
      }
    },
    saveData() {
      const curData = {
        label: this.name,
        value: this.value,
      };
      this.$emit("saveCard", curData);
    },
    goBack() {
      this.getData(this.fieldValue);
      this.$emit("cancelCard");
    },
  },

  computed: {
    nameComputed: {
      get() {
        return this.name || "";
      },
      set(newValue) {
        this.name = newValue;
      },
    },
    valueComputed: {
      get() {
        return this.value || "";
      },
      set(newValue) {
        this.value = newValue;
      },
    },
  },

  created() {
    this.getData(this.fieldValue);
  },
};
</script>
