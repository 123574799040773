<template>
  <div>
    <h4>{{ $t("devices.alarmPinsTriggers") }}</h4>
    <v-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <th v-for="pin in pins" :key="pin.id" class="text-left">
              <v-text-field
                v-model="pin.name"
                density="comfortable"
                variant="outlined"
                color="primary"
                hide-details
                @update:modelValue="onChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="relay in relays" :key="relay.name">
            <td class="text-no-wrap" style="width: 20%">
              <strong>{{ $t("devices.relay") }} {{ relay.id }}</strong>
            </td>
            <td
              v-for="item in alarmPinsRelayDependenceState"
              :key="item.alarm_id"
            >
              <v-checkbox
                v-model="item.relays"
                :value="relay.id"
                density="comfortable"
                color="primary"
                @update:modelValue="onChange"
              />
            </td>
          </tr>
          <tr>
            <td class="text-no-wrap">
              <strong>{{ $t("devices.typeAlarmInput") }}</strong>
            </td>
            <td v-for="pin in pins" :key="pin.id" class="text-left">
              <v-select
                v-model="pin.type_alarm_input"
                :items="typeAlarmInputOptions"
                item-title="text"
                item-value="value"
                density="comfortable"
                variant="outlined"
                color="primary"
                hide-details
                @update:modelValue="onChange"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
  </div>
</template>

<script>
export default {
  name: "AlarmPins",

  props: {
    relays: {
      type: Array,
      default: () => [],
    },

    alarmPins: {
      type: Array,
      default: () => [],
    },

    alarmPinsRelayDependence: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      pins: this.alarmPins,
      alarmPinsRelayDependenceState: this.alarmPinsRelayDependence,
      typeAlarmInputOptions: [
        { value: "open", text: this.$t("devices.alarmInputOpen") },
        { value: "close", text: this.$t("devices.alarmInputClose") },
      ],
    };
  },

  methods: {
    onChange() {
      const { alarmPinsRelayDependenceState, pins } = this;

      this.$emit("changeState", {
        alarmPinsRelayDependence: alarmPinsRelayDependenceState,
        alarmPins: pins,
      });
    },
  },
};
</script>
