<template>
  <v-row>
    <v-col>
      <el-tree-select
        size="large"
        color="danger"
        v-model="modelValueComputed"
        :data="accessLevels"
        check-on-click-node
        node-key="id"
        placeholder=""
        show-checkbox
        check-strictly
        filterable
        multiple
      />
      <slot name="error-messages" />
    </v-col>
  </v-row>
  <v-row v-if="accessLevelObjects.length">
    <v-col
      v-for="object in accessLevelObjects"
      :key="object.id"
      cols="12"
      md="4"
    >
      <v-hover>
        <template #default="{ props: hoverProps, isHovering }">
          <v-card
            v-bind="hoverProps"
            variant="outlined"
            :class="[isHovering ? 'opacity-100' : 'opacity-50']"
          >
            <v-card-title>
              <v-row>
                <v-col>
                  {{ object.name }}
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <AccessSchedule
                :schedules="object.access_modes"
                :id="object.id"
                @updateSchedules="updateSchedule"
              />
            </v-card-text>
          </v-card>
        </template>
      </v-hover>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="auto">
      <v-btn color="primary" @click="goToAddAccessMode">
        <v-icon start>mdi-calendar-plus-outline</v-icon>
        {{ $t("access-levels.addAccessMode") }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import AccessSchedule from "./AccessSchedule";

import walkTree from "@/mixins/walkTree";

export default {
  mixins: [walkTree],

  components: { AccessSchedule },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    accessLevelObjects: {
      type: Array,
      default: () => [],
    },

    accessLevels: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    updateAccessLevelObjects(newValue) {
      const curValues = [];

      newValue.forEach((id) => {
        const curObject = this.accessLevelObjects.find(
          (object) => object.id == id
        );

        if (curObject) {
          curValues.push(curObject);
          return;
        }

        this.walkArray(this.accessLevels, (accessLevel) => {
          if (accessLevel.id == id) {
            curValues.push(structuredClone(accessLevel));
          }
        });
      });

      return curValues;
    },

    updateSchedule(event) {
      const curValues = this.accessLevelObjects.map((object) => {
        const curObject = object;
        if (object.id == event.id) {
          curObject.access_modes = event.schedule;
        }

        return curObject;
      });

      this.$emit("update:accessLevelObjects", curValues);
    },

    goToAddAccessMode() {
      const route = this.$router.resolve({
        name: this.$const.ROUTES.ADD_ACCESS_MODES,
        query: { close: true },
      });

      window.open(route.href, "_blank");
    },
  },

  computed: {
    modelValueComputed: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        const curAccessLevelObjects = this.updateAccessLevelObjects(newValue);

        this.$emit("update:modelValue", newValue);
        this.$emit("update:accessLevelObjects", curAccessLevelObjects);
      },
    },
  },
};
</script>
