import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  resetGetAccessModes({ commit }) {
    commit("setAccessModes", []);
    commit("setLoadingGetAccessModes", { type: "" });
  },

  getAccessModes({ commit }, params) {
    commit("setLoadingGetAccessModes", { type: "IS_PENDING" });

    return $axios(`accessmodes`, { params })
      .then((response) => {
        const { data } = response.data;

        commit("setAccessModes", data);
        commit("setLoadingGetAccessModes", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetAccessModes", {
          type: "IS_FAILURE",
          payload:
            error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading"),
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },

  resetAddAccessMode({ commit }) {
    commit("setLoadingAddAccessMode", { type: "" });
  },

  addAccessMode({ commit }, data) {
    commit("setLoadingAddAccessMode", { type: "IS_PENDING" });

    return $axios
      .post("accessmodes/add", data)
      .then((response) => {
        commit("setLoadingAddAccessMode", { type: "IS_SUCCESS" });
        $toast.success(i18n.global.t("message.saveSuccess"));
        return response;
      })
      .catch((error) => {
        commit("setLoadingAddAccessMode", {
          type: "IS_FAILURE",
          payload:
            error?.response?.data?.status?.message ||
            i18n.global.t("message.saveError"),
        });

        $toast.error(i18n.global.t("message.saveError"));
        throw error;
      });
  },

  resetGetAccessMode({ commit }) {
    commit("setLoadingGetAccessMode", { type: "" });
    commit("setAccessMode", {});
  },

  getAccessMode({ commit }, id) {
    commit("setLoadingGetAccessMode", { type: "IS_PENDING" });

    return $axios(`accessmode/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("setAccessMode", data);
        commit("setLoadingGetAccessMode", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetAccessMode", {
          type: "IS_FAILURE",
          payload:
            error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading"),
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },

  resetUpdateAccessMode({ commit }) {
    commit("setLoadingUpdateAccessMode", { type: "" });
  },

  updateAccessMode({ commit }, { id, data }) {
    commit("setLoadingUpdateAccessMode", { type: "IS_PENDING" });

    return $axios
      .put(`accessmode/${id}`, data)
      .then((response) => {
        commit("setLoadingUpdateAccessMode", { type: "IS_SUCCESS" });
        $toast.success(i18n.global.t("message.saveSuccess"));
        return response;
      })
      .catch((error) => {
        commit("setLoadingUpdateAccessMode", {
          type: "IS_FAILURE",
          payload:
            error?.response?.data?.status?.message ||
            i18n.global.t("message.saveError"),
        });

        $toast.error(i18n.global.t("message.saveError"));
        throw error;
      });
  },

  resetDeleteAccessMode({ commit }) {
    commit("setLoadingDeleteAccessMode", { type: "" });
  },

  deleteAccessMode({ commit }, id) {
    commit("setLoadingDeleteAccessMode", { type: "IS_PENDING" });

    return $axios
      .delete(`accessmode/${id}`)
      .then((response) => {
        commit("setLoadingDeleteAccessMode", { type: "IS_SUCCESS" });
        $toast.success(i18n.global.t("message.deleteSuccess"));
        return response;
      })
      .catch((error) => {
        commit("setLoadingDeleteAccessMode", {
          type: "IS_FAILURE",
          payload:
            error?.response?.data?.status?.message ||
            i18n.global.t("message.deleteError"),
        });

        $toast.error(i18n.global.t("message.deleteError"));
        throw error;
      });
  },
};
