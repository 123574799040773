<template>
  <v-card
    :color="
      'enable' in channelState && !channelState.enable ? '#F3F2FF' : '#DCD9FD'
    "
    elevation="0"
  >
    <v-card-title class="text-h5">
      <v-row align-content="center">
        <v-col class="h5">{{ title }}</v-col>
        <v-col v-if="'enable' in channelState" cols="auto">
          <v-switch
            v-model="channelState.enable"
            color="primary"
            density="comfortable"
            hide-details
            class="mt-0"
            @update:modelValue="store"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col :cols="12" :md="deviceType != 'AUTOMARSHAL' ? 6 : 12">
          {{ isDisabledForm }}
          <v-text-field
            v-model="channelState.name"
            :label="`${$t('devices.nameDevice')} *`"
            :disabled="isDisabledForm"
            :rules="nameRules"
            variant="outlined"
            color="primary"
            density="comfortable"
            required
            hide-details
            @update:modelValue="store"
          />
        </v-col>
        <v-col :cols="12" :md="6" v-if="deviceType != 'AUTOMARSHAL'">
          <v-text-field
            v-model="uid"
            :label="`${$t('devices.deviceUid')} *`"
            :disabled="isDisabledForm"
            :rules="cameraUidRules"
            variant="outlined"
            color="primary"
            density="comfortable"
            required
            @update:modelValue="store"
          />
        </v-col>
        <v-col :cols="12" v-else>
          <v-switch
            v-model="is_fixed_pass"
            :label="`${$t('devices.fixedPassAuto')}`"
            :disabled="isDisabledForm"
            color="primary"
            density="comfortable"
            required
            @update:modelValue="store"
          />
        </v-col>
      </v-row>
      <v-row v-if="deviceType != 'AUTOMARSHAL'">
        <v-col>
          <v-select
            ref="zones"
            v-model="channelState.zones_id"
            :items="zonesTo"
            item-title="name"
            item-value="id"
            :label="
              channelState.purpose === 'exit'
                ? `${$t('devices.zoneFrom')} *`
                : `${$t('devices.zoneTo')} *`
            "
            :disabled="isDisabledForm"
            variant="outlined"
            color="primary"
            density="comfortable"
            @update:modelValue="store"
          />
        </v-col>
      </v-row>
      <v-row v-if="deviceType != 'AUTOMARSHAL'"
        ><v-col cols="12" md="6">
          {{ $t("devices.face_recognition") }}
          <v-slider
            v-model="faceRecognition"
            class="align-center"
            :max="faceRecognitionMax"
            :min="1"
            step="1"
            :disabled="isDisabledForm"
            color="primary"
            density="comfortable"
            hide-details
            @update:modelValue="store"
          >
            <template v-slot:append>
              <v-text-field
                v-model="faceRecognition"
                class="mt-0 pt-0"
                hide-details
                :max="faceRecognitionMax"
                :min="1"
                step="1"
                type="number"
                style="width: 80px"
                :disabled="isDisabledForm"
                variant="outlined"
                color="primary"
                density="comfortable"
                @update:modelValue="store"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
        <v-col cols="12" md="6">
          {{ $t("devices.recogniion_delay") }}
          <v-slider
            v-model="recognitionTimeOut"
            class="align-center"
            :max="recognitionTimeOutMax"
            :min="1"
            step="1"
            :disabled="isDisabledForm"
            hide-details
            color="primary"
            density="comfortable"
            @update:modelValue="store"
          >
            <template v-slot:append>
              <v-text-field
                v-model="recognitionTimeOut"
                class="mt-0 pt-0"
                hide-details
                :max="recognitionTimeOutMax"
                :min="1"
                step="1"
                type="number"
                style="width: 80px"
                :disabled="isDisabledForm"
                variant="outlined"
                color="primary"
                density="comfortable"
                @update:modelValue="store"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            v-if="isShowRemoveBtn"
            @click="removeChannel()"
            class="mt-0"
            color="error"
            size="small"
          >
            {{ $t("devices.deleteCamera") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { isUUID } from "validator";
export default {
  name: "SmartCameras",
  props: {
    channel: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isShowRemoveBtn: {
      type: Boolean,
      default: true,
    },
    deviceType: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valid: true,
      channelState: this.channel,
      zones: [],
      faceRecognition: 90,
      faceRecognitionMax: 100,
      recognitionTimeOut: 2,
      recognitionTimeOutMax: 60,
      is_fixed_pass: false,
      uid: "",
      cameraUidRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => isUUID(v) || this.$t("message.errorUID"),
      ],
      nameRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => v?.length <= 20 || "Name must be less than 20 characters",
      ],
    };
  },
  methods: {
    getZones() {
      this.axios("zones").then((response) => {
        this.zones = [
          { id: null, name: this.$t("devices.zoneEmpty") },
          ...response.data.data,
        ];
      });
    },
    store() {
      let data = {};
      switch (this.deviceType) {
        case "AUTOMARSHAL":
          this.channelState.is_fixed_pass = this.is_fixed_pass;
          break;
        default:
          data = {
            face_recognition: this.faceRecognition,
            recognition_timeout: this.recognitionTimeOut,
            uid: this.uid,
          };
      }
      this.channelState.data = JSON.stringify(data);
      this.$emit("changeState", this.channelState);
    },
    removeChannel() {
      this.$emit("remove-channel", this.index);
    },
    getStore() {
      if (this.channelState?.data?.length) {
        const data = JSON.parse(this.channelState.data);
        this.uid = data.uid;
        this.faceRecognition = data.face_recognition;
        this.recognitionTimeOut = data.recognition_timeout;
        this.is_fixed_pass = this.channelState.is_fixed_pass;
      }
    },
  },
  computed: {
    zonesTo() {
      return this.zones.filter(
        (zone) => zone.id !== this.channelState.source_id && zone.id !== null
      );
    },
    isDisabledForm() {
      return "enable" in this.channelState ? !this.channelState.enable : false;
    },
  },
  created() {
    if (this.deviceType != "AUTOMARSHAL") this.getZones();
    this.getStore();
    this.store();
  },
};
</script>
