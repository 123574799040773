<template>
  <Alert v-if="isFailureGetProfileTypeState" type="error">
    {{ errorMessageGetProfileTypeState }}
  </Alert>

  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card
      elevation="2"
      :disabled="isPendingGetProfileTypeState || disabled"
      :loading="isPendingGetProfileTypeState"
    >
      <template #loader="{ isActive }">
        <v-progress-linear
          v-if="isActive"
          color="primary"
          height="10"
          indeterminate
        />
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="name"
              :label="`${$t('access-levels.name')}*`"
              :error-messages="nameErrors"
              variant="outlined"
              density="comfortable"
              color="primary"
              hide-details="auto"
              @update:modelValue="v$.name.$touch"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="type"
              :label="`${$t('object-type.type')}*`"
              :error-messages="typeErrors"
              variant="outlined"
              density="comfortable"
              color="primary"
              hide-details="auto"
              @update:modelValue="v$.type.$touch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="description"
              :auto-grow="true"
              :label="$t('object-type.description')"
              variant="outlined"
              density="comfortable"
              color="primary"
              hide-details="auto"
              rows="2"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CreateCustomFields
              v-model="fields"
              v-model:isAdd="isAdd"
              customFieldsType="profile"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn @click="cancelForm">
          <v-icon start>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="v$.$invalid"
          variant="flat"
          color="green"
          @click="setData"
        >
          <v-icon start>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { mapActions, mapState } from "vuex";

import validator from "./validator";

import Alert from "@/components/Alert";
import CreateCustomFields from "@/components/ObjectsTools/CreateCustomFields";

export default {
  emits: ["cancelForm", "setData"],

  mixins: [validator],

  components: { Alert, CreateCustomFields },

  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isAdd: false,
      valid: true,
      maxFields: 30,

      testValue: "",

      name: "",
      description: "",
      fields: [],
    };
  },

  methods: {
    ...mapActions({
      resetGetProfileTypeAction: "profileTypes/resetGetProfileType",
      getProfileTypeAction: "profileTypes/getProfileType",
    }),

    async getData() {
      if (this.id == undefined) return;

      await this.getProfileTypeAction(this.id).then(() => {
        let objectType = structuredClone(this.profileTypeState);

        //Оборачиваем полученную структуру в прокси
        if (objectType.fields?.length) {
          objectType.fields = objectType.fields.map((type) => {
            const curObject = {
              isEdit: false,
              key: type.name,
              value: type,
            };

            if (curObject.value.items?.length) {
              curObject.value.items = curObject.value.items.map((item) => {
                const curItem = {
                  isEdit: false,
                  key: item.value,
                  value: item,
                };

                return curItem;
              });
            }

            return curObject;
          });
        }

        this.name = objectType.name ?? "";
        this.type = objectType.type ?? "";
        this.description = objectType.description ?? "";
        this.fields = objectType.fields ?? [];
      });
    },

    async setData() {
      let fields = this.fields.map((field) => {
        let curField = field.value;

        if (curField.items?.length)
          curField.items = curField.items.map((item) => item.value);

        return curField;
      });

      const params = {
        name: this.name,
        type: this.type,
        description: this.description,
        fields: fields,
      };

      this.$emit("setData", params);
    },

    cancelForm() {
      this.$emit("cancelForm");
    },
  },

  computed: {
    ...mapState({
      profileTypeState: (state) => state.profileTypes.profileType,

      isPendingGetProfileTypeState: (state) =>
        state.profileTypes.isPendingGetProfileType,
      isFailureGetProfileTypeState: (state) =>
        state.profileTypes.isFailureGetProfileType,
      errorMessageGetProfileTypeState: (state) =>
        state.profileTypes.errorMessageGetProfileType,
    }),
  },

  created() {
    this.getData();
  },

  unmounted() {
    this.resetGetProfileTypeAction();
  },
};
</script>
