<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="operators"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col cols="12" :lg="!pageData.isMini ? 9 : 12">
      <v-row v-if="isFailureGetOperatorsListState">
        <v-col>
          <Alert type="error">
            {{ errorMessageGetOperatorsListState }}
          </Alert>
        </v-col>
      </v-row>

      <v-row v-if="can($const.RULES.OPERATOR_ADD)">
        <v-spacer />
        <v-col cols="12" md="auto">
          <v-btn color="primary" @click="goToAdd">
            <v-icon start> mdi-plus </v-icon>
            {{ $t("button.add") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :loading="isPendingGetOperatorsListState"
            :headers="headers"
            :items="operatorsList"
            :server-items-length="pagination.per_page"
            hide-default-footer
            disable-sort
            :row-props="rowProps"
          >
            <template #[`item.username`]="{ item }">
              <router-link
                v-if="can($const.RULES.OPERATOR_EDIT)"
                class="text-primary"
                :to="{
                  name: $const.ROUTES.EDIT_OPERATOR,
                  params: {
                    id: item.id,
                  },
                }"
                >{{ item.username }}</router-link
              >
              <template v-else>
                {{ item.username }}
              </template>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip v-if="can($const.RULES.OPERATOR_EDIT)" location="top">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="primary"
                    @click.stop="goToEdit(item.id)"
                  >
                    <v-icon size="x-small">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("button.edit") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="can($const.RULES.OPERATOR_DELETE)"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="red"
                    @click.stop="
                      onRemove({
                        id: item.id,
                        isBindPerson: item.persons_id ? true : false,
                      })
                    "
                  >
                    <v-icon size="x-small">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("button.delete") }}</span>
              </v-tooltip>
            </template>

            <template #bottom>
              <DataTableFooterV2
                v-model="pagination"
                @update:modelValue="onPagination"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";

import permissions from "@/mixins/permissions";

export default {
  name: "OperatorsList",

  mixins: [permissions],

  components: { Alert, DataTableFooterV2, Filter },

  data() {
    return {
      operatorsList: [],

      headers: [
        {
          title: "ID",
          value: "id",
        },
        {
          title: this.$t("operators.login"),
          value: "username",
        },
        {
          value: "actions",
          align: "end",
          width: 121,
        },
      ],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      resetGetOperatorsListAction: "operators/resetGetOperatorsList",
      getOperatorsListAction: "operators/getOperatorsList",

      remove: "operator/remove",

      setStatePageDataAction: "stateOperators/setPageData",
      setStateFilterAction: "stateOperators/setFilter",
      setStatePaginationAction: "stateOperators/setPagination",
    }),

    getOperators() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getOperatorsListAction(params).then((response) => {
        this.operatorsList = this.operatorsListState;

        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;

          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }
      });
    },

    goToAdd() {
      this.$router.push({
        name: this.$const.ROUTES.ADD_OPERATOR,
        params: {
          query: this.$route.query,
        },
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_OPERATOR,
        params: {
          id,
          query: this.$route.query,
        },
      });
    },

    onRemove(data) {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),
        agree: () => this.delete(data),
      });
    },

    async delete({ id, isBindPerson = false }) {
      const deleteOperator = async (isBindPerson = false) => {
        const params = { id };

        if (isBindPerson) {
          params.is_delete_person = true;
        }

        await this.remove(params);
        this.getOperators();
      };

      if (this.can(this.$const.RULES.OBJECT_DELETE) && isBindPerson) {
        this.$root.$prompt({
          title: this.$t("operator.deleteBindingPerson"),
          message: this.$t("operator.deleteBindingPersonMessage"),
          width: 500,
          btnAgree: this.$t("common.yes"),
          btnDisagree: this.$t("common.no"),

          agree: async () => {
            deleteOperator(true);
          },

          disagree: async () => {
            deleteOperator(false);
          },
        });
      } else {
        deleteOperator();
      }
    },
    rowProps(item) {
      const props = {};
      if (
        this.$vuetify.display.mobile &&
        item.item.id &&
        this.can(this.$const.RULES.OPERATOR_EDIT)
      ) {
        props.onClick = () => this.goToUser(item);
      }

      return props;
    },
    goToUser(item) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_OPERATOR,
        params: {
          id: item.item.id,
        },
      });
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния страницы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния страницы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },
    setFilterState() {
      this.setStateFilterAction(this.filter);
    },
    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };
      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getOperators();
    },
  },

  computed: {
    ...mapState({
      isPendingGetOperatorsListState: (state) =>
        state.operators.isPendingGetOperatorsList,
      isFailureGetOperatorsListState: (state) =>
        state.operators.isFailureGetOperatorsList,
      errorMessageGetOperatorsListState: (state) =>
        state.operators.errorMessageGetOperatorsList,

      operatorsListState: (state) => state.operators.operatorsList,

      statePageData: (state) => state.stateOperators.data,
      stateFilter: (state) => state.stateOperators.filter,
      statePagination: (state) => state.stateOperators.pagination,
    }),
  },

  created() {
    this.getState();
    this.getOperators();
  },

  unmounted() {
    this.resetGetOperatorsListAction();
  },
};
</script>
