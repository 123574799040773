<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <!-- TODO: вернуть поле в версии 1.5.2 -->
        <!-- <v-col v-if="!isEdit" cols="12" md="6">
          <v-text-field
            v-model="pass"
            :label="`${$t('devices.pass')} *`"
            type="password"
            outlined
            required
            :rules="isRequiredRules"
            @change="inputValue"
          />
        </v-col> -->

        <v-col cols="12">
          <v-select
            v-model="doorsNumber"
            :items="doorsNumberOptions"
            :label="$t('devices.doorsNumber')"
            item-title="text"
            item-value="value"
            variant="outlined"
            density="comfortable"
            color="primary"
            hide-details
            @update:modelValue="inputValue"
          />
        </v-col>

        <v-col
          v-if="isEdit"
          cols="12"
          md="6"
          class="d-flex align-center justify-center"
        >
          <v-btn @click="openNetworkSettings">
            <v-icon start> mdi-ip-network </v-icon>
            {{ $t("devices.changeDeviceNetworkSettings") }}
          </v-btn>
        </v-col>

        <template v-if="!isEdit">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="macCodeState"
              :label="$t('devices.macCode')"
              variant="outlined"
              density="comfortable"
              maxlength="17"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="mask"
              :label="`${$t('devices.mask')} *`"
              variant="outlined"
              density="comfortable"
              color="primary"
              required
              @update:modelValue="inputValue"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="gateway"
              :label="$t('devices.gateway')"
              variant="outlined"
              density="comfortable"
              color="primary"
              @update:modelValue="inputValue"
            />
          </v-col>
        </template>

        <v-col>
          <v-switch
            v-model="antipassback"
            :label="$t('setting.anti-passback')"
            density="comfortable"
            color="primary"
            @update:modelValue="inputValue"
          />
        </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col
          v-for="door of getDoors()"
          :key="door.doorIndex"
          class="ba-1"
          cols="12"
          :md="door.col"
        >
          <v-card
            style="
              background-color: rgb(220, 217, 253);
              border: solid 1px rgb(240 240 243);
              border-radius: 6px;
            "
            class="elevation-0"
          >
            <v-card-title class="text-h5">
              <v-row align-content="center">
                <v-col>{{ door.title }}</v-col>
                <v-col cols="auto"> </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="channel of door.readers"
                  :key="channel.channel"
                  :cols="door.cols"
                  :md="door.col == 12 ? 6 : 12"
                >
                  <Channel
                    :title="getChannelTitle(channel.channel)"
                    :channel="channel"
                    :relays="
                      channel.channel === 0 || channel.channel === 1
                        ? firstRelaysOptions
                        : secondRelaysOptions
                    "
                    isShowName
                    :isShowRelays="doorsNumber === 2"
                    :isController="true"
                    @changeState="updateChannels($event, channel.channel)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-col v-if="'is_fixed_pass' in door">
              <v-switch
                v-model="door.is_fixed_pass"
                :label="$t('devices.fixedPass')"
                :disabled="isDisabledForm"
                hide-details
                class="mt-0"
                @change="inputValue"
              />
            </v-col> -->

                <v-col>
                  <v-select
                    v-model="getDoor(door.doorIndex).relay_id"
                    :items="getRelaysOptions(door.relay_id)"
                    :label="$t('devices.relay')"
                    :disabled="door.disabled"
                    item-title="text"
                    item-value="value"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    hide-details
                    @update:modelValue="inputValue"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model.number="getDoor(door.doorIndex).open_relay_delay"
                    :label="`${$t('devices.delay')} *`"
                    :disabled="door.disabled"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    required
                    type="number"
                    min="0"
                    max="255"
                    :rules="isRequiredRules"
                    @update:modelValue="inputValue"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="getDoor(door.doorIndex).type_sensor_input"
                    :items="typeSensorInputOptions"
                    :label="$t('devices.typeSensorInput')"
                    :disabled="door.disabled"
                    item-title="text"
                    item-value="value"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    hide-details
                    @update:modelValue="inputValue"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-tabs
        v-model="settingTab"
        color="primary"
        background-color="grey-lighten-3 mb-5"
      >
        <v-tab>{{ $t("devices.readerSettings") }}</v-tab>
        <!--v-tab>{{ $t("devices.relay") }}</v-tab-->
        <v-tab>{{ $t("devices.alarmSettings") }}</v-tab>
      </v-tabs>

      <v-window v-model="settingTab">
        <!-- Reader -->
        <v-window-item>
          <v-row>
            <v-col cols="12">
              <v-switch
                v-model="passSensor"
                :label="$t('devices.passSensor')"
                density="comfortable"
                color="primary"
                @update:modelValue="inputValue"
              />
            </v-col>
            <v-col cols="12" :md="cardType === 'CUST' ? 3 : 6">
              <v-select
                v-model="cardType"
                :items="cardTypes"
                item-title="name"
                item-value="val"
                variant="outlined"
                density="comfortable"
                color="primary"
                :label="$t('card.type')"
                @update:modelValue="inputValue"
                outlined
              />
            </v-col>
            <v-col v-show="cardType === 'CUST'" cols="12" md="9">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="numberOfWiegandBits"
                    :label="`${$t('devices.numberOfWiegandBits')} *`"
                    type="number"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    required
                    @update:modelValue="inputValue"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="validWiegandBits"
                    :label="`${$t('devices.validWiegandBits')} *`"
                    type="number"
                    :max="numberOfWiegandBits"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    required
                    :disabled="!numberOfWiegandBits"
                    @update:modelValue="inputValue"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="parityWiegandBits1"
                    :label="`${$t('devices.parityWiegandBit')} 1`"
                    :max="numberOfWiegandBits"
                    type="number"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    required
                    :disabled="!validWiegandBits"
                    @update:modelValue="inputValue"
                  />
                  <v-text-field
                    v-model.number="parityWiegandBits2"
                    :label="`${$t('devices.parityWiegandBit')} 2`"
                    :max="numberOfWiegandBits"
                    type="number"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    required
                    :disabled="!validWiegandBits"
                    @update:modelValue="inputValue"
                  />
                  <v-text-field
                    v-model.number="parityWiegandBits3"
                    :label="`${$t('devices.parityWiegandBit')} 3`"
                    :max="numberOfWiegandBits"
                    type="number"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    required
                    :disabled="!validWiegandBits"
                    @update:modelValue="inputValue"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-window-item>

        <!-- Relay -->
        <!-- <v-tab-item>
          <v-row>
            <v-col
              v-for="(relay, index) of relays"
              :key="relay.id"
              cols="12"
              :md="4"
            >
              <Relay :relay="relay" @change="updateRelays($event, index)" />
            </v-col>
          </v-row>
        </v-tab-item> -->

        <!-- Alarm -->
        <v-window-item>
          <v-row>
            <v-col cols="12" col="12">
              <Alarm
                :relays="relays"
                :alarmPins="alarmPins"
                :alarmPinsRelayDependence="alarmPinsRelayDependence"
                @changeState="updateAlarm"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(relay, index) of relays"
              :key="relay.id"
              cols="12"
              :md="4"
            >
              <Relay
                :relay="relay"
                @updateState="updateRelays($event, index)"
              />
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-col>

    <v-row justify="center">
      <v-dialog
        v-model="isShowNetworkSettings"
        max-width="700px"
        @click:outside="closeNetworkSettings"
      >
        <ModalNetworkSettings
          :id="id"
          :ipAddress="ipAddress"
          :macCode="macCode"
          :mask="mask"
          :gateway="gateway"
          @close="closeNetworkSettings"
          @submit="onSubmitNetworkSettings"
        />
      </v-dialog>
    </v-row>
  </v-row>
</template>

<script>
import { Mask } from "maska";
import { mapState } from "vuex";

import Channel from "../Channel";
import Relay from "./Relay";
import Alarm from "./Alarm";
import ModalNetworkSettings from "./ModalNetworkSettings";

const mask = new Mask({ mask: "**:**:**:**:**:**" });

export default {
  components: {
    Channel,
    Relay,
    Alarm,
    ModalNetworkSettings,
  },

  props: {
    modelValue: Object,

    ipAddress: {
      type: String,
      required: true,
    },

    id: {
      type: [String, Number],
      required: true,
    },

    channels: {
      type: Array,
      default: () => [],
    },

    isEdit: {
      type: Boolean,
      required: true,
    },

    zones: {
      type: Array,
      default: () => [],
    },

    zonesFrom: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    let parityWiegandBits = [];

    if (
      !this.modelValue?.config?.parity_wiegand_bits ||
      !this.modelValue?.config?.parity_wiegand_bits.length
    ) {
      parityWiegandBits = [0, 0, 0];
    } else {
      parityWiegandBits = this.modelValue.config.parity_wiegand_bits;
    }

    return {
      settingTab: null,

      isShowNetworkSettings: false,

      typeSensorInputOptions: [
        { value: "none", text: this.$t("devices.typeNone") },
        { value: "open", text: this.$t("devices.normalOpen") },
        { value: "close", text: this.$t("devices.normalClose") },
      ],

      // TODO: сделать поле пустым по дефолту в версии 1.5.2
      pass: "123456",
      macCode: this.modelValue?.mac_code || "",
      mask: this.modelValue?.mask || "255.255.255.0",
      gateway: this.modelValue?.gateway || "",
      doorsNumber: this.modelValue?.doors_number || 4,
      antipassback: this.modelValue?.anti_passback || false,

      doors: this.modelValue?.doors || [
        {
          id: 1,
          name: `${this.$t("devices.door")} 1`,
          relay_id: 1,
          open_relay_delay: 3,
          is_fixed_pass: false,
          type_button_input: "open",
          button_name: this.$t("devices.exit"),
          type_sensor_input: "open",
        },
        {
          id: 2,
          name: `${this.$t("devices.door")} 2`,
          relay_id: 2,
          open_relay_delay: 3,
          is_fixed_pass: false,
          type_button_input: "open",
          button_name: this.$t("devices.exit"),
          type_sensor_input: "open",
        },
        {
          id: 3,
          name: `${this.$t("devices.door")} 3`,
          relay_id: 3,
          open_relay_delay: 3,
          is_fixed_pass: false,
          type_button_input: "open",
          button_name: this.$t("devices.exit"),
          type_sensor_input: "open",
        },
        {
          id: 4,
          name: `${this.$t("devices.door")} 4`,
          relay_id: 4,
          open_relay_delay: 3,
          is_fixed_pass: false,
          type_button_input: "open",
          button_name: this.$t("devices.exit"),
          type_sensor_input: "open",
        },
      ],

      relays: this.modelValue?.relays || [
        {
          id: 1,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
        {
          id: 2,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
        {
          id: 3,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
        {
          id: 4,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
        {
          id: 5,
          type_alarm_input: "open",
          sync_relay_state: true,
          alarm_relay_delay: 1,
          open_relay_delay: 3,
        },
      ],

      alarmPins: this.modelValue?.alarm_pins || [
        {
          id: 1,
          name: "Pin 1",
          type_alarm_input: "open",
          alarm_relay_delay: 1,
        },
        {
          id: 2,
          name: "Pin 2",
          type_alarm_input: "open",
          alarm_relay_delay: 1,
        },
        {
          id: 3,
          name: "Pin 3",
          type_alarm_input: "open",
          alarm_relay_delay: 1,
        },
        {
          id: 4,
          name: "Pin 4",
          type_alarm_input: "open",
          alarm_relay_delay: 1,
        },
      ],

      alarmPinsRelayDependence: this.modelValue
        ?.alarm_pins_relay_dependence || [
        {
          alarm_id: 1,
          relays: [1, 2, 3, 4, 5],
        },
        {
          alarm_id: 2,
          relays: [],
        },
        {
          alarm_id: 3,
          relays: [],
        },
        {
          alarm_id: 4,
          relays: [],
        },
      ],

      // Reader
      cardType: this.modelValue?.config?.card_type || "WDEC26",
      numberOfWiegandBits:
        this.modelValue?.config?.number_of_wiegand_bits || null,
      validWiegandBits: this.modelValue?.config?.valid_wiegand_bits || null,
      parityWiegandBits1: parityWiegandBits[0] || 0,
      parityWiegandBits2: parityWiegandBits[1] || 0,
      parityWiegandBits3: parityWiegandBits[2] || 0,
      passSensor: this.modelValue?.config?.pass_sensor || false,

      cardTypes: [
        { name: "Wiegand26", val: "WDEC26" },
        { name: "Wiegand34", val: "WDEC34" },
        { name: "Wiegand37", val: "WDEC37" },
        { name: this.$t("devices.custom"), val: "CUST" },
      ],

      doorsNumberOptions: [
        { text: this.$t("devices.twoDoors"), value: 2 },
        { text: this.$t("devices.fourDoors"), value: 4 },
      ],

      // Rules
      isRequiredRules: [(v) => !!v || this.$t("message.NameIsRequired")],
    };
  },

  methods: {
    inputValue() {
      const {
        pass,
        macCode,
        mask,
        gateway,
        cardType,
        numberOfWiegandBits,
        validWiegandBits,
        parityWiegandBits1,
        parityWiegandBits2,
        parityWiegandBits3,
        passSensor,
        relays,
        doors,
        alarmPins,
        alarmPinsRelayDependence,
        doorsNumber,
        antipassback,
      } = this;

      const data = {
        pass,
        mac_code: macCode,
        mask,

        gateway,
        device_type: "CONTROLLER",
        config: {
          card_type: cardType,
          number_of_wiegand_bits: numberOfWiegandBits,
          valid_wiegand_bits: validWiegandBits,
          parity_wiegand_bits: [
            parityWiegandBits1,
            parityWiegandBits2,
            parityWiegandBits3,
          ],
          pass_sensor: passSensor,
        },
        relays,
        doors,
        alarm_pins: alarmPins,
        alarm_pins_relay_dependence: alarmPinsRelayDependence,
        doors_number: doorsNumber,
        anti_passback: antipassback,
      };

      this.$emit("update:modelValue", data);
    },

    updateChannels(e, index) {
      const channels = this.channels;
      channels[index] = e;

      // Sync relay id by channel id
      switch (e.channel) {
        case 0:
        case 1:
          channels[0].relay_id = e.relay_id;
          channels[1].relay_id = e.relay_id;
          break;
        case 2:
        case 3:
          channels[2].relay_id = e.relay_id;
          channels[3].relay_id = e.relay_id;
          break;
        default:
          break;
      }

      this.$emit("change-channels", channels);
    },

    updateRelays(e, index) {
      this.relays[index] = e;

      this.inputValue();
    },

    updateAlarm({ alarmPinsRelayDependence, alarmPins }) {
      this.alarmPinsRelayDependence = alarmPinsRelayDependence;
      this.alarmPins = alarmPins;

      this.inputValue();
    },

    relaysOptions() {
      return this.relays.map((relay) => ({
        value: relay.id,
        text: `${this.$t("devices.relay")} ${relay.id}`,
      }));
    },

    openNetworkSettings() {
      this.isShowNetworkSettings = true;
    },

    closeNetworkSettings() {
      this.isShowNetworkSettings = false;
    },

    onSubmitNetworkSettings({ ipAddress, macCode, mask, gateway }) {
      this.$emit("change-ip-address", ipAddress);
      this.macCode = macCode;
      this.mask = mask;
      this.gateway = gateway;

      this.closeNetworkSettings();
      this.inputValue();
    },

    getDoor(id) {
      return this.doors.find((door) => door.id === id);
    },

    getDoors() {
      const doors = [];
      // let title = `${this.$t("devices.door")} ${index + 1} / ${this.$t(
      //   "devices.readerSettings"
      // )} 1`;
      let readers = [];
      let doorIndex = 0;
      let enable = false;
      this.channels.forEach((channel, index) => {
        readers.push(channel);
        if (this.doorsNumber === 2) {
          if (channel.enable || false) {
            enable = true;
          }
          if (index % 2 === 1) {
            doorIndex++;
            const d = this.getDoor(doorIndex);
            doors.push({
              title: d.name,
              type_button_input: d.type_button_input || "none",
              button_name: d.button_name,
              readers,
              doorIndex,
              col: 12,
              cols: 6,
              disabled: !enable,
            });
            readers = [];
            enable = false;
          }
        } else {
          let enable = channel.enable || false;
          doorIndex++;
          const d = this.getDoor(doorIndex);
          doors.push({
            title: d.name,
            readers,
            doorIndex,
            type_button_input: d.type_button_input || "none",
            button_name: d.button_name,
            col: 6,
            cols: 12,
            disabled: !enable,
          });
          readers = [];
          enable = false;
        }
      });

      // if (this.doorsNumber === 2) {
      //   let doorNumber = 1;
      //   let readerNumber = 1;
      //   if (index === 2 || index === 3) {
      //     doorNumber = 2;
      //   }
      //   if (index % 2) {
      //     readerNumber = 2;
      //   }

      //   title = `${this.$t("devices.door")} ${doorNumber} / ${this.$t(
      //     "devices.readerSettings"
      //   )} ${readerNumber}`;
      // }

      return doors;
    },
    getChannelTitle(index) {
      let title = `${this.$t("devices.readerSettings")} ${index + 1}`;

      // if (this.doorsNumber === 2) {
      //   let doorNumber = 1;
      //   let readerNumber = 1;
      //   if (index === 2 || index === 3) {
      //     doorNumber = 2;
      //   }
      //   if (index % 2) {
      //     readerNumber = 2;
      //   }

      //   title = `${this.$t("devices.door")} ${doorNumber} / ${this.$t(
      //     "devices.readerSettings"
      //   )} ${readerNumber}`;
      // }

      return title;
    },
    getRelaysOptions(relay_id) {
      let relays = this.relays; //.filter((relay) => relay.id !== relay_id);

      return relays.map((relay) => ({
        value: relay.id,
        text: `${this.$t("devices.relay")} ${relay.id}`,
      }));
    },
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),

    macCodeState: {
      get: function () {
        return mask.masked(this.macCode.toUpperCase());
      },
      set: function (newValue) {
        this.macCode = mask.masked(newValue.toUpperCase());
      },
    },

    firstRelaysOptions() {
      return this.relays.filter(
        (relay) => relay.id !== this.channels[2].relay_id
      );
    },

    secondRelaysOptions() {
      return this.relays.filter(
        (relay) => relay.id !== this.channels[0].relay_id
      );
    },
  },
  watch: {
    ipAddress(val) {
      if (!this.isEdit) {
        let g = this.gateway.split(".");
        let f = false;
        if (g.length < 4) {
          for (let i = g.length; i < 4; i++) {
            g.push("0");
            f = true;
          }
        }
        val.split(".").forEach((item, index) => {
          if (g.length > 3 && g[index] !== item && index < 4) {
            g[index] = item;
            if (index === 3) {
              g[index] = "1";
            }
            f = true;
          }
        });
        if (f) {
          this.gateway = g.join(".");
          this.inputValue();
        }
      }
    },
  },

  created() {
    this.inputValue();
  },
};
</script>
