<template>
  <v-container class="px-0 py-0">
    <v-row v-if="isPending">
      <v-col cols="12" md="4">
        <v-skeleton-loader type="image, actions" />
      </v-col>
      <v-col cols="12" md="8">
        <v-skeleton-loader type="list-item-three-line" />
      </v-col>
    </v-row>

    <Alert v-if="!$auth.user().password_upd" type="info">
      <template>
        {{ $t("message.setNewPassword") }}
      </template>
    </Alert>

    <Alert v-if="isSuccessStore" type="success">
      <template>
        {{ $t("message.saveSuccess") }}
      </template>
    </Alert>

    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Alert v-if="isFailureStore" type="error">
      <template v-if="errorMessageStore">
        {{ errorMessageStore }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Alert v-if="isFailureChangePassword" type="error">
      <template v-if="errorMessageChangePassword">
        {{ errorMessageChangePassword }}
      </template>
      <template v-else>
        {{ $t("message.errorChangePassword") }}
      </template>
    </Alert>

    <template v-if="isHasPerson">
      <PersonForm
        v-if="isSuccess"
        :id="id"
        :data="data"
        :isPendingStore="isPendingStore || isPendingChangePassword"
        @store="onEdit"
      />
    </template>
    <template v-else>
      <OperatorForm
        :isPendingStore="isPendingChangePassword"
        @store="onEditOperator"
      />
    </template>
  </v-container>
</template>

<script>
import userRedirect from "@/mixins/userRedirect";
import { mapActions, mapState, mapMutations } from "vuex";

import PersonForm from "./components/Form";
import OperatorForm from "./components/OperatorForm";
import Alert from "@/components/Alert";

export default {
  name: "EditPersonal",

  mixins: [userRedirect],

  components: { Alert, PersonForm, OperatorForm },

  data() {
    return {
      id: this.$auth.user().id,
      operatorId: this.$auth.user().admin_id,
    };
  },

  methods: {
    ...mapActions({
      edit: "person/edit",
      get: "person/get",
      changePassword: "operator/edit",
    }),

    ...mapMutations({
      setPasswordUpdate: "user/setPasswordUpdate",
      setLoading: "person/setLoading",
    }),

    async onEdit({ data, password }) {
      this.edit({ id: this.id, data });

      if (password) {
        await this.changePassword({ id: this.operatorId, data: { password } });

        if (this.isSuccessChangePassword) {
          this.setPasswordUpdate();
          this.$router.push({ path: "/" });
        }
      }
    },

    async onEditOperator({ password }) {
      await this.changePassword({ id: this.operatorId, data: { password } });

      if (this.isSuccessChangePassword) {
        this.setPasswordUpdate();
        this.fastRedirect();
      }
    },
  },

  computed: {
    ...mapState({
      data: (state) => state.person.data,
      isPending: (state) => state.person.isPending,
      isSuccess: (state) => state.person.isSuccess,
      isFailure: (state) => state.person.isFailure,
      errorMessage: (state) => state.person.errorMessage,
      isPendingStore: (state) => state.person.isPendingStore,
      isSuccessStore: (state) => state.person.isSuccessStore,
      isFailureStore: (state) => state.person.isFailureStore,
      errorMessageStore: (state) => state.person.errorMessageStore,
      isPendingChangePassword: (state) => state.operator.isPendingEdit,
      isSuccessChangePassword: (state) => state.operator.isSuccessEdit,
      isFailureChangePassword: (state) => state.operator.isFailureEdit,
      errorMessageChangePassword: (state) => state.operator.errorMessageEdit,
    }),

    isHasPerson() {
      return !!this.id;
    },
  },

  created() {
    if (this.isHasPerson) {
      this.get({ id: this.id });
    }
  },
};
</script>
