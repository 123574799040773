<template>
  <v-card
    v-ripple="!edit"
    class="overflow-visible bg-transparent"
    :class="edit ? 'pa-3' : ''"
    :flat="!edit"
    id="TimeZone"
  >
    <!-- SHOW -->
    <div v-show="!edit">
      <v-btn
        class="h-100 w-100 pa-2 subtitle-2 font-weight-medium"
        variant="tonal"
        flat
        @click="toggleEdit"
      >
        <div class="text-wrap" v-html="timeZoneLabel" />
      </v-btn>
    </div>
    <!-- EDIT -->
    <div v-show="edit">
      <v-row>
        <v-col class="flex-shrink-0" cols="12">
          <v-row>
            <v-col class="text-h6">
              {{ $t("access-modes.date") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="timeZoneTypeComputed"
                :items="timeZoneTypeItems"
                item-value="value"
                item-title="label"
                variant="outlined"
                density="compact"
                color="primary"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row v-if="modelValue.timeZoneType == 'calendarDay'">
            <v-col>
              <CalendarPicker v-model="datesComputed" />
            </v-col>
          </v-row>
          <template v-if="modelValue.timeZoneType == 'week'">
            <v-row>
              <v-col cols="12" md="6">
                <el-date-picker
                  v-model="startDateComputed"
                  :clearable="false"
                  type="date"
                  size="large"
                />
              </v-col>
              <v-col cols="12" md="6">
                <el-date-picker
                  v-model="expireDateComputed"
                  :placeholder="$t('access-modes.endlessly')"
                  type="date"
                  size="large"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn-toggle v-model="daysOfWeekComputed" multiple>
                  <v-btn
                    v-for="button in showDaysOfWeek"
                    :key="button.value"
                    color="primary"
                    :size="$vuetify.display.xs ? 'small' : 'default'"
                    icon
                    :value="button.value"
                    >{{ button.label }}</v-btn
                  >
                </v-btn-toggle>
              </v-col>
            </v-row>
          </template>
          <template v-if="modelValue.timeZoneType == 'repeateTemplate'">
            <v-row>
              <v-col cols="12" md="6">
                <el-date-picker
                  v-model="startDateComputed"
                  :clearable="false"
                  type="date"
                  size="large"
                />
              </v-col>
              <v-col cols="12" md="6">
                <el-date-picker
                  v-model="expireDateComputed"
                  :placeholder="$t('access-modes.endlessly')"
                  type="date"
                  size="large"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <CustomRepeatTemplatePicker v-model="repeateTemplateComputed" />
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col class="w-100">
          <v-row>
            <v-col class="text-h6">
              {{ $t("access-modes.timeWindows") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-fade-transition :group="true">
                <v-row
                  v-for="timeWindow in modelValue.timeWindows"
                  :key="timeWindow.key"
                >
                  <v-col>
                    <TimeRange
                      v-model="timeWindow.value"
                      @remove="deleteTimeWindow(timeWindow.key)"
                      @update:modelValue="updateModelValue(modelValue)"
                    />
                  </v-col>
                </v-row>
              </v-fade-transition>
              <v-row v-if="modelValue.timeWindows?.length < maxTimeWindows">
                <v-col>
                  <v-btn variant="text" color="green" @click="addWindow">
                    <v-icon start>mdi-clock-plus-outline</v-icon
                    >{{ $t("access-modes.addTimeWindow") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col class="py-0" cols="12">
              <v-switch
                v-model="intervalComputed"
                :label="$t('timeZone.repeat')"
                color="primary"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row v-if="modelValue?.interval">
            <v-col cols="12">
              <CustomInterval
                v-model="intervalValueComputed"
                v-model:label="intervalLabel"
                :title="$t('customInterval.editInterval')"
              />
            </v-col>
          </v-row> -->
        </v-col>
      </v-row>
    </div>
    <slot name="actions" />
  </v-card>
</template>
<script>
//import CustomInterval from "./components/CustomInterval";
import TimeRange from "./components/TimeRange.vue";
import CalendarPicker from "./components/CalendarPicker";
import CustomRepeatTemplatePicker from "./components/CustomRepeatTemplatePicker.vue";

export default {
  components: {
    TimeRange,
    CalendarPicker,
    CustomRepeatTemplatePicker /*, CustomInterval*/,
  },
  props: {
    //Values
    modelValue: {
      type: Object,
      default: () => {},
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },

    maxTimeWindows: {
      type: Number,
      default: 6,
    },

    dateFormat: {
      type: String,
      default: "DD.MM.YYYY",
    },
    timeFormat: {
      type: String,
      default: "HH:mm",
    },
  },

  data() {
    return {
      edit: true,

      defaultState: {
        timeZoneType: "calendarDay",
        dates: [],
        daysOfWeek: [],
        startDate: new Date(),
        expireDate: null,
        timeWindows: [],
        repeatTemplate: "111",
      },
      showDaysOfWeek: [
        { label: this.$t("customInterval.monday"), value: 1 },
        { label: this.$t("customInterval.tuesday"), value: 2 },
        { label: this.$t("customInterval.wednesday"), value: 3 },
        { label: this.$t("customInterval.thursday"), value: 4 },
        { label: this.$t("customInterval.friday"), value: 5 },
        { label: this.$t("customInterval.saturday"), value: 6 },
        { label: this.$t("customInterval.sunday"), value: 7 },
      ],

      timeZoneTypeItems: [
        { label: this.$t("access-modes.calendarDay"), value: "calendarDay" },
        { label: this.$t("access-modes.week"), value: "week" },
        {
          label: this.$t("access-modes.repeateTemplate"),
          value: "repeateTemplate",
        },
      ],

      intervalLabel: "",
    };
  },

  methods: {
    toggleEdit() {
      this.edit = true;
    },

    setDefaultValue() {
      const curData = {
        timeZoneType:
          this.modelValue?.timeZoneType ??
          this.defaultValue?.timeZoneType ??
          this.defaultState.timeZoneType,
        timeWindows:
          this.modelValue?.timeWindows ??
          this.defaultValue?.timeWindows ??
          this.defaultState.timeWindows,
        // interval:
        //   this.modelValue?.interval ??
        //   this.defaultValue?.interval ??
        //   this.defaultState.interval,
      };

      switch (curData.timeZoneType) {
        case "calendarDay":
          curData.dates =
            this.modelValue?.dates ??
            this.defaultValue?.dates ??
            this.defaultState.dates;
          break;
        case "week":
          curData.startDate =
            this.modelValue?.startDate ??
            this.defaultValue?.startDate ??
            this.defaultState.startDate;

          curData.expireDate =
            this.modelValue?.expireDate ??
            this.defaultValue?.expireDate ??
            this.defaultState.expireDate;

          curData.daysOfWeek =
            this.modelValue?.daysOfWeek ??
            this.defaultValue?.daysOfWeek ??
            this.defaultState.daysOfWeek;
          break;

        case "repeateTemplate":
          curData.startDate =
            this.modelValue?.startDate ??
            this.defaultValue?.startDate ??
            this.defaultState.startDate;

          curData.expireDate =
            this.modelValue?.expireDate ??
            this.defaultValue?.expireDate ??
            this.defaultState.expireDate;

          curData.repeatTemplate =
            this.modelValue?.repeatTemplate ??
            this.defaultValue?.repeatTemplate ??
            this.defaultState.repeatTemplate;
          break;

        default:
          break;
      }

      this.updateModelValue(curData);
    },

    genKey() {
      const activeKeys = this.modelValue.timeWindows.map(
        (window) => window.key
      );

      for (let i = 1; i <= this.maxTimeWindows; i++) {
        if (!activeKeys.includes(i)) {
          return i;
        }
      }

      return 0;
    },
    addWindow() {
      const params = {
        key: this.genKey(),
        value: {},
      };

      if (!params.key) return;

      const curData = {
        ...this.modelValue,
        timeWindows: [...this.modelValue.timeWindows, params],
      };

      this.updateModelValue(curData);
    },
    deleteTimeWindow(key) {
      const curTimeWindows = this.modelValue.timeWindows.filter(
        (timeWindow) => timeWindow.key != key
      );
      const curData = {
        ...this.modelValue,
        timeWindows: [...curTimeWindows],
      };

      this.updateModelValue(curData);
    },

    updateModelValue(data) {
      this.$emit("update:modelValue", data);
    },
  },

  computed: {
    timeZoneLabel() {
      let label = "";

      if (!this.modelValue) return label;

      label += `${this.$t("timeZone.start")} - ${this.$moment(
        this.modelValue.startDate
      ).format(this.dateFormat)}, `;

      label += `${this.$t("timeZone.end")} - ${this.$moment(
        this.modelValue.expireDate
      ).format(this.dateFormat)}`;

      // if (!this.modelValue.allDay) {
      //   label += `${this.$t("timeZone.from")} ${this.$moment(
      //     this.modelValue.startTime
      //   ).format(this.timeFormat)} `;

      //   label += `${this.$t("timeZone.to")} ${this.$moment(
      //     this.modelValue.expireTime
      //   ).format(this.timeFormat)}`;
      // }

      // if (this.modelValue.interval) {
      //   label += `<br /> ${this.intervalLabel}`;
      // }

      return label;
    },

    timeZoneTypeComputed: {
      get() {
        return this.modelValue.timeZoneType;
      },
      set(newValue) {
        const curData = {
          ...this.modelValue,
          timeZoneType: newValue,
        };

        delete curData.startDate;
        delete curData.expireDate;
        delete curData.daysOfWeek;
        delete curData.dates;
        delete curData.repeatTemplate;

        switch (newValue) {
          case "calendarDay":
            curData.dates = this.defaultValue?.dates ?? this.defaultState.dates;
            break;
          case "week":
            curData.startDate =
              this.defaultValue?.startDate ?? this.defaultState.startDate;

            curData.expireDate =
              this.defaultValue?.expireDate ?? this.defaultState.expireDate;

            curData.daysOfWeek =
              this.defaultValue?.daysOfWeek ?? this.defaultState.daysOfWeek;
            break;
          case "repeateTemplate":
            curData.startDate =
              this.defaultValue?.startDate ?? this.defaultState.startDate;
            curData.expireDate =
              this.defaultValue?.expireDate ?? this.defaultState.expireDate;
            curData.repeatTemplate =
              this.defaultValue?.repeatTemplate ??
              this.defaultState.repeatTemplate;
            break;

          default:
            break;
        }

        this.updateModelValue(curData);
      },
    },

    datesComputed: {
      get() {
        return this.modelValue.dates.map((date) => {
          return typeof date == "string" ? new Date(date) : date;
        });
      },
      set(newValue) {
        const curData = {
          ...this.modelValue,
          dates: newValue,
        };

        this.updateModelValue(curData);
      },
    },

    expireDateComputed: {
      get() {
        return this.modelValue.expireDate;
      },
      set(value) {
        let newValue = value;

        if (
          newValue &&
          this.$moment(newValue).isBefore(this.modelValue.startDate)
        ) {
          newValue = this.modelValue.startDate;
        }

        const curData = {
          ...this.modelValue,
          expireDate: newValue,
        };

        this.updateModelValue(curData);
      },
    },

    startDateComputed: {
      get() {
        return this.modelValue.startDate;
      },
      set(newValue) {
        const curData = {
          ...this.modelValue,
          startDate: newValue,
        };

        if (
          this.modelValue.expireDate &&
          this.$moment(newValue).isAfter(this.modelValue.expireDate)
        ) {
          curData.expireDate = newValue;
        }

        this.updateModelValue(curData);
      },
    },

    repeateTemplateComputed: {
      get() {
        return this.modelValue.repeatTemplate;
      },
      set(newValue) {
        const curData = {
          ...this.modelValue,
          repeatTemplate: newValue,
        };

        this.$emit("update:modelValue", curData);
      },
    },

    daysOfWeekComputed: {
      get() {
        return this.modelValue.daysOfWeek;
      },
      set(newValue) {
        const curData = {
          ...this.modelValue,
          daysOfWeek: newValue,
        };

        this.$emit("update:modelValue", curData);
      },
    },

    // intervalValueComputed: {
    //   get() {
    //     return this.modelValue?.intervalValue;
    //   },
    //   set(newValue) {
    //     const curData = {
    //       ...this.modelValue,
    //       intervalValue: newValue,
    //     };

    //     this.updateModelValue(curData);
    //   },
    // },

    // intervalComputed: {
    //   get() {
    //     return this.modelValue?.interval;
    //   },
    //   set(newValue) {
    //     const curData = {
    //       ...this.modelValue,
    //       interval: newValue,
    //     };
    //     if (curData.intervalValue ?? false) delete curData.intervalValue;

    //     if (curData.interval) {
    //       curData.intervalValue =
    //         this.defaultValue?.intervalValue ?? this.defaultState.intervalValue;
    //     }

    //     this.updateModelValue(curData);
    //   },
    // },
  },

  created() {
    this.setDefaultValue();
  },
};
</script>
