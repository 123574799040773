<template>
  <v-row>
    <v-col>
      <v-card>
        <v-row type="flex">
          <v-col cols="12" :class="avatarClass" :md="avatarCardMd">
            <v-card elevation="0">
              <v-card-text>
                <ImgProtected
                  v-if="modelValue.photo"
                  :src="`/avatar/profile/${modelValue.photo}`"
                />

                <v-img v-else height="200" class="primary">
                  <template #placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-icon size="150" color="primary">
                        mdi-account-circle
                      </v-icon>
                    </v-row>
                  </template>
                </v-img>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" :md="contentCardMd">
            <v-card elevation="0">
              <v-card-title>
                <v-row>
                  <v-col
                    cols="auto"
                    class="text-primary text-h4 text-auto-wrap"
                  >
                    {{ getName }}
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pt-3">
                <v-row>
                  <v-col cols="6" class="grey--text">ID</v-col>
                  <v-col cols="6">{{ modelValue.id }}</v-col>
                </v-row>
                <CustomFields
                  v-model="getCustomFields"
                  :fields="curObjectType?.fields"
                />
                <v-row>
                  <v-col cols="6" class="grey--text">{{
                    $t("user.createdAt")
                  }}</v-col>
                  <v-col cols="6">{{
                    $moment(modelValue.created_at).format("DD.MM.YYYY HH:mm")
                  }}</v-col>
                </v-row>
                <v-row v-if="modelValue.updated_at">
                  <v-col cols="6" class="grey--text">{{
                    $t("user.updatedAt")
                  }}</v-col>
                  <v-col cols="6">{{
                    $moment(modelValue.updated_at).format("DD.MM.YYYY HH:mm")
                  }}</v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <slot name="EndOfRightContainer" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import CustomFields from "../../CustomFields";

import ImgProtected from "@/components/ImgProtected";

export default {
  components: { ImgProtected, CustomFields },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    avatarClass: {
      type: [Array, Object, String],
      default: undefined,
    },

    avatarCardMd: {
      type: [String, Number],
      default: 4,
    },

    contentCardMd: {
      type: [String, Number],
      default: 8,
    },

    //Настройки типов объекта
    objectTypes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      BREAK_STRING_CHAR: "&*|",
    };
  },

  computed: {
    getCustomFields() {
      return this.modelValue.values ?? [];
    },

    curObjectType() {
      return this.objectTypes[0];
    },

    getName() {
      let curName = this.modelValue.name;
      if (!curName) return "";
      curName = curName.split(this.BREAK_STRING_CHAR);
      curName = curName.join(" ");
      return curName;
    },
  },
};
</script>
