export default {
  setLoadingAddProfile(state, { type, payload = "" }) {
    state.isPendingAddProfile = type === "IS_PENDING";
    state.isSuccessAddProfile = type === "IS_SUCCESS";
    state.isFailureAddProfile = type === "IS_FAILURE";
    state.errorMessageAddProfile = payload;
  },

  setLoadingGetProfilesTypes(state, payload) {
    state.isPendingGetProfilesTypes = payload.type === "IS_PENDING";
    state.isSuccessGetProfilesTypes = payload.type === "IS_SUCCESS";
    state.isFailureGetProfilesTypes = payload.type === "IS_FAILURE";
    state.errorMessageGetProfilesTypes = payload.payload;
  },

  setProfilesTypes(state, payload) {
    state.profilesTypes = payload;
  },

  setLoadingGetProfile(state, payload) {
    state.isPendingGetProfile = payload.type === "IS_PENDING";
    state.isSuccessGetProfile = payload.type === "IS_SUCCESS";
    state.isFailureGetProfile = payload.type === "IS_FAILURE";
    state.errorMessageGetProfile = payload.payload;
  },

  setProfile(state, payload) {
    state.profile = payload;
  },

  setLoadingUpdateProfile(state, payload) {
    state.isPendingUpdateProfile = payload.type === "IS_PENDING";
    state.isSuccessUpdateProfile = payload.type === "IS_SUCCESS";
    state.isFailureUpdateProfile = payload.type === "IS_FAILURE";
    state.errorMessageUpdateProfile = payload.payload;
  },

  setLoadingDeleteProfile(state, payload) {
    state.isPendingDeleteProfile = payload.type === "IS_PENDING";
    state.isSuccessDeleteProfile = payload.type === "IS_SUCCESS";
    state.isFailureDeleteProfile = payload.type === "IS_FAILURE";
    state.errorMessageDeleteProfile = payload.payload;
  },
};
