export default {
  setLoadingAddObjectType(state, { type, payload = "" }) {
    state.isPendingAddObjectType = type === "IS_PENDING";
    state.isSuccessAddObjectType = type === "IS_SUCCESS";
    state.isFailureAddObjectType = type === "IS_FAILURE";
    state.errorMessageAddObjectType = payload;
  },

  setLoadingGetObjectType(state, { type, payload = "" }) {
    state.isPendingGetObjectType = type === "IS_PENDING";
    state.isSuccessGetObjectType = type === "IS_SUCCESS";
    state.isFailureGetObjectType = type === "IS_FAILURE";
    state.errorMessageGetObjectType = payload;
  },

  setObjectType(state, payload) {
    state.objectType = payload || [];
  },

  setLoadingUpdateObjectType(state, { type, payload = "" }) {
    state.isPendingUpdateObjectType = type === "IS_PENDING";
    state.isSuccessUpdateObjectType = type === "IS_SUCCESS";
    state.isFailureUpdateObjectType = type === "IS_FAILURE";
    state.errorMessageUpdateObjectType = payload;
  },
};
