<template>
  <List />
</template>

<script>
import List from "./components/List";

export default {
  name: "Devices",

  components: { List },
};
</script>
