import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  //Старое хранилище api
  get({ commit }, { page, limit, search } = {}) {
    const params = {};

    if (page) params.page = page;
    if (limit) params.limit = limit;
    if (search) params.search = search;

    commit("setLoading", { type: "IS_PENDING" });

    return $axios("operators", { params })
      .then((response) => {
        const {
          data,
          meta: { pagination },
        } = response.data;

        commit("set", { data, pagination });
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  //Новое хранилище api
  resetGetOperatorsList({ commit }) {
    commit("setLoadingGetOperatorsList", { type: "" });
    commit("setOperatorsList", []);
  },

  getOperatorsList({ commit }, params) {
    commit("setLoadingGetOperatorsList", { type: "IS_PENDING" });

    return $axios("operators", { params })
      .then((response) => {
        const data = response.data;

        commit("setOperatorsList", data.data);
        commit("setLoadingGetOperatorsList", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetOperatorsList", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },
};
