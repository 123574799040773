export default {
  getCorrectPasses: (state) => {
    return state.data.map((item) => {
      let avatarStatic = "";
      let avatar = "";
      let eventType = "";

      if (parseInt(item.event.code) === 350) {
        avatarStatic = require("@/assets/img/press-btn.svg");
        eventType = "pressBtn";
      } else if (parseInt(item.event.code) >= 301) {
        avatarStatic = require("@/assets/img/error.svg");
        eventType = "deviceAlarm";
      } else {
        eventType = "userAlarm";
      }

      if (item.photo) {
        avatar = item.photo;
      } else if (item.event.image_encode) {
        avatar = `data:image/png;base64,${item.event.image_encode}`;
      }

      return {
        ...item,
        avatarStatic,
        avatar,
        eventType,
        data: item.event,
      };
    });
  },
};
