import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      v$: useVuelidate(),
      number: "",
      checkTokenData: {
        result: false,
        person_id: 0,
      },
    };
  },

  validations() {
    return {
      number: {
        require: helpers.withParams({ type: "require" }, (value) => {
          return !!value;
        }),

        async isUnique(value) {
          if (value === "") return true;

          const params = {
            token: this.number,
            type: this.type,
          };

          if (this.personId) {
            params.id = this.personId;
          }

          const response = await this.checkToken(params);
          this.checkTokenData = response.data.data;

          return !(await response?.data?.data?.result);
        },
      },
    };
  },

  methods: {
    ...mapActions({
      checkToken: "persons/checkToken",
    }),

    getHrefByName(name, params) {
      const route = this.$router.resolve({ name, params });
      return route.href;
    },
  },

  computed: {
    numberErrors() {
      const errors = [];
      if (!this.v$.number.$dirty) return errors;
      this.v$.number.require.$invalid &&
        errors.push(this.$t("message.NameIsRequired"));
      this.v$.number.isUnique.$invalid &&
        errors.push(
          this.$t("message.duplicate", {
            href: this.getHrefByName(this.$const.ROUTES.SHOW_PERSON, {
              id: this.checkTokenData.person_id,
            }),
          })
        );
      return errors;
    },
  },
};
