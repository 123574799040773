import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  //Добавление типа пользователя
  resetAddProfileType({ commit }) {
    commit("setLoadingAddProfileType", { type: "" });
  },

  addProfileType({ commit }, params) {
    commit("setLoadingAddProfileType", { type: "IS_PENDING" });

    return $axios
      .post(`profiles/types`, params)
      .then((response) => {
        commit("setLoadingAddProfileType", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingAddProfileType", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Получение типа пользователя
  resetGetProfileType({ commit }) {
    commit("setLoadingGetProfileType", { type: "" });
    commit("setProfileType", {});
  },

  getProfileType({ commit }, id) {
    commit("setLoadingGetProfileType", { type: "IS_PENDING" });

    return $axios(`profiles/type/${id}`)
      .then((response) => {
        const { data } = response.data;
        commit("setProfileType", data);

        commit("setLoadingGetProfileType", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetProfileType", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Получение типа пользователя
  resetUpdateProfileType({ commit }) {
    commit("setLoadingUpdateProfileType", { type: "" });
  },

  updateProfileType({ commit }, { id, data }) {
    commit("setLoadingUpdateProfileType", { type: "IS_PENDING" });

    return $axios
      .put(`profiles/type/${id}`, data)
      .then((response) => {
        commit("setLoadingUpdateProfileType", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingUpdateProfileType", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },
};
