export default {
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  set(state, payload) {
    state.data = payload;
  },

  setLoadingStore(state, { type, payload = "" }) {
    state.isPendingStore = type === "IS_PENDING";
    state.isSuccessStore = type === "IS_SUCCESS";
    state.isFailureStore = type === "IS_FAILURE";
    state.errorMessageStore = payload;
  },

  setLoadingEdit(state, { type, payload = "" }) {
    state.isPendingEdit = type === "IS_PENDING";
    state.isSuccessEdit = type === "IS_SUCCESS";
    state.isFailureEdit = type === "IS_FAILURE";
    state.errorMessageEdit = payload;
  },

  setAllRoles(state, payload) {
    state.allRoles = payload;
  },

  setRoles(state, payload) {
    state.roles = payload;
  },

  setLoadingRoles(state, payload) {
    state.isPendingRoles = payload.type === "IS_PENDING";
    state.isSuccessRoles = payload.type === "IS_SUCCESS";
    state.isFailureRoles = payload.type === "IS_FAILURE";
    state.errorMessageRoles = payload.payload;
  },
};
