<template>
  <v-text-field
    :model-value="modelValue"
    :label="`${$t('permits.number')} *`"
    color="primary"
    density="comfortable"
    variant="outlined"
    :append-icon="modelValue ? 'mdi-qrcode' : 'mdi-autorenew'"
    :error-messages="errorMessages"
    hide-details="auto"
    @click:append="clickAppend"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #message="{ message }">
      <span v-html="message" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    clickAppend() {
      if (this.modelValue) {
        this.openQrCodeDialog();
      } else {
        this.generateQRCode();
      }
    },

    openQrCodeDialog() {
      this.$emit("openQrCodeDialog", this.modelValue);
    },

    generateQRCode() {
      this.$emit("generateQRCode", this.modelValue);
    },
  },
};
</script>
