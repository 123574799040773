<template>
  <v-row>
    <v-col cols="12" :md="settings.md">
      <el-date-picker
        v-model="startDateValue"
        type="datetime"
        :clearable="settings.clearable"
        :size="settings.size"
        style="width: 100%"
        :placeholder="$t('requests.startDue')"
        :disabled-date="disabledStartDate"
        :disabled-hours="disabledStartHours"
        :disabled-minutes="disabledStartMinutes"
      />
    </v-col>
    <v-col cols="12" :md="settings.md">
      <el-date-picker
        v-model="endDateValue"
        type="datetime"
        :clearable="settings.clearable"
        :size="settings.size"
        style="width: 100%"
        :placeholder="$t('requests.endDue')"
        :disabled-date="disabledEndDate"
        :disabled-hours="disabledEndHours"
        :disabled-minutes="disabledEndMinutes"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => {
        return {
          md: "6",

          clearable: false,
          size: "default",

          minDateTime: "",
          maxDateTime: "",
          maxRange: null,
        };
      },
    },
  },
  data() {
    return {
      testDate2: "",
    };
  },
  computed: {
    startDateValue: {
      get() {
        if (!this.modelValue) return null;
        return this.modelValue[0];
      },
      set(newValue) {
        let curValueStart = newValue;
        let curValueEnd = this.modelValue[1];

        if (curValueStart) {
          if (
            this.settings.minDateTime &&
            this.$moment(curValueStart).isBefore(this.settings.minDateTime)
          ) {
            curValueStart = this.settings.minDateTime;
          }

          if (
            this.settings.maxDateTime &&
            this.$moment(curValueStart).isAfter(this.settings.maxDateTime)
          ) {
            curValueStart = this.settings.maxDateTime;
          }

          if (this.settings.maxRange && curValueStart) {
            let maxSelectedRange = this.$moment(curValueStart).add(
              this.settings.maxRange,
              "days"
            );
            if (
              this.settings.maxDateTime &&
              this.$moment(maxSelectedRange).isAfter(this.settings.maxDateTime)
            ) {
              maxSelectedRange = this.settings.maxDateTime;
            }
            if (
              curValueEnd &&
              this.$moment(curValueEnd).isBefore(curValueStart)
            ) {
              curValueEnd = curValueStart;
            }
            if (
              curValueEnd &&
              this.$moment(curValueEnd).isAfter(maxSelectedRange)
            ) {
              curValueEnd = maxSelectedRange.format();
            }
          }

          if (this.$moment(curValueStart).isAfter(curValueEnd)) {
            curValueEnd = curValueStart;
          }
        }

        const event = [curValueStart, curValueEnd];
        this.$emit("update:modelValue", event);
      },
    },
    endDateValue: {
      get() {
        if (!this.modelValue) return null;
        return this.modelValue[1];
      },
      set(newValue) {
        let curValueEnd = newValue;
        let curValueStart = this.modelValue[0];

        if (curValueEnd) {
          if (
            this.settings.minDateTime &&
            this.$moment(curValueEnd).isBefore(this.settings.minDateTime)
          ) {
            curValueEnd = this.settings.minDateTime;
          }
          if (
            this.settings.maxDateTime &&
            this.$moment(curValueEnd).isAfter(this.settings.maxDateTime)
          ) {
            curValueEnd = this.settings.maxDateTime;
          }

          if (this.$moment(curValueEnd).isBefore(curValueStart)) {
            curValueEnd = curValueStart;
          }
        }

        const event = [this.modelValue[0], curValueEnd];
        this.$emit("update:modelValue", event);
      },
    },
  },
  methods: {
    //Start Date
    disabledStartDate(date) {
      const curDate = this.$moment(date).format("YYYY-MM-DD");
      if (this.settings.minDateTime) {
        const formatStartDate = this.$moment(this.settings.minDateTime).format(
          "YYYY-MM-DD"
        );
        if (this.$moment(curDate).isBefore(formatStartDate)) return true;
      }
      if (this.settings.maxDateTime) {
        const formatEndDate = this.$moment(this.settings.maxDateTime).format(
          "YYYY-MM-DD"
        );
        if (this.$moment(curDate).isAfter(formatEndDate)) return true;
      }
      return false;
    },
    disabledStartHours() {
      const curHours = [];
      if (!this.modelValue[0]) curHours;
      if (this.settings.minDateTime) {
        const formatStartDate = this.$moment(this.settings.minDateTime).format(
          "YYYY-MM-DD"
        );
        const curDate = this.$moment(this.modelValue[0]).format("YYYY-MM-DD");
        if (this.$moment(curDate).isSame(formatStartDate)) {
          for (let i = 0; i < 24; i++) {
            if (i < this.$moment(this.settings.minDateTime).toObject().hours)
              curHours.push(i);
          }
        }
      }
      if (this.settings.maxDateTime) {
        const formatEndDate = this.$moment(this.settings.maxDateTime).format(
          "YYYY-MM-DD"
        );
        const curDate = this.$moment(this.modelValue[0]).format("YYYY-MM-DD");
        if (this.$moment(curDate).isSame(formatEndDate)) {
          for (let i = 0; i < 24; i++) {
            if (i > this.$moment(this.settings.maxDateTime).toObject().hours)
              curHours.push(i);
          }
        }
      }
      return curHours;
    },
    disabledStartMinutes() {
      const curMinutes = [];
      if (!this.modelValue[0]) curMinutes;
      if (this.settings.minDateTime) {
        const formatStartDate = this.$moment(this.settings.minDateTime).format(
          "YYYY-MM-DD"
        );
        const curDate = this.$moment(this.modelValue[0]).format("YYYY-MM-DD");
        if (this.$moment(curDate).isSame(formatStartDate)) {
          for (let i = 0; i < 60; i++) {
            if (i < this.$moment(this.settings.minDateTime).toObject().minutes)
              curMinutes.push(i);
          }
        }
      }
      if (this.settings.maxDateTime) {
        const formatEndDate = this.$moment(this.settings.maxDateTime).format(
          "YYYY-MM-DD"
        );
        const curDate = this.$moment(this.modelValue[0]).format("YYYY-MM-DD");
        if (this.$moment(curDate).isSame(formatEndDate)) {
          for (let i = 0; i < 60; i++) {
            if (i > this.$moment(this.settings.maxDateTime).toObject().minutes)
              curMinutes.push(i);
          }
        }
      }
      return curMinutes;
    },
    //End Date
    disabledEndDate(date) {
      const curDate = this.$moment(date).format("YYYY-MM-DD");
      if (!this.modelValue[0]) return;
      if (this.modelValue[0]) {
        const formatStartDate = this.$moment(this.modelValue[0]).format(
          "YYYY-MM-DD"
        );
        if (this.$moment(curDate).isBefore(formatStartDate)) return true;
      }
      if (this.settings.maxDateTime) {
        const formatEndDate = this.$moment(this.settings.maxDateTime).format(
          "YYYY-MM-DD"
        );
        if (this.$moment(curDate).isAfter(formatEndDate)) return true;
      }
      if (this.settings.maxRange && this.modelValue[0]) {
        const maxSelectedRange = this.$moment(this.modelValue[0])
          .add(this.settings.maxRange, "days")
          .format("YYYY-MM-DD");
        if (this.$moment(curDate).isAfter(maxSelectedRange)) {
          return true;
        }
      }
      return false;
    },
    disabledEndHours() {
      const curHours = [];
      if (!this.modelValue[0]) return;
      if (this.modelValue[0]) {
        const formatStartDate = this.$moment(this.modelValue[0]).format(
          "YYYY-MM-DD"
        );
        const curDate = this.$moment(this.modelValue[1]).format("YYYY-MM-DD");
        if (this.$moment(curDate).isSame(formatStartDate)) {
          for (let i = 0; i < 24; i++) {
            if (i < this.$moment(this.modelValue[0]).toObject().hours)
              curHours.push(i);
          }
        }
      }
      if (this.settings.maxDateTime) {
        const formatEndDate = this.$moment(this.settings.maxDateTime).format(
          "YYYY-MM-DD"
        );
        const curDate = this.$moment(this.modelValue[1]).format("YYYY-MM-DD");
        if (this.$moment(curDate).isSame(formatEndDate)) {
          for (let i = 0; i < 24; i++) {
            if (i > this.$moment(this.settings.maxDateTime).toObject().hours)
              curHours.push(i);
          }
        }
      }
      return curHours;
    },
    disabledEndMinutes() {
      const curMinutes = [];
      if (!this.modelValue[0]) return;
      if (this.modelValue[0]) {
        const formatStartDate = this.$moment(this.modelValue[0]).format(
          "YYYY-MM-DD"
        );
        const curDate = this.$moment(this.modelValue[1]).format("YYYY-MM-DD");
        if (this.$moment(curDate).isSame(formatStartDate)) {
          for (let i = 0; i < 60; i++) {
            if (i < this.$moment(this.modelValue[0]).toObject().minutes)
              curMinutes.push(i);
          }
        }
      }
      if (this.settings.maxDateTime) {
        const formatEndDate = this.$moment(this.settings.maxDateTime).format(
          "YYYY-MM-DD"
        );
        const curDate = this.$moment(this.modelValue[1]).format("YYYY-MM-DD");
        if (this.$moment(curDate).isSame(formatEndDate)) {
          for (let i = 0; i < 60; i++) {
            if (i > this.$moment(this.settings.maxDateTime).toObject().minutes)
              curMinutes.push(i);
          }
        }
      }
      return curMinutes;
    },
  },
};
</script>
