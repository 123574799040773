import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  isPendingStore: false,
  isSuccessStore: false,
  isFailureStore: false,
  errorMessageStore: "",

  data: null,
};

export default {
  state,
  mutations,
  actions,
};
