<template>
  <Alert v-if="isFailureAddProfileTypeState" type="error">
    {{ errorMessageAddProfileTypeState }}
  </Alert>

  <Form
    :disabled="isPendingAddProfileTypeState"
    @cancelForm="goBack"
    @setData="saveForm"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";

import Alert from "@/components/Alert";

export default {
  components: { Form, Alert },

  methods: {
    ...mapActions({
      resetAddProfileTypeAction: "profileTypes/resetAddProfileType",
      addObjectTypeAction: "profileTypes/addProfileType",
    }),

    saveForm(data) {
      this.addObjectTypeAction(data).then(this.goBack);
    },

    goBack() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      isPendingAddProfileTypeState: (state) =>
        state.profileTypes.isPendingAddProfileType,
      isFailureAddProfileTypeState: (state) =>
        state.profileTypes.isFailureAddProfileType,
      errorMessageAddProfileTypeState: (state) =>
        state.profileTypes.errorMessageAddProfileType,
    }),
  },

  unmounted() {
    this.resetAddProfileTypeAction();
  },
};
</script>
