<template>
  <v-card class="mx-auto" outlined color="#F3F2FF">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="type"
            :items="templates"
            :label="$t('operators.typeRole')"
            item-title="name"
            item-value="type"
            variant="outlined"
            density="comfortable"
            color="primary"
            @update:modelValue="onChangeType"
          />
        </v-col>

        <v-col cols="12" md="4">
          <label>{{ departmentsLabel }}</label>
          <el-tree-select
            v-model="departments"
            node-key="id"
            :data="departmentsList"
            check-strictly
            :render-after-expand="false"
            show-checkbox
            check-on-click-node
            :props="{ label: 'name' }"
            :placeholder="$t('operators.all')"
            filterable
            multiple
            @update:modelValue="onChange"
          />
        </v-col>

        <v-col cols="12" md="4">
          <label>{{ accessLevelLabel }}</label>
          <el-tree-select
            v-model="accessLevels"
            node-key="id"
            :data="accessLevelsList"
            check-strictly
            :render-after-expand="false"
            show-checkbox
            check-on-click-node
            :props="{ label: 'name' }"
            :placeholder="$t('operators.all')"
            filterable
            multiple
            @update:modelValue="onChange"
          />
        </v-col>

        <v-col cols="12" md="4">
          <label>{{ zonesLabel }}</label>
          <el-tree-select
            v-model="zones"
            node-key="id"
            :data="zonesList"
            check-strictly
            :render-after-expand="false"
            show-checkbox
            check-on-click-node
            :props="{ label: 'name' }"
            :placeholder="$t('operators.all')"
            filterable
            multiple
            @update:modelValue="onChange"
          />
        </v-col>

        <v-col cols="12">
          <v-card class="mb-4" elevation="0">
            <v-toolbar
              :color="this.v$.rules.$invalid ? 'red' : 'primary'"
              dark
              flat
            >
              <v-toolbar-title>{{ $t("operators.rules") }}</v-toolbar-title>
            </v-toolbar>

            <v-row>
              <v-col cols="12" md="6">
                <v-card-text>
                  <el-tree
                    ref="rulesTree"
                    :data="items"
                    node-key="id"
                    :props="{
                      label: 'name',
                    }"
                    default-expand-all
                    :expand-on-click-node="false"
                    check-on-click-node
                    :highlight-current="true"
                    show-checkbox
                    :default-checked-keys="defaultCheckedKeysComputed"
                    @check="onCheck"
                  >
                    <template #="{ data }">
                      {{ data.name }}
                      <v-tooltip
                        v-if="data.info"
                        location="right"
                        max-width="250"
                      >
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props" color="grey" size="16"
                            >mdi-information</v-icon
                          >
                        </template>
                        <span>{{ data.info }}</span>
                      </v-tooltip>
                    </template>
                  </el-tree>
                </v-card-text>
              </v-col>

              <v-divider class="roles__divider-opacity" vertical />

              <v-col cols="12" md="6">
                <v-card-text>
                  <div
                    v-if="rules.length === 0"
                    key="title"
                    class="text-h6 font-weight-light grey--text pa-4 text-center"
                  >
                    {{ $t("operators.chooseRules") }}
                  </div>

                  <v-scroll-x-transition group hide-on-leave>
                    <div v-for="rule in getItemsArray" :key="rule.id">
                      <div v-if="getActiveGroup(rule).length" class="mb-5">
                        <h2 class="mb-3">{{ rule.name }}</h2>

                        <v-chip
                          v-if="!rule.children?.length"
                          color="primary"
                          dark
                          small
                          closable
                          outlined
                          @click:close="removeRuleByID(rule.id)"
                          class="ma-1"
                        >
                          {{ $t(`rules.full.${rule.id}`) }}
                        </v-chip>

                        <template
                          v-for="child in rule.children"
                          :key="child.id"
                        >
                          <template v-if="rules.includes(child.id)">
                            <v-chip
                              v-if="!child.virtual"
                              color="info"
                              dark
                              small
                              closable
                              outlined
                              @click:close="removeRuleByID(child.id)"
                              class="ma-1"
                            >
                              {{ $t(`rules.full.${child.id}`) }}
                            </v-chip>

                            <v-chip
                              v-else
                              color="primary"
                              dark
                              small
                              closable
                              outlined
                              @click:close="removeRuleByID(rule.id)"
                              class="ma-1"
                            >
                              {{ $t(`rules.full.${child.id}`) }}
                            </v-chip>
                          </template>
                        </template>
                      </div>
                    </div>
                  </v-scroll-x-transition>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-if="isDelete">
      <v-spacer />
      <v-btn color="red" text @click="onCancel">
        {{ $t("button.delete") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

import validator from "./validator";

import walkTree from "@/mixins/walkTree";

export default {
  name: "OperatorRole",

  mixins: [validator, walkTree],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => [],
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    templates: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      type: "",
      departments: [],
      accessLevels: [],
      zones: [],
      rules: [],

      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
        };
      },
    };
  },

  watch: {
    data() {
      this.getData();
    },
  },

  computed: {
    ...mapState({
      departmentsList: (state) => state.departments.tree,
      accessLevelsList: (state) => state.accessLevels.tree,
      zonesList: (state) => state.zones.list,
    }),

    defaultCheckedKeysComputed() {
      const curKeys = [];

      this.walkArray(this.items, (item) => {
        if (!item.children?.length && this.rules.includes(item.id)) {
          curKeys.push(item.id);
        }
      });

      return curKeys;
    },

    isAccessLevelsRequired() {
      let isRequired = false;

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        isRequired = roleArr[0].req_accesslevels;
      }

      return isRequired;
    },

    isDepartmentsRequired() {
      let isRequired = false;

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        isRequired = roleArr[0].req_departments;
      }

      return isRequired;
    },

    isZonesRequired() {
      let isRequired = false;

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        isRequired = roleArr[0].req_zones;
      }

      return isRequired;
    },

    accessLevelLabel() {
      let label = this.$t("operators.accessLevel");

      if (this.isAccessLevelsRequired) {
        label += " *";
      }

      return label;
    },

    zonesLabel() {
      let label = this.$t("operators.zone");

      if (this.isZonesRequired) {
        label += " *";
      }

      return label;
    },

    departmentsLabel() {
      let label = this.$t("operators.department");

      if (this.isDepartmentsRequired) {
        label += " *";
      }

      return label;
    },

    getItemsArray() {
      const curArray = [];

      this.items.forEach((arrItem) => {
        const curArrItem = {
          ...arrItem,
          children: arrItem.children?.filter(
            (child) => child.children == undefined
          ),
        };

        curArray.push(curArrItem);

        if (arrItem.children?.length) {
          this.walkArray(arrItem.children, (item) => {
            if (item?.children?.length) {
              const curItem = {
                ...item,
                children: item.children.filter(
                  (child) => child.children == undefined
                ),
              };

              curArray.push(curItem);
            }
          });
        }
      });

      return curArray;
    },

    getCheckedKeys() {
      const curKeys = [];

      this.walkArray(this.items, (item) => {
        if (!item.children?.length && this.rules.includes(item.id)) {
          curKeys.push(item.id);
        }
      });

      return curKeys;
    },
  },

  methods: {
    onCheck(item, nodes) {
      if (item?.virtual && !nodes.checkedKeys.includes(item.id)) {
        this.removeRuleByID(item.virtual);
        return;
      }

      const curRequiredFields = [];

      nodes.halfCheckedNodes.forEach((node) => {
        if (node?.children.length) {
          const curFindNode = node.children.find((child) => child?.virtual);

          if (curFindNode) {
            curRequiredFields.push(curFindNode.id);
          }
        }
      });

      this.$emit("updateData", {
        loopId: this.data.loopId,
        type: this.type,
        departments: this.departments,
        accesslevels: this.accessLevels,
        allowed_zones: this.zones,
        permissions: [
          ...nodes.checkedKeys,
          ...nodes.halfCheckedKeys,
          ...curRequiredFields,
        ],
      });
    },

    onChange() {
      const curData = {
        loopId: this.data.loopId,
        type: this.type,
        departments: this.departments,
        accesslevels: this.accessLevels,
        allowed_zones: this.zones,
        permissions: [
          ...this.$refs.rulesTree.getCheckedKeys(),
          ...this.$refs.rulesTree.getHalfCheckedKeys(),
        ],
      };

      this.$emit("updateData", curData);
      this.v$.rules.$touch();
    },

    onChangeType() {
      this.rules = [...this.getDefaultPermissions()];
      this.$refs.rulesTree.setCheckedKeys(this.getCheckedKeys, true);
      this.onChange();
    },

    getActiveGroup(rule) {
      const curKeys = [];

      if (rule.children?.length) {
        curKeys.push(rule.children.map((child) => child.id));
      }

      curKeys.push(rule.id);

      return curKeys.filter((item) => this.rules.includes(item));
    },

    removeRuleByID(ruleID) {
      this.$refs.rulesTree.setChecked(ruleID, false, true);
      this.onChange();
    },

    onCancel() {
      this.$emit("cancel", this.data.loopId);
    },

    getDefaultPermissions() {
      let permissions = [];

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        permissions = roleArr[0].permissions;
      }

      return permissions;
    },
    toDefault() {
      this.rules = this.rules.length
        ? this.rules
        : [...this.getDefaultPermissions()];
    },
    getData() {
      this.type = this.data?.type || this.$const.ROLES.OPERATOR;
      this.departments = this.data?.departments || [];
      this.accessLevels = this.data?.accesslevels || [];
      this.zones = this.data?.allowed_zones || [];
      this.rules = this.data?.permissions || [];
    },
  },

  created() {
    this.getData();
    this.toDefault();
  },

  mounted() {
    this.onChange();
  },
};
</script>
<style lang="scss">
.roles__divider-opacity {
  margin: 0 !important;
  border: none !important;
  padding: 12px 0;
  &::after {
    content: "";
    height: 100%;
    box-shadow: -1px 0px 0px 1px black !important;
  }
}
</style>
