<template>
  <div>
    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Form :isPendingStore="isPending" @store="onStore" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  name: "AddZone",

  components: { Form, Alert },

  methods: {
    ...mapActions({ store: "zone/store" }),

    ...mapMutations({
      setLoading: "zone/setLoading",
    }),

    async onStore(data) {
      await this.store(data);

      if (!this.isFailure) {
        this.$router.push({ name: this.$const.ROUTES.ZONES });
      }
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.department.isPendingStore,
      isFailure: (state) => state.department.isFailureStore,
      errorMessage: (state) => state.department.errorMessageStore,
    }),
  },

  unmounted() {
    this.setLoading({ type: "IS_RESET_STORE" });
  },
};
</script>
