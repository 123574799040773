import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

import { errorHandler } from "@/store/helpers";

export default {
  get({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios(`department/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("set", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        errorHandler(error, commit, "IS_FAILURE");
      });
  },

  store({ commit }, data) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios
      .post("departments/add", data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        errorHandler(error, commit, "IS_FAILURE");
      });
  },

  edit({ commit }, { id, data }) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios
      .put(`department/${id}`, data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        errorHandler(error, commit, "IS_FAILURE");
      });
  },

  remove({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios
      .delete(`department/${id}`)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS" });

        $toast.success(i18n.global.t("message.deleteSuccess"));
      })
      .catch((error) => {
        errorHandler(error, commit, "IS_FAILURE");
      });
  },
};
