<template>
  <Alert v-if="isFailureAddObjectTypeState" type="error">
    {{ errorMessageAddObjectTypeState }}
  </Alert>

  <Form
    :disabled="isPendingAddObjectTypeState"
    @cancelForm="goBack"
    @setData="save"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";

import Alert from "@/components/Alert";

export default {
  components: { Form, Alert },

  methods: {
    ...mapActions({
      resetAddObjectTypeAction: "objectTypes/resetAddObjectType",
      addObjectTypeAction: "objectTypes/addObjectType",
    }),

    save(data) {
      this.addObjectTypeAction(data).then(this.goBack);
    },

    goBack() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      isPendingAddObjectTypeState: (state) =>
        state.objectTypes.isPendingAddObjectType,
      isFailureAddObjectTypeState: (state) =>
        state.objectTypes.isFailureAddObjectType,
      errorMessageAddObjectTypeState: (state) =>
        state.objectTypes.errorMessageAddObjectType,
    }),
  },

  unmounted() {
    this.resetAddObjectTypeAction();
  },
};
</script>
