import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  get({ commit }) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("permit/templates")
      .then((response) => {
        const { data } = response.data;

        commit("set", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },
};
