<template>
  <Alert v-if="isFailureGetPermitState" type="error">
    {{ errorMessageGetPermitState }}
  </Alert>

  <v-card
    elevation="2"
    :disabled="isPendingGetPermitState || disabled"
    :loading="isPendingGetPermitState"
  >
    <template #loader="{ isActive }">
      <v-progress-linear
        v-if="isActive"
        color="primary"
        height="10"
        indeterminate
      />
    </template>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <Permit
            :personId="1"
            :data="permit"
            :readonly="false"
            :isShowTokens="true"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="pa-4">
      <v-btn @click="cancel">
        <v-icon start>mdi-cancel</v-icon>
        {{ $t("button.cancel") }}
      </v-btn>
      <v-spacer />
      <v-btn variant="flat" color="green" @click="setData">
        <v-icon start>mdi-content-save-outline</v-icon>
        {{ $t("button.save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import Permit from "@/components/Permits/Card";

export default {
  emits: ["cancel", "setData"],

  components: { Permit, Alert },

  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      permit: {},
    };
  },

  methods: {
    ...mapActions({
      getPermitAction: "permits/getPermit",
    }),

    cancel() {
      this.$emit("cancel");
    },

    async getData() {
      if (this.id == undefined) return;

      await this.getPermitAction(this.id).then(() => {
        this.permit = structuredClone(this.permitState);
      });
    },

    setData() {
      const params = {
        ...this.permit,
      };

      this.$emit("setData", params);
    },
  },

  computed: {
    ...mapState({
      permitState: (state) => state.accessModes.permit,
      isPendingGetPermitState: (state) => state.permits.isPendingGetPermit,
      isFailureGetPermitState: (state) => state.permits.isFailureGetPermit,
      errorMessageGetPermitState: (state) =>
        state.permits.errorMessageGetPermit,
    }),
    maxModesLeft() {
      return this.maxModes - this.modes.length;
    },
  },

  async created() {
    await this.getData();
  },
};
</script>
