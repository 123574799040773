import actions from "./actions";
import mutations from "./mutations";
import { jsonParse } from "../../helpers";

//По умолчанию
const defaultPageData = {};
const defaultFilter = {};
const defaultPagination = {
  page: 1,
  total: 1,
  limit: 10,
  totalItems: 0,
};

//Получение данных
const pageData =
  jsonParse(localStorage.getItem("objectStatisticsStatePageData")) ||
  defaultPageData;
const filterData =
  jsonParse(localStorage.getItem("objectStatisticsStateFilter")) ||
  defaultFilter;
const paginationData =
  jsonParse(localStorage.getItem("objectStatisticsStatePagination")) ||
  defaultPagination;

const state = {
  data: pageData,
  filter: filterData,
  pagination: paginationData,
};

export default {
  state,
  mutations,
  actions,
};
