export default {
  //Monitoring
  changeMonitoringFilters(state, params) {
    state.monitoring.filter = { ...state.monitoring.filter, ...params };
    localStorage.setItem("monitoringState", JSON.stringify(state.monitoring));
  },
  changeMonitoringContent(state, params) {
    state.monitoring.content = { ...state.monitoring.content, ...params };
    localStorage.setItem("monitoringState", JSON.stringify(state.monitoring));
  },

  //IndoorsTable
  changeIndoorsFilters(state, params) {
    state.indoorsTable.filter = { ...state.indoorsTable.filter, ...params };
    localStorage.setItem(
      "indoorsTableState",
      JSON.stringify(state.indoorsTable)
    );
  },
  changeIndoorsPagination(state, params) {
    state.indoorsTable.pagination = {
      ...state.indoorsTable.pagination,
      ...params,
    };
    localStorage.setItem(
      "indoorsTableState",
      JSON.stringify(state.indoorsTable)
    );
  },

  //EventLogsTable
  changeEventLogsFilter(state, params) {
    state.eventLogsTable.filter = { ...state.eventLogsTable.filter, ...params };
    localStorage.setItem(
      "eventLogsTableState",
      JSON.stringify(state.eventLogsTable)
    );
  },
  changeEventLogsPagination(state, params) {
    state.eventLogsTable.pagination = {
      ...state.eventLogsTable.pagination,
      ...params,
    };
    localStorage.setItem(
      "eventLogsTableState",
      JSON.stringify(state.eventLogsTable)
    );
  },
};
