<template>
  <div>
    <v-skeleton-loader v-if="isPending" type=" list-item-three-line, actions" />

    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Alert v-if="isFailureStore" type="error">
      <template v-if="errorMessageStore">
        {{ errorMessageStore }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Form
      v-if="isSuccess"
      :id="id"
      :data="data"
      :isPendingStore="isPendingStore"
      @store="onEdit({ id, data: $event })"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  name: "EditZone",

  components: { Form, Alert },

  data() {
    return {
      id: parseInt(this.$route.params.id),
    };
  },

  methods: {
    ...mapActions({ edit: "zone/edit", get: "zone/get" }),

    ...mapMutations({
      setLoading: "zone/setLoading",
    }),

    async onEdit(data) {
      await this.edit(data);

      if (!this.isFailureStore) {
        this.$router.push({ name: this.$const.ROUTES.ZONES });
      }
    },
  },

  computed: {
    ...mapState({
      data: (state) => state.zone.data,
      isPending: (state) => state.zone.isPending,
      isSuccess: (state) => state.zone.isSuccess,
      isFailure: (state) => state.zone.isFailure,
      errorMessage: (state) => state.zone.errorMessage,
      isPendingStore: (state) => state.zone.isPendingStore,
      isFailureStore: (state) => state.zone.isFailureStore,
      errorMessageStore: (state) => state.zone.errorMessageStore,
    }),
  },

  created() {
    this.get(this.id);
  },
};
</script>
