<template>
  <v-slide-x-transition>
    <div v-if="url" class="zoom-img">
      <img :src="url" class="zoom-img__img" />
    </div>
  </v-slide-x-transition>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
  },
};
</script>
