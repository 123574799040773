<template>
  <Alert v-if="isFailureAddAccessModeState" type="error">
    {{ errorMessageAddAccessModeState }}
  </Alert>

  <Form
    :disabled="isPendingAddAccessModeState"
    @setData="save"
    @cancel="goBack"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  components: { Form, Alert },

  data() {
    return {
      backRoute: "ACCESS_MODES",
    };
  },

  methods: {
    ...mapActions({
      resetAddAccessModeAction: "accessModes/resetAddAccessMode",
      addAccessModeAction: "accessModes/addAccessMode",
    }),

    getRouteData() {
      this.backRoute = history.state.route
        ? history.state.route
        : this.backRoute;
    },

    goBack() {
      if (this.$route?.query?.close) {
        return window.close();
      }

      this.$router.go(-1);
    },

    save(data) {
      this.addAccessModeAction(data).then(this.goBack);
    },
  },

  computed: {
    ...mapState({
      isPendingAddAccessModeState: (state) =>
        state.accessModes.isPendingAddAccessMode,
      isFailureAddAccessModeState: (state) =>
        state.accessModes.isFailureAddAccessMode,
      errorMessageAddAccessModeState: (state) =>
        state.accessModes.errorMessageAddAccessMode,
    }),
  },

  created() {
    this.getRouteData();
  },

  unmounted() {
    this.resetAddAccessModeAction();
  },
};
</script>
