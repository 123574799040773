export default {
  data() {
    return {
      qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
      imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
      dotsOptions: { type: "rounded", color: "#7165ff", gradient: null },
      backgroundOptions: { color: "#ffffff" },
      image:
        "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9Ii04IC0xMyA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00MS42MTcyIDE3LjA5MThDNDEuODE3NyA3LjYxOTgxIDMzLjU5ODYgLTAuMTQ4MjAyIDI0LjIyNjggMC4wMDIxNDYzOUMxNC44MDUgLTAuMDk4MDg2MiA2LjczNjI3IDcuNjE5ODEgNi44ODY2MiAxNy4wOTE4QzYuNjg2MTUgMjYuNTYzOCAxNC43NTQ5IDM0LjMzMTggMjQuMjI2OCAzNC4xODE0QzMzLjU5ODYgMzQuMzMxOCA0MS44MTc3IDI2LjU2MzggNDEuNjE3MiAxNy4wOTE4Wk0xMi4zOTk0IDE3LjA0MTdDMTIuMzk5NCAxMC4wNzU1IDE3LjQxMSA0Ljg2MzQgMjQuMjc3IDQuODYzNEMzMC45OTI1IDQuODYzNCAzNi4xNTQ1IDEwLjEyNTYgMzYuMTU0NSAxNy4wNDE3QzM2LjE1NDUgMjQuMTA4MSAzMS4wNDI3IDI5LjMyMDIgMjQuMjc3IDI5LjMyMDJDMTcuNDExIDI5LjMyMDIgMTIuMzk5NCAyNC4xMDgxIDEyLjM5OTQgMTcuMDQxN1pNMCA0LjMwMTc3QzAgNi4yMzIzMSAxLjU1MjgyIDcuNzQzMTcgMy40NDEzOSA3Ljc0MzE3QzUuMzcxOTQgNy43NDMxNyA2Ljg4MjggNi4xOTAzNCA2Ljg4MjggNC4zMDE3N0M2Ljg4MjggMi4zNzEyMyA1LjMyOTk3IDAuODYwMzcgMy40NDEzOSAwLjg2MDM3QzEuNTUyODIgMC44NjAzNyAwIDIuNDEzMiAwIDQuMzAxNzdaIiBmaWxsPSIjNzE2NUZGIi8+Cjwvc3ZnPgo=",
      dotsOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#6a1a4c",
          color2: "#6a1a4c",
          rotation: "0",
        },
      },
      cornersSquareOptions: { type: "dot", color: "#7165ff" },
      cornersSquareOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      cornersDotOptions: { type: "dot", color: "#7165ff" },
      cornersDotOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      backgroundOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#ffffff",
          color2: "#ffffff",
          rotation: "0",
        },
      },
    };
  },
};
