<template>
  <v-sheet v-if="isPending" class="bg-grey-lighten-4 rounded-lg pa-3 h-100">
    <v-skeleton-loader
      v-if="showHeader"
      class="overflow-hidden rounded-lg mb-3"
      max-height="100"
      type="image"
    />
    <v-skeleton-loader
      class="overflow-hidden rounded-lg mb-3"
      max-height="300"
      type="image"
    />
    <v-skeleton-loader
      class="overflow-hidden rounded-lg"
      max-height="30"
      type="image"
    />
  </v-sheet>
  <div v-else class="h-100">
    <!-- Visible -->
    <v-sheet class="bg-grey-lighten-4 rounded-lg pa-3 h-100">
      <v-card
        style="z-index: unset"
        class="mb-3 elevation-0 rounded-lg overflow-visible"
      >
        <v-card-title>
          <v-row v-if="showHeader">
            <v-col cols="auto"> {{ $t("filter.filter") }} </v-col>
            <v-spacer />
            <v-col v-if="showOptions || allActiveCount" cols="auto">
              <v-btn
                v-if="allActiveCount"
                :ripple="false"
                color="red"
                variant="text"
                density="comfortable"
                icon="mdi-filter-remove-outline"
                @click="clearModelValue"
              />
              <v-menu v-if="showOptions">
                <template #activator="{ isActive, props }">
                  <v-btn
                    v-bind="props"
                    :ripple="false"
                    :class="{ 'text-primary --active': isActive }"
                    class="settings-button"
                    variant="plain"
                    density="comfortable"
                    icon="mdi-cog-outline"
                  />
                </template>
                <v-list>
                  <slot name="optionsList" />
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-fade-transition group>
        <v-card
          v-for="item in visible"
          :key="item.name"
          style="z-index: unset"
          class="mb-3 elevation-0 rounded-lg overflow-visible"
        >
          <v-card-title class="text-subtitle-1">
            {{ item.name }}
          </v-card-title>
          <v-card-text>
            <TreeFilterItem
              v-if="item.type == 'tree'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <CheckboxesFilter
              v-if="item.type == 'checkboxes'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <SearchFilter
              v-if="item.type == 'search'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <SelectFilter
              v-if="item.type == 'select'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <SwitchFilter
              v-if="item.type == 'switch'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <DateRangeFilter
              v-if="item.type == 'dateRange'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
          </v-card-text>
        </v-card>
      </v-fade-transition>
    </v-sheet>

    <!-- Hidden -->
    <v-sheet class="bg-transparent" v-if="!!hidden.length">
      <template v-if="expand">
        <v-card
          v-for="item in hidden"
          :key="item.name"
          class="mb-3 elevation-0 rounded-lg"
        >
          <v-card-title class="text-subtitle-1">
            {{ item.name }}
          </v-card-title>
          <v-card-text>
            <TreeFilterItem
              v-if="item.type == 'tree'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <CheckboxesFilter
              v-if="item.type == 'checkboxes'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <SearchFilter
              v-if="item.type == 'search'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <SelectFilter
              v-if="item.type == 'select'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <SwitchFilter
              v-if="item.type == 'switch'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
            <DateRangeFilter
              v-if="item.type == 'dateRange'"
              :modelValue="modelValue[item.param]"
              :item="item"
              @update:modelValue="update"
            />
          </v-card-text>
        </v-card>
      </template>
      <v-btn
        class="rounded-lg elevation-0 bg-white"
        :text="
          expand
            ? $t('user.collapse')
            : `${$t('user.expand')} (${hiddenActiveCount})`
        "
        variant="plain"
        size="small"
        block
        @click="toggleExpand"
      />
    </v-sheet>
  </div>
</template>

<script>
import TreeFilterItem from "./filterItems/TreeFilter.vue";
import CheckboxesFilter from "./filterItems/CheckboxesFilter.vue";
import SearchFilter from "./filterItems/SearchFilter.vue";
import SelectFilter from "./filterItems/SelectFilter.vue";
import SwitchFilter from "./filterItems/SwitchFilter.vue";
import DateRangeFilter from "./filterItems/DateRangeFilter.vue";

export default {
  components: {
    TreeFilterItem,
    CheckboxesFilter,
    SearchFilter,
    SelectFilter,
    SwitchFilter,
    DateRangeFilter,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    hidden: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Array,
      default: () => [],
    },

    showHeader: {
      type: Boolean,
      default: true,
    },

    showOptions: {
      type: Boolean,
      default: true,
    },

    isPending: {
      type: Boolean,
      default: false,
    },

    allActiveCount: {
      type: Number,
      default: 0,
    },
    visibleActiveCount: {
      type: Number,
      default: 0,
    },
    hiddenActiveCount: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      expand: false,
    };
  },

  methods: {
    toggleExpand() {
      this.expand = !this.expand;
    },

    update(event) {
      this.$emit("update:modelValue", event);
    },

    clearModelValue() {
      this.$emit("clearModelValue");
    },
  },
};
</script>
