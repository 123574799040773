import { mapState, mapGetters, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions({
      setCurPageAction: "stateSystem/setCurPage",
    }),

    routerBefore() {
      this.$router.beforeEach((to, from, next) => {
        if (!this.user.isSuccess) {
          const checkAuth = setInterval(() => {
            if (this.user.isSuccess) {
              clearInterval(checkAuth);
              const userPermissions = this.getRole.permissions || [];
              const isSuperAdmin = this.user.data?.is_admin || false;
              const isSetNewPassword = !this.user.data?.password_upd;
              if (!isSuperAdmin) {
                // Проверяем есть ли права доступа к странице
                if (to.meta.permissions && to.meta.permissions.length > 0) {
                  let isAllowed = userPermissions.some((p) =>
                    to.meta.permissions.includes(p)
                  );
                  if (!isAllowed) return next("/access-denied");
                }

                // Проверяем нужно ли установить новый пароль
                if (isSetNewPassword) {
                  return next("/personal");
                }
              }

              return next();
            }
          }, 100);
        } else {
          const userPermissions = this.getRole.permissions || [];
          const isSuperAdmin = this.user.data?.is_admin || false;

          // Проверяем есть ли права доступа к странице
          if (
            !isSuperAdmin &&
            to.meta.permissions &&
            to.meta.permissions.length > 0
          ) {
            let isAllowed = userPermissions.some((p) =>
              to.meta.permissions.includes(p)
            );
            if (!isAllowed) {
              return next("/access-denied");
            }
          }
          return next();
        }
      });
    },

    afterEach() {
      this.$router.afterEach((to) => {
        if (this.$auth.check()) {
          const curTo = {
            name: to.name,
            params: to.params,
          };

          this.setCurPageAction(curTo);
        }
      });
    },

    routerInit() {
      this.routerBefore();
      this.afterEach();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters({
      getRole: "user/getRole",
    }),
  },
  created() {
    this.routerInit();
  },
};
