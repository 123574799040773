import useVuelidate from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  sameAs,
  helpers,
} from "@vuelidate/validators";

const validUserName = helpers.regex(/^[a-zA-Z0-9!@#$%^&*-_]+$/);
const validUserPass = helpers.regex(/^[a-zA-Z0-9!@#$%^&*-_]+$/);

export default {
  data() {
    return {
      v$: useVuelidate(),
      username: "",
      password: "",
      passwordConfirm: "",
    };
  },

  validations() {
    const data = {};

    if (!this.id || this.isChangeAuth) {
      data.username = {
        required,
        validUserName,
        minLength: minLength(5),
        maxLength: maxLength(100),
      };

      data.password = {
        required,
        validUserPass,
        minLength: minLength(6),
        maxLength: maxLength(255),
      };

      data.passwordConfirm = {
        required,
        sameAsPassword: sameAs(this.password),
      };
    }

    return data;
  },

  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.v$.username.$dirty) return errors;

      this.v$.username.validUserName.$invalid &&
        errors.push(this.$t("auth.username_char_valid"));

      (this.v$.username.minLength.$invalid ||
        this.v$.username.maxLength.$invalid) &&
        errors.push(this.$t("auth.lenghtn_username_8"));

      this.v$.username.required.$invalid &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.v$.password.$dirty) return errors;

      (this.v$.password.minLength.$invalid ||
        this.v$.password.maxLength.$invalid) &&
        errors.push(this.$t("operators.errLenghtnPassword"));

      this.v$.password.validUserPass.$invalid &&
        errors.push(this.$t("auth.chars_password"));

      this.v$.password.required.$invalid &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    passwordConfirmErrors() {
      const errors = [];
      if (!this.v$.passwordConfirm.$dirty) return errors;

      this.v$.passwordConfirm.sameAsPassword.$invalid &&
        errors.push(this.$t("auth.passwords_must_match"));

      this.v$.passwordConfirm.required.$invalid &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },
  },

  methods: {
    validateForm() {
      if (this.$refs.roles) {
        let isInvalid = false;

        this.v$.$touch();
        isInvalid = this.v$.$invalid;

        for (let i = 0; i < this.$refs.roles.roles.length; i++) {
          const element =
            this.$refs.roles.$refs[
              `role-${this.$refs.roles.roles[i].loopId}`
            ][0].v$;
          element.$touch();
          if (element.$invalid) {
            isInvalid = true;
          }
        }

        return !isInvalid;
      } else {
        return true;
      }
    },
  },
};
