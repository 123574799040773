<template>
  <v-row>
    <v-col>
      <v-btn-group>
        <v-speed-dial
          v-for="(button, index) in buttonsComputed"
          :key="index"
          :open-on-click="false"
          :open-on-focus="false"
          open-on-hover
          location="bottom center"
          transition="fade-transition"
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              v-tooltip="
                button == '1'
                  ? $t('access-modes.workDay')
                  : $t('access-modes.dayOff')
              "
              :key="index"
              :color="button == '1' ? 'success' : 'error'"
              :size="$vuetify.display.xs ? 'small' : 'default'"
              :icon="
                button == '1' ? 'mdi-account-hard-hat-outline' : 'mdi-sleep'
              "
              :value="button.value"
              @click="changeMode(index)"
            />
          </template>
          <v-btn
            icon="mdi-close"
            key="1"
            color="red"
            @click="removeMode(index)"
          />
        </v-speed-dial>
        <v-btn
          v-if="modelValue.length < 30"
          v-tooltip="$t('button.add')"
          :size="$vuetify.display.xs ? 'small' : 'default'"
          color="primary"
          icon="mdi-plus"
          @click="addButton"
        />
      </v-btn-group>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },

  methods: {
    addButton() {
      const curValue = this.modelValue + "0";

      this.$emit("update:modelValue", curValue);
    },
    changeMode(index) {
      const startValue = this.modelValue.slice(0, index);
      const endValue = this.modelValue.slice(index + 1);
      const curValue = this.modelValue.slice(index, index + 1);

      this.$emit(
        "update:modelValue",
        startValue + (curValue == "1" ? "0" : "1") + endValue
      );
    },

    removeMode(index) {
      const startValue = this.modelValue.slice(0, index);
      const endValue = this.modelValue.slice(index + 1);

      this.$emit("update:modelValue", startValue + endValue);
    },
  },

  computed: {
    buttonsComputed() {
      return this.modelValue.split("");
    },
  },
};
</script>
<style>
.v-btn-group {
  height: auto !important;
  flex-wrap: wrap;
}
.v-btn-group button {
  height: 48px !important;
}
</style>
