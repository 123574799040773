import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  get({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios(`accesslevel/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("set", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  store({ commit }, data) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return $axios
      .post("accesslevels/add", data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.saveError"));
      });
  },

  edit({ commit }, { id, data }) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return $axios
      .put(`accesslevel/${id}`, data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.saveError"));
      });
  },

  remove({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return $axios
      .delete(`accesslevel/${id}`)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        $toast.success(i18n.global.t("message.deleteSuccess"));
      })
      .catch((error) => {
        let message =
          error?.response?.data?.status?.message ||
          i18n.t("message.deleteError");

        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: message,
        });

        $toast.error(message);
      });
  },
};
