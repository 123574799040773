export default {
  setLoadingAddProfileType(state, { type, payload = "" }) {
    state.isPendingAddProfileType = type === "IS_PENDING";
    state.isSuccessAddProfileType = type === "IS_SUCCESS";
    state.isFailureAddProfileType = type === "IS_FAILURE";
    state.errorMessageAddProfileType = payload;
  },

  setLoadingGetProfileType(state, { type, payload = "" }) {
    state.isPendingGetProfileType = type === "IS_PENDING";
    state.isSuccessGetProfileType = type === "IS_SUCCESS";
    state.isFailureGetProfileType = type === "IS_FAILURE";
    state.errorMessageGetProfileType = payload;
  },

  setProfileType(state, payload) {
    state.profileType = payload || [];
  },

  setLoadingUpdateProfileType(state, { type, payload = "" }) {
    state.isPendingUpdateProfileType = type === "IS_PENDING";
    state.isSuccessUpdateProfileType = type === "IS_SUCCESS";
    state.isFailureUpdateProfileType = type === "IS_FAILURE";
    state.errorMessageUpdateProfileType = payload;
  },
};
