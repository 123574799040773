import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  //Старое хранилище api
  getList({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("accesslevels", { params })
      .then((response) => {
        const data = response.data;

        commit("setList", {
          data: data.data,
          pagination: data.meta?.pagination || {
            current_page: 1,
            per_page: 30,
            total: 0,
          },
        });
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  getTree({ commit }) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("accesslevels/tree")
      .then((response) => {
        const { data } = response.data;

        commit("setTree", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  //Новое хранилище api
  resetGetAccessLevelsList({ commit }) {
    commit("setLoadingGetAccessLevelsList", { type: "" });
    commit("setAccessLevelsList", []);
  },

  getAccessLevelsList({ commit }, params) {
    commit("setLoadingGetAccessLevelsList", { type: "IS_PENDING" });

    return $axios("accesslevels", { params })
      .then((response) => {
        const data = response.data;

        commit("setAccessLevelsList", data.data);
        commit("setLoadingGetAccessLevelsList", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetAccessLevelsList", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  resetGetAccessLevelsTree({ commit }) {
    commit("setLoadingGetAccessLevelsList", { type: "" });
    commit("setAccessLevelsList", []);
  },

  getAccessLevelsTree({ commit }, params) {
    commit("setLoadingGetAccessLevelsTree", { type: "IS_PENDING" });

    return $axios("accesslevels/tree", { params })
      .then((response) => {
        const data = response.data;

        commit("setAccessLevelsTree", data.data);
        commit("setLoadingGetAccessLevelsTree", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetAccessLevelsTree", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },
};
