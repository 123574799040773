import { mapState } from "vuex";

export default {
  methods: {
    correctVisit(visit, is_short) {
      if (!visit || !Object.keys(visit).length) return {};

      const DATE_FORMATE = is_short ? "HH:mm:ss" : "DD.MM.YY HH:mm:ss";
      const event = structuredClone(visit);

      //Аватар
      event.isStaticAvatar = false;
      if (parseInt(event.event?.code) == 350) {
        event.avatar = require("@/assets/img/press-btn.svg");
        event.isStaticAvatar = true;
      } else if (parseInt(event.event?.code) >= 301) {
        event.avatar = require("@/assets/img/error.svg");
        event.isStaticAvatar = true;
      } else if (event.photo) {
        event.avatarMax = `/avatar/profile/${event.photo}`;
        event.avatar = `/avatar/small/${event.photo}`;
      } else if (event.event?.image_encode) {
        event.avatar = `data:image/png;base64,${event.event?.image_encode}`;
      } else {
        event.avatar = "";
      }

      //Устройство
      if (event.event?.device_name) event.deviceName = event.event.device_name;

      //Тип прохода
      if (!event.purpose) event.purpose = "exit";
      event.purposeType = event.purpose;
      if (event.event?.zone)
        event.purpose = `${event.event.zone} / ${event.event?.device_name}`;

      //Типы идентификатора
      event.identityTypes = event.event?.identity_types
        ? this.getIdentityTypesArr(event.event.identity_types)
        : [];

      //Тип события
      if (parseInt(event.event?.code) >= 301) {
        event.eventType = "deviceAlarm";
      } else {
        event.eventType = "userAlarm";
      }

      event.pass_type = event.action;

      //Дата создания
      event.formatCreatedAt = this.$moment(String(event.created_at)).format(
        DATE_FORMATE
      );

      //Зона
      event.zone_id = event.event?.zone_id || null;

      //Температура
      if (!this.settings.temperature_rise && event.event?.temperature > 0) {
        event.temperature = `/ ${this.calculateCalcFeel(
          event.event.temperature
        )}° ${this.settings.temp_meas.toUpperCase()}`;
      } else {
        event.temperature = null;
      }

      // let zone = "";
      // let zone_id = 0;
      // let data = {};
      // let fullname = "";
      // let udata = {};
      // let device = "";
      // let identity_types = [];
      // let identity_value = "";
      // let message = "";
      // let code = "";
      // let pass_type = "";
      // if (item.event) {
      //   try {
      //     data = item.event;
      //     zone = data.zone || "";
      //     fullname = data.name || this.$t("users.unrecognized");
      //     device = data.device_name || "";
      //     identity_value = data.identity_value || "";
      //     message = data.message || "";
      //     code = data.code || "";
      //     zone_id = data.zone_id || "";
      //     identity_types = this.getIdentityTypesArr(data.identity_types);
      //     if (data.type) {
      //       pass_type = data.type;
      //     } else {
      //       pass_type = item.user_id ? "before" : "alarm";
      //     }
      //   } catch (err) {
      //     return;
      //   }
      // }
      // if (!item.full_name || !item.full_name.trim()) {
      //   item.full_name = fullname;
      // }

      // if (item.udata) {
      //   try {
      //     udata = JSON.parse(item.udata);
      //   } catch (err) {
      //     return;
      //   }
      // }
      // if (data.temperature > 0) {
      //   item.temperatureValue = data.temperature;
      //   if (!this.settings.temperature_rise)
      //     item.temperature =
      //       "/ " +
      //       this.calculateCalcFeel(data.temperature) +
      //       "°" +
      //       this.settings.temp_meas.toUpperCase();
      // }

      // item.zone_id = zone_id;
      // item.identity_value = identity_value;
      // item.message = message;
      // item.code = code;
      // item.identity_types = identity_types;
      // item.pass_type = pass_type;
      // item.company = "";
      // let cmpname = [];
      // item.organization = item.organization || [];
      // item.organization.forEach((el) => {
      //   cmpname.push(el.name);
      // });

      // item.company = cmpname.join(", ");

      // item.isHasPhoto = item.avatar ? true : false;

      // // Вывод информационного поля
      // if (item.type_user == "guest") {
      //   item.info = udata["visit_purpose"];
      // } else {
      //   item.info = udata["post"];
      // }
      // item.udata = "";

      // if (item.type_user) {
      //   item.utype = this.$t("users." + item.type_user);
      // }

      // item.data = "";

      return event;
    },
    getIdentityTypesArr(types) {
      let result = [];

      if (types) {
        result = types.split(",");
      }

      return result;
    },
    calculateCalcFeel(t) {
      if (this.settings.temp_meas == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return t;
      }
    },
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
};
