export default {
  setPageData({ commit }, data = {}) {
    localStorage.setItem("objectHistoryStatePageData", JSON.stringify(data));
    commit("setPageData", data);
  },

  setFilter({ commit }, data = {}) {
    localStorage.setItem("objectHistoryStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },

  setPagination({ commit }, data = {}) {
    localStorage.setItem("objectHistoryStatePagination", JSON.stringify(data));
    commit("setPagination", data);
  },
};
