<template>
  <v-row>
    <v-spacer />
    <Filter
      v-model="filter"
      cols="auto"
      md="auto"
      filterName="indoors"
      filterType="defaultMini"
      @update:modelValue="onFilter"
    />
  </v-row>
  <v-row>
    <v-col>
      <v-data-table
        :headers="fields"
        :items="filterIndoors"
        disable-sort
        :items-per-page="pagination.limit"
        :row-props="
          (row) => {
            return { class: indicator(row.item) };
          }
        "
        item-key="id"
      >
        <template #[`item.avatar`]="{ item }">
          <EventAvatar :item="item" />
        </template>

        <template #[`header.button`]="{ item }">
          <v-btn
            v-if="!!filterIndoors.length"
            variant="outlined"
            rounded
            color="primary"
            size="small"
            @click="closeVisitAll(item)"
          >
            {{ $t("users.eventCloseAll") }}
          </v-btn>
        </template>
        <template #[`item.button`]="{ item }">
          <v-btn
            variant="outlined"
            rounded
            color="primary"
            size="small"
            @click="closeVisit(item)"
          >
            {{ $t("users.eventClose") }}
          </v-btn>
        </template>

        <template #[`item.content`]="{ item }">
          <EventContent :item="item" />
          <EventContentChips :item="item" />
        </template>

        <template #[`item.formatCreatedAt`]="{ item }">
          <EventContent
            :item="item"
            :name="{
              show: false,
            }"
            :purpose="{ show: false }"
            :temperature="{ show: false }"
            :formatCreatedAt="{
              show: true,
            }"
          />
        </template>

        <template #bottom>
          <DataTableFooterV2
            v-model="pagination"
            @update:modelValue="onPagination"
          />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";
import {
  EventAvatar,
  EventContent,
  EventContentChips,
  EventIndicator,
} from "@/components/EventsTools";

import corrVisit from "@/mixins/correctVisit";

export default {
  components: {
    DataTableFooterV2,
    EventAvatar,
    EventContent,
    EventContentChips,
    Filter,
  },

  mixins: [corrVisit, EventIndicator],

  data() {
    return {
      indoors: [],
      closedItems: [],

      indoorsInterval: null,

      fields: [
        {
          value: "avatar",
          width: 50,
          cellProps: {
            class: "py-2",
          },
        },
        {
          value: "content",
          title: this.$t("users.visitor").toUpperCase(),
          cellProps: {
            class: "py-2",
          },
        },
        {
          value: "button",
          width: 160,
          align: "center",
        },
        {
          value: "formatCreatedAt",
          title: this.$t("users.visit_time").toUpperCase(),
          width: 50,
          align: "center",
        },
      ],

      pagination: {},
      filter: {},
    };
  },

  methods: {
    ...mapActions({
      getIndoorsAction: "visits/getIndoors",
      closeVisitsAction: "visits/closeVisits",

      setStatePaginationAction: "stateIndoorTable/setPagination",
      setStateFilterAction: "stateIndoorTable/setFilter",
    }),

    //Получение состояния страницы
    getState() {
      this.pagination = { ...this.statePagination };
      this.filter = { ...this.stateFilter };
    },

    //Запись состояния страницы
    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };
      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getIndoors();
    },

    //Получение и обновление данных таблицы
    getIndoors() {
      let params = {
        ...this.pagination,
        ...this.filter,
      };

      this.getIndoorsAction(params).then((response) => {
        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;
          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }

        const list = [];
        this.indoorsState.forEach((visit) => {
          list.push(this.correctVisit(visit, false));
        });

        this.indoors = list;
        this.closedItems = [];
      });
    },
    setIndoorInterval() {
      this.indoorsInterval = setInterval(this.getIndoors, 5000);
    },
    deleteIndoorInterval() {
      clearInterval(this.indoorsInterval);
    },

    //Закрытие визитов
    closeVisit(item) {
      const params = {
        ids: [item.objects_id],
      };

      this.closedItems = [...this.closedItems, item.objects_id];

      this.closeVisitsAction(params).then(() => this.$emit("closeVisits"));
    },
    closeVisitAll() {
      if (!this.indoors.length) return;

      this.closedItems = this.indoors.map((indoor) => indoor.objects_id);

      const params = {
        isAll: true,
      };

      this.closeVisitsAction(params).then(() => this.$emit("closeVisits"));
    },
  },

  computed: {
    ...mapState({
      indoorsState: (state) => state.visits.indoors,

      stateFilter: (state) => state.stateIndoorTable.filter,
      statePagination: (state) => state.stateIndoorTable.pagination,
    }),

    filterIndoors() {
      return this.indoors.filter(
        (indoor) => !this.closedItems.includes(indoor.objects_id)
      );
    },
  },

  created() {
    this.getState();
    this.getIndoors();
    this.setIndoorInterval();
  },

  unmounted() {
    this.deleteIndoorInterval();
  },
};
</script>
