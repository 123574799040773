import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  //Добавление типа пользователя
  resetAddObjectType({ commit }) {
    commit("setLoadingAddObjectType", { type: "" });
  },

  addObjectType({ commit }, params) {
    commit("setLoadingAddObjectType", { type: "IS_PENDING" });

    return $axios
      .post(`objects/types`, params)
      .then((response) => {
        commit("setLoadingAddObjectType", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingAddObjectType", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Получение типа пользователя
  resetGetObjectType({ commit }) {
    commit("setLoadingGetObjectType", { type: "" });
    commit("setObjectType", {});
  },

  getObjectType({ commit }, id) {
    commit("setLoadingGetObjectType", { type: "IS_PENDING" });

    return $axios(`objects/type/${id}`)
      .then((response) => {
        const { data } = response.data;
        commit("setObjectType", data);

        commit("setLoadingGetObjectType", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetObjectType", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  //Получение типа пользователя
  resetUpdateObjectType({ commit }) {
    commit("setLoadingUpdateObjectType", { type: "" });
  },

  updateObjectType({ commit }, { id, data }) {
    commit("setLoadingUpdateObjectType", { type: "IS_PENDING" });

    return $axios
      .put(`objects/type/${id}`, data)
      .then((response) => {
        commit("setLoadingUpdateObjectType", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingUpdateObjectType", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },
};
