<template>
  <template v-for="field in fields" :key="field.name">
    <TextFieldShow
      v-if="
        field.type == 'text' ||
        field.type == 'phone' ||
        field.type == 'email' ||
        field.type == 'res_phone' ||
        field.type == 'res_tab_num'
      "
      :showValues="showValues"
      :field="field"
    />
    <SelectShow
      v-if="field.type == 'select'"
      :showValues="showValues"
      :field="field"
    />
    <SelectInputShow
      v-if="field.type == 'select_input'"
      :showValues="showValues"
      :field="field"
    />
  </template>
</template>

<script>
import TextFieldShow from "./components/TextFieldShow";
import SelectShow from "./components/SelectShow";
import SelectInputShow from "./components/SelectInputShow";

export default {
  components: { TextFieldShow, SelectShow, SelectInputShow },

  props: {
    showValues: {
      type: Array,
      default: () => [],
    },

    fields: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
