<template>
  <v-img
    :src="imgProtected"
    v-bind="$attrs"
    :aspect-ratio="aspectRatio"
    :max-width="maxWidth"
  />
</template>

<script>
import imgProtectedMixin from "@/mixins/imgProtected";

export default {
  name: "ImageProtected",

  mixins: [imgProtectedMixin],

  props: {
    src: {
      type: String,
      required: true,
    },
    aspectRatio: {
      type: String,
      default: "",
    },
    maxWidth: {
      type: [Number, String],
      default: undefined,
    },
  },

  watch: {
    src: {
      handler(src) {
        this.getImage(src);
      },
      immediate: true,
    },
  },
};
</script>
