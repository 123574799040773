export default {
  methods: {
    indicator(item) {
      switch (item?.indicator) {
        case "error":
          return "bg-red-lighten-4";
        case "success":
          return "bg-green-lighten-4";
        case "warning":
          return "bg-yellow-lighten-4";
        default:
          return "";
      }
    },
  },
};
