import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  //Старое хранилище api
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  list: [],
  tree: [],

  //Новое хранилище api
  isPendingGetZonesList: false,
  isSuccessGetZonesList: false,
  isFailureGetZonesList: false,
  errorMessageGetZonesList: "",

  zonesList: [],

  isPendingGetZonesTree: false,
  isSuccessGetZonesTree: false,
  isFailureGetZonesTree: false,
  errorMessageGetZonesTree: "",

  zonesTree: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
