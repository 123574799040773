export default {
  setPageData({ commit }, data = {}) {
    localStorage.setItem("eventsStatePageData", JSON.stringify(data));
    commit("setPageData", data);
  },

  setFilter({ commit }, data = {}) {
    localStorage.setItem("eventsStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },

  setPagination({ commit }, data = {}) {
    localStorage.setItem("eventsStatePagination", JSON.stringify(data));
    commit("setPagination", data);
  },
};
