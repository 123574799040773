export default {
  //Старое хранилище api
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  set(state, { data, pagination }) {
    state.data = data;
    state.pagination = pagination;
  },

  setLimit(state, limit) {
    localStorage.setItem("eventsLimit", limit);
    state.limit = limit;
  },

  //Новое хранилище api
  setLoadingGetEventsList(state, { type, payload = "" }) {
    state.isPendingGetEventsList = type === "IS_PENDING";
    state.isSuccessGetEventsList = type === "IS_SUCCESS";
    state.isFailureGetEventsList = type === "IS_FAILURE";
    state.errorMessageGetEventsList = payload;
  },
  setEventsList(state, data) {
    state.eventsList = data;
  },

  setLoadingReport(state, { type, payload = "" }) {
    state.isPendingReport = type === "IS_PENDING";
    state.isSuccessReport = type === "IS_SUCCESS";
    state.isFailureReport = type === "IS_FAILURE";
    state.errorMessageReport = payload;
  },

  setLoadingFirstEntranceEvent(state, { type, payload = "" }) {
    state.isPendingFirstEntranceEvent = type === "IS_PENDING";
    state.isSuccessFirstEntranceEvent = type === "IS_SUCCESS";
    state.isFailureFirstEntranceEvent = type === "IS_FAILURE";
    state.errorMessageFirstEntranceEvent = payload;
  },
  setFirstEntranceEvent(state, data) {
    state.dataFirstEntranceEvent = data;
  },

  setLoadingFirstExitEvent(state, { type, payload = "" }) {
    state.isPendingFirstExitEvent = type === "IS_PENDING";
    state.isSuccessFirstExitEvent = type === "IS_SUCCESS";
    state.isFailureFirstExitEvent = type === "IS_FAILURE";
    state.errorMessageFirstExitEvent = payload;
  },
  setFirstExitEvent(state, data) {
    state.dataFirstExitEvent = data;
  },
};
