export const reservedObjectFieldTypes = [
  "res_phone",
  "res_email",
  "res_tab_num",
];
export const reservedObjectFieldNames = ["phone", "email", "tab_num"];

export default {
  data() {
    return {
      reservedObjectFieldTypes,
      reservedObjectFieldNames,
    };
  },
};
