import actions from "./actions";
import mutations from "./mutations";
import { jsonParse } from "../../helpers";

//По умолчанию
const defaultCurPage = {};
const defaultCurVersion = "";

//Получение данных
const curPage =
  jsonParse(localStorage.getItem("systemCurPage")) || defaultCurPage;
const curVersion =
  jsonParse(localStorage.getItem("systemCurVersion")) || defaultCurVersion;

const state = {
  curPage,
  curVersion,
};

export default {
  state,
  mutations,
  actions,
};
