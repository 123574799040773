<template>
  <el-tree-select
    v-model="treeValue"
    :data="item.values"
    :node-key="item.nodeKey || 'id'"
    :filterable="item.filterable"
    :expand-on-click-node="false"
    :multiple="item.multiple"
    :props="item.options"
    check-on-click-node
    check-strictly
    show-checkbox
    :teleported="false"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Array, Number],
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    treeValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        const event = {};
        event[this.item.param] = newValue;
        this.$emit("update:modelValue", event);
      },
    },
  },
};
</script>
