import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const limit = window.localStorage.getItem("eventsLimit") || 30;

const state = {
  //Старое хранилище api
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  data: [],
  limit: parseInt(limit),
  pagination: {
    current_page: 1,
    per_page: 30,
    total: 0,
    total_items: 0,
  },

  //Новое хранилище api
  isPendingGetEventsList: false,
  isSuccessGetEventsList: false,
  isFailureGetEventsList: false,
  errorMessageGetEventsList: "",

  eventsList: [],

  // Report
  isPendingReport: false,
  isSuccessReport: false,
  isFailureReport: false,
  errorMessageReport: "",

  isPendingFirstEntranceEvent: false,
  isSuccessFirstEntranceEvent: false,
  isFailureFirstEntranceEvent: false,
  errorMessageFirstEntranceEvent: "",
  dataFirstEntranceEvent: {},

  isPendingFirstExitEvent: false,
  isSuccessFirstExitEvent: false,
  isFailureFirstExitEvent: false,
  errorMessageFirstExitEvent: "",
  dataFirstExitEvent: {},
};

export default {
  state,
  mutations,
  actions,
  getters,
};
