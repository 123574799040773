<template>
  <v-row type="flex">
    <v-col>
      <v-card elevation="2">
        <v-card-title
          ><v-row>
            <v-col>{{ curProfileType?.name }}</v-col>
            <v-spacer />
            <v-col v-if="showDeleteProfile" cols="auto">
              <v-btn
                :ripple="false"
                color="red"
                variant="text"
                density="comfortable"
                icon="mdi-delete-outline"
                location="top"
                @click.stop="onRemoveProfile(modelValue.profile_types_id)"
              />
            </v-col> </v-row
        ></v-card-title>
        <v-card-text class="pt-3">
          <v-row>
            <v-col cols="6" class="grey--text">ID</v-col>
            <v-col cols="6">{{ modelValue.id }}</v-col>
          </v-row>
          <v-row v-if="modelValue.departments_id">
            <v-col cols="6" class="grey--text">{{
              $t("profile.department")
            }}</v-col>
            <v-col cols="6">{{ modelValue.department_name }}</v-col>
          </v-row>
          <CustomFields
            v-model="getCustomFields"
            :fields="curProfileType?.fields"
          />
          <v-row v-if="showPermitList">
            <v-col>
              <PermitList
                :data="modelValue.permits"
                :showAddPermit="showAddPermit"
                :showEdit="showEditPermit"
                :showDelete="showDeletePermit"
                @updatePermits="onChangePermits"
                @editedStatus="isPermitsEdited = $event"
              />
              <v-checkbox
                v-model="isPermitsEdited"
                style="display: none !important"
                :rules="[(v) => !v || 'Error']"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { CustomFields } from "@/components/ObjectsTools";
import PermitList from "@/components/Permits";

export default {
  emits: ["removeProfile", "update:modelValue"],

  components: { CustomFields, PermitList },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    profileTypes: {
      type: Array,
      default: () => [],
    },

    //Настройки департаментов
    departments: {
      type: Array,
      default: () => [],
    },

    /* <-- Profile --> */

    showDeleteProfile: {
      type: Boolean,
      default: true,
    },

    /* <-- Permits --> */

    showPermitList: {
      type: Boolean,
      default: true,
    },

    showAddPermit: {
      type: Boolean,
      default: true,
    },

    showEditPermit: {
      type: Boolean,
      default: true,
    },

    showDeletePermit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isPermitsEdited: false,
    };
  },

  methods: {
    onChangePermits(permits) {
      const params = {
        ...this.modelValue,
        permits: permits,
      };

      this.$emit("update:modelValue", params);
    },

    onRemoveProfile(profileTypeId) {
      this.$emit("removeProfile", profileTypeId);
    },
  },

  computed: {
    curProfileType() {
      return this.profileTypes.find(
        (type) => type.id == this.modelValue.profile_types_id
      );
    },

    getCustomFields() {
      return this.modelValue.values ?? [];
    },
  },
};
</script>
