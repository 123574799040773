<template>
  <ZoomImg v-show="isOpenZoomImg" :url="zoomImgUrl" />

  <v-speed-dial
    activator="parent"
    location="right center"
    transition="slide-x-reverse-transition"
  >
    <template #activator="{ props: activatorProps }">
      <v-avatar v-if="isStaticAvatar" v-bind="activatorProps" size="50">
        <v-img :src="avatar" lazy-src="" />
      </v-avatar>
      <v-avatar
        v-else-if="isPhotoBase64(avatar)"
        v-bind="activatorProps"
        class="active-avatar"
        size="50"
        color="primary"
        @mouseenter="avatar ? onHoverImg(avatar) : null"
        @mouseleave="closeZoomImg"
      >
        <v-img :src="avatar" />
      </v-avatar>
      <v-avatar
        v-else-if="avatar"
        v-bind="activatorProps"
        size="50"
        color="primary"
        class="active-avatar"
        @mouseenter="
          avatarMax || avatar ? onHoverImg(avatarMax || avatar, true) : null
        "
        @mouseleave="closeZoomImg"
      >
        <ImgProtected :src="avatar" :alt="altName" />
      </v-avatar>
      <v-avatar
        v-else
        v-bind="activatorProps"
        class="active-avatar"
        color="primary"
        size="50"
      >
        <v-icon size="35"> mdi-account-circle </v-icon>
      </v-avatar>
    </template>

    <v-btn
      v-if="isCan && Object.keys(routerLink).length"
      key="1"
      color="primary"
      icon="mdi-account-arrow-right-outline"
      @click="goTo"
    />
    <v-btn
      v-if="(this.avatarMax || this.avatar) && downloadable"
      key="2"
      color="primary"
      icon="mdi-download-outline"
      @click="download"
    />
  </v-speed-dial>
</template>

<script>
import ZoomImg from "@/components/ZoomImg";

import ImgProtected from "@/components/ImgProtected";
import hoverImage from "@/mixins/hoverImage";

export default {
  mixins: [hoverImage],
  components: { ImgProtected, ZoomImg },
  props: {
    isStaticAvatar: {
      type: Boolean,
      default: false,
    },

    avatarMax: {
      type: String,
      default: "",
    },

    avatar: {
      type: String,
      default: "",
    },

    isCan: {
      type: Boolean,
      default: false,
    },

    altName: {
      type: String,
      default: "",
    },

    downloadable: {
      type: Boolean,
      default: true,
    },

    downloadName: {
      type: String,
      default: "",
    },

    routerLink: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    download() {
      const downloadAvatar = this.avatarMax || this.avatar;
      if (!downloadAvatar || !this.downloadable) return;

      const link = document.createElement("a");
      link.href = downloadAvatar;
      link.download = this.downloadName
        ? this.downloadName
        : this.$moment().format();
      link.click();
      link.remove();
    },

    goTo() {
      if (!Object.keys(this.routerLink).length) return;

      this.$router.push(this.routerLink);
    },
  },
};
</script>
