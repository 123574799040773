<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="accesslevels"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col cols="12" :lg="!pageData.isMini ? 9 : 12">
      <v-row v-if="isFailureGetAccessLevelsListState">
        <v-col>
          <Alert type="error">
            {{ errorMessageGetAccessLevelsListState }}
          </Alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            :loading="isPendingGetAccessLevelsListState"
            :headers="headers"
            :items="accessLevelsList"
            :items-per-page="pagination.limit"
            hide-default-footer
            disable-sort
            :row-props="rowProps"
          >
            <template #[`item.name`]="{ item }">
              <v-tooltip v-if="item.is_guest" location="top">
                <template #activator="{ props }">
                  <v-icon v-bind="props" start size="small"
                    >mdi-account-clock</v-icon
                  >
                </template>
                <span>{{ $t("access-levels.isGuest") }}</span>
              </v-tooltip>
              <router-link
                v-if="can($const.RULES.ACCESS_LEVELS_EDIT)"
                class="text-primary"
                :to="{
                  name: $const.ROUTES.EDIT_ACCESS_LEVEL,
                  params: {
                    id: item.id,
                  },
                }"
              >
                {{ item.name }}
              </router-link>
              <template v-else>
                {{ item.name }}
              </template>
            </template>

            <template #[`item.access_modes`]="{ item }">
              <v-row class="ma-n1 justify-center">
                <ToggleArrayItems :items="item.access_modes" :minLength="3">
                  <template #default="{ items }">
                    <v-col
                      v-for="mode in items"
                      :key="mode.id"
                      cols="auto"
                      class="pa-1"
                    >
                      <v-chip
                        size="small"
                        @click="
                          can($const.RULES.ACCESS_MODES_EDIT)
                            ? goToEditAccessModes(mode.id)
                            : undefined
                        "
                      >
                        {{ mode.name }}
                      </v-chip>
                    </v-col>
                  </template>
                  <template #button="{ toggleFull, isFull }">
                    <v-col cols="auto" class="pa-1">
                      <v-btn
                        v-tooltip="
                          isFull ? $t('user.collapse') : $t('user.expand')
                        "
                        size="small"
                        color="primary"
                        variant="tonal"
                        density="comfortable"
                        :ripple="false"
                        :icon="
                          isFull ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'
                        "
                        @click.stop="toggleFull"
                      />
                    </v-col>
                  </template>
                </ToggleArrayItems>
              </v-row>
            </template>

            <template #[`item.parent`]="{ item }">
              <template v-if="item.parent">
                <router-link
                  v-if="can($const.RULES.ACCESS_LEVELS_EDIT)"
                  class="text-primary"
                  :to="{
                    name: $const.ROUTES.EDIT_ACCESS_LEVEL,
                    params: { id: item.parent.id },
                  }"
                >
                  {{ item.parent.name }}
                </router-link>
                <template v-else>
                  {{ item.parent.name }}
                </template>
              </template>
              <span v-else>
                {{ $t("access-levels.withoutParent") }}
              </span>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip
                v-if="can($const.RULES.ACCESS_LEVELS_EDIT)"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    variant="text"
                    density="compact"
                    icon
                    color="primary"
                    @click.stop="goToEdit(item.id)"
                  >
                    <v-icon size="x-small">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("access-levels.edit") }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="can($const.RULES.ACCESS_LEVELS_DELETE)"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    variant="text"
                    density="compact"
                    icon
                    color="red"
                    @click.stop="onRemove(item.id)"
                  >
                    <v-icon size="x-small">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("access-levels.delete") }}</span>
              </v-tooltip>
            </template>

            <template #bottom>
              <DataTableFooterV2
                v-model="pagination"
                @update:modelValue="onPagination"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";
import ToggleArrayItems from "@/components/ToggleArrayItems.vue";

import permissions from "@/mixins/permissions";

export default {
  name: "AccessLevelsList",

  mixins: [permissions],

  components: { Alert, Filter, DataTableFooterV2, ToggleArrayItems },

  data() {
    return {
      accessLevelsList: [],

      headers: [
        {
          title: "ID",
          value: "id",
        },
        {
          title: this.$t("access-levels.name"),
          value: "name",
        },
        {
          title: this.$t("access-levels.accessModesList"),
          value: "access_modes",
          align: "center",
          cellProps: {
            class: "pa-2",
          },
        },
        {
          title: this.$t("access-levels.parent"),
          value: "parent",
        },
        {
          value: "actions",
          align: "right",
          width: 100,
        },
      ],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      resetGetAccessLevelsListAction: "accessLevels/resetGetAccessLevelsList",
      getAccessLevelsListAction: "accessLevels/getAccessLevelsList",

      remove: "accessLevel/remove",

      setStatePageDataAction: "stateAccessLevels/setPageData",
      setStateFilterAction: "stateAccessLevels/setFilter",
      setStatePaginationAction: "stateAccessLevels/setPagination",
    }),

    getAccessLevels() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getAccessLevelsListAction(params).then((response) => {
        this.accessLevelsList = this.accessLevelsListState;

        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;

          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ACCESS_LEVEL,
        params: {
          id,
        },
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(id);
          this.getAccessLevels();
        },
      });
    },

    goToEditAccessModes(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ACCESS_MODES,
        params: {
          id,
        },
      });
    },

    rowProps(item) {
      const props = {};
      if (
        this.$vuetify.display.mobile &&
        item.item.id &&
        this.can(this.$const.RULES.ACCESS_LEVELS_EDIT)
      ) {
        props.onClick = () => this.goToUser(item);
      }

      return props;
    },

    goToUser(item) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ACCESS_LEVEL,
        params: {
          id: item.item.id,
        },
      });
    },

    geToAccessLinks(item) {
      this.$router.push({
        name: this.$const.ROUTES.ACCESS_LINKS,
        state: {
          filter: {
            accesslevels: [item.id],
          },
        },
      });
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния таблицы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },

    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };

      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getAccessLevels();
    },
  },

  computed: {
    ...mapState({
      isPendingGetAccessLevelsListState: (state) =>
        state.accessLevels.isPendingGetAccessLevelsList,
      isFailureGetAccessLevelsListState: (state) =>
        state.accessLevels.isFailureGetAccessLevelsList,
      errorMessageGetAccessLevelsListState: (state) =>
        state.accessLevels.errorMessageGetAccessLevelsList,

      accessLevelsListState: (state) => state.accessLevels.accessLevelsList,

      statePageData: (state) => state.stateAccessLevels.data,
      stateFilter: (state) => state.stateAccessLevels.filter,
      statePagination: (state) => state.stateAccessLevels.pagination,
    }),
  },

  created() {
    this.getState();
    this.getAccessLevels();
  },

  unmounted() {
    this.resetGetAccessLevelsListAction();
  },
};
</script>
