<template>
  <v-row>
    <v-col class="pb-0" cols="12">
      <el-date-picker
        v-model="startDateValue"
        type="datetime"
        style="width: 100%"
        :placeholder="$t('requests.startDue')"
        :disabled-date="disabledStartDate"
        :disabled-hours="disabledStartHours"
        :disabled-minutes="disabledStartMinutes"
      />
    </v-col>
    <v-col cols="12">
      <el-date-picker
        v-model="endDateValue"
        type="datetime"
        style="width: 100%"
        :clearable="false"
        :placeholder="$t('requests.endDue')"
        :disabled="!this.modelValue[0]"
        :disabled-date="disabledEndDate"
        :disabled-hours="disabledEndHours"
        :disabled-minutes="disabledEndMinutes"
      />
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      testDate2: "",
    };
  },

  computed: {
    startDateValue: {
      get() {
        if (!this.modelValue) return null;
        let curValue = this.modelValue[0];
        return curValue;
      },
      set(newValue) {
        const event = {};
        let curValueStart = newValue;
        let curValueEnd = newValue ? this.modelValue[1] : null;

        //Новое начальное значение
        //Проверка начальной даты на минимальное значение
        if (
          this.item.minDateTime &&
          this.$moment(curValueStart).isBefore(this.item.minDateTime)
        ) {
          curValueStart = this.item.minDateTime;
        }

        //Проверка начальной даты на максимальное значение
        if (
          this.item.maxDateTime &&
          this.$moment(curValueStart).isAfter(this.item.maxDateTime)
        ) {
          curValueStart = this.item.maxDateTime;
        }

        //Проверка конечной даты на существование при наличии начальной
        if (curValueStart && !curValueEnd) {
          curValueEnd = new Date(
            this.$moment(curValueStart).endOf("day").toString()
          );
        }

        //Проверка конечной даты на переполнение максимального значения
        if (curValueEnd && this.$moment(curValueStart).isAfter(curValueEnd)) {
          curValueEnd = curValueStart;
        }

        //Проверка конечной даты на переполнение максимального значения
        if (
          this.item.maxDateTime &&
          this.$moment(curValueEnd).isAfter(this.item.maxDateTime)
        ) {
          curValueEnd = this.item.maxDateTime;
        }

        //Проверки связанные с Data range
        if (this.item.maxRange && curValueStart) {
          let maxSelectedRange = this.$moment(curValueStart).add(
            this.item.maxRange,
            "days"
          );

          //Установка верхней границы
          if (
            this.item.maxDateTime &&
            this.$moment(maxSelectedRange).isAfter(this.item.maxDateTime)
          ) {
            maxSelectedRange = this.item.maxDateTime;
          }

          //Устранение переполнения конечного значения
          if (this.$moment(curValueEnd).isAfter(maxSelectedRange)) {
            curValueEnd = maxSelectedRange.format();
          }
        }

        event[this.item.param] = [curValueStart, curValueEnd];
        this.$emit("update:modelValue", event);
      },
    },
    endDateValue: {
      get() {
        if (!this.modelValue) return null;
        let curValue = this.modelValue[1];
        return curValue;
      },
      set(newValue) {
        const event = {};
        let curValue = newValue;

        if (
          this.item.minDateTime &&
          this.$moment(curValue).isBefore(this.item.minDateTime)
        ) {
          curValue = this.item.minDateTime;
        }

        if (
          this.item.maxDateTime &&
          this.$moment(curValue).isAfter(this.item.maxDateTime)
        ) {
          curValue = this.item.maxDateTime;
        }

        event[this.item.param] = [this.modelValue[0], curValue];
        this.$emit("update:modelValue", event);
      },
    },
  },

  methods: {
    //Start Date
    disabledStartDate(date) {
      const curDate = this.$moment(date).format("YYYY-MM-DD");

      if (this.item.minDateTime) {
        const formatStartDate = this.$moment(this.item.minDateTime).format(
          "YYYY-MM-DD"
        );

        if (this.$moment(curDate).isBefore(formatStartDate)) return true;
      }

      if (this.item.maxDateTime) {
        const formatEndDate = this.$moment(this.item.maxDateTime).format(
          "YYYY-MM-DD"
        );

        if (this.$moment(curDate).isAfter(formatEndDate)) return true;
      }

      return false;
    },
    disabledStartHours() {
      const curHours = [];

      if (!this.modelValue[0]) curHours;

      if (this.item.minDateTime) {
        const formatStartDate = this.$moment(this.item.minDateTime).format(
          "YYYY-MM-DD"
        );

        const curDate = this.$moment(this.modelValue[0]).format("YYYY-MM-DD");

        if (this.$moment(curDate).isSame(formatStartDate)) {
          for (let i = 0; i < 24; i++) {
            if (i < this.$moment(this.item.minDateTime).toObject().hours)
              curHours.push(i);
          }
        }
      }

      if (this.item.maxDateTime) {
        const formatEndDate = this.$moment(this.item.maxDateTime).format(
          "YYYY-MM-DD"
        );

        const curDate = this.$moment(this.modelValue[0]).format("YYYY-MM-DD");

        if (this.$moment(curDate).isSame(formatEndDate)) {
          for (let i = 0; i < 24; i++) {
            if (i > this.$moment(this.item.maxDateTime).toObject().hours)
              curHours.push(i);
          }
        }
      }

      return curHours;
    },
    disabledStartMinutes() {
      const curMinutes = [];

      if (!this.modelValue[0]) curMinutes;

      if (this.item.minDateTime) {
        const formatStartDate = this.$moment(this.item.minDateTime).format(
          "YYYY-MM-DD"
        );

        const curDate = this.$moment(this.modelValue[0]).format("YYYY-MM-DD");

        if (this.$moment(curDate).isSame(formatStartDate)) {
          for (let i = 0; i < 60; i++) {
            if (i < this.$moment(this.item.minDateTime).toObject().minutes)
              curMinutes.push(i);
          }
        }
      }

      if (this.item.maxDateTime) {
        const formatEndDate = this.$moment(this.item.maxDateTime).format(
          "YYYY-MM-DD"
        );

        const curDate = this.$moment(this.modelValue[0]).format("YYYY-MM-DD");

        if (this.$moment(curDate).isSame(formatEndDate)) {
          for (let i = 0; i < 60; i++) {
            if (i > this.$moment(this.item.maxDateTime).toObject().minutes)
              curMinutes.push(i);
          }
        }
      }

      return curMinutes;
    },

    //End Date
    disabledEndDate(date) {
      const curDate = this.$moment(date).format("YYYY-MM-DD");

      if (!this.modelValue[0]) return;

      if (this.modelValue[0]) {
        const formatStartDate = this.$moment(this.modelValue[0]).format(
          "YYYY-MM-DD"
        );

        if (this.$moment(curDate).isBefore(formatStartDate)) return true;
      }

      if (this.item.maxDateTime) {
        const formatEndDate = this.$moment(this.item.maxDateTime).format(
          "YYYY-MM-DD"
        );

        if (this.$moment(curDate).isAfter(formatEndDate)) return true;
      }

      if (this.item.maxRange && this.modelValue[0]) {
        const maxSelectedRange = this.$moment(this.modelValue[0])
          .add(this.item.maxRange, "days")
          .format("YYYY-MM-DD");

        if (this.$moment(curDate).isAfter(maxSelectedRange)) {
          return true;
        }
      }

      return false;
    },
    disabledEndHours() {
      const curHours = [];

      if (!this.modelValue[0]) return;

      if (this.modelValue[0]) {
        const formatStartDate = this.$moment(this.modelValue[0]).format(
          "YYYY-MM-DD"
        );

        const curDate = this.$moment(this.modelValue[1]).format("YYYY-MM-DD");

        if (this.$moment(curDate).isSame(formatStartDate)) {
          for (let i = 0; i < 24; i++) {
            if (i < this.$moment(this.modelValue[0]).toObject().hours)
              curHours.push(i);
          }
        }
      }

      if (this.item.maxDateTime) {
        const formatEndDate = this.$moment(this.item.maxDateTime).format(
          "YYYY-MM-DD"
        );

        const curDate = this.$moment(this.modelValue[1]).format("YYYY-MM-DD");

        if (this.$moment(curDate).isSame(formatEndDate)) {
          for (let i = 0; i < 24; i++) {
            if (i > this.$moment(this.item.maxDateTime).toObject().hours)
              curHours.push(i);
          }
        }
      }

      return curHours;
    },
    disabledEndMinutes() {
      const curMinutes = [];

      if (!this.modelValue[0]) return;

      if (this.modelValue[0]) {
        const formatStartDate = this.$moment(this.modelValue[0]).format(
          "YYYY-MM-DD"
        );

        const curDate = this.$moment(this.modelValue[1]).format("YYYY-MM-DD");

        if (this.$moment(curDate).isSame(formatStartDate)) {
          for (let i = 0; i < 60; i++) {
            if (i < this.$moment(this.modelValue[0]).toObject().minutes)
              curMinutes.push(i);
          }
        }
      }

      if (this.item.maxDateTime) {
        const formatEndDate = this.$moment(this.item.maxDateTime).format(
          "YYYY-MM-DD"
        );

        const curDate = this.$moment(this.modelValue[1]).format("YYYY-MM-DD");
        if (this.$moment(curDate).isSame(formatEndDate)) {
          for (let i = 0; i < 60; i++) {
            if (i > this.$moment(this.item.maxDateTime).toObject().minutes)
              curMinutes.push(i);
          }
        }
      }

      return curMinutes;
    },
  },
};
</script>
