import VueApexCharts from "vue3-apexcharts";
import ru from "apexcharts/dist/locales/ru.json";

export default {
  install: (app) => {
    app.use(VueApexCharts);
    window.Apex = {
      chart: {
        locales: [ru],
        defaultLocale: "en",
      },
    };
  },
};
