import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  //Получить типы профилей
  resetGetProfilesTypes({ commit }) {
    commit("setLoadingGetProfilesTypes", { type: "" });
    commit("setProfilesTypes", []);
  },

  getProfilesTypes({ commit }) {
    commit("setLoadingGetProfilesTypes", { type: "IS_PENDING" });

    return $axios("/profiles/types")
      .then((response) => {
        commit("setProfilesTypes", response.data.data);
        commit("setLoadingGetProfilesTypes", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetProfilesTypes", {
          type: "IS_FAILURE",
          payload: error?.response?.data || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );

        throw error;
      });
  },

  //Добавить профиль
  resetAddProfile({ commit }) {
    commit("setLoadingAddProfile", { type: "" });
  },

  addProfile({ commit }, params) {
    commit("setLoadingAddProfile", { type: "IS_PENDING" });

    return $axios
      .post(`profiles/add`, params)
      .then((response) => {
        commit("setLoadingAddProfile", { type: "IS_SUCCESS" });
        $toast.success(i18n.global.t("message.saveSuccess"));
        return response;
      })
      .catch((error) => {
        commit("setLoadingAddProfile", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.saveError"));
        throw error;
      });
  },

  //Получить профиль
  resetGetProfile({ commit }) {
    commit("setLoadingGetProfile", { type: "" });
    commit("setProfile", {});
  },

  getProfile({ commit }, id) {
    commit("setLoadingGetProfile", { type: "IS_PENDING" });

    return $axios(`/profile/${id}`)
      .then((response) => {
        commit("setProfile", response.data.data);
        commit("setLoadingGetProfile", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetProfile", {
          type: "IS_FAILURE",
          payload: error?.response?.data || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );

        throw error;
      });
  },

  //Обновить профиль
  resetUpdateProfile({ commit }) {
    commit("setLoadingUpdateProfile", { type: "" });
  },

  updateProfile({ commit }, { data, id }) {
    commit("setLoadingUpdateProfile", { type: "IS_PENDING" });

    return $axios
      .put(`/profile/${id}`, data)
      .then((response) => {
        commit("setLoadingUpdateProfile", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingUpdateProfile", {
          type: "IS_FAILURE",
          payload: error?.response?.data || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );

        throw error;
      });
  },

  //Удалить профиль
  resetDeleteProfile({ commit }) {
    commit("setLoadingDeleteProfile", { type: "" });
  },

  deleteProfile({ commit }, id) {
    commit("setLoadingDeleteProfile", { type: "IS_PENDING" });

    return $axios
      .delete(`/profile/${id}`)
      .then((response) => {
        commit("setLoadingDeleteProfile", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingDeleteProfile", {
          type: "IS_FAILURE",
          payload: error?.response?.data || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );

        throw error;
      });
  },
};
