<template>
  <v-card>
    <v-card-title class="pb-2 pt-2 px-2 bg-primary">
      <v-row>
        <v-col cols="auto">
          <v-tooltip :text="$t('users.monitoringFullScreen')" location="right">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                icon="mdi-monitor-screenshot"
                variant="text"
                @click="goToFullscreen"
              />
            </template>
          </v-tooltip>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-tooltip
            :text="
              viewType === 1
                ? $t('users.combinedMode')
                : $t('users.separateMode')
            "
            location="right"
          >
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                :icon="
                  viewType === 1
                    ? 'mdi-card-outline'
                    : 'mdi-card-multiple-outline'
                "
                variant="text"
                @click="toggleViewType"
              />
            </template>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="user-card pa-0">
      <AllEvents
        v-if="viewType === 0"
        :lastVisit="lastVisit"
        @unblock="unblock"
      />
      <template v-else>
        <EntranceEvents :entrance="entranceVisit" @unblock="unblock" />
        <ExitEvents :exit="exitVisit" @unblock="unblock" />
      </template>

      <v-row justify="center" v-if="isShowAlarmDialog">
        <v-dialog :value="true" max-width="700px" persistent>
          <ModalAlarmDialog :data="deviceAlarm" @close="closeAlarmDialog" />
        </v-dialog>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";

import faceValidate from "@/mixins/faceValidate";
import correctVisit from "@/mixins/correctVisit";

import AllEvents from "./allEvents.vue";
import EntranceEvents from "./entranceEvents.vue";
import ExitEvents from "./exitEvents.vue";
import ModalAlarmDialog from "@/components/ModalAlarmDialog";

export default {
  mixins: [faceValidate, correctVisit],

  components: {
    AllEvents,
    EntranceEvents,
    ExitEvents,
    ModalAlarmDialog,
  },

  props: {
    viewType: {
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      entranceVisit: null,
      exitVisit: null,

      isShowAlarmDialog: false,
      deviceAlarm: null,
    };
  },

  methods: {
    ...mapActions({
      getFirstEntranceEventAction: "events/getFirstEntranceEvent",
      getFirstExitEventAction: "events/getFirstExitEvent",
    }),

    //Прослушивание вебсокетов
    connectWebSockets() {
      this.$emitter.on(`ws2-${this.$const.WS_CHANNELS.NEW_EVENT}`, (visit) => {
        this.setEntranceAndExit(visit);
      });
    },
    disconnectedWebSockets() {
      this.$emitter.off(`ws2-${this.$const.WS_CHANNELS.NEW_EVENT}`);
    },

    //Установка проходов при старте картчоки
    setEntranceOnStart() {
      this.getFirstEntranceEventAction().then(() => {
        this.entranceVisit = this.correctVisit(this.firstEntranceEvent, false);
      });
    },
    setExitOnStart() {
      this.getFirstExitEventAction().then(() => {
        this.exitVisit = this.correctVisit(this.firstExitEvent, false);
      });
    },

    //Установка проходов
    setEntranceAndExit(newVisit, isAlarm = false) {
      let visit = this.correctVisit(newVisit, false);
      if (isAlarm && correctVisit.eventType === "deviceAlarm") {
        this.showAlarmDialog(visit);
      }

      const entranceLength = this.entranceVisit
        ? Object.keys(this.entranceVisit).length
        : null;
      const exitLength = this.exitVisit
        ? Object.keys(this.exitVisit).length
        : null;

      if (visit) {
        switch (visit.purposeType) {
          case "entrance":
          case "duplex":
            if (
              !entranceLength ||
              this.$moment(visit.created_at).isAfter(
                this.$moment(this.entranceVisit.created_at)
              )
            ) {
              this.entranceVisit = { ...visit };
            } else if (
              this.$moment(visit.created_at).isSame(
                this.entranceVisit.created_at
              ) &&
              visit.id > this.entranceVisit.id
            ) {
              this.entranceVisit = { ...visit };
            }
            break;
          case "exit":
            if (
              !exitLength ||
              this.$moment(visit.created_at).isAfter(
                this.$moment(this.exitVisit.created_at)
              )
            ) {
              this.exitVisit = { ...visit };
            } else if (
              this.$moment(visit.created_at).isSame(
                this.exitVisit.created_at
              ) &&
              visit.id > this.exitVisit.id
            ) {
              this.exitVisit = { ...visit };
            }
            break;
          default:
            this.entranceVisit = null;
            this.exitVisit = null;
            break;
        }
      }
    },

    //Переключение вида карточки
    toggleViewType() {
      switch (this.viewType) {
        case 0:
          this.$emit("change_view_type", 1);

          break;
        default:
          this.$emit("change_view_type", 0);
      }
    },

    //Перейти на полный экран
    goToFullscreen() {
      const routeData = this.$router.resolve({
        name: this.$const.ROUTES.FULLSCREEN_MONITORING,
      });
      window.open(routeData.href, "_blank");
    },

    //Аларм окно
    closeAlarmDialog() {
      this.isShowAlarmDialog = false;
      this.deviceAlarm = null;
    },

    showAlarmDialog(alarm) {
      this.deviceAlarm = alarm;
      this.isShowAlarmDialog = true;
    },

    //Разблокировать пользователя
    unblock(person) {
      if (this.entranceVisit.person_id === person) {
        this.entranceVisit.Blocked = false;
      }
      if (this.exitVisit.person_id === person) {
        this.exitVisit.Blocked = false;
      }
    },
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
      firstEntranceEvent: (state) => state.events.dataFirstEntranceEvent,
      firstExitEvent: (state) => state.events.dataFirstExitEvent,
    }),
    lastVisit() {
      let result = null;

      const entranceLength = this.entranceVisit
        ? Object.keys(this.entranceVisit).length
        : null;
      const exitLength = this.exitVisit
        ? Object.keys(this.exitVisit).length
        : null;

      if (entranceLength || exitLength) {
        if (entranceLength && !exitLength) {
          result = this.entranceVisit;
        } else if (!entranceLength && exitLength) {
          result = this.exitVisit;
        } else {
          const isAfter = this.$moment(this.entranceVisit.created_at).isAfter(
            this.exitVisit.created_at
          );

          if (isAfter) {
            result = this.entranceVisit;
          } else {
            result = this.exitVisit;
          }
        }
      }

      return result;
    },
  },

  created() {
    this.connectWebSockets();
    this.setEntranceOnStart();
    this.setExitOnStart();
  },

  unmounted() {
    this.disconnectedWebSockets();
  },
};
</script>
