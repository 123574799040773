<template>
  <Alert v-if="isFailureGetObject" type="error" prominent>
    {{ errorMessageGetObject }}
  </Alert>

  <Alert v-if="isFailureUpdateProfile" type="error" prominent>
    {{ errorMessageUpdateProfile }}
  </Alert>

  <v-row>
    <v-col class="pb-8">
      <ObjectCard
        v-model="object"
        avatarClass="avatart__profile-min"
        :isEdit="false"
        :showProfileList="false"
      />
    </v-col>
  </v-row>

  <Form
    v-if="!isPendingGet"
    v-model="profile"
    :isPending="isPendingGetObject || isPendingUpdateProfile"
    @SaveForm="onSave"
    @cancelForm="onCancel"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";

import ObjectCard from "@/components/ObjectsTools/ObjectCard";
import Alert from "@/components/Alert";

export default {
  name: "AddProfile",

  emits: ["update:modelValue"],

  components: { Form, Alert, ObjectCard },

  data() {
    return {
      profile: {},
      object: {},
      object_id: null,
      profile_id: null,

      //TODO: Убрать после рефактора пермитов
      isPendingGet: false,
    };
  },

  methods: {
    ...mapActions({
      resetGetObjectAction: "object/resetGetObject",
      getObjectAction: "object/getObject",

      updateProfileAction: "profiles/updateProfile",
      resetUpdateProfileAction: "profiles/resetUpdateProfile",
    }),

    getData() {
      let curIds = this.$route.params.objId_profId;

      if (!curIds) this.onCancel();

      curIds = curIds.split("-");

      this.object_id = curIds[0];
      this.profile_id = curIds[1];
    },

    getObject() {
      this.isPendingGet = true;

      this.getObjectAction(this.object_id).then(() => {
        this.object = structuredClone(this.objectState);
        this.profile = this.object.profiles.find(
          (profile) => profile.id == this.profile_id
        );

        this.isPendingGet = false;
      });
      //.catch(this.onCancel);
    },

    onSave() {
      const params = {
        data: this.profile,
        id: this.profile_id,
      };

      this.updateProfileAction(params).then(this.onCancel);
    },

    onCancel() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      isPendingGetObject: (state) => state.object.isPendingGetObject,
      isFailureGetObject: (state) => state.object.isFailureGetObject,
      errorMessageGetObject: (state) => state.object.errorMessageGetObject,

      objectState: (state) => state.object.object,

      isPendingUpdateProfile: (state) => state.profiles.isPendingUpdateProfile,
      isFailureUpdateProfile: (state) => state.profiles.isFailureUpdateProfile,
      errorMessageUpdateProfile: (state) =>
        state.profiles.errorMessageUpdateProfile,
    }),
  },

  created() {
    this.getData();
    this.getObject();
  },

  unmounted() {
    this.resetGetObjectAction();
    this.resetUpdateProfileAction();
  },
};
</script>
<style>
.avatart__profile-min {
  flex-basis: 300px;

  @media screen and (max-width: 960px) {
    margin: 0 auto;
  }
}
</style>
