import ru from "element-plus/dist/locale/ru";
import en from "element-plus/dist/locale/en";

export default {
  methods: {
    switchLang(lang) {
      this.$vuetify.locale.current = lang;
      window.Apex.chart.defaultLocale = lang;
      this.$moment.locale(lang);
      this.$i18n.locale = lang;
    },
  },

  computed: {
    locale() {
      switch (this.$i18n.locale) {
        case "ru":
          return ru;
        default:
          return en;
      }
    },
  },
};
