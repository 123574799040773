import permissions from "./permissions";

export default {
  mixins: [permissions],
  methods: {
    tryRedirect(to) {
      if (!to || !Object.keys(to).length) return this.fastRedirect();

      const curRoutes = this.$router.getRoutes();

      const findRoute = curRoutes.find((route) => {
        return route.name == to.name;
      });

      if (!findRoute) return this.fastRedirect();

      if (findRoute?.meta?.permissions?.length) {
        if (this.can(findRoute.meta.permissions)) {
          this.$router.push(to);
        } else {
          this.fastRedirect();
        }
      } else {
        this.$router.push(to);
      }
    },

    getRedirects() {
      const curRoutes = this.$router.getRoutes();

      const pagesForRedirect = [];

      curRoutes.forEach((rout) => {
        if (!rout?.meta?.forRedirect) return;

        if (rout?.meta?.permissions?.length) {
          if (this.can(rout.meta.permissions)) pagesForRedirect.push(rout);
        } else {
          pagesForRedirect.push(rout);
        }
      });
      pagesForRedirect.sort((a, b) =>
        a.meta?.redirectIndex > b.meta?.redirectIndex ? 1 : -1
      );

      return pagesForRedirect;
    },

    fastRedirect() {
      const redirects = this.getRedirects();
      console.log(redirects);
      if (redirects.length) {
        this.$router.push(redirects[0]);
      } else {
        this.$router.push({ name: this.$const.ROUTES.ACCESS_DENIED });
      }
    },
  },
};
