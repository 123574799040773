export default {
  setLoadingGetAccessModes(state, { type, payload = "" }) {
    state.isPendingGetAccessModes = type === "IS_PENDING";
    state.isSuccessGetAccessModes = type === "IS_SUCCESS";
    state.isFailureGetAccessModes = type === "IS_FAILURE";
    state.errorMessageGetAccessModes = payload;
  },

  setAccessModes(state, payload) {
    state.dataAccessModes = payload;
  },

  setLoadingAddAccessMode(state, { type, payload = "" }) {
    state.isPendingAddAccessMode = type === "IS_PENDING";
    state.isSuccessAddAccessMode = type === "IS_SUCCESS";
    state.isFailureAddAccessMode = type === "IS_FAILURE";
    state.errorMessageAddAccessMode = payload;
  },

  setLoadingGetAccessMode(state, { type, payload = "" }) {
    state.isPendingGetAccessMode = type === "IS_PENDING";
    state.isSuccessGetAccessMode = type === "IS_SUCCESS";
    state.isFailureGetAccessMode = type === "IS_FAILURE";
    state.errorMessageGetAccessMode = payload;
  },

  setAccessMode(state, payload) {
    state.dataAccessMode = payload;
  },

  setLoadingUpdateAccessMode(state, { type, payload = "" }) {
    state.isPendingUpdateAccessMode = type === "IS_PENDING";
    state.isSuccessUpdateAccessMode = type === "IS_SUCCESS";
    state.isFailureUpdateAccessMode = type === "IS_FAILURE";
    state.errorMessageUpdateAccessMode = payload;
  },

  setLoadingDeleteAccessMode(state, { type, payload = "" }) {
    state.isPendingDeleteAccessMode = type === "IS_PENDING";
    state.isSuccessDeleteAccessMode = type === "IS_SUCCESS";
    state.isFailureDeleteAccessMode = type === "IS_FAILURE";
    state.errorMessageDeleteAccessMode = payload;
  },
};
