import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPendingGetPermits: false,
  isSuccessGetPermits: false,
  isFailureGetPermits: false,
  errorMessageGetPermits: "",

  permitsList: [],

  isPendingGetPermit: false,
  isSuccessGetPermit: false,
  isFailureGetPermit: false,
  errorMessageGetPermit: "",
  permit: {},

  isPendingUpdatePermit: false,
  isSuccessUpdatePermit: false,
  isFailureUpdatePermit: false,
  errorMessageUpdatePermit: "",
};

export default {
  state,
  mutations,
  actions,
};
