<template>
  <span v-if="item.eventType !== 'deviceAlarm'">
    <v-chip
      v-if="item.pass_type === 'before'"
      prepend-icon="mdi-check"
      color="success"
    >
      {{ $t("users.recognizedAndPass") }}
    </v-chip>

    <v-chip
      v-if="item.pass_type === 'pass'"
      prepend-icon="mdi-check-all"
      color="success"
    >
      {{ $t("users.recognizedAndPass") }}
    </v-chip>

    <v-chip
      v-if="item.pass_type === 'alarm'"
      prepend-icon="mdi-account-remove-outline"
      color="error"
    >
      {{ $t("users.notRecognized") }}
    </v-chip>

    <v-chip
      v-if="item.pass_type === 'error'"
      prepend-icon="mdi-alert-circle-outline"
      color="error"
    >
      {{ $t("users.error") }}
    </v-chip>
  </span>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
