<template>
  <v-card :loading="isPending">
    <v-card-title class="pb-5 mb-5">
      <span class="headline">{{
        $t("devices.changeDeviceNetworkSettings")
      }}</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <v-alert v-if="isFailure && errorMessage" type="error">{{
            errorMessage
          }}</v-alert>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="ipAddressState"
              :label="`${$t('devices.ipDevice')} *`"
              :rules="requiredRules"
              max-length="17"
              density="comfortable"
              variant="outlined"
              color="primary"
              required
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="macCodeComputed"
              :label="`${$t('devices.macCode')} *`"
              :rules="requiredRules"
              density="comfortable"
              variant="outlined"
              color="primary"
              maxlength="17"
              required
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="maskState"
              :label="`${$t('devices.mask')} *`"
              :rules="requiredRules"
              density="comfortable"
              variant="outlined"
              color="primary"
              required
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="gatewayState"
              :label="`${$t('devices.gateway')} *`"
              :rules="requiredRules"
              density="comfortable"
              variant="outlined"
              color="primary"
              required
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn variant="text" @click="onClose">{{ $t("button.close") }}</v-btn>
      <v-btn color="primary" variant="text" @click="setSettings">{{
        $t("button.save")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Mask } from "maska";
const mask = new Mask({ mask: "**:**:**:**:**:**" });

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    ipAddress: {
      type: String,
      required: true,
    },
    macCode: {
      type: String,
      required: true,
    },
    mask: {
      type: String,
      required: true,
    },
    gateway: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      valid: true,

      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",

      ipAddressState: this.ipAddress,
      macCodeState: this.macCode,
      maskState: this.mask,
      gatewayState: this.gateway,

      requiredRules: [(v) => !!v || this.$t("message.NameIsRequired")],
    };
  },

  computed: {
    macCodeComputed: {
      get: function () {
        return mask.masked(this.macCodeState.toUpperCase());
      },
      set: function (newValue) {
        this.macCodeState = mask.masked(newValue.toUpperCase());
      },
    },
  },

  methods: {
    async setSettings() {
      let valid = false;
      await this.$refs.form.validate().then((result) => {
        if (result.valid) valid = true;
      });
      if (!valid) return;

      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;
      this.errorMessage = "";

      this.axios
        .post(`device/${this.id}/setNetworkSettings`, {
          ipAddress: this.ipAddressState,
          macCode: this.macCodeState,
          mask: this.maskState,
          gateway: this.gatewayState,
        })
        .then(() => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.$emit("submit", {
            ipAddress: this.ipAddressState,
            macCode: this.macCodeState,
            mask: this.maskState,
            gateway: this.gatewayState,
          });
        })
        .catch((error) => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;
          this.errorMessage = error.response.data.status.message;
        });
    },
    onClose() {
      this.$emit("close");
    },
  },

  watch: {
    ipAddressState(val) {
      let g = this.gatewayState.split(".");
      let f = false;
      if (g.length < 4) {
        for (let i = g.length; i < 4; i++) {
          g.push("0");
          f = true;
        }
      }
      val.split(".").forEach((item, index) => {
        if (g.length > 3 && g[index] !== item && index < 4) {
          g[index] = item;
          if (index === 3) {
            g[index] = "1";
          }
          f = true;
        }
      });
      if (f) {
        this.gatewayState = g.join(".");
      }
    },
  },
};
</script>
