import useVuelidate from "@vuelidate/core";
import { required, integer, minValue, minLength } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useVuelidate(),
      type: "",
      pass: "",
      passChange: "",
      permitAccess: [],
    };
  },
  validations() {
    return {
      type: { required },
      pass: { required, integer, minValue: minValue(0) },
      passChange: { integer, minValue: minValue(0) },
      permitAccess: { required, minLength: minLength(1) },
    };
  },
  computed: {
    typeErrors() {
      const errors = [];
      if (!this.v$.type.$dirty) return errors;

      this.v$.type.required.$invalid &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    passErrors() {
      const errors = [];
      if (!this.v$.pass.$dirty) return errors;

      this.v$.pass.minValue.$invalid &&
        errors.push(
          this.$t("message.minValue", {
            value: this.v$.pass.minValue.$params.min,
          })
        );
      this.v$.pass.integer.$invalid && errors.push(this.$t("message.isInt"));
      this.v$.pass.required.$invalid &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    passChangeErrors() {
      const errors = [];
      if (!this.v$.passChange.$dirty) return errors;

      this.v$.passChange.minValue.$invalid &&
        errors.push(
          this.$t("message.minValue", {
            value: this.v$.passChange.minValue.$params.min,
          })
        );
      this.v$.passChange.integer.$invalid &&
        errors.push(this.$t("message.isInt"));

      return errors;
    },

    permitAccessErrors() {
      const errors = [];
      if (!this.v$.permitAccess.$dirty) return errors;

      (this.v$.permitAccess.required.$invalid ||
        this.v$.permitAccess.minLength.$invalid) &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },
  },

  methods: {
    validateForm() {
      let isInvalid = false;
      this.v$.$touch();
      isInvalid = this.v$.$invalid;

      for (let i = 0; i < this.tokens.length; i++) {
        const element = this.$refs[`token-${this.tokens[i].loopId}`][0].v$;
        element.$touch();
        if (element.$invalid) {
          isInvalid = true;
        }
      }

      return !isInvalid;
    },
  },
};
