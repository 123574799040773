export default {
  //Старое хранилище api
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  setList(state, { data, pagination }) {
    state.list = data;
    state.pagination = pagination;
  },

  setTree(state, payload) {
    state.tree = payload || [];
  },

  setLimit(state, limit) {
    localStorage.setItem("departmentsLimit", limit);
    state.limit = limit;
  },

  //Новое хранилище api
  setLoadingGetDepartmentsList(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  setDepartmentsList(state, payload) {
    state.departmentsList = payload;
  },

  setLoadingGetDepartmentsTree(state, { type, payload = "" }) {
    state.isPendingGetDepartmentsTree = type === "IS_PENDING";
    state.isSuccessGetDepartmentsTree = type === "IS_SUCCESS";
    state.isFailureGetDepartmentsTree = type === "IS_FAILURE";
    state.errorMessageGetDepartmentsTree = payload;
  },

  setDepartmentsTree(state, payload) {
    state.departmentsTree = payload;
  },
};
