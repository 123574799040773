import {
  reservedObjectFieldTypes,
  reservedObjectFieldNames,
} from "../utils/constants";

import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const validName = helpers.regex(/^[a-zA-Z0-9\\-\\_]+$/);

export default {
  data() {
    return {
      v$: useVuelidate({ $scope: false }),
      name: "",
      type: "",
      required: false,
      items: [],
      defaultValue: "",
      label: "",
      subLabel: "",
      subDefaultValue: "",
    };
  },

  validations() {
    const data = {
      name: {
        required,
        validName,
        nameReserved: helpers.withParams({ type: "nameReserved" }, (value) => {
          if (
            this.customFieldsType == "object" &&
            !reservedObjectFieldTypes.includes(this.type) &&
            reservedObjectFieldNames.includes(value)
          ) {
            return false;
          }

          return true;
        }),
        noRepeat: helpers.withParams({ type: "noRepeat" }, (value) => {
          if (!value) return true;

          if (
            this.isNew &&
            this.allFields.find((field) => field.key == value)
          ) {
            return false;
          }

          if (
            this.allFields?.filter((selectField) => selectField.key == value)
              .length > 1
          ) {
            return false;
          }

          const findField = this.allFields.find(
            (selectField) => selectField.key == value
          );

          if (findField && findField.key != this.fieldValue.name) {
            return false;
          }

          return true;
        }),
      },
      type: { required },
      items: {
        selectItemsValid: helpers.withParams(
          { type: "selectItemsValid" },
          (value) => {
            if (this.isAdd) return false;

            if (!value?.length) return true;

            return !value.filter((valueItem) => valueItem.isEdit == true)
              .length;
          }
        ),
      },
    };

    return data;
  },

  computed: {
    typeErrors() {
      const errors = [];

      if (this.v$.type.$dirty && this.v$.type.required.$invalid) {
        errors.push(this.$t("object-type.errSelectItemRequire"));
      }

      return errors;
    },

    nameErrors() {
      const errors = [];

      if (this.v$.name.$dirty && this.v$.name.required.$invalid) {
        errors.push(this.$t("object-type.errSelectItemRequire"));
      }

      if (this.v$.name.$dirty && this.v$.name.validName.$invalid) {
        errors.push(this.$t("object-type.errTypeRegex"));
      }

      if (this.v$.name.$dirty && this.v$.name.nameReserved.$invalid) {
        errors.push(this.$t("object-type.errReserved"));
      }

      if (this.v$.name.$dirty && this.v$.name.noRepeat.$invalid) {
        errors.push(this.$t("object-type.errDublicateSelectItem"));
      }

      return errors;
    },

    itemsErrors() {
      const errors = [];

      if (this.v$.items.$dirty && this.v$.items.selectItemsValid.$invalid) {
        errors.push(this.$t("object-type.errSelectItemRequire"));
      }

      return errors;
    },
  },
};
