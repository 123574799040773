export default {
  getObjectToShow: (state) => {
    const curObject = {
      ...state.object,
      name: state.object.name.split("&*|").join(" "),
    };

    return curObject;
  },
  getObjectsToShow: (state) => {
    return state.objectsList.map((object) => {
      const curObject = {
        ...object,
        name: object.name.split("&*|").join(" ").trim(),
      };

      return curObject;
    });
  },
};
