<template>
  <ShowFields v-if="show" :showValues="fieldsComputed" :fields="fields" />
  <EditFields
    v-else
    v-model="fieldsComputed"
    :settings="settings"
    :fields="fields"
  />
</template>
<script>
import ShowFields from "./ShowFields";
import EditFields from "./EditFields";

export default {
  components: { ShowFields, EditFields },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    settings: {
      type: Object,
      default: () => {},
    },

    fields: {
      type: Array,
      default: () => {},
    },

    show: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    fieldsComputed: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>
