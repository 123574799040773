<template>
  <div
    :class="[
      'device-monitoring',
      { 'device-monitoring--min-menu': isMenuMinimize },
      { 'device-monitoring--hide': isHide },
    ]"
  >
    <v-tooltip right>
      <template v-slot:activator="{ props }">
        <button
          v-bind="props"
          class="device-monitoring__hide-button"
          @click="toggleMenu"
        >
          <v-icon v-if="isHide">mdi-chevron-up</v-icon>
          <v-icon v-else>mdi-chevron-down</v-icon>
        </button>
      </template>
      <span v-if="isHide">{{ $t("devices.showMenu") }}</span>
      <span v-else>{{ $t("devices.hideMenu") }}</span>
    </v-tooltip>

    <v-alert v-if="isFailure && errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>

    <template v-if="isSuccess">
      <div class="device-monitoring__wrapper">
        <Device
          v-for="device in devices"
          :key="device.id"
          :data="device"
          :successList="monitoringData"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Device from "./Device.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "DeviceMonitoring",

  components: {
    Device,
  },

  data() {
    const isHide = localStorage.getItem("isHideDeviceMenu") === "true";

    return {
      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",

      devices: [],
      syncDeviceInterval: null,
      monitoringData: [],

      isHide,
    };
  },

  methods: {
    ...mapActions({
      getMonitoringAction: "devices/getMonitoring",
    }),
    getDevices() {
      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;
      this.errorMessage = "";

      this.axios("devices")
        .then((response) => {
          this.isPending = false;
          this.isSuccess = true;

          this.devices = response.data.data;
          this.getStatus();
        })
        .catch((error) => {
          this.isPending = false;
          this.isFailure = true;
          this.errorMessage = error.response.data.status.errors;
        });
    },
    getStatus() {
      let arrStatus = [];
      this.devices.forEach((device) => {
        const status = {
          id: device.id,
          status: device.monitoring.status,
        };
        arrStatus.push(status);
      });
      this.monitoringData = arrStatus;
    },

    connectWebSockets() {
      this.syncDeviceInterval = setInterval(() => {
        if (!this.isHide) {
          this.getMonitoringAction().then(() => {
            this.monitoringData = this.monitoringDataState;
          });
        }
      }, 10000);
    },
    disconnectedWebSockets() {
      clearInterval(this.syncDeviceInterval);
    },

    toggleMenu() {
      this.isHide = !this.isHide;
      localStorage.setItem("isHideDeviceMenu", this.isHide);
    },
  },

  computed: {
    ...mapState({
      isMenuMinimize: (state) => state.leftMenu.isMenuMinimize,
      monitoringDataState: (state) => state.devices.dataMonitoring,
    }),
  },

  created() {
    this.getDevices();
    this.connectWebSockets();
  },
  unmounted() {
    this.disconnectedWebSockets();
  },
};
</script>
