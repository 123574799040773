<template>
  <v-col id="smartFilter" :cols="cols" :lg="md">
    <template v-if="!isMini">
      <defaultFilter
        v-if="filterType == 'default'"
        :modelValue="modelValue"
        :isPending="isPending"
        :hidden="hidden"
        :visible="visible"
        :showHeader="showHeader"
        :allActiveCount="allActiveCount"
        :visibleActiveCount="visibleActiveCount"
        :hiddenActiveCount="hiddenActiveCount"
        :showOptions="showOptions"
        @clearModelValue="clearModelValue"
        @update:modelValue="update"
      >
        <template #optionsList>
          <v-list-item @click="toggleMiniFilter">
            {{ $t("filter.changeFilterType") }}
          </v-list-item>
        </template>
      </defaultFilter>
      <defaultHorizontal
        v-if="filterType == 'defaultHorizontal'"
        :modelValue="modelValue"
        :isPending="isPending"
        :hidden="hidden"
        :visible="visible"
        :showHeader="showHeader"
        :allActiveCount="allActiveCount"
        :visibleActiveCount="visibleActiveCount"
        :hiddenActiveCount="hiddenActiveCount"
        :showOptions="showOptions"
        @clearModelValue="clearModelValue"
        @update:modelValue="update"
      >
        <template #optionsList>
          <v-list-item @click="toggleMiniFilter">
            {{ $t("filter.changeFilterType") }}
          </v-list-item>
        </template>
      </defaultHorizontal>
      <defaultMiniFilter
        v-if="filterType == 'defaultMini'"
        :modelValue="modelValue"
        :isPending="isPending"
        :hidden="hidden"
        :visible="visible"
        :showHeader="showHeader"
        :allActiveCount="allActiveCount"
        :visibleActiveCount="visibleActiveCount"
        :hiddenActiveCount="hiddenActiveCount"
        :showOptions="showOptions"
        @clearModelValue="clearModelValue"
        @update:modelValue="update"
      />
    </template>
    <template v-else>
      <defaultFilter
        v-if="filterMiniType == 'default'"
        :modelValue="modelValue"
        :isPending="isPending"
        :hidden="hidden"
        :visible="visible"
        :showHeader="showHeader"
        :allActiveCount="allActiveCount"
        :visibleActiveCount="visibleActiveCount"
        :hiddenActiveCount="hiddenActiveCount"
        :showOptions="showOptions"
        @clearModelValue="clearModelValue"
        @update:modelValue="update"
      />
      <defaultHorizontal
        v-if="filterMiniType == 'defaultHorizontal'"
        :modelValue="modelValue"
        :isPending="isPending"
        :hidden="hidden"
        :visible="visible"
        :showHeader="showHeader"
        :allActiveCount="allActiveCount"
        :visibleActiveCount="visibleActiveCount"
        :hiddenActiveCount="hiddenActiveCount"
        :showOptions="showOptions"
        @clearModelValue="clearModelValue"
        @update:modelValue="update"
      >
        <template #optionsList>
          <v-list-item density="comfortable" @click="toggleMiniFilter">
            {{ toggleFilterTypeText }}
          </v-list-item>
        </template>
      </defaultHorizontal>
      <defaultMiniFilter
        v-if="filterMiniType == 'defaultMini'"
        :modelValue="modelValue"
        :isPending="isPending"
        :hidden="hidden"
        :visible="visible"
        :showHeader="showHeader"
        :allActiveCount="allActiveCount"
        :visibleActiveCount="visibleActiveCount"
        :hiddenActiveCount="hiddenActiveCount"
        :showOptions="showOptions"
        @clearModelValue="clearModelValue"
        @update:modelValue="update"
      />
    </template>
  </v-col>
</template>

<script>
import { mapState, mapActions } from "vuex";

import defaultFilter from "./types/default";
import defaultHorizontal from "./types/defaultHorizontal";
import defaultMiniFilter from "./types/defaultMini";

import clearOnStart from "./clearOnStart";

export default {
  name: "Filter",

  components: { defaultFilter, defaultHorizontal, defaultMiniFilter },

  mixins: [clearOnStart],

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    filterType: {
      type: String,
      default: "default",
    },

    filterMiniType: {
      type: String,
      default: "defaultMini",
    },

    cols: {
      type: [String, Number],
      default: 12,
    },

    md: {
      type: [String, Number],
      default: 12,
    },

    filterName: {
      type: String,
      default: "",
    },

    toggleFilterTypeText: {
      type: String,
      default: "",
    },

    showHeader: {
      type: Boolean,
      default: true,
    },

    showOptions: {
      type: Boolean,
      default: true,
    },

    useMini: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      visible: [],
      hidden: [],

      allActiveCount: 0,
      visibleActiveCount: 0,
      hiddenActiveCount: 0,
    };
  },

  watch: {
    modelValue() {
      this.calculateActiveFields();
    },
  },

  methods: {
    ...mapActions({
      getFilterTemplateAction: "utils/getFilterTemplate",
    }),

    clearModelValue() {
      this.$emit("update:modelValue", {});
    },

    update(event) {
      const value = {
        ...this.modelValue,
        ...event,
      };
      this.$emit("update:modelValue", value);
    },

    getFilterTemplate() {
      this.getFilterTemplateAction(this.filterName).then(() => {
        const clearedValue = this.clearModel(
          this.modelValue,
          this.filterTemplate.data
        );

        if (clearedValue.needUpdate) {
          this.$emit("update:modelValue", clearedValue.data);
        }

        this.filterTemplate.data.forEach((filter) => {
          if (filter.hidden) {
            this.hidden.push(filter);
          } else {
            this.visible.push(filter);
          }
        });

        this.calculateActiveFields();
      });
    },

    toggleMiniFilter() {
      this.$emit("toggleMini");
    },

    isActiveField(field = {}, value) {
      switch (field.type) {
        case "tree":
        case "select":
        case "checkboxes":
          if (field.multiple) {
            return !!value.length;
          }

          return value != undefined && value != null;

        case "dateRange":
          return !!value && (value[0] || value[1]);

        case "search":
          if (!value) return false;

          if (value.includes(":")) {
            const curSearch = value.split(":");

            return curSearch[0] && curSearch[1];
          }

          return !!value;

        case "switch":
          return value;

        default:
          return false;
      }
    },

    calculateActiveFields() {
      if (!this.visible.length && !this.hidden.length) this.setActiveCount();

      let curVisibleActiveFields = 0;
      let hiddenVisibleActiveFields = 0;

      for (const key in this.modelValue) {
        const curField = this.visible.find((field) => field.param == key);

        if (curField) {
          if (this.isActiveField(curField, this.modelValue[key]))
            ++curVisibleActiveFields;

          continue;
        }

        const curHiddenField = this.hidden.find((field) => field.param == key);

        if (curHiddenField) {
          if (this.isActiveField(curHiddenField, this.modelValue[key]))
            ++hiddenVisibleActiveFields;
        }
      }

      this.setActiveCount(curVisibleActiveFields, hiddenVisibleActiveFields);
    },

    setActiveCount(visibleActiveCount = 0, hiddenActiveCount = 0) {
      this.visibleActiveCount = visibleActiveCount;
      this.hiddenActiveCount = hiddenActiveCount;
      this.allActiveCount = visibleActiveCount + hiddenActiveCount;
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.utils.isPendingFilter,
      filterTemplate: (state) => state.utils.filterTemplate,
    }),
    isMini() {
      if (!this.useMini) return false;
      return this.$vuetify.display.mdAndDown;
    },
  },

  created() {
    this.getFilterTemplate();
  },
};
</script>
