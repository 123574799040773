<template>
  <div>
    <Alert v-if="isFailureGetObject" type="error">
      {{ errorMessageGetObject }}
    </Alert>

    <Alert v-if="isFailureUpdateObject" type="error" prominent>
      {{ errorMessageUpdateObject }}
    </Alert>

    <Form
      v-model="object"
      :isPending="isPendingGetObject || isPendingUpdateObject"
      @SaveForm="onSave"
      @cancelForm="onCancel"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Form from "../components/Form";

import Alert from "@/components/Alert";

import faceValidate from "@/mixins/faceValidate";

export default {
  name: "EditPerson",

  mixins: [faceValidate],

  components: { Form, Alert },

  data() {
    return {
      id: null,
      object: {},
    };
  },

  methods: {
    ...mapActions({
      updateObjectAction: "object/updateObject",
      resetUpdateObjectAction: "object/resetUpdateObject",

      getObjectAction: "object/getObject",
      resetGetObjectAction: "object/resetGetObject",
    }),

    getId() {
      this.id = parseInt(this.$route.params.id);
    },

    getObject() {
      this.getObjectAction(this.id)
        .then(() => {
          this.object = structuredClone(this.objectState);
        })
        .catch(this.onCancel);
    },

    onSave() {
      const curObject = structuredClone(this.object);
      if (curObject.profiles.length) delete curObject.profiles;

      const data = {
        id: this.id,
        params: curObject,
      };

      this.updateObjectAction(data).then(this.onCancel);
    },

    onCancel() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      objectState: (state) => state.object.object,

      isPendingGetObject: (state) => state.object.isPendingGetObject,
      isFailureGetObject: (state) => state.object.isFailureGetObject,
      errorMessageGetObject: (state) => state.object.errorMessageGetObject,

      isPendingUpdateObject: (state) => state.object.isPendingUpdateObject,
      isFailureUpdateObject: (state) => state.object.isFailureUpdateObject,
      errorMessageUpdateObject: (state) =>
        state.object.errorMessageUpdateObject,
    }),
  },

  created() {
    this.getId();
    this.getObject();
  },

  unmounted() {
    this.resetUpdateObjectAction();
    this.resetGetObjectAction();
  },
};
</script>
