export default {
  getRole: (state) => {
    if (state.data.roles) {
      return state.data.roles.filter(
        (item) => item.id === state.selectedRole
      )[0];
    }

    return {
      allowed_zones: [],
      permissions: [],
    };
  },
};
