import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  getFilterTemplate({ commit }, name) {
    if (!name) return;

    commit("setLoadingFilter", { type: "IS_PENDING" });

    return $axios(`filter/${name}`)
      .then((response) => {
        commit("setFilterTemplate", response.data.data);
        commit("setLoadingFilter", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingFilter", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },
};
