export default {
  methods: {
    getIdentityValueType(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);
      return type;
    },
    getIdentityValue(identityValue) {
      const separator = identityValue.indexOf(":");
      return identityValue.substring(identityValue.length, separator + 1);
    },
  },
};
