<template>
  <div>
    <v-row class="visitors-container">
      <v-col
        :cols="12"
        :lg="viewTypeComputed == 0 ? 4 : 8"
        :xl="viewTypeComputed == 0 ? 4 : 6"
      >
        <EventCard
          :viewType="viewTypeComputed"
          @change_view_type="setViewType($event)"
        />
      </v-col>

      <v-col
        :cols="12"
        :lg="viewTypeComputed == 0 ? 8 : 4"
        :xl="viewTypeComputed == 0 ? 8 : 6"
      >
        <v-card elevation="2" class="monitoring__table">
          <v-tabs v-model="curTabComputed" show-arrows color="primary">
            <v-tab
              v-for="(tab, i) in tabs"
              :key="i"
              :value="tab.value"
              :to="tab.to"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>

          <v-card-text>
            <router-view />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <DeviceMonitoring v-if="can(this.$const.RULES.DEVICE_VIEW)" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import DeviceMonitoring from "./components/devices";

import EventCard from "@/components/EventCard";

import permissions from "@/mixins/permissions";

export default {
  mixins: [permissions],

  components: {
    EventCard,
    DeviceMonitoring,
  },

  data() {
    return {
      tabs: [
        {
          name: this.$t("users.alarm"),
          to: { name: this.$const.ROUTES.EVENT_LOG_TABLE },
          value: this.$const.ROUTES.EVENT_LOG_TABLE,
        },
        {
          name: this.$t("users.indoors"),
          to: { name: this.$const.ROUTES.INDOOR_TABLE },
          value: this.$const.ROUTES.INDOOR_TABLE,
        },
      ],

      pageData: {},
    };
  },

  methods: {
    ...mapActions({
      setStatePageDataAction: "stateMonitoring/setPageData",
    }),

    setViewType(newValue) {
      const curParams = {
        ...this.pageData,
        viewType: newValue,
      };

      this.pageData = curParams;
      this.setPageDataState();
    },

    redirect() {
      if (this.$route.name === this.$const.ROUTES.MONITORING) {
        this.$router.replace({
          name: this.statePageData.curTab ?? this.tabs[0].value,
        });
      }
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
    },

    //Запись состояния таблицы
    setPageDataState() {
      this.setStatePageDataAction(this.pageData);
    },
  },

  watch: {
    $route: {
      handler() {
        this.redirect();
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      statePageData: (state) => state.stateMonitoring.data,
    }),

    curTabComputed: {
      get() {
        return this.pageData.curTab ?? "";
      },
      set(newValue) {
        this.pageData.curTab = newValue;

        this.setPageDataState();
      },
    },

    viewTypeComputed: {
      get() {
        return this.pageData.viewType ?? 0;
      },
      set(newValue) {
        this.pageData.viewType = newValue;

        this.setPageDataState();
      },
    },
  },

  created() {
    this.getState();
  },
};
</script>
