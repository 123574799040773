<template>
  <Avatar
    :isStaticAvatar="item.isStaticAvatar"
    :avatarMax="item.avatarMax"
    :avatar="item.avatar"
    :isCan="true"
    :altName="item.name"
    :downloadable="!item.isStaticAvatar && !item.objects_id"
    :routerLink="
      item.person_id
        ? {
            name: $const.ROUTES.SHOW_PERSON,
            params: { id: item.person_id },
            state: { id: item.person_id },
          }
        : {}
    "
  />
</template>

<script>
import Avatar from "@/components/UIKits/Avatar";

export default {
  components: { Avatar },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
