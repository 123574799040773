import { createStore } from "vuex";
import modules from "./modules";
import stateModules from "./state-modules";

//Объединение модулей
const allModules = { ...stateModules, ...modules };

const store = createStore({
  modules: allModules,
  strict: process.env.NODE_ENV !== "production",
});

for (const moduleName of Object.keys(allModules)) {
  if (allModules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`);
  }
}

export const storeState = store;

export default {
  install: (app) => {
    app.use(store);
  },
};
