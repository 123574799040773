export default {
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },

  set(state, payload) {
    state.data = payload;
  },

  setVisits(state, payload) {
    state.visits = payload;
  },

  setStoreErrorData(state, payload) {
    state.errorDataStore = payload;
  },

  setLoadingVisits(state, { type, payload = "" }) {
    state.isPendingVisits = type === "IS_PENDING";
    state.isSuccessVisits = type === "IS_SUCCESS";
    state.isFailureVisits = type === "IS_FAILURE";
    state.errorMessageVisits = payload;
  },

  setLoadingRefeature(state, { type, payload = "" }) {
    state.isPendingRefeature = type === "IS_PENDING";
    state.isSuccessRefeature = type === "IS_SUCCESS";
    state.isFailureRefeature = type === "IS_FAILURE";
    state.errorMessageRefeature = payload;
  },

  setLoadingPassBio(state, { type, payload = "" }) {
    state.isPendingPassBio = type === "IS_PENDING";
    state.isSuccessPassBio = type === "IS_SUCCESS";
    state.isFailurePassBio = type === "IS_FAILURE";
    state.errorMessagePassBio = payload;
  },

  clearBioLink(state) {
    state.bioLink = "";
  },

  setBioLink(state, payload) {
    state.bioLink = payload;
  },

  setLoadingSendSms(state, payload) {
    state.isPendingSendSms = payload.type === "IS_PENDING";
    state.isSuccessSendSms = payload.type === "IS_SUCCESS";
    state.isFailureSendSms = payload.type === "IS_FAILURE";
    state.errorMessageSendSms = payload.payload;
  },

  setLoadingSendEmail(state, payload) {
    state.isPendingSendEmail = payload.type === "IS_PENDING";
    state.isSuccessSendEmail = payload.type === "IS_SUCCESS";
    state.isFailureSendEmail = payload.type === "IS_FAILURE";
    state.errorMessageSendEmail = payload.payload;
  },

  setLoadingSendQrd(state, payload) {
    state.isPendingSendQrd = payload.type === "IS_PENDING";
    state.isSuccessSendQrd = payload.type === "IS_SUCCESS";
    state.isFailureSendQrd = payload.type === "IS_FAILURE";
    state.errorMessageSendQrd = payload.payload;
  },

  setLoadingOffQrd(state, payload) {
    state.isPendingOffQrd = payload.type === "IS_PENDING";
    state.isSuccessOffQrd = payload.type === "IS_SUCCESS";
    state.isFailureOffQrd = payload.type === "IS_FAILURE";
    state.errorMessageOffQrd = payload.payload;
  },

  setLoadingValidateFace(state, payload) {
    state.isPendingValidateFace = payload.type === "IS_PENDING";
    state.isSuccessValidateFace = payload.type === "IS_SUCCESS";
    state.isFailureValidateFace = payload.type === "IS_FAILURE";
    state.errorMessageValidateFace = payload.payload;
  },
};
