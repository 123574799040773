import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPendingAddProfile: false,
  isSuccessAddProfile: false,
  isFailureAddProfile: false,
  errorMessageAddProfile: "",

  isPendingGetProfilesTypes: false,
  isSuccessGetProfilesTypes: false,
  isFailureGetProfilesTypes: false,
  errorMessageGetProfilesTypes: "",

  profilesTypes: [],

  isPendingGetProfile: false,
  isSuccessGetProfile: false,
  isFailureGetProfile: false,
  errorMessageGetProfile: "",

  profile: {},

  isPendingUpdateProfile: false,
  isSuccessUpdateProfile: false,
  isFailureUpdateProfile: false,
  errorMessageUpdateProfile: "",

  isPendingDeleteProfile: false,
  isSuccessDeleteProfile: false,
  isFailureDeleteProfile: false,
  errorMessageDeleteProfile: "",
};

export default {
  state,
  mutations,
  actions,
};
