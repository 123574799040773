<template>
  <div>
    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Form :isPendingStore="isPending" @store="onStore" @cancel="onCancel" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  name: "AddAccessLevel",

  components: { Form, Alert },

  data() {
    const { query } = this.$route.params;

    return {
      query,
    };
  },

  methods: {
    ...mapActions({ store: "accessLevel/store" }),

    ...mapMutations({
      setLoading: "accessLevel/setLoading",
    }),

    async onStore(data) {
      await this.store(data);

      if (!this.isFailure) {
        this.$router.push({
          name: this.$const.ROUTES.ACCESS_LEVELS,
          query: this.query,
        });
      }
    },

    onCancel() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.accessLevel.isPendingStore,
      isFailure: (state) => state.accessLevel.isFailureStore,
      errorMessage: (state) => state.accessLevel.errorMessageStore,
    }),
  },
};
</script>
