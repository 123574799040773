<template>
  <span>
    <v-tooltip
      v-if="item.purposeType === 'entrance' || item.purposeType === 'duplex'"
      :text="$t('device.purpose_entrance')"
      location="top"
    >
      <template #activator="{ props }">
        <v-icon v-bind="props" size="small" color="success"
          >mdi-location-enter</v-icon
        >
      </template>
    </v-tooltip>
    <v-tooltip
      v-if="item.purposeType === 'exit'"
      :text="$t('device.purpose_exit')"
      location="top"
    >
      <template #activator="{ props }">
        <v-icon v-bind="props" size="small" color="error" class="rotate-180"
          >mdi-location-exit</v-icon
        >
      </template>
    </v-tooltip>
    <v-tooltip
      v-if="item.purposeType === 'basiclock'"
      :text="$t('device.purpose_basiclock')"
      location="top"
    >
      <template #activator="{ props }">
        <v-icon v-bind="props" size="small" color="warning">mdi-key</v-icon>
      </template>
    </v-tooltip>
  </span>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
