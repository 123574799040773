<template>
  <v-row v-if="curValue">
    <v-col cols="6">{{ field.label || field.name }}</v-col>
    <v-col cols="6">{{ curValue }}</v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    showValues: {
      type: Array,
      default: () => [],
    },

    field: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    curShowValue() {
      return this.showValues.find((value) => value.name == this.field.name);
    },

    curValue() {
      const curItem =
        this.field.items.find(
          (item) => item.value == this.curShowValue?.value
        ) ?? {};

      if (curItem.label == undefined) return "";

      return `${curItem.label}: ${this.curShowValue.input_value}`;
    },
  },
};
</script>
