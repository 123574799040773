<template>
  <v-row
    v-if="lastVisit && Object.keys(lastVisit).length"
    :class="indicator(lastVisit)"
    class="ma-0 pos-relative"
  >
    <v-col cols="12" class="pa-0">
      <div class="d-flex align-center flex-column">
        <EventContent
          :item="lastVisit"
          :name="{
            show: false,
          }"
          :temperature="{
            show: false,
          }"
          :purpose="{
            show: false,
            class:
              'user-card__badge py-1 px-3 text-center bg-grey-lighten-3 rounded-md rounded-t-0',
            direction: { show: true, class: 'text-uppercase font-weight-bold' },
          }"
        />
        <EventCardAvatar :item="lastVisit" />
        <div class="flex-grow-1">
          <div class="flex-grow-1 d-flex pa-3 ml-4 flex-column justify-center">
            <EventActions
              :item="lastVisit"
              :isIcons="false"
              @unblock="unblock"
            />
            <div class="mb-2 text-center">
              <EventPurposeType :item="lastVisit" />
              <EventIdentityTypes :item="lastVisit" />
            </div>

            <EventContent
              :item="lastVisit"
              :name="{
                show: true,
                class: 'text-center font-weight-bold text-h6',
              }"
              :purpose="{ show: false }"
              :organization="{
                show: true,
                class: 'text-center mt-1 text-caption font-weight-bold',
              }"
              :typeUser="{
                show: true,
                class: 'text-center mt-1 text-caption',
              }"
              :info="{ show: true, class: 'text-center mt-1 text-caption' }"
              :formatCreatedAt="{
                show: true,
                class: 'text-center mt-1 text-caption',
              }"
            />
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  EventCardAvatar,
  EventIdentityTypes,
  EventPurposeType,
  EventActions,
  EventContent,
  EventIndicator,
} from "@/components/EventsTools";

export default {
  components: {
    EventPurposeType,
    EventCardAvatar,
    EventIdentityTypes,
    EventActions,
    EventContent,
  },

  mixins: [EventIndicator],

  props: {
    lastVisit: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    unblock(data) {
      this.$emit("unblock", data);
    },
  },
};
</script>
