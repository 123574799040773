<template>
  <v-card class="overflow-visible" elevation="2">
    <v-tabs show-arrows color="primary">
      <v-tab v-for="(tab, i) in tabByPermission" :key="i" :to="tab.to">
        <v-icon start>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-window class="pa-4">
      <router-view />
    </v-window>
  </v-card>
</template>

<script>
import permissions from "@/mixins/permissions";

export default {
  name: "SettingsPage",

  mixins: [permissions],

  watch: {
    $route: {
      handler() {
        if (this.tabByPermission.length) {
          this.redirect();
        }
      },
      immediate: true,
    },
  },

  methods: {
    redirect() {
      if (this.$route.name === this.$const.ROUTES.SETTINGS) {
        this.$router.replace(this.tabByPermission[0].to);
      }
    },
  },

  computed: {
    tabByPermission() {
      return this.tabs.filter((tab) => this.can(tab.permission));
    },
    tabs() {
      return [
        {
          name: this.$t("setting.GeneralSettings"),
          to: { name: this.$const.ROUTES.GENERAL_SETTINGS },
          icon: "mdi-tune-vertical-variant",
          permission: this.$const.RULES.SETTINGS_VIEW,
        },
        {
          name: this.$t("setting.import"),
          to: { name: this.$const.ROUTES.IMPORT },
          icon: "mdi-swap-vertical",
          permission: this.$const.RULES.SETTINGS_VIEW,
        },
        {
          name: this.$t("setting.notifications"),
          to: { name: this.$const.ROUTES.NOTIFICATIONS },
          icon: "mdi-bell-cog-outline",
          permission: this.$const.RULES.SETTINGS_VIEW,
        },
        {
          name: this.$t("setting.log"),
          to: { name: this.$const.ROUTES.LOG },
          icon: "mdi-post-outline",
          permission: this.$const.RULES.SETTINGS_VIEW,
        },
        {
          name: this.$t("menu.object-type"),
          to: { name: this.$const.ROUTES.OBJECT_TYPE },
          icon: "mdi-folder-account",
          permission: this.$const.RULES.SETTINGS_VIEW,
        },
        {
          name: this.$t("menu.profile-type"),
          to: { name: this.$const.ROUTES.PROFILE_TYPE },
          icon: "mdi-clipboard-account-outline",
          permission: this.$const.RULES.SETTINGS_VIEW,
        },
      ];
    },
  },
};
</script>
