export default {
  setPageData({ commit }, data = {}) {
    localStorage.setItem("departmentsStatePageData", JSON.stringify(data));
    commit("setPageData", data);
  },

  setFilter({ commit }, data = {}) {
    localStorage.setItem("departmentsStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },

  setPagination({ commit }, data = {}) {
    localStorage.setItem("departmentsStatePagination", JSON.stringify(data));
    commit("setPagination", data);
  },
};
