<template>
  <v-dialog v-model="dialog" :max-width="width">
    <v-card :title="title">
      <v-card-text>
        <v-row v-if="message">
          <v-col>
            {{ message }}
          </v-col>
        </v-row>
        <v-row v-if="useTextField">
          <v-col>
            <v-textarea
              v-model="textField"
              variant="outlined"
              density="compact"
              color="primary"
              hide-details
              no-resize
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn v-if="btnDisagree" color="red-darken-1" @click="disagree">
          {{ btnDisagree }}
        </v-btn>

        <v-btn v-if="btnAgree" color="green-darken-1" @click="agree">
          {{ btnAgree }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PromptDialog",

  data() {
    return {
      dialog: false,
      title: "",
      message: "",
      width: 300,
      btnDisagree: "",
      btnAgree: "",
      useTextField: false,
      textField: "",
      labelTextField: "",
      agreeCallback: null,
      disagreeCallback: null,
    };
  },

  methods: {
    open({
      title,
      message,
      width,
      btnDisagree,
      btnAgree,
      useTextField,
      textField,
      labelTextField,
      agree = () => {},
      disagree = () => {},
    }) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.width = width ? width : 300;
      this.btnDisagree = btnDisagree ? btnDisagree : this.$t("button.cancel");
      this.btnAgree = btnAgree ? btnAgree : this.$t("button.accept");
      this.useTextField = useTextField;
      this.textField = textField;
      this.labelTextField = labelTextField;
      this.agreeCallback = agree;
      this.disagreeCallback = disagree;
    },
    disagree() {
      this.dialog = false;
      this.disagreeCallback();
    },
    agree() {
      this.dialog = false;
      this.agreeCallback({ textField: this.textField });
    },
  },
};
</script>
