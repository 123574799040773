<template>
  <v-row justify="center">
    <v-dialog
      :modelValue="dialog"
      persistent
      max-width="400px"
      @click:outside="close"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("auth.enterPassword") }}</span>
        </v-card-title>
        <v-card-text class="px-4">
          <v-container>
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  :label="$t('auth.password') + '*'"
                  v-model="password"
                  color="primary"
                  density="comfortable"
                  variant="outlined"
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ $t("button.cancel") }}</v-btn>
          <v-btn
            v-if="password"
            :loading="loading"
            :disabled="loading"
            color="primary"
            variant="text"
            @click="confirm"
            >{{ $t("button.apply") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      dialog: true,
      password: "",
    };
  },
  props: ["modelValue", "loading"],
  methods: {
    close() {
      this.$emit("update:modelValue", "");
      this.$emit("toggle", false);
    },
    confirm() {
      this.$emit("update:modelValue", this.password);
      this.$emit("toggle", true);
    },
  },
  mounted() {
    this.dialog = true;
  },
};
</script>
