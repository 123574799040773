import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPendingGetAccessLinks: false,
  isSuccessGetAccessLinks: false,
  isFailureGetAccessLinks: false,
  errorMessageGetAccessLinks: "",

  accessLinks: [],
};

export default {
  state,
  mutations,
  actions,
};
