import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPendingAddProfileType: false,
  isSuccessAddProfileType: false,
  isFailureAddProfileType: false,
  errorMessageAddProfileType: "",

  isPendingGetProfileType: false,
  isSuccessGetProfileType: false,
  isFailureGetProfileType: false,
  errorMessageGetProfileType: "",

  profileType: {},

  isPendingUpdateProfileType: false,
  isSuccessUpdateProfileType: false,
  isFailureUpdateProfileType: false,
  errorMessageUpdateProfileType: "",
};

export default {
  state,
  mutations,
  actions,
};
