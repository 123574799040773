import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  get({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("requests", { params })
      .then((response) => {
        const {
          data,
          meta: { pagination },
        } = response.data;

        commit("set", { data, pagination });
        commit("setLoading", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },
};
