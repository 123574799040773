<template>
  <v-card
    :color="
      'enable' in channelState && !channelState.enable ? '#F3F2FF' : '#DCD9FD'
    "
    elevation="0"
  >
    <v-card-title class="text-h5">
      <v-row align-content="center">
        <v-col>{{ title }}</v-col>
        <v-col v-if="'enable' in channelState" cols="auto">
          <v-switch
            v-model="channelState.enable"
            hide-details
            class="mt-0"
            density="compact"
            color="primary"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col v-if="'name' in channelState && isShowName">
          <v-text-field
            v-model="channelState.name"
            :label="$t('devices.nameChannel')"
            hide-details
            variant="outlined"
            density="comfortable"
            color="primary"
            required
            :disabled="isDisabledForm"
          />
        </v-col>
        <v-col>
          <v-select
            v-model="channelState.purpose"
            :items="modeItems"
            item-title="name"
            item-value="val"
            :label="$t('devices.purpose')"
            hide-details
            variant="outlined"
            density="comfortable"
            color="primary"
            :disabled="isDisabledForm"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            ref="zones"
            v-model="channelState.zones_id"
            :items="zonesTo"
            item-title="name"
            item-value="id"
            :label="`${$t('devices.zoneTo')} *`"
            :disabled="isDisabledForm"
            hide-details="auto"
            variant="outlined"
            density="comfortable"
            color="primary"
          />
        </v-col>
        <v-col class="pa-0 d-flex justify-center align-center" cols="auto">
          <v-btn
            variant="text"
            :ripple="false"
            density="comfortable"
            icon="mdi-swap-horizontal"
            color="primary"
            @click.stop="swapZones"
        /></v-col>
        <v-col>
          <v-select
            ref="sources"
            v-model="channelState.source_id"
            :items="zonesFrom"
            item-title="name"
            item-value="id"
            :label="`${$t('devices.zoneFrom')} *`"
            :disabled="isDisabledForm"
            hide-details="auto"
            variant="outlined"
            density="comfortable"
            color="primary"
          />
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col v-if="'is_fixed_pass' in channelState">
          <v-switch
            v-model="channelState.is_fixed_pass"
            :label="$t('devices.fixedPass')"
            :disabled="isDisabledForm"
            hide-details
            class="mt-0"
          />
        </v-col>

        <v-col v-if="'relay_id' in channelState && isShowRelays">
          <v-select
            v-model="channelState.relay_id"
            :items="relaysOptions"
            :label="$t('devices.relay')"
            outlined
            hide-details
            :disabled="isDisabledForm"
          />
        </v-col>
      </v-row> -->
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from "lodash.debounce";

const PURPOSE_PASS = "entrance";
const PURPOSE_DUPLEX = "duplex";
const PURPOSE_BASICLOCK = "basiclock";

export default {
  name: "Channel",

  props: {
    channel: {
      type: Object,
      required: true,
    },

    relays: {
      type: Array,
      default: () => [],
    },

    isShowRelays: {
      type: Boolean,
      default: true,
    },

    isShowName: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },
    isController: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    channelState: {
      handler: debounce(function () {
        this.$emit("changeState", this.channelState);
      }, 300),
      deep: true,
    },
  },

  data() {
    return {
      channelState: this.channel,
      source_id: this.channel.source_id,
      zones: [],
      purposeItems: [
        { val: PURPOSE_PASS, name: this.$t("button.pass") },
        { val: PURPOSE_DUPLEX, name: this.$t("button.duplex") },
        { val: PURPOSE_BASICLOCK, name: this.$t("button.basiclock") },
      ],
    };
  },

  methods: {
    swapZones() {
      const oldSource = this.channelState.source_id;
      this.channelState.source_id = this.channelState.zones_id;
      this.channelState.zones_id = oldSource;
    },
    getZones() {
      this.axios("zones").then((response) => {
        this.zones = [
          { id: null, name: this.$t("devices.zoneEmpty") },
          ...response.data.data,
        ];
      });
    },
  },

  computed: {
    zonesTo() {
      return this.zones.filter(
        (zone) => zone.id !== this.channelState.source_id
      );
    },
    modeItems() {
      return !this.isController
        ? this.purposeItems.filter((item) => item.val != PURPOSE_BASICLOCK)
        : this.purposeItems;
    },
    zonesFrom() {
      return this.zones.filter(
        (zone) => zone.id !== this.channelState.zones_id
      );
    },

    isDisabledForm() {
      return "enable" in this.channelState ? !this.channelState.enable : false;
    },

    relaysOptions() {
      return this.relays.map((relay) => ({
        value: relay.id,
        text: `${this.$t("devices.relay")} ${relay.id}`,
      }));
    },
  },

  created() {
    this.getZones();
  },
};
</script>
