<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="2" :loading="isPendingStore">
      <template #progress>
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <PasswordForm @update:modelValue="pass = $event" />
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="$auth.user().password_upd"
          :loading="isPendingStore"
          @click="goBack"
        >
          <v-icon start>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" :loading="isPendingStore" @click="save">
          <v-icon start>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import PasswordForm from "./PasswordForm";

export default {
  name: "Form",

  components: { PasswordForm },

  props: {
    isPendingStore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: true,
      pass: "",
    };
  },

  methods: {
    async save() {
      let valid = false;
      await this.$refs.form.validate().then((reslut) => (valid = reslut.valid));
      if (!valid) return;

      this.$emit("store", {
        password: this.pass,
      });
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
