<template>
  <div
    :class="[
      'device-monitoring__item',
      { 'device-monitoring__item--success': isSuccess },
      { 'device-monitoring__item--cursor-pointer': isEdit },
    ]"
    @click="goToDevice"
  >
    <strong>{{ data.device_type }}</strong
    >:
    {{ data.name }}
  </div>
</template>

<script>
import permissions from "@/mixins/permissions";

export default {
  mixins: [permissions],

  props: {
    data: {
      type: Object,
      required: true,
    },
    successList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    isEdit() {
      return this.can(this.$const.RULES.DEVICE_EDIT);
    },
    isSuccess() {
      let success = false;
      this.successList.forEach((item) => {
        if (item.id == this.data.id) {
          success = item.status;
        }
      });
      return success;
    },
  },

  methods: {
    goToDevice() {
      if (this.isEdit) {
        this.$router.push({
          name: this.$const.ROUTES.EDIT_DEVICE,
          params: {
            id: this.data.id,
          },
        });
      }
    },
  },
};
</script>
