<template>
  <v-text-field
    v-model="fieldComputed"
    ref="email"
    density="comfortable"
    color="primary"
    variant="outlined"
    :rules="emailRules"
    :label="field.label"
    hide-details="auto"
  >
    <template #message="{ message }">
      <span v-html="message" />
    </template>
  </v-text-field>
</template>
<script>
import { isEmail } from "validator";

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    field: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      emailRules: [
        (v) => {
          if (this.field.required && !v) {
            return this.$t("requests.oneOfContactIsRequired");
          }

          if (v && !isEmail(v)) {
            return this.$t("user.email_char_valid");
          }

          return true;
        },
      ],
    };
  },

  computed: {
    fieldComputed: {
      get() {
        const curValue = this.modelValue.find(
          (value) => value.name == this.field.name
        );

        return curValue?.value;
      },
      set(newValue) {
        let isEdit = false;

        const curValues = this.modelValue.map((value) => {
          const curValue = { ...value };

          if (value.name == this.field.name) {
            curValue.value = newValue;
            isEdit = true;
          }

          return curValue;
        });

        if (!isEdit) {
          curValues.push({
            type: this.field.type,
            name: this.field.name,
            value: newValue,
          });
        }

        this.$emit("update:modelValue", curValues);
      },
    },
  },
};
</script>
