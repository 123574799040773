export default {
  data() {
    return {
      isOpenDepartmentDialog: false,
      isOpenAccessLevelsDialog: false,
    };
  },

  methods: {
    //Удаление
    onRemove(data) {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),
        agree: () => this.delete(data),
      });
    },
    async delete({ ids, filter }) {
      if (ids?.length) {
        const params = { ids };

        await this.deleteObjectAction(params);
      } else {
        const params = { ...filter };

        await this.deleteObjectAction(params);
      }

      this.getObjects();
      this.selected = [];
    },
    async onRestore({ ids, filter }) {
      if (ids && ids.length) {
        await this.restoreObjectAction({ ids });
      } else {
        await this.restoreObjectAction({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },

    //Блокировка
    async onBlock({ ids, filter }) {
      if (ids?.length) {
        await this.blockObjectAction({ ids });
      } else {
        await this.blockObjectAction({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },
    async onUnblock({ ids, filter }) {
      if (ids?.length) {
        await this.unblockObjectAction({ ids });
      } else {
        await this.unblockObjectAction({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },

    //Отслеживание
    async onBlack({ ids, filter }) {
      if (ids?.length) {
        await this.blackObjectAction({ ids });
      } else {
        await this.blackObjectAction({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },
    async onUnblack({ ids, filter }) {
      if (ids?.length) {
        await this.unblackObjectAction({ ids });
      } else {
        await this.unblackObjectAction({ ...filter });
      }

      this.getObjects();
      this.selected = [];
    },

    //Репорты
    async onReport({ ids, filter }) {
      if (ids?.length) {
        await this.reportObjectAction({ ids });
      } else {
        await this.reportObjectAction({ ...filter });
      }
    },

    onMultiSetDepartment() {
      this.getObjects();
      this.selected = [];
      this.isOpenDepartmentDialog = false;
    },

    onMultiSetAccessLevel() {
      this.getObjects();
      this.selected = [];
      this.isOpenAccessLevelsDialog = false;
    },
  },

  computed: {
    isDeleteObject() {
      return this.can(this.$const.RULES.OBJECT_DELETE);
    },

    isRestoreObject() {
      return this.can(this.$const.RULES.OBJECT_RESTORE);
    },
  },
};
