<template>
  <v-sheet v-if="isPending" class="bg-grey-lighten-4 rounded-lg pa-2 w-100">
    <v-row class="ma-n2">
      <v-col v-if="showHeader" class="pa-2" cols="12">
        <v-skeleton-loader
          class="overflow-hidden rounded-lg"
          max-height="50"
          type="image"
        />
      </v-col>
      <v-col class="pa-2" cols="12" md="6" lg="4">
        <v-skeleton-loader
          class="overflow-hidden rounded-lg"
          max-height="50"
          type="image"
        />
      </v-col>
      <v-col class="pa-2" cols="12" md="6" lg="4">
        <v-skeleton-loader
          class="overflow-hidden rounded-lg"
          max-height="50"
          type="image"
        />
      </v-col>
      <v-col class="pa-2" cols="12" md="6" lg="4">
        <v-skeleton-loader
          class="overflow-hidden rounded-lg"
          max-height="50"
          type="image"
        />
      </v-col>
    </v-row>
  </v-sheet>
  <div v-else class="h-100">
    <!-- Visible -->
    <v-sheet class="bg-grey-lighten-4 rounded-lg pa-2 w-100">
      <v-row v-if="showHeader" class="ma-n2">
        <v-col class="pa-2" cols="12">
          <v-card
            style="z-index: unset"
            class="elevation-0 rounded-lg overflow-visible"
          >
            <v-card-title>
              <v-row>
                <v-col cols="auto"> {{ $t("filter.filter") }} </v-col>
                <v-spacer />
                <v-col v-if="!!hidden.length" cols="auto">
                  <v-btn
                    v-bind="props"
                    :ripple="false"
                    density="comfortable"
                    :icon="isActive ? 'mdi-menu-up' : 'mdi-menu-down'"
                    variant="text"
                    @click="toggleExpand"
                  />
                </v-col>
                <v-col v-if="showOptions || allActiveCount" cols="auto">
                  <v-btn
                    v-if="allActiveCount"
                    :ripple="false"
                    color="red"
                    variant="text"
                    density="comfortable"
                    icon="mdi-filter-remove-outline"
                    @click="clearModelValue"
                  />
                  <v-menu v-if="showOptions">
                    <template #activator="{ isActive, props }">
                      <v-btn
                        v-bind="props"
                        :ripple="false"
                        :class="{ 'text-primary --active': isActive }"
                        class="settings-button"
                        density="comfortable"
                        icon="mdi-cog-outline"
                        variant="plain"
                      />
                    </template>
                    <v-list density="compact">
                      <slot name="optionsList" />
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="ma-n2">
        <v-fade-transition group>
          <v-col
            v-for="item in visible"
            :key="item.name"
            class="pa-2"
            cols="12"
            md="6"
            lg="4"
          >
            <v-card
              style="z-index: unset"
              class="elevation-0 rounded-lg overflow-visible h-100"
            >
              <v-card-title class="text-subtitle-1">
                {{ item.name }}
              </v-card-title>
              <v-card-text>
                <TreeFilterItem
                  v-if="item.type == 'tree'"
                  :modelValue="modelValue[item.param]"
                  :item="item"
                  @update:modelValue="update"
                />
                <CheckboxesFilter
                  v-if="item.type == 'checkboxes'"
                  :modelValue="modelValue[item.param]"
                  :item="item"
                  @update:modelValue="update"
                />
                <SearchFilter
                  v-if="item.type == 'search'"
                  :modelValue="modelValue[item.param]"
                  :item="item"
                  @update:modelValue="update"
                />
                <SelectFilter
                  v-if="item.type == 'select'"
                  :modelValue="modelValue[item.param]"
                  :item="item"
                  @update:modelValue="update"
                />
                <SwitchFilter
                  v-if="item.type == 'switch'"
                  :modelValue="modelValue[item.param]"
                  :item="item"
                  @update:modelValue="update"
                />
                <DateRangeFilter
                  v-if="item.type == 'dateRange'"
                  :modelValue="modelValue[item.param]"
                  :item="item"
                  @update:modelValue="update"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-fade-transition>
        <template v-if="expand">
          <v-fade-transition group>
            <v-col
              v-for="item in hidden"
              :key="item.name"
              class="pa-2"
              cols="12"
              md="6"
              lg="4"
            >
              <v-card class="elevation-0 rounded-lg h-100">
                <v-card-title class="text-subtitle-1">
                  {{ item.name }}
                </v-card-title>
                <v-card-text>
                  <TreeFilterItem
                    v-if="item.type == 'tree'"
                    :modelValue="modelValue[item.param]"
                    :item="item"
                    @update:modelValue="update"
                  />
                  <CheckboxesFilter
                    v-if="item.type == 'checkboxes'"
                    :modelValue="modelValue[item.param]"
                    :item="item"
                    @update:modelValue="update"
                  />
                  <SearchFilter
                    v-if="item.type == 'search'"
                    :modelValue="modelValue[item.param]"
                    :item="item"
                    @update:modelValue="update"
                  />
                  <SelectFilter
                    v-if="item.type == 'select'"
                    :modelValue="modelValue[item.param]"
                    :item="item"
                    @update:modelValue="update"
                  />
                  <SwitchFilter
                    v-if="item.type == 'switch'"
                    :modelValue="modelValue[item.param]"
                    :item="item"
                    @update:modelValue="update"
                  />
                  <DateRangeFilter
                    v-if="item.type == 'dateRange'"
                    :modelValue="modelValue[item.param]"
                    :item="item"
                    @update:modelValue="update"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-fade-transition>
        </template>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import TreeFilterItem from "./filterItems/TreeFilter.vue";
import CheckboxesFilter from "./filterItems/CheckboxesFilter.vue";
import SearchFilter from "./filterItems/SearchFilter.vue";
import SelectFilter from "./filterItems/SelectFilter.vue";
import SwitchFilter from "./filterItems/SwitchFilter.vue";
import DateRangeFilter from "./filterItems/DateRangeFilter.vue";

export default {
  components: {
    TreeFilterItem,
    CheckboxesFilter,
    SearchFilter,
    SelectFilter,
    SwitchFilter,
    DateRangeFilter,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    hidden: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Array,
      default: () => [],
    },

    showHeader: {
      type: Boolean,
      default: true,
    },

    showOptions: {
      type: Boolean,
      default: true,
    },

    isPending: {
      type: Boolean,
      default: false,
    },

    allActiveCount: {
      type: Number,
      default: 0,
    },
    visibleActiveCount: {
      type: Number,
      default: 0,
    },
    hiddenActiveCount: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      expand: false,
    };
  },

  methods: {
    toggleExpand() {
      this.expand = !this.expand;
    },

    update(event) {
      this.$emit("update:modelValue", event);
    },

    clearModelValue() {
      this.$emit("clearModelValue");
    },
  },
};
</script>
