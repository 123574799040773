<template>
  <v-row>
    <v-col cols="4">
      <v-text-field
        v-model="surnameCapital"
        :label="`${$t('user.surname')}`"
        :rules="surnameRules"
        :disabled="disabled"
        density="comfortable"
        color="primary"
        variant="outlined"
        hide-details="auto"
      />
    </v-col>

    <v-col cols="4">
      <v-text-field
        ref="firstname"
        v-model="nameCapital"
        :label="`${$t('user.firstname')}*`"
        :rules="firstnameRules"
        :disabled="disabled"
        density="comfortable"
        color="primary"
        variant="outlined"
        hide-details="auto"
      />
    </v-col>

    <v-col cols="4">
      <v-text-field
        ref="lastname"
        v-model="patronymicCapital"
        :label="`${$t('user.lastname')}`"
        :rules="patronymicRules"
        :disabled="disabled"
        density="comfortable"
        color="primary"
        variant="outlined"
        hide-details="auto"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FullName",

  props: {
    surname: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    patronymic: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      surnameState: this.surname,
      nameState: this.name,
      patronymicState: this.patronymic,

      surnameRules: [(v) => v.length <= 40 || this.$t("user.nameSizeValidate")],
      firstnameRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => v.length <= 40 || this.$t("user.nameSizeValidate"),
      ],
      patronymicRules: [
        (v) => v.length <= 40 || this.$t("user.nameSizeValidate"),
      ],
    };
  },

  methods: {
    validateName(name) {
      const regex = /^[a-zA-Zа-яА-ЯёЁ]{1,}'?-?[a-zA-Zа-яА-ЯёЁ]{1,}$/;

      return regex.test(name);
    },
  },

  computed: {
    surnameCapital: {
      get: function () {
        return this.surname;
      },
      set: function (newSurname) {
        let surname = newSurname;
        if (surname.length < 1) {
          this.$emit("update:surname", "");
          return;
        }
        surname = surname.replace(/^./, surname[0].toUpperCase());
        this.$emit("update:surname", surname);
      },
    },

    nameCapital: {
      get: function () {
        return this.name;
      },
      set: function (newName) {
        let name = newName;
        if (name.length < 1) {
          this.$emit("update:name", "");
          return;
        }
        name = name.replace(/^./, name[0].toUpperCase());
        this.$emit("update:name", name);
      },
    },

    patronymicCapital: {
      get: function () {
        return this.patronymic;
      },
      set: function (newPatronymic) {
        let patronymic = newPatronymic;
        if (patronymic.length < 1) {
          this.$emit("update:patronymic", "");
          return;
        }
        patronymic = patronymic.replace(/^./, patronymic[0].toUpperCase());
        this.$emit("update:patronymic", patronymic);
      },
    },
  },
};
</script>
