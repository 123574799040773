<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="zones"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col cols="12" :lg="!pageData.isMini ? 9 : 12">
      <v-row v-if="isFailureGetZonesListState">
        <v-col>
          <Alert type="error">
            {{ errorMessageGetZonesListState }}
          </Alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :items-per-page="pagination.limit"
            :loading="isPendingGetZonesListState"
            :headers="headers"
            :items="zonesList"
            :row-props="rowProps"
            hide-default-footer
            disable-sort
          >
            <template #[`item.name`]="{ item }">
              <router-link
                v-if="can($const.RULES.ZONES_EDIT)"
                class="text-primary"
                :to="{ name: $const.ROUTES.EDIT_ZONE, params: { id: item.id } }"
                >{{ item.name }}</router-link
              >
              <template v-else>
                {{ item.name }}
              </template>
            </template>

            <template #[`item.parent_id`]="{ item }">
              <template v-if="item.parent_id">
                <router-link
                  v-if="can($const.RULES.ZONES_EDIT)"
                  class="text-primary"
                  :to="{
                    name: $const.ROUTES.EDIT_ZONE,
                    params: { id: item.parent_id },
                  }"
                >
                  {{ item.parent_name }}
                </router-link>
                <template v-else>
                  {{ item.parent_name }}
                </template>
              </template>
              <span v-else>
                {{ $t("zones.withoutParent") }}
              </span>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-tooltip v-if="can($const.RULES.ZONES_EDIT)" location="top">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="primary"
                    @click.stop="goToEdit(item.id)"
                  >
                    <v-icon size="x-small">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("zones.change") }}</span>
              </v-tooltip>

              <v-tooltip v-if="can($const.RULES.ZONE_DELETE)" location="top">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="red"
                    @click.stop="onRemove(item.id)"
                  >
                    <v-icon size="x-small">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("zones.delete") }}</span>
              </v-tooltip>
            </template>

            <template #bottom>
              <DataTableFooterV2
                v-model="pagination"
                @update:modelValue="onPagination"
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";

import permissions from "@/mixins/permissions";

export default {
  name: "ZonesList",

  mixins: [permissions],

  components: { Alert, Filter, DataTableFooterV2 },

  data() {
    return {
      zonesList: [],

      headers: [
        {
          title: "ID",
          value: "id",
        },
        {
          title: this.$t("zones.name"),
          value: "name",
        },
        {
          title: this.$t("zones.parent"),
          value: "parent_id",
        },
        {
          value: "actions",
          align: "end",
          width: 100,
        },
      ],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      getZonesListAction: "zones/getZonesList",

      remove: "zone/remove",

      setStatePageDataAction: "stateZones/setPageData",
      setStateFilterAction: "stateZones/setFilter",
      setStatePaginationAction: "stateZones/setPagination",
    }),

    getZonesList() {
      const curParams = {
        ...this.filter,
        ...this.pagination,
      };

      this.getZonesListAction(curParams).then((response) => {
        this.zonesList = this.zonesListState;

        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;
          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };
        }
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ZONE,
        params: { id },
      });
    },

    goToUser(item) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ZONE,
        params: {
          id: item.item.id,
        },
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(id);
          this.get();
        },
      });
    },

    rowProps(item) {
      const props = {};
      if (
        this.$vuetify.display.mobile &&
        item.item.id &&
        this.can(this.$const.RULES.ZONES_EDIT)
      ) {
        props.onClick = () => this.goToUser(item);
      }

      return props;
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния страницы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },
    //Запись состояния страницы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },
    setFilterState() {
      this.setStateFilterAction(this.filter);
    },
    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };
      this.setStatePaginationAction(this.pagination);
    },
    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getZonesList();
    },
    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },
  },

  computed: {
    ...mapState({
      isPendingGetZonesListState: (state) => state.zones.isPendingGetZonesList,
      isFailureGetZonesListState: (state) => state.zones.isFailureGetZonesList,
      errorMessageGetZonesListState: (state) =>
        state.zones.errorMessageGetZonesList,

      zonesListState: (state) => state.zones.zonesList,

      statePageData: (state) => state.stateZones.data,
      stateFilter: (state) => state.stateZones.filter,
      statePagination: (state) => state.stateZones.pagination,
    }),
  },

  created() {
    this.getState();
    this.getZonesList();
  },
};
</script>
