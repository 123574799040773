<template>
  <v-dialog v-model="openFilter" max-width="500" z-index="1500">
    <template #activator="{ props: activatorProps }">
      <v-badge
        :model-value="!!allActiveCount"
        :content="allActiveCount"
        color="red"
      >
        <v-btn
          v-bind="activatorProps"
          color="primary"
          :text="$t('users.filter')"
          variant="flat"
          prepend-icon="mdi-filter-variant"
        />
      </v-badge>
    </template>
    <template #default>
      <v-btn
        class="smart-filter__close-button"
        density="comfortable"
        icon="mdi-close"
        color="primary"
        @click="close"
      />
      <div class="smart-filter__mobile-default">
        <defaultFilter
          :modelValue="modelValue"
          :hidden="hidden"
          :visible="visible"
          :showOptions="false"
          :isPending="isPending"
          :showHeader="showHeader"
          :allActiveCount="allActiveCount"
          :visibleActiveCount="visibleActiveCount"
          :hiddenActiveCount="hiddenActiveCount"
          @clearModelValue="clearModelValue"
          @update:modelValue="update"
        />
      </div>
    </template>
  </v-dialog>
</template>
<script>
import defaultFilter from "../default";
export default {
  components: { defaultFilter },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    hidden: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Array,
      default: () => [],
    },

    showHeader: {
      type: Boolean,
      default: true,
    },

    isPending: {
      type: Boolean,
      default: false,
    },

    allActiveCount: {
      type: Number,
      default: 0,
    },
    visibleActiveCount: {
      type: Number,
      default: 0,
    },
    hiddenActiveCount: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      openFilter: false,
    };
  },

  methods: {
    close() {
      this.openFilter = false;
    },
    update(event) {
      this.$emit("update:modelValue", event);
    },

    clearModelValue() {
      this.$emit("clearModelValue");
    },
  },
};
</script>
