import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  getAccessLinks({ commit }, params) {
    commit("setLoadingGetAccessLinks", { type: "IS_PENDING" });

    return $axios("/accessmode/links", { params })
      .then((response) => {
        const { data } = response.data;

        commit("setAccessLinks", data);
        commit("setLoadingGetAccessLinks", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoadingGetAccessLinks", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },
};
