<template>
  <!-- <div v-if="isIcons">
    <div
      v-show="
        item.pass_type === 'alarm' &&
        item.event?.identity_types &&
        item.event.identity_types.includes('face')
      "
    >
      <v-tooltip :text="$t('users.guestPass')" max-width="250" location="top">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-account-clock"
            size="x-small"
            variant="outlined"
            color="error"
            density="comfortable"
            @click.stop="guestReg"
          />
        </template>
      </v-tooltip>
    </div>
  </div>
  <div v-else>
    <div v-if="item.Blocked" class="d-flex justify-center mb-2">
      <v-btn color="success" @click="guestUnblock">
        {{ $t("users.unblock") }}
      </v-btn>
    </div>
    <div
      v-if="
        !item.pass_type === 'alarm' &&
        item.event?.identity_types &&
        item.event.identity_types.includes('face')
      "
      class="d-flex justify-center mb-2"
    >
      <v-btn color="primary" @click="guestReg">
        {{ $t("users.guestPass") }}
      </v-btn>
    </div>
  </div> -->
  <div></div>
</template>
<script>
// import identityValue from "@/mixins/identityValue";
// import { mapActions } from "vuex";

// export default {
//   mixins: [identityValue],

//   props: {
//     item: {
//       type: Object,
//       default: () => {},
//     },
//     isIcons: {
//       type: Boolean,
//       default: true,
//     },
//   },

//   methods: {
//     ...mapActions({
//       unblock: "persons/unblock",
//     }),
//     //Регистрация пользователя
//     guestReg() {
//       const visitor = this.item;
//       const index = visitor.avatar.indexOf(",");
//       const photo = !visitor.isStaticAvatar
//         ? visitor.avatar.slice(index + 1)
//         : null;

//       const person = {
//         last_name: `${this.$t("users.guest")}-${this.$moment().format(
//           "DDMMYYHHmm"
//         )}`,
//         photo,
//         permits: [
//           {
//             isEdit: true,
//             is_active: true,
//             type: "guest",
//             onepass: true,
//             expire_time: this.$moment().add(24, "hour").toISOString(),
//             token: [
//               {
//                 type:
//                   this.getIdentityValueType(
//                     visitor.event?.identity_value || ""
//                   ) || null,
//                 number:
//                   this.getIdentityValueType(
//                     visitor.event?.identity_value || ""
//                   ) === "card"
//                     ? visitor.event?.identity_value
//                     : null,
//               },
//             ],
//           },
//         ],
//       };

//       this.$router.push({
//         name: this.$const.ROUTES.ADD_PERSON,
//         state: { person },
//       });
//     },

//     //Разблокировать пользователя
//     async guestUnblock() {
//       const visitor = this.item;
//       if (visitor && visitor.Blocked && visitor.person_id) {
//         await this.unblock({ ids: [parseInt(visitor.person_id)] });
//         this.$emit("unblock", visitor.person_id);
//       }
//     },
//   },
// };
//
</script>
