<template>
  <v-switch
    v-model="switchValue"
    :label="item.label"
    color="primary"
    density="compact"
    hide-details
  />
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    switchValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        const event = {};
        event[this.item.param] = newValue;
        this.$emit("update:modelValue", event);
      },
    },
  },
};
</script>
