<template>
  <v-row v-if="isFailureGetObjectHistoryState">
    <v-col>
      <Alert type="error">
        {{ errorMessageGetObjectHistoryState }}
      </Alert>
    </v-col>
  </v-row>

  <v-row>
    <v-col>
      <v-row v-if="history.profiles?.length">
        <v-col cols="12" md="4">
          <v-select
            v-model="selectedProfile"
            :items="history.profiles"
            :label="$t('object.profiles')"
            item-value="Type"
            item-title="Title"
            density="compact"
            color="primary"
            variant="outlined"
            clearable
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-if="isPendingGetObjectHistoryState"
            class="mx-auto"
            type="list-item@3"
          />

          <el-tree
            v-else
            :data="historyComputed"
            :props="{
              children: 'values',
              class: parentClass,
            }"
            :teleported="false"
            default-expand-all
          >
            <template #="{ data }">
              <v-row v-if="data.label">
                <v-col>
                  {{ data.label }}
                </v-col>
              </v-row>
              <template v-else>
                <v-row class="ma-0">
                  <v-col cols="12" md="4">
                    {{ data.created_name || "-" }}
                  </v-col>
                  <v-col cols="12" md="4">
                    {{ data.value }}
                  </v-col>
                  <v-col cols="12" md="4">
                    {{
                      data.created_at
                        ? $moment(data.created_at).format("DD.MM.YYYY HH:mm")
                        : ""
                    }}
                  </v-col>
                  <v-divider v-if="$vuetify.display.mdAndDown" />
                </v-row>
              </template>
            </template>
          </el-tree>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";

export default {
  components: {
    Alert,
  },

  data() {
    return {
      id: null,

      selectedProfile: undefined,

      history: [],
    };
  },

  methods: {
    ...mapActions({
      resetGetObjectHistory: "object/resetGetObjectHistory",
      getObjectHistoryAction: "object/getObjectHistory",
    }),

    getId() {
      this.id = parseInt(this.$route.params.id);
    },

    getObjectHistory() {
      this.getObjectHistoryAction({ id: this.id, params: {} }).then(() => {
        this.history = this.objectHistoryState;
      });
    },

    parentClass(data) {
      if (data.label) {
        return "parent-node rounded-lg overflow-hidden mb-2";
      }

      return "";
    },
  },

  computed: {
    ...mapState({
      isPendingGetObjectHistoryState: (state) =>
        state.object.isPendingGetObjectHistory,
      isFailureGetObjectHistoryState: (state) =>
        state.object.isFailureGetObjectHistory,
      errorMessageGetObjectHistoryState: (state) =>
        state.object.errorMessageGetObjectHistory,

      objectHistoryState: (state) => state.object.objectHistory,
    }),

    historyComputed() {
      if (!this.selectedProfile) {
        return this.history.history_values;
      }

      const curHistory = this.history.profiles.find(
        (profile) => profile.Type == this.selectedProfile
      );

      return curHistory.history_values;
    },
  },

  created() {
    this.getId();
    this.getObjectHistory();
  },

  unmounted() {
    this.resetGetObjectHistory();
  },
};
</script>

<style>
.el-tree-node__content {
  height: auto !important;
  min-height: 46px;
}
.parent-node > .el-tree-node__content {
  background-color: rgb(var(--v-theme-primary)) !important;
  color: white;
}
.parent-node > .el-tree-node__content .el-tree-node__expand-icon {
  color: white;
}
</style>
