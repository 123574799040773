<template>
  <v-row v-if="isFailureGetObjectsListStateState">
    <v-col>
      <Alert type="error">
        {{ errorMessageGetObjectsListState }}
      </Alert>
    </v-col>
  </v-row>

  <v-form ref="form">
    <v-card elevation="2" :loading="isPendingStore">
      <template v-slot:progress>
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <v-row>
          <v-col>
            <slot name="prepend" />
          </v-col>
        </v-row>

        <template v-if="isShowForm">
          <v-row>
            <v-col v-if="!isMulti" cols="12" md="6">
              <v-row no-gutters>
                <v-col>
                  <v-autocomplete
                    v-model="objectId"
                    :items="objects"
                    v-model:search="searchObjects"
                    :label="`${$t('object.objects')} *`"
                    :disabled="!isSaveRequest"
                    item-title="name"
                    item-value="id"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    clearable
                    :error-messages="objectIdErrors"
                    @update:search="onsearchObjects"
                    @update:modelValue="
                      (newValue) => {
                        v$.objectId.$touch();
                      }
                    "
                  >
                    <!-- <template #append-item>
                      <v-sheet v-intersect="endIntersect" />
                    </template> -->
                  </v-autocomplete>
                </v-col>
                <v-col cols="auto" class="pt-3 pl-3">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        variant="text"
                        density="compact"
                        icon
                        color="primary"
                        :disabled="!isSaveRequest"
                        @click="goToAddPerson"
                      >
                        <v-icon>mdi-account-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("users.addNewUser") }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-text-field
                v-model="description"
                :label="$t('requests.description')"
                :disabled="!isSaveRequest"
                variant="outlined"
                density="comfortable"
                color="primary"
              />
            </v-col>
          </v-row>

          <v-divider class="my-5" />

          <PermitList
            :data="permits"
            :readonly="!isSaveRequest"
            :error-messages="permitsErrors"
            :isShowTokens="isShowPermitTokens"
            @updatePermits="onChangePermits"
          />
        </template>

        <v-row>
          <v-col>
            <slot name="append" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import debounce from "lodash.debounce";

import validator from "./validator";

import PermitList from "@/components/Permits";
import Alert from "@/components/Alert";

export default {
  name: "RequestForm",

  emits: ["store"],

  mixins: [validator],

  components: { PermitList, Alert },

  props: {
    data: {
      type: Object,
      default: null,
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isMulti: {
      type: Boolean,
      default: false,
    },
    isShowForm: {
      type: Boolean,
      default: true,
    },
    isShowPermitTokens: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    let objectId = "";
    const description = this.data?.description || "";
    const permits = this.data?.person?.permits || [];

    // ! костыль валидации персоны для множественных заявок
    if (this.isMulti) {
      objectId = "0";
    } else if (this.data?.object?.id) {
      objectId = this.data.person.id;
    }

    return {
      isWebCamShow: false,
      isCameraShow: false,

      objectId,
      description,
      permits,

      objects: [],

      searchObjects: null,

      virtualPagination: {
        page: 1,
        limit: 100,
      },
    };
  },

  methods: {
    ...mapActions({
      getObjectsAction: "object/getObjectsList",
    }),

    getObjects(data = {}, add = false) {
      if (!add) {
        this.virtualPagination = {
          page: 1,
        };
      }
      const curPrams = {
        ...this.virtualPagination,
        ...data,
      };

      return this.getObjectsAction(curPrams).then((response) => {
        if (!add) {
          this.objects = structuredClone(this.objectsGetter);
        } else {
          this.objects.push(...structuredClone(this.objectsGetter));

          if (response?.data?.meta?.pagination) {
            const pagination = response.data.meta.pagination;

            this.virtualPagination = {
              ...this.virtualPagination,
              page: pagination.current_page,
              limit: pagination.per_page,
              total: pagination.total,
              total_items: pagination.total_items,
            };
          }
        }
      });
    },

    onsearchObjects: debounce(function (name) {
      //console.log(`Значение: ${name}`);
      const params = {};

      if (name) {
        params.search = `name:${name.trim()}`;
      }

      this.getObjects(params).then(() => this.endIntersect());
    }, 700),

    endIntersect(show) {
      if (
        this.virtualPagination.page === this.virtualPagination.total &&
        !this.isPendingGetObjectsListState
      )
        return;

      if (show) {
        this.getObjects(
          {
            lazy: true,
            search: this.searchObjects,
            page: this.virtualPagination.page + 1,
          },
          true
        );
      }
    },

    onChangePermits(permits) {
      this.permits = permits;
    },

    goToAddPerson() {
      const router = this.$router.resolve({
        name: this.$const.ROUTES.ADD_OBJECT,
      });
      window.open(router.href, "_blank");
    },

    save() {
      if (!this.validateForm()) return;

      this.store();
    },

    store() {
      const data = {
        description: this.description,
        permits: this.permits,
      };

      if (this.objectId && !this.isMulti) {
        data.objects_id = this.objectId;
      }

      this.$emit("store", data);
    },
  },

  computed: {
    ...mapState({
      isPendingGetObjectsListState: (state) =>
        state.object.isPendingGetObjectsList,
      isFailureGetObjectsListStateState: (state) =>
        state.object.isFailureGetObjectsList,
      errorMessageGetObjectsListState: (state) =>
        state.object.errorMessageGetObjectsList,
    }),

    ...mapGetters({
      objectsGetter: "object/getObjectsToShow",
    }),

    isSaveRequest() {
      return !this.isEdit || this.data.status === 0;
    },
  },

  created() {
    this.getObjects({ search: `id:${this.objectId}` });
  },
};
</script>
