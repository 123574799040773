<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="departments"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col cols="12" :lg="!pageData.isMini ? 9 : 12">
      <v-row v-if="isFailureGetDepartmentsTreeState">
        <v-col>
          <Alert type="error">
            {{ errorMessageGetDepartmentsTreeState }}
          </Alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-if="isPendingGetDepartmentsTreeState"
            class="mx-auto"
            type="list-item@3"
          />
          <el-tree
            v-else
            ref="departmentTree"
            :data="departmentsTree"
            :props="{ label: 'name' }"
            :expand-on-click-node="false"
            :filter-node-method="searchTreeFilter"
          >
            <template #default="{ data }">
              <v-tooltip
                v-if="can($const.RULES.DEPARTMENT_DELETE)"
                location="top"
              >
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="red"
                    @click="onRemove(data.id)"
                  >
                    <v-icon size="x-small">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("departments.delete") }}</span>
              </v-tooltip>

              <span class="el-tree-node__label">
                <router-link
                  v-if="can($const.RULES.DEPARTMENT_EDIT)"
                  class="text-primary"
                  :to="{
                    name: $const.ROUTES.EDIT_DEPARTMENT,
                    params: { id: data.id },
                  }"
                  >{{ data.name }}</router-link
                >
                <template v-else>
                  {{ data.name }}
                </template>
              </span>
            </template>
          </el-tree>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import Filter from "@/components/Filter";

import permissions from "@/mixins/permissions";

export default {
  name: "DepartmentsTree",

  mixins: [permissions],

  components: { Alert, Filter },

  data() {
    return {
      departmentsTree: [],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      resetGetDepartmentsTreeAction: "departments/resetGetDepartmentsTree",
      getDepartmentsTreeAction: "departments/getDepartmentsTree",

      remove: "department/remove",

      setStatePageDataAction: "stateDepartments/setPageData",
      setStateFilterAction: "stateDepartments/setFilter",
      setStatePaginationAction: "stateDepartments/setPagination",
    }),

    getDepartmentsTree() {
      const params = {
        ...this.filter,
      };

      this.getDepartmentsTreeAction(params).then(() => {
        this.departmentsTree = this.departmentsTreeState;
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_DEPARTMENT,
        params: { id },
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(id);
          this.get();
        },
      });
    },

    searchTreeFilter(value, data) {
      if (!value) return true;

      return data.name.includes(value);
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния таблицы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },

    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };

      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getDepartmentsTree();
    },
  },

  computed: {
    ...mapState({
      isPendingGetDepartmentsTreeState: (state) =>
        state.departments.isPendingGetDepartmentsTree,
      isFailureGetDepartmentsTreeState: (state) =>
        state.departments.isFailureGetDepartmentsTree,
      errorMessageGetDepartmentsTreeState: (state) =>
        state.departments.errorMessageGetDepartmentsTree,

      departmentsTreeState: (state) => state.departments.departmentsTree,

      statePageData: (state) => state.stateDepartments.data,
      stateFilter: (state) => state.stateDepartments.filter,
      statePagination: (state) => state.stateDepartments.pagination,
    }),
  },

  created() {
    this.getState();
    this.getDepartmentsTree();
  },

  unmounted() {
    this.resetGetDepartmentsTreeAction();
  },
};
</script>
