import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  //Старое хранилище api
  getList({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("departments", { params })
      .then((response) => {
        const data = response.data;

        commit("setList", {
          data: data.data,
          pagination: data.meta?.pagination || {
            current_page: 1,
            per_page: 30,
            total: 0,
          },
        });
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  getTree({ commit }) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("departments/tree")
      .then((response) => {
        const { data } = response.data;

        commit("setTree", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  //Новое хранилище api
  resetGetDepartmentsList({ commit }) {
    commit("setLoadingGetDepartmentsList", { type: "" });
    commit("setDepartmentsList", []);
  },

  getDepartmentsList({ commit }, params) {
    commit("setLoadingGetDepartmentsList", { type: "IS_PENDING" });

    return $axios("departments", { params })
      .then((response) => {
        const data = response.data;

        commit("setDepartmentsList", data.data);
        commit("setLoadingGetDepartmentsList", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetDepartmentsList", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },

  resetGetDepartmentsTree({ commit }) {
    commit("setLoadingGetDepartmentsTree", { type: "" });
    commit("setDepartmentsTree", []);
  },

  getDepartmentsTree({ commit }, params) {
    commit("setLoadingGetDepartmentsTree", { type: "IS_PENDING" });

    return $axios("departments/tree", { params })
      .then((response) => {
        const { data } = response.data;

        commit("setDepartmentsTree", data);
        commit("setLoadingGetDepartmentsTree", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetDepartmentsTree", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));

        throw error;
      });
  },
};
