<template>
  <span v-if="item.eventType !== 'deviceAlarm'">
    <div v-show="item.pass_type === 'before'">
      <v-tooltip :text="$t('users.recognized')" location="top">
        <template #activator="{ props }">
          <v-icon v-bind="props" size="small" color="success">
            mdi-check
          </v-icon>
        </template>
      </v-tooltip>
    </div>

    <div v-show="item.pass_type === 'pass'">
      <v-tooltip :text="$t('users.recognizedAndPass')" location="top">
        <template #activator="{ props }">
          <v-icon v-bind="props" size="small" color="success">
            mdi-check-all
          </v-icon>
        </template>
      </v-tooltip>
    </div>

    <div v-show="item.pass_type === 'alarm'">
      <v-tooltip max-width="250" location="top">
        <template #activator="{ props }">
          <v-icon v-bind="props" size="small" color="error">
            mdi-account-remove-outline
          </v-icon>
        </template>
        <span>
          {{ $t("users.notRecognized") }}<br />
          <template v-if="item.event?.message">
            {{ item.event.message }}
          </template>
        </span>
      </v-tooltip>
    </div>

    <div v-show="item.pass_type === 'error'">
      <v-tooltip max-width="250" location="top">
        <template #activator="{ props }">
          <v-icon v-bind="props" size="small" color="error">
            mdi-alert-circle-outline
          </v-icon>
        </template>

        {{ $t("users.error") }}
      </v-tooltip>
    </div>
  </span>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
