import { moment } from "@/plugins/vue-moment";

export default {
  PERSONS: {
    departments: [],
    accessLevels: [],
    search: "",
    mode: "",
    permit: "",
  },

  EVENTS: {
    identityTypes: [],
    type: [],
    search: "",
    startDate: "",
    endDate: "",
  },

  PASSES: {
    identityTypes: [],
    userType: [],
    subdivisions: [],
    departments: [],
    isOnObject: false,
    search: "",
    startDate: moment().startOf("month").toISOString(),
    endDate: moment().endOf("month").toISOString(),
  },

  PERMIT_REQUESTS: {
    excludedStatus: [2],
    userType: null,
    onlyActive: false,
    onePass: null,
    search: "",
  },
};
