import actions from "./actions";
import mutations from "./mutations";

const limitIndoors = window.localStorage.getItem("limitIndoors") || 10;
const limitEventLogs = window.localStorage.getItem("limitEventLogs") || 10;

const state = {
  isPendingVisits: false,
  isSuccessVisits: false,
  isFailureVisits: false,
  errorMessageVisits: "",
  visits: [],

  isPendingIndoors: false,
  isSuccessIndoors: false,
  isFailureIndoors: false,
  errorMessageIndoors: "",
  indoors: [],
  limitIndoors: parseInt(limitIndoors),
  indoorsPagination: {
    current_page: 1,
    per_page: 30,
    total: 0,
    total_items: 0,
  },

  isPendingEventLogs: false,
  isSuccessEventLogs: false,
  isFailureEventLogs: false,
  errorMessageEventLogs: "",
  eventLogs: [],
  limitEventLogs: parseInt(limitEventLogs),
  eventLogsPagination: {
    current_page: 1,
    per_page: 30,
    total: 0,
    total_items: 0,
  },

  isPendingCloseVisits: false,
  isSuccessCloseVisits: false,
  isFailureCloseVisits: false,
  errorMessageCloseVisits: "",
};

export default {
  state,
  mutations,
  actions,
};
