<template>
  <div>
    <v-row>
      <v-col
        v-for="(channel, index) of channels"
        :key="channel.channel"
        cols="12"
      >
        <Channel
          :title="$t('devices.accessPoint')"
          :channel="channel"
          @changeState="updateChannels($event, index)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card color="#DCD9FD" elevation="0">
          <v-card-title class="text-h5">
            <v-row align-content="center">
              <v-col>{{ $t("devices.system_mode") }}</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="systemmode"
                      :items="systemmodes"
                      item-title="name"
                      item-value="val"
                      :label="$t('devices.system_mode')"
                      variant="outlined"
                      density="comfortable"
                      color="primary"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :cols="!controlModeSelect(controlMode) ? 12 : 6">
                    <v-select
                      v-model="controlMode"
                      :items="controls"
                      item-title="name"
                      item-value="val"
                      :label="$t('devices.controlMode')"
                      variant="outlined"
                      density="comfortable"
                      color="primary"
                      hide-details
                      @update:modelValue="store()"
                    >
                      <template v-slot:item="{ props, item }">
                        <v-list-item v-bind="props">
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                          <template #append>
                            <v-tooltip location="top">
                              <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" size="small"
                                  >mdi-information-outline</v-icon
                                >
                              </template>
                              <span>{{ item.raw.description }}</span>
                            </v-tooltip>
                          </template>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col v-if="controlModeSelect(controlMode)" cols="6">
                    <v-select
                      v-model="cardtype"
                      :items="cardtypes"
                      item-title="name"
                      item-value="val"
                      :label="$t('card.type')"
                      variant="outlined"
                      density="comfortable"
                      color="primary"
                      @update:modelValue="store()"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-if="!checkAuthenticationCard(authenticationType)"
                      v-model="authenticationType"
                      :items="authentications"
                      item-title="name"
                      item-value="val"
                      :label="$t('devices.authenticationType')"
                      variant="outlined"
                      density="comfortable"
                      color="primary"
                      hide-details
                    />
                    <v-row v-else style="margin-top: -12px">
                      <v-col cols="6">
                        <v-select
                          v-model="authenticationType"
                          :items="authentications"
                          item-title="name"
                          item-value="val"
                          :label="$t('devices.authenticationType')"
                          variant="outlined"
                          density="comfortable"
                          color="primary"
                          hide-details
                          @update:modelValue="updateAuthenticationType()"
                        />
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          v-model="effectiveWiegandLen"
                          :items="effectiveWiegandLens"
                          item-title="name"
                          item-value="val"
                          :label="$t('devices.effectiveWiegandLen')"
                          variant="outlined"
                          density="comfortable"
                          color="primary"
                          hide-details
                          @update:modelValue="store()"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="asReader"
                          :label="$t('devices.readerMode')"
                          density="comfortable"
                          color="primary"
                          hide-details
                          @update:modelValue="store()"
                        />
                      </v-col>
                      <v-col>
                        <template
                          v-if="
                            systemmode !== 'temp_sensor' &&
                            authenticationType === 'face'
                          "
                        >
                          <v-switch
                            v-model="cardRepeatMode"
                            :label="$t('devices.cardRepeatMode')"
                            density="comfortable"
                            color="primary"
                            hide-details
                            @update:modelValue="store()"
                          />
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card variant="outlined" elevation="0">
          <v-card-title class="text-h5" style="margin-bottom: 10px">
            <v-row align-content="center">
              <v-col>{{ $t("devices.deviceSettings") }}</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="displayMode"
                  :items="displaymodes"
                  item-title="name"
                  item-value="val"
                  :label="$t('devices.DisplayMode')"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  cols="12"
                  v-model="showName"
                  :items="shownames"
                  item-title="name"
                  item-value="val"
                  :label="$t('devices.ShowFullname')"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-switch
                  v-model="isEnableScreensaver"
                  :label="$t('devices.isEnableScreensaver')"
                  density="compact"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                />
                <template v-if="isEnableScreensaver">
                  <v-divider />
                  {{ $t("devices.screensaverDelay") }}
                  <v-slider
                    v-model="screensaverDelay"
                    class="align-center"
                    max="120"
                    min="1"
                    step="1"
                    density="comfortable"
                    color="primary"
                    hide-details
                    @update:modelValue="store()"
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="screensaverDelay"
                        class="mt-0 pt-0"
                        hide-details
                        max="120"
                        min="1"
                        step="1"
                        type="number"
                        style="width: 80px"
                        density="comfortable"
                        variant="outlined"
                        color="primary"
                        @update:modelValue="store()"
                      />
                    </template>
                  </v-slider>
                </template>
              </v-col>
            </v-row>

            <v-row v-if="purpose !== 'off' && apiVersion">
              <v-col cols="12" md="12">
                <v-switch
                  v-model="showDirection"
                  :label="$t('device.showDirection')"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                />
              </v-col>
            </v-row>

            <v-row v-if="purpose !== 'off' && apiVersion">
              <v-col cols="12" md="12">
                <v-switch
                  v-if="authModeByFace(authenticationType)"
                  v-model="silentMode"
                  density="comfortable"
                  color="primary"
                  hide-details
                  :label="$t('devices.silentMode')"
                  @update:modelValue="store()"
                >
                  <template v-slot:label>
                    <v-tooltip location="right" max-width="250">
                      <template v-slot:activator="{ props }">
                        {{ $t("devices.silentMode") }}
                        <v-icon
                          class="ml-2"
                          v-bind="props"
                          color="grey lighten-1"
                          >mdi-information</v-icon
                        >
                      </template>
                      <span>{{ $t("advanced.silentMode") }}</span>
                    </v-tooltip>
                  </template>
                </v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <template
                  v-if="
                    authenticationType !== 'card' &&
                    authenticationType !== 'qr_code'
                  "
                >
                  <v-switch
                    v-model="temperatureEnable"
                    :label="$t('devices.temperatureEnable')"
                    density="comfortable"
                    color="primary"
                    hide-details
                    @update:modelValue="updateTemperatureEnable()"
                  />
                </template>
                <template v-if="temperatureEnable">
                  <v-divider />
                  <v-switch
                    v-model="showThempInfo"
                    :label="$t('devices.ShowThempInfo')"
                    density="comfortable"
                    color="primary"
                    hide-details
                    @update:modelValue="store()"
                  />
                </template>
                <template
                  v-if="temperatureEnable && systemmode != 'temperature_pass'"
                >
                  <v-switch
                    v-model="showAccess"
                    :label="$t('devices.ShowAccess')"
                    density="comfortable"
                    color="primary"
                    hide-details
                    @update:modelValue="store()"
                  />
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card variant="outlined" elevation="0">
          <v-card-title class="text-h5" style="margin-bottom: 10px">
            <v-row align-content="center">
              <v-col>{{ $t("devices.recognitionSettings") }}</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                :md="recognitionMode == 'by_frame_in_cloud' ? 6 : 12"
              >
                <v-select
                  v-model="recMode"
                  :items="recognitionItems"
                  item-title="name"
                  item-value="value"
                  :label="$t('devices.recognitionMode')"
                  density="comfortable"
                  variant="outlined"
                  color="primary"
                  hide-details
                  @update:modelValue="updateRecMode"
                />
              </v-col>
              <v-col
                v-if="recognitionMode == 'by_frame_in_cloud'"
                cols="12"
                md="6"
              >
                <v-text-field
                  :rules="recognitionServerRules"
                  v-model="recognitionServer"
                  hide-details="auto"
                  :label="$t('devices.recognitionServer')"
                  :placeholder="$t('devices.recognitionServerExample')"
                  density="comfortable"
                  variant="outlined"
                  color="primary"
                  @update:modelValue="store()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-select
                  v-model="aspoofing"
                  :items="antispoofing"
                  item-title="name"
                  item-value="val"
                  :label="$t('devices.AntispoofingMode')"
                  density="comfortable"
                  variant="outlined"
                  color="primary"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6"
                >{{ $t("devices.recognition_range") }}
                <template v-if="l.temp_meas == 'c'">
                  {{ $t("devices.recognition_metr") }}
                </template>
                <template v-else>
                  {{ $t("devices.recognition_imper") }}
                </template>
                <v-slider
                  v-model="recognitionRange"
                  class="align-center"
                  :max="getFft(recognitionRangeMax)"
                  :min="getFft(0.3)"
                  step="0.1"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="recognitionRange"
                      class="mt-0 pt-0"
                      hide-details
                      :max="getFft(recognitionRangeMax)"
                      :min="getFft(0.3)"
                      step="0.1"
                      type="number"
                      style="width: 80px"
                      density="comfortable"
                      variant="outlined"
                      color="primary"
                      @update:modelValue="store()"
                    />
                  </template>
                </v-slider>

                {{ $t("devices.pass_timeout") }}
                <v-slider
                  v-model="mltimeout"
                  class="align-center"
                  max="60"
                  min="0"
                  step="1"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="mltimeout"
                      class="mt-0 pt-0"
                      hide-details
                      max="60"
                      min="0"
                      step="1"
                      type="number"
                      style="width: 80px"
                      density="comfortable"
                      variant="outlined"
                      color="primary"
                      @update:modelValue="store()"
                    />
                  </template>
                </v-slider>

                <v-switch
                  v-model="maskRecognition"
                  :label="$t('setting.maskRecognition')"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="updateMaskRecognition()"
                />

                <v-switch
                  v-if="maskRecognition"
                  density="comfortable"
                  color="primary"
                  hide-details
                  v-model="maskEnabled"
                  :label="$t('setting.maskEnabled')"
                  @update:modelValue="store()"
                />
              </v-col>
              <v-col cols="12" md="6">
                {{ $t("devices.recognition_face") }}
                <v-slider
                  v-model="recognitionFace"
                  class="align-center"
                  max="1"
                  min="0"
                  step="0.01"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="recognitionFace"
                      class="mt-0 pt-0"
                      hide-details
                      max="1"
                      min="0"
                      step="0.01"
                      type="number"
                      style="width: 80px"
                      density="comfortable"
                      variant="outlined"
                      color="primary"
                      @update:modelValue="store()"
                    />
                  </template>
                </v-slider>

                {{ $t("devices.controller_polling_delay") }}
                <v-slider
                  v-model="controller_polling_delay"
                  class="align-center"
                  max="90"
                  min="0"
                  step="1"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="controller_polling_delay"
                      class="mt-0 pt-0"
                      hide-details
                      max="90"
                      min="0"
                      step="1"
                      type="number"
                      style="width: 80px"
                      density="comfortable"
                      variant="outlined"
                      color="primary"
                      @update:modelValue="store()"
                    />
                  </template>
                </v-slider>

                <div v-if="maskRecognition && maskEnabled">
                  {{ $t("devices.recognition_mask") }}
                </div>
                <v-slider
                  v-if="maskRecognition && maskEnabled"
                  v-model="recognitionMask"
                  class="align-center"
                  max="1"
                  min="0"
                  step="0.01"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="recognitionMask"
                      class="mt-0 pt-0"
                      hide-details
                      max="1"
                      min="0"
                      step="0.01"
                      type="number"
                      style="width: 80px"
                      density="comfortable"
                      variant="outlined"
                      color="primary"
                      @update:modelValue="store()"
                    />
                  </template>
                </v-slider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="0">
          <v-card-title class="text-h5" style="margin-bottom: 10px">
            <v-row align-content="center">
              <v-col>{{ $t("devices.additionalSettings") }}</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-switch
                  v-model="antipassback"
                  density="comfortable"
                  color="primary"
                  hide-details
                  :label="$t('setting.anti-passback')"
                  @update:modelValue="store()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-switch
                  v-model="callbackMode"
                  density="comfortable"
                  color="primary"
                  hide-details
                  :label="$t('devices.callbackMode')"
                  @update:modelValue="updateCallbackMode()"
                />
              </v-col>
              <v-col v-if="callbackMode" cols="12" md="6">
                <v-text-field
                  v-model="ipAddressCallback"
                  :rules="callbackIpRules"
                  :label="`${$t('devices.ipCallback')} *`"
                  density="comfortable"
                  variant="outlined"
                  color="primary"
                  hide-details="auto"
                  required
                  @update:modelValue="store()"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-switch
                  v-model="extraFactor"
                  :label="$t('devices.extraFactor')"
                  density="comfortable"
                  color="primary"
                  hide-details
                  @update:modelValue="store()"
                />
                <!-- ExtraFactor -->
                <template v-if="extraFactor">
                  <v-divider />
                  {{ $t("devices.extraFactorTimeoutSec") }}
                  <v-slider
                    v-model="extraFactorTimeoutSec"
                    class="align-center"
                    max="90"
                    min="1"
                    step="1"
                    density="comfortable"
                    color="primary"
                    hide-details
                    @update:modelValue="store()"
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="extraFactorTimeoutSec"
                        class="mt-0 pt-0"
                        hide-details
                        max="90"
                        min="1"
                        step="1"
                        type="number"
                        style="width: 80px"
                        density="comfortable"
                        variant="outlined"
                        color="primary"
                        @update:modelValue="store()"
                      />
                    </template>
                  </v-slider>
                  <v-row style="margin-top: 10px">
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="extraFactorUrl"
                        :label="`${$t('devices.extraFactorUrl')} *`"
                        density="comfortable"
                        variant="outlined"
                        color="primary"
                        hide-details
                        required
                        @update:modelValue="store()"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="extraFactorMethod"
                        :label="`${$t('devices.extraFactorMethod')} *`"
                        density="comfortable"
                        variant="outlined"
                        color="primary"
                        hide-details
                        required
                        @update:modelValue="store()"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-select
                        cols="12"
                        v-model="extraFactorMode"
                        :items="[
                          { name: 'hard', val: 'hard' },
                          { name: 'soft', val: 'soft' },
                        ]"
                        item-title="name"
                        item-value="val"
                        :label="$t('devices.extraFactorMode')"
                        density="comfortable"
                        variant="outlined"
                        color="primary"
                        hide-details
                        @update:modelValue="store()"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        type="number"
                        v-model="extraFactorAttempsLimit"
                        :label="`${$t('devices.extraFactorAttempsLimit')} *`"
                        min="1"
                        max="10"
                        density="comfortable"
                        variant="outlined"
                        color="primary"
                        hide-details
                        required
                        @update:modelValue="store()"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-divider />

    <v-expansion-panels focusable class="mt-5">
      <v-expansion-panel>
        <v-expansion-panel-title>
          {{ $t("devices.wiegandErrorsCode") }}
        </v-expansion-panel-title>
        <v-expansion-panel-text class="py-4">
          <v-row v-for="note of notificationReferenceBooks" :key="note.code">
            <v-col :cols="12" :md="6" class="d-flex align-center">
              {{ getWiegandErrorTitle(note.code) }}
            </v-col>
            <v-col :cols="12" :md="5">
              <v-row class="jus">
                <v-col class="d-flex align-center">
                  <v-text-field
                    :model-value="note.value.toUpperCase()"
                    density="comfortable"
                    variant="outlined"
                    color="primary"
                    hide-details="auto"
                    required
                    :rules="hexRules"
                    @update:modelValue="getWiegandUppercase(note, $event)"
                  />
                </v-col>
                <v-col cols="auto" class="d-flex align-center">
                  <v-btn
                    variant="text"
                    density="compact"
                    icon
                    color="red"
                    @click="removeWError(note.code)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="d-flex align-center">
              <v-select
                v-model="newCode"
                :items="notificationCodes"
                item-title="name"
                item-value="val"
                :label="$t('devices.addWiegandError')"
                density="comfortable"
                variant="outlined"
                color="primary"
                hide-details
                @update:modelValue="store()"
              />
            </v-col>
            <v-col class="d-flex align-center">
              <v-btn v-if="newCode" color="primary" @click="addWError">
                <v-icon start> mdi-plus </v-icon>
                {{ $t("devices.addWiegandError") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <password
      v-if="pdlg"
      v-model="pconfirm"
      @toggle="confirm($event)"
      :loading="isPendingCheckPass"
    />

    <password
      v-if="pdlgRecMode"
      v-model="pconfirmRecMode"
      @toggle="confirmRecMode($event)"
      :loading="isPendingCheckPass"
    />
  </div>
</template>
<script>
import password from "@/components/PasswordDlg.vue";
import Channel from "../Channel";
import { mapState, mapActions } from "vuex";
import { isHexadecimal, isURL } from "validator";

export default {
  props: ["modelValue", "purpose", "channels", "uid"],
  components: {
    password,
    Channel,
  },
  watch: {
    aspoofing(val) {
      if (val != this.antispoofingMode && val == "off") {
        this.pdlg = true;
        this.pconfirm = "";
      } else {
        this.antispoofingMode = val;
      }
      this.recognitionRangeMax = 3;

      this.store();
    },
    systemmode(systemmode) {
      if (systemmode === "temp_sensor") {
        this.controlMode = "only_led";
      }

      if (systemmode === "bypass") {
        this.controlMode = "card";
      }

      this.store();
    },
    authenticationType(authenticationType) {
      if (authenticationType === "card" || authenticationType === "qr_code") {
        this.temperatureEnable = false;
      }
      this.store();
    },
  },
  data() {
    return {
      callbackMode: false,
      ipAddressCallback: "",
      newCode: null,
      cardRepeatMode: false,
      asReader: false,
      pdlg: false,
      npdlg: false,
      pdlgRecMode: false,
      loading: false,
      recognitionRange: this.getFft(2),
      isEnableScreensaver: false,
      screensaverDelay: 7,
      temperatureEnable: false,
      showFullname: "",
      controlMode: "no",
      authenticationsType: "face",
      showName: "full",
      recMode: "local",
      recognitionMode: "local",
      recognitionServer: "",
      recognitionItems: [
        {
          name: this.$t("devices.recognitionLocal"),
          value: "local",
        },
        {
          name: this.$t("devices.recognitionCloud"),
          value: "by_frame_in_cloud",
        },
      ],
      shownames: [
        { name: this.$t("devices.showNo"), val: "no" },
        { name: this.$t("devices.showFullName"), val: "full" },
        { name: this.$t("devices.showShortName"), val: "short" },
        { name: this.$t("devices.showAltName"), val: "alt" },
      ],
      enabled: true,
      silentMode: false,
      mltimeout: 0,
      controller_polling_delay: 0,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 20 || "Name must be less than 20 characters",
      ],
      systemmode: "default",
      cardtype: "WDEC26",
      cardtypes: [
        { name: "Wiegand26", val: "WDEC26" },
        { name: "Wiegand34", val: "WDEC34" },

        { name: "Wiegand37", val: "WDEC37" },
        { name: "Wiegand42", val: "WDEC42" },
        { name: "Wiegand44", val: "WDEC44" },
        { name: "Wiegand48", val: "WDEC48" },
        { name: "Wiegand50", val: "WDEC50" },

        { name: "Wiegand58", val: "WDEC58" },
        { name: "WiegandUni", val: "UDEC" },
        { name: "Touch Memory", val: "TM" },
      ],
      recognitionRangeMax: 3,
      recognitionFace: 0.7,
      recognitionMask: 0.7,
      effectiveWiegandLen: 0,
      showAccess: false,
      showThempInfo: false,
      maskRecognition: false,
      maskEnabled: false,
      passlist: ["only_led", "door", "door_inverted", "card"],
      errorMessage: "",
      autoregistrationMode: false,
      authenticationType: "face",
      antispoofingMode: "print",
      aspoofing: "print",
      pconfirm: "",
      pconfirmRecMode: "",
      npconfirm: {},
      apiVersion: "",
      deviceType: "OMINI",
      antispoofingKey: "",
      antispoofingKeyName: "",
      featuresVersion: {
        basic: {
          version: "",
          thresholds: {
            min: 0.5,
            max: 0.6,
          },
        },
        masked: {
          version: "",
          thresholds: {
            min: 0.5,
            max: 0.6,
          },
        },
      },
      antispoofing: [
        { val: "off", name: this.$t("button.AntispoofingOff") },
        //{ val: "phone", name: this.$t("button.AntispoofingPhone") },
        //{ val: "print", name: this.$t("button.AntispoofingPhoto") },
        { val: "print", name: this.$t("button.AntispoofingFull") },
      ],
      displayMode: "static",
      displaymodes: [
        { val: "static", name: this.$t("devices.modeDiaplayStatic") },
        { val: "video", name: this.$t("devices.modeDiaplayVideo") },
      ],

      showDirection: false,
      antipassback: false,
      extraFactor: false,
      extraFactorTimeoutSec: 10,
      extraFactorUrl: "http://192.168.88.167:7777",
      extraFactorMethod: "/pass",
      extraFactorMode: "hard",
      extraFactorAttempsLimit: 3,
      state_text: {},
      notificationReferenceBooks: [],
      notificationReferenceBooksCodes: [
        { val: 7, name: this.$t("devices.wiegandError7") },
        { val: 12, name: this.$t("devices.wiegandError12") },
        { val: 23, name: this.$t("devices.wiegandError23") },
        { val: 3, name: this.$t("devices.wiegandError3") },
        { val: 7, name: this.$t("devices.wiegandError7") },
        { val: 5, name: this.$t("devices.wiegandError5") },
        { val: 27, name: this.$t("devices.wiegandError27") },
        { val: 9, name: this.$t("devices.wiegandError9") },
        { val: 13, name: this.$t("devices.wiegandError13") },
        { val: 20, name: this.$t("devices.wiegandError20") },
        { val: 24, name: this.$t("devices.wiegandError24") },
        { val: 26, name: this.$t("devices.wiegandError26") },
        { val: 29, name: this.$t("devices.wiegandError29") },
        { val: 36, name: this.$t("devices.wiegandError36") },
        { val: 37, name: this.$t("devices.wiegandError37") },
        { val: 100, name: this.$t("devices.wiegandError100") },
      ],

      // notificationReferenceBook7: {
      //   value: "FFFFFF",
      //   code: 7,
      // },
      // notificationReferenceBook12: {
      //   value: "FFFFF0",
      //   code: 12,
      // },
      // notificationReferenceBook23: {
      //   value: "FFFFF1",
      //   code: 23,
      // },
      // notificationReferenceBook3: {
      //   value: "FFFFFA",
      //   code: 3,
      // },

      callbackItems: [
        { text: this.$t("devices.callbackDefault"), value: "default" },
        { text: this.$t("devices.callbackCustom"), value: "custom" },
      ],

      hexRules: [(v) => isHexadecimal(v) || this.$t("message.isHex")],
      callbackIpRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => isURL(v) || this.$t("message.isCallbackIP"),
      ],
      recognitionServerRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => isURL(v) || this.$t("message.isCallbackIP"),
      ],
    };
  },
  methods: {
    ...mapActions({
      checkPass: "user/checkPass",
    }),
    updateTemperatureEnable() {
      if (!this.temperatureEnable && this.systemmode == "temp_sensor") {
        this.systemmode = this.systemmodes[0].val;
      }
      this.store();
    },
    updateRecMode(val) {
      if (val != this.recognitionMode) {
        this.pdlgRecMode = true;
        this.pconfirmRecMode = "";
      } else {
        this.recognitionMode = val;
      }

      this.store();
    },
    getWiegandUppercase(note, event) {
      note.value = event.toUpperCase();
      this.store();
    },
    updateMaskRecognition() {
      if (!this.maskRecognition) this.maskEnabled = false;
      this.store();
    },
    updateCallbackMode() {
      if (!this.callbackMode) {
        this.ipAddressCallback = "";
      }
    },
    removeWError(code) {
      this.notificationReferenceBooks = this.notificationReferenceBooks.filter(
        (item) => item.code !== code
      );
      this.store();
    },
    updateAuthenticationType() {
      if (!this.authModeByFace(this.authenticationType)) {
        this.silentMode = false;
      }
      if (
        this.authenticationType === "card" ||
        this.authenticationType === "qr_code"
      ) {
        this.temperatureEnable = false;
      }
      this.store();
    },
    authModeByFace(mode) {
      return mode.indexOf("face") != -1;
    },
    addWError() {
      if (!this.newCode) {
        return;
      }
      this.notificationReferenceBooks.push({
        value: "",
        code: this.newCode,
      });
      this.newCode = null;
    },
    getWiegandErrorTitle(code) {
      let a = this.notificationReferenceBooksCodes.find(
        (item) => item.val === code
      );
      if (a) {
        return a.name;
      }
    },

    confirm(ev) {
      if (ev) {
        this.checkPass({ password: this.pconfirm })
          .then(() => {
            this.antispoofingMode = this.aspoofing;
            this.store();
          })
          .catch(() => {
            this.aspoofing = this.antispoofingMode;
            this.store();
          });
      } else {
        this.aspoofing = this.antispoofingMode;
        this.store();
      }
      this.pdlg = false;
    },

    confirmRecMode(ev) {
      if (ev) {
        this.checkPass({ password: this.pconfirmRecMode })
          .then(() => {
            this.recognitionServer = "";
            this.recognitionMode = this.recMode;
            this.store();
          })
          .catch(() => {
            this.recMode = this.recognitionMode;
            this.store();
          });
      } else {
        this.recMode = this.recognitionMode;
        this.store();
      }
      this.pdlgRecMode = false;
    },

    controlModeSelect(mode) {
      return mode.indexOf("card") != -1;
    },
    getFft(m) {
      if (this.l !== undefined && this.l["temp_meas"] == "f") {
        return Math.round(m * 3.28084 * 10) / 10;
      } else {
        return m;
      }
    },
    getMetr(m) {
      if (this.l !== undefined && this.l["temp_meas"] == "f") {
        return Math.round((m / 3.28084) * 10) / 10;
      } else {
        return m;
      }
    },
    store() {
      let temperature_enable = this.temperatureEnable ? "on" : "off";
      if (
        (this.systemmode != "default" && this.systemmode != "bypass") ||
        this.authenticationType != "face"
      ) {
        this.cardRepeatMode = false;
      }
      let basic = this.featuresVersion.basic;
      let masked = this.featuresVersion.masked;
      let data = {
        api_version: this.apiVersion,
        temperature_enable: temperature_enable,
        recognition_range: parseFloat(this.getMetr(this.recognitionRange)),
        screen_saver: this.isEnableScreensaver,
        screen_saver_delay: parseInt(this.screensaverDelay),
        control_mode: this.controlMode,
        card_type: this.cardtype,
        authentication_type: this.authenticationType,
        pass_timeout: parseInt(this.mltimeout),
        controller_polling_delay: parseInt(this.controller_polling_delay),
        system_mode: this.systemmode,
        recognition_face: parseFloat(this.recognitionFace),
        recognition_mask: parseFloat(this.recognitionMask),
        mask_recognition: this.maskRecognition,
        mask_enabled: this.maskRecognition ? this.maskEnabled : false,
        show_name: this.showName,
        show_themp_info: this.showThempInfo,
        show_access: this.showAccess,
        antispoofing_mode: this.antispoofingMode,
        as_reader: this.asReader,
        card_repeat_mode: this.cardRepeatMode,
        display_mode: this.displayMode,
        autoregistration_mode: this.autoregistrationMode,
        device_type: this.deviceType,
        features_version: { basic, masked },
        show_direction: this.showDirection,
        effective_wiegand_len: this.effectiveWiegandLen,
        anti_passback: this.antipassback,
        extra_factor: this.extraFactor,
        extra_factor_timeout_sec: parseInt(this.extraFactorTimeoutSec),
        extra_factor_url: this.extraFactorUrl,
        extra_factor_method: this.extraFactorMethod,
        extra_factor_mode: this.extraFactorMode,
        extra_factor_attemps_limit: parseInt(this.extraFactorAttempsLimit),
        state_text: this.stateText,
        notification_reference_book: this.notificationReferenceBooks,
        silent_mode: this.silentMode,
        recognition_mode: this.recognitionMode,
        recognition_server: this.recognitionServer,
      };

      if (this.ipAddressCallback) {
        data.ip_address_callback = this.ipAddressCallback;
      }

      this.$emit("update:modelValue", data);
    },
    getData(data) {
      this.ipAddressCallback = data["ip_address_callback"] || "";
      this.callbackMode = data["ip_address_callback"] ? true : false;
      this.asReader = data["as_reader"] || false;
      this.cardRepeatMode = data["card_repeat_mode"] || false;
      this.temperatureEnable = data["temperature_enable"] == "on";
      this.recognitionRange = this.getFft(data["recognition_range"] || 2);
      this.isEnableScreensaver = data["screen_saver"] || false;
      this.screensaverDelay = data["screen_saver_delay"] || 7;
      this.recognitionFace = data["recognition_face"] || 0;
      this.recognitionMask = data["recognition_mask"] || 0;
      this.controlMode = data["control_mode"] || "no";
      this.apiVersion = data["api_version"] || "";
      this.effectiveWiegandLen = data["effective_wiegand_len"] || 24;
      this.authenticationType = data["authentication_type"] || "face";
      this.systemmode = data["system_mode"] || "default";
      this.showFullname = data["show_fullname"] || false;
      this.showName = data["show_name"] || "full";
      this.showAccess = data["show_access"] || false;
      this.showThempInfo = data["show_themp_info"] || false;
      this.maskEnabled = data["mask_enabled"] || false;
      this.maskRecognition = data["mask_recognition"] || false;
      this.mltimeout = data["pass_timeout"] || 0;
      this.antispoofingKeyName = this.uid + "_antispoofingKeyName";
      this.antispoofingKey =
        localStorage.getItem(this.antispoofingKeyName) || "facechain123";
      this.controller_polling_delay =
        data["controller_polling_delay"] ||
        data["controller_polling_delay"] === 0
          ? data["controller_polling_delay"]
          : 3;
      this.silentMode = data["silent_mode"] ? data["silent_mode"] : false;
      this.cardtype = data["card_type"] || "WDEC26";
      this.autoregistrationMode = data["autoregistration_mode"] || false;
      this.aspoofing = data["antispoofing_mode"] || "off";
      this.antispoofingMode = this.aspoofing;
      this.recognitionMode = data["recognition_mode"] || "local";
      this.recognitionServer = data["recognition_server"] || "";
      this.recMode = data["recognition_mode"] || "local";
      this.displayMode = data["display_mode"] || "static";
      this.deviceType = data["device_type"] || "OMINI";
      this.featuresVersion = data["features_version"] || {
        basic: { version: "", thresholds: { min: 0.5, max: 0.6 } },
        masked: { version: "", thresholds: { min: 0.5, max: 0.6 } },
      };
      this.showDirection = data["show_direction"];
      this.stateText = data["state_text"] || {};
      this.antipassback = data["anti_passback"] || false;
      this.extraFactor = data["extra_factor"] || false;
      this.extraFactorTimeoutSec = data["extra_factor_timeout_sec"] || 10;
      this.extraFactorUrl =
        data["extra_factor_url"] || "http://192.68.88.164:7777";
      this.extraFactorMethod = data["extra_factor_method"] || "/pass";
      this.extraFactorMode = data["extra_factor_mode"] || "hard";
      this.extraFactorAttempsLimit = data["extra_factor_attemps_limit"] || 3;
      this.notificationReferenceBooks =
        data["notification_reference_book"] || [];

      // if (data["notification_reference_book"]) {
      //   this.notificationReferenceBook7 =
      //     data["notification_reference_book"][0];
      //   this.notificationReferenceBook12 =
      //     data["notification_reference_book"][1];
      //   this.notificationReferenceBook23 =
      //     data["notification_reference_book"][2];
      //   this.notificationReferenceBook3 =
      //     data["notification_reference_book"][3];
      // }
    },

    checkAuthenticationCard(auth) {
      return auth.indexOf("card") != -1;
    },

    updateChannels(e, index) {
      const channels = this.channels;
      channels[index] = e;

      this.$emit("change-channels", channels);
    },
  },

  computed: {
    ...mapState({
      l: (state) => state.settings.data,
      isPendingCheckPass: (state) => state.device.isPendingCheckPass,
    }),

    notificationCodes() {
      let codes = [];
      this.notificationReferenceBooksCodes.forEach((c) => {
        if (
          !this.notificationReferenceBooks.find((item) => item.code === c.val)
        ) {
          codes.push(c);
        }
      });
      return codes;
    },

    systemmodes() {
      const list = [
        {
          val: "default",
          name: this.$t("devices.defaultMode"),
        },
        {
          val: "bypass",
          name: this.$t("devices.bypassMode"),
        },
      ];
      if (this.temperatureEnable)
        list.push({
          val: "temp_sensor",
          name: this.$t("devices.ThermalSensor"),
        });
      return list;
    },

    controls() {
      let list = [];

      if (this.systemmode === "bypass") {
        list = [
          {
            name: this.$t("controls.card"),
            val: "card",
            description: this.$t("controls.cardDescription"),
          },
          {
            name: this.$t("controls.cardConfirm"),
            val: "card_confirm",
            description: this.$t("controls.cardConfirmDescription"),
          },
          {
            name: this.$t("controls.cardPass"),
            val: "card_pass",
            description: this.$t("controls.cardPassDescription"),
          },
        ];
      }

      if (this.systemmode === "default") {
        list = [
          {
            name: this.$t("controls.card"),
            val: "card",
            description: this.$t("controls.cardDescription"),
          },
          {
            name: this.$t("controls.cardConfirm"),
            val: "card_confirm",
            description: this.$t("controls.cardConfirmDescription"),
          },
          {
            name: this.$t("controls.cardPass"),
            val: "card_pass",
            description: this.$t("controls.cardPassDescription"),
          },
          {
            name: this.$t("controls.dryContact"),
            val: "dry_contact",
            description: this.$t("controls.dryContactDescription"),
          },
          {
            name: this.$t("controls.door"),
            val: "door",
            description: this.$t("controls.doorDescription"),
          },
          {
            name: this.$t("controls.doorInverted"),
            val: "door_inverted",
            description: this.$t("controls.doorInvertedDescription"),
          },
          {
            name: this.$t("controls.doorInvertedImpulse"),
            val: "door_inverted_impulse",
            description: this.$t("controls.doorInvertedImpulseDescription"),
          },
          {
            name: this.$t("controls.onlyLed"),
            val: "only_led",
            description: this.$t("controls.onlyLedDescription"),
          },
          {
            name: this.$t("controls.no"),
            val: "no",
            description: this.$t("controls.noDescription"),
          },
        ];
      }

      if (this.systemmode === "temp_sensor") {
        list = [
          {
            name: this.$t("controls.onlyLed"),
            val: "only_led",
            description: this.$t("controls.onlyLedDescription"),
          },
          {
            name: this.$t("controls.no"),
            val: "no",
            description: this.$t("controls.noDescription"),
          },
        ];
      }

      return list;
    },

    effectiveWiegandLens() {
      const list = [
        { name: "16 bit", val: 16 },
        { name: "24 bit", val: 24 },
        { name: "32 bit", val: 32 },
        { name: "35 bit", val: 35 },
        { name: "40 bit", val: 40 },
        { name: "42 bit", val: 42 },
        { name: "46 bit", val: 46 },
        { name: "48 bit", val: 48 },
        { name: "56 bit", val: 56 },
        { name: "64 bit", val: 64 },
      ];
      return list;
    },
    authentications() {
      let list = [{ name: this.$t("devices.auth.face"), val: "face" }];

      if (
        this.systemmode === "default" ||
        this.systemmode === "bypass"
        //TODO: удалить если все будет работать хорошо
        //&& this.apiVersion
      ) {
        list = [
          ...list,

          { name: this.$t("devices.auth.card"), val: "card" },
          { name: this.$t("devices.auth.qrCode"), val: "qr_code" },

          {
            name: this.$t("devices.auth.faceOrCard"),
            val: "face_or_card",
          },
          {
            name: this.$t("devices.auth.faceOrQrCode"),
            val: "face_or_qr_code",
          },
          {
            name: this.$t("devices.auth.faceOrCardOrQrCode"),
            val: "face_or_card_or_qr_code",
          },
        ];

        switch (this.deviceType) {
          case "OGATEV8":
            break;
          default:
            list = [
              ...list,

              {
                name: this.$t("devices.auth.faceAndCard"),
                val: "face_and_card",
              },
            ];
            break;
        }
      }

      return list;
    },
  },

  created() {
    if (this.modelValue) {
      this.getData(this.modelValue);
    }
    this.store();
  },
};
</script>
