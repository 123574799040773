import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
import { moment as $moment } from "@/plugins/vue-moment";
const $toast = useToast();

export default {
  get({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING" });
    params.type = ["pass"];

    return $axios("events", { params }) //visit/log
      .then((response) => {
        const {
          data,
          meta: { pagination },
        } = response.data;

        commit("set", { data, pagination });
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  report({ commit }, { params, url = "report/stat" }) {
    commit("setLoadingReport", { type: "IS_PENDING" });

    const requestParams = {
      method: "post",
      url,
      responseType: "blob",
      params,
    };

    return $axios
      .request(requestParams)
      .then((response) => {
        if (response.status === 204) {
          $toast.error(i18n.global.t("advanced.nopass"));

          commit("setLoadingReport", {
            type: "IS_FAILURE",
            payload: response?.data?.status?.message || "",
          });
        } else {
          const filename = `${requestParams.url}-${$moment().format(
            "YYYYMMDDHHmmss"
          )}.xlsx`;

          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/xlsx",
            })
          );

          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();

          commit("setLoadingReport", { type: "IS_SUCCESS" });
        }
      })
      .catch((error) => {
        commit("setLoadingReport", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("advanced.nopass"));
      });
  },
};
