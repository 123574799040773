<template>
  <div>
    <v-row align="center" class="mb-5">
      <v-col cols="auto">
        <h2>
          {{ $t("operators.changeAuth") }}
        </h2>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-btn @click="onGeneratePassword">{{
          $t("operators.generatePassword")
        }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          :type="isShowPass ? 'text' : 'password'"
          v-model="pass"
          variant="outlined"
          density="comfortable"
          color="primary"
          :rules="passwordRules"
          :label="`${$t('auth.newPassword')} *`"
          autocomplete="off"
          :append-icon="isShowPass ? 'mdi-eye' : 'mdi-eye-off'"
          hide-details="auto"
          @click:append="isShowPass = !isShowPass"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          ref="passwordConfirmation"
          :type="isShowConfirmPass ? 'text' : 'password'"
          v-model="confirmPass"
          variant="outlined"
          density="comfortable"
          color="primary"
          :rules="passwordConfirmRules"
          :label="`${$t('auth.passwordConfirm')} *`"
          autocomplete="off"
          :append-icon="isShowConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
          hide-details="auto"
          @click:append="isShowConfirmPass = !isShowConfirmPass"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import passwordGenerator from "generate-password-browser";

export default {
  name: "PasswordForm",

  data() {
    return {
      pass: "",
      confirmPass: "",
      isShowPass: false,
      isShowConfirmPass: false,
      passRegex: new RegExp(/^[a-zA-Z0-9!@#$%^&*-_]+$/),

      passwordRules: [
        (v) => v.length >= 6 || this.$t("operators.errLenghtnPassword"),
        (v) => this.passRegex.test(v) || this.$t("auth.chars_password"),
        (v) => v.length <= 255 || this.$t("operators.errLenghtnPassword"),
      ],
      passwordConfirmRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => v == this.pass || this.$t("auth.passwords_must_match"),
      ],
    };
  },

  watch: {
    pass() {
      this.$emit("update:modelValue", this.pass);
    },
  },

  methods: {
    getPassword() {
      return passwordGenerator.generate({
        length: 8,
        numbers: true,
        excludeSimilarCharacters: true,
        strict: true,
      });
    },

    onGeneratePassword() {
      this.$toast.success(this.$t("operators.passwordGeneratedMessage"));

      const generatedPassword = this.getPassword();
      this.pass = generatedPassword;
      this.confirmPass = generatedPassword;
    },
  },
};
</script>
