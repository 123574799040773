export default {
  setLoading(state, { type, payload = "" }) {
    state.isPending = type === "IS_PENDING";
    state.isSuccess = type === "IS_SUCCESS";
    state.isFailure = type === "IS_FAILURE";
    state.errorMessage = payload;
  },
  setLoadingClearDB(state, { type, payload = "" }) {
    state.isPendingClearDB = type === "IS_PENDING";
    state.isSuccessClearDB = type === "IS_SUCCESS";
    state.isFailureClearDB = type === "IS_FAILURE";
    state.errorMessageClearDB = payload;
  },
  setLoadingDownloadAllLog(state, { type, payload = "" }) {
    state.isPendingDownloadAllLog = type === "IS_PENDING";
    state.isSuccessDownloadAllLog = type === "IS_SUCCESS";
    state.isFailureDownloadAllLog = type === "IS_FAILURE";
    state.errorMessageDownloadAllLog = payload;
  },
  setLoadingGetLog(state, { type, payload = "" }) {
    state.isPendingGetLog = type === "IS_PENDING";
    state.isSuccessGetLog = type === "IS_SUCCESS";
    state.isFailureGetLog = type === "IS_FAILURE";
    state.errorMessageGetLog = payload;
  },
  setLog(state, payload) {
    state.log = payload;
  },
  setLoadingGetUserLog(state, { type, payload = "" }) {
    state.isPendingGetUserLog = type === "IS_PENDING";
    state.isSuccessGetUserLog = type === "IS_SUCCESS";
    state.isFailureGetUserLog = type === "IS_FAILURE";
    state.errorMessageGetUserLog = payload;
  },
  setUserLog(state, payload) {
    state.userLog = payload;
  },
  setLoadingDownloadAllUserLog(state, { type, payload = "" }) {
    state.isPendingDownloadAllUserLog = type === "IS_PENDING";
    state.isSuccessDownloadAllUserLog = type === "IS_SUCCESS";
    state.isFailureDownloadAllUserLog = type === "IS_FAILURE";
    state.errorMessageDownloadAllUserLog = payload;
  },
};
