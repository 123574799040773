import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
import { moment as $moment } from "@/plugins/vue-moment";
const $toast = useToast();

export default {
  //Старое хранилище api
  get({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("events", { params })
      .then((response) => {
        const {
          data,
          meta: { pagination },
        } = response.data;

        commit("set", { data, pagination });
        commit("setLoading", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  //Новое хранилище api
  resetGetEventsList({ commit }) {
    commit("setLoadingGetEventsList", { type: "" });
    commit("setEventsList", []);
  },

  getEventsList({ commit }, params) {
    commit("setLoadingGetEventsList", { type: "IS_PENDING" });

    return $axios("events", { params })
      .then((response) => {
        commit("setEventsList", response.data.data);
        commit("setLoadingGetEventsList", { type: "IS_SUCCESS" });

        return response;
      })
      .catch((error) => {
        commit("setLoadingGetEventsList", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },

  getFirstEntranceEvent({ commit }) {
    commit("setLoadingFirstEntranceEvent", { type: "IS_PENDING" });
    const params = {
      limit: 1,
      page: 1,
      purpose: "entrance",
    };
    return $axios("events", { params })
      .then((response) => {
        commit("setFirstEntranceEvent", response.data.data[0] || {});
        commit("setLoadingFirstEntranceEvent", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingFirstEntranceEvent", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  getFirstExitEvent({ commit }) {
    commit("setLoadingFirstExitEvent", { type: "IS_PENDING" });
    const params = {
      limit: 1,
      page: 1,
      purpose: "exit",
    };
    return $axios("events", { params })
      .then((response) => {
        commit("setFirstExitEvent", response.data.data[0] || {});
        commit("setLoadingFirstExitEvent", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingFirstExitEvent", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  report({ commit }, { data, params }) {
    commit("setLoadingReport", { type: "IS_PENDING" });

    const requestParams = {
      method: "post",
      url: "report/events",
      responseType: "blob",
      data,
      params,
    };

    return $axios
      .request(requestParams)
      .then((response) => {
        const filename = `events-${$moment().format("YYYYMMDDHHmmss")}.xlsx`;

        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/xlsx",
          })
        );

        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        commit("setLoadingReport", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingReport", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("advanced.nopass"));
      });
  },
};
