<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-select
        v-model="callbackMode"
        :items="callbackItems"
        :label="$t('devices.callbackMode')"
        item-title="text"
        item-value="value"
        variant="outlined"
        density="comfortable"
        color="primary"
        @update:modelValue="inputValue"
      />
    </v-col>
    <v-col v-if="callbackMode === 'custom'" cols="12" md="6">
      <v-text-field
        v-model="ipAddressCallback"
        :rules="callbackIpRules"
        :label="`${$t('devices.ipCallback')} *`"
        variant="outlined"
        density="comfortable"
        color="primary"
        required
        @update:modelValue="inputValue"
      />
    </v-col>

    <v-col
      v-for="(channel, index) of channels"
      :key="channel.channel"
      cols="12"
    >
      <Channel
        :channel="channel"
        @changeState="updateChannels($event, index)"
      />
    </v-col>

    <v-col v-show="!isEdit">
      <v-text-field
        v-model="pass"
        :label="`${$t('devices.pass')} *`"
        type="password"
        variant="outlined"
        density="comfortable"
        color="primary"
        required
        :rules="[(v) => !!v || $t('message.NameIsRequired')]"
        @update:modelValue="inputValue"
      />
    </v-col>

    <template v-if="isEdit">
      <UfaceForm
        v-if="isUface"
        v-model="form"
        @update:modelValue="inputValue"
      />
      <UfaceXForm
        v-if="isUfaceX"
        v-model="form"
        @update:modelValue="inputValue"
      />
    </template>
  </v-row>
</template>

<script>
import Channel from "../Channel";
import UfaceForm from "./forms/uface";
import UfaceXForm from "./forms/ufaceX";

import { isHexadecimal, isURL } from "validator";

export default {
  components: {
    Channel,
    UfaceForm,
    UfaceXForm,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      reqired: true,
    },
    channels: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      settingTab: null,

      pass: this.modelValue?.pass || "",
      deviceVersion: this.modelValue?.device_version || "",
      callbackMode: this.modelValue?.ip_address_callback ? "custom" : "default",
      ipAddressCallback: this.modelValue?.ip_address_callback || "",
      form: this.modelValue,

      callbackItems: [
        { text: this.$t("devices.callbackDefault"), value: "default" },
        { text: this.$t("devices.callbackCustom"), value: "custom" },
      ],

      callbackIpRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => isURL(v) || this.$t("message.isCallbackIP"),
      ],
    };
  },

  watch: {
    callbackMode() {
      if (this.callbackMode === "default") {
        this.ipAddressCallback = "";

        this.inputValue();
      }
    },
  },

  methods: {
    inputValue() {
      const { pass, form } = this;

      const data = {
        pass,
        device_type: "UFACE",
        ...form,
      };

      if (this.ipAddressCallback) {
        data.ip_address_callback = this.ipAddressCallback;
      }

      this.$emit("update:modelValue", data);
    },

    updateChannels(e, index) {
      const channels = this.channels;
      channels[index] = e;

      this.$emit("change-channels", channels);
    },
  },

  computed: {
    isUface() {
      if (!this.deviceVersion || this.deviceVersion.slice(0, 2) === "GD")
        return true;

      return false;
    },

    isUfaceX() {
      if (this.deviceVersion.slice(0, 2) === "OS") return true;

      return false;
    },
  },
};
</script>
