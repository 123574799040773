<template>
  <div></div>
</template>
<script>
import userRedirect from "@/mixins/userRedirect";

export default {
  mixins: [userRedirect],

  created() {
    this.fastRedirect();
  },
};
</script>
