export default {
  data() {
    return {
      objectErrors: [],
      isOpenObjectErrorLogs: false,
    };
  },

  methods: {
    openObjectErrorLogs(errors) {
      this.objectErrors = errors;
      this.isOpenObjectErrorLogs = true;
    },

    closeObjectErrorLogs() {
      this.isOpenObjectErrorLogs = false;
    },
  },
};
