<template>
  <div>
    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <Filter
        v-model="filter"
        filterName="permitrequest"
        :md="3"
        @update:modelValue="onFilter"
      />
      <v-col cols="12" md="9">
        <v-row>
          <v-spacer />
          <v-col v-if="isAddRequest" cols="12" md="auto">
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" color="primary">
                  <v-icon start> mdi-plus </v-icon>
                  {{ $t("button.add") }}&nbsp;
                  <v-icon end> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list density="comfortable">
                <v-list-item
                  :to="{
                    name: this.$const.ROUTES.ADD_PERMIT_REQUEST,
                    params: {
                      query: $route.query,
                    },
                  }"
                >
                  <v-list-item-title>{{
                    $t("requests.oneRequest")
                  }}</v-list-item-title>
                </v-list-item>

                <v-list-item
                  :to="{
                    name: this.$const.ROUTES.ADD_MULTI_PERMIT_REQUEST,
                    params: {
                      query: $route.query,
                    },
                  }"
                >
                  <v-list-item-title>{{
                    $t("button.multiAdd")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :loading="isPending"
              :headers="headers"
              :items="requests"
              :server-items-length="pagination.per_page"
              hide-default-footer
            >
              <template #[`item.person_name`]="{ item }">
                <router-link
                  :to="{
                    name: $const.ROUTES.EDIT_PERMIT_REQUEST,
                    params: { id: item.id, query: $route.query },
                  }"
                  >{{ item.person_name }}</router-link
                >
              </template>

              <template #[`item.status`]="{ item }">
                <v-chip :color="getColorByStatus(item.status)" outlined small>
                  <v-icon start size="small">
                    {{ getIconByStatus(item.status) }}
                  </v-icon>
                  {{ getStatusText(item.status) }}
                </v-chip>
              </template>

              <template #[`item.created_at`]="{ item }">
                {{ $moment(item.created_at).format("DD.MM.YYYY HH:mm") }}
              </template>

              <template #[`item.actions`]="{ item }">
                <v-tooltip location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      variant="text"
                      density="compact"
                      icon
                      color="green"
                      @click="goToEdit(item.id)"
                    >
                      <v-icon size="x-small">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("button.show") }}</span>
                </v-tooltip>

                <v-tooltip v-if="isRequestConfirm(item.status)" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      variant="text"
                      density="compact"
                      icon
                      color="green"
                      @click="confirmRequest(item.id)"
                    >
                      <v-icon size="small"> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("requests.confirmRequest") }}</span>
                </v-tooltip>

                <v-tooltip v-if="isRequestCancel(item)" location="top">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      v-bind="props"
                      variant="text"
                      density="compact"
                      icon
                      color="red"
                      @click="cancelRequest(item.id)"
                    >
                      <v-icon size="x-small"> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("requests.cancelRequest") }}</span>
                </v-tooltip>
              </template>

              <template #bottom>
                <DataTableFooterV2
                  v-model="pagination"
                  @update:modelValue="onPagination"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Alert from "@/components/Alert";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";

import permissions from "@/mixins/permissions";
import filter from "@/mixins/filter";

export default {
  name: "RequestsList",

  mixins: [permissions, filter],

  components: { Alert, DataTableFooterV2, Filter },

  data() {
    return {
      search: "",
      page: parseInt(this.$route.query.page) || 1,

      filter: {},
      pagination: {},

      headers: [
        {
          title: "ID",
          value: "id",
        },
        {
          title: this.$t("requests.name"),
          value: "person_name",
        },
        {
          title: this.$t("requests.status"),
          value: "status",
          sortable: false,
        },
        {
          title: this.$t("requests.date"),
          value: "created_at",
        },
        {
          value: "actions",
          align: "end",
          sortable: false,
          width: 121,
        },
      ],
    };
  },

  methods: {
    ...mapMutations({
      setLimit: "requests/setLimit",
    }),

    ...mapActions({
      getRequestsAction: "requests/get",
      setStatus: "request/setStatus",
      setStateFilterAction: "statePermitRequests/setFilter",
      setStatePaginationAction: "statePermitRequests/setPagination",
    }),

    //Получение состояния страницы
    getState() {
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния страницы
    setFilterState() {
      this.setStateFilterAction(this.filter);
    },
    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };
      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getRequests();
    },

    getRequests() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getRequestsAction(params).then((response) => {
        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;
          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            totalItems: pagination.total_items,
          };
        }
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_PERMIT_REQUEST,
        params: { id, query: this.$route.query },
      });
    },

    async confirmRequest(id) {
      await this.setStatus({ id, status: 1 });

      this.getRequests();
    },

    async cancelRequest(id) {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.setStatus({ id, status: 3 });
          this.getRequests();
        },
      });
    },

    isRequestConfirm(status) {
      return this.can(this.$const.RULES.REQUEST_CONFIRM) && status === 0;
    },

    isRequestCancel(item) {
      return (
        (this.$auth.user().admin_id === item.owner_id ||
          this.can(this.$const.RULES.REQUEST_CANCEL)) &&
        item.status !== 3 &&
        item.status !== 4
      );
    },

    getStatusText(code) {
      switch (code) {
        case 0:
          return this.$t("requests.statusNew");
        case 1:
          return this.$t("requests.statusWait");
        case 2:
          return this.$t("requests.statusDone");
        case 3:
          return this.$t("requests.statusCancel");
        case 4:
          return this.$t("requests.statusUserDelete");
        default:
          break;
      }
    },

    getColorByStatus(status) {
      switch (status) {
        case 0:
          return "blue";
        case 1:
          return "orange";
        case 2:
          return "green";
        case 3:
          return "";
        default:
          return "";
      }
    },

    getIconByStatus(status) {
      switch (status) {
        case 0:
          return "mdi-new-box";
        case 1:
          return "mdi-clock";
        case 2:
          return "mdi-check";
        case 3:
          return "mdi-cancel";
        case 4:
          return "mdi-account-remove";
        default:
          return "";
      }
    },

    rowProps(item) {
      const props = {};
      if (this.$vuetify.display.mobile && item.item.id) {
        props.onClick = () => this.goToUser(item);
      }

      return props;
    },
    goToUser(item) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_PERMIT_REQUEST,
        params: {
          id: item.item.id,
        },
      });
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.requests.isPending,
      requests: (state) => state.requests.data,
      errorMessage: (state) => state.requests.errorMessage,
      stateFilter: (state) => state.statePermitRequests.filter,
      statePagination: (state) => state.statePermitRequests.pagination,
    }),

    isAddRequest() {
      return this.can(this.$const.RULES.REQUEST_ADD);
    },
  },

  created() {
    this.getState();
    this.getRequests();
  },
};
</script>
