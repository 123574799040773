export default {
  setPageData({ commit }, data = {}) {
    localStorage.setItem("devicesStatePageData", JSON.stringify(data));
    commit("setPageData", data);
  },
  setFilter({ commit }, data = {}) {
    localStorage.setItem("devicesStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },
  setPagination({ commit }, data = {}) {
    localStorage.setItem("devicesStatePagination", JSON.stringify(data));
    commit("setPagination", data);
  },
};
