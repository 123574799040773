<template>
  <v-select
    v-model="fieldComputed"
    :items="field.items"
    :rules="selectRules"
    :label="field.label"
    item-title="label"
    item-value="value"
    color="primary"
    variant="outlined"
    hide-details="auto"
    density="comfortable"
  />
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    field: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    fieldComputed: {
      get() {
        const curValue = this.modelValue.find(
          (value) => value.name == this.field.name
        );

        return curValue?.value;
      },
      set(newValue) {
        let isEdit = false;

        const curValues = this.modelValue.map((value) => {
          const curValue = { ...value };

          if (value.name == this.field.name) {
            curValue.value = newValue;
            isEdit = true;
          }

          return curValue;
        });

        if (!isEdit) {
          curValues.push({
            type: this.field.type,
            name: this.field.name,
            value: newValue,
          });
        }

        this.$emit("update:modelValue", curValues);
      },
    },

    selectRules() {
      const rules = [];

      if (this.field.required) {
        rules.push((v) => {
          if (!v) {
            return this.$t("requests.oneOfContactIsRequired");
          }

          return true;
        });
      }

      return rules;
    },
  },
};
</script>
