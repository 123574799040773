<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="2" :loading="isPendingStore">
      <template #progress>
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="name"
              :label="`${$t('zones.name')} *`"
              :rules="nameRules"
              variant="outlined"
              density="comfortable"
              color="primary"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model.number="parentId"
              :items="zones(id)"
              :label="$t('zones.parent')"
              item-value="id"
              item-title="name"
              variant="outlined"
              density="comfortable"
              color="primary"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="departmentsId"
              :items="departments"
              :label="$t('zones.departmentsGroup')"
              multiple
              item-value="id"
              item-title="name"
              variant="outlined"
              density="comfortable"
              color="primary"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="accesslevelsId"
              :items="accesslevels"
              :label="$t('zones.groupaccess')"
              multiple
              item-value="id"
              item-title="name"
              variant="outlined"
              density="comfortable"
              color="primary"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-switch
              v-model="isOuterZone"
              density="comfortable"
              color="primary"
              :label="$t('zone.isOuterZone')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="isPendingStore" @click="cancel">
          <v-icon start>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" :loading="isPendingStore" @click="save">
          <v-icon start>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { isHexadecimal } from "validator";

export default {
  name: "ZoneForm",

  props: {
    id: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: null,
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const name = this.data?.name || "";
    const parentId = this.data?.parent_id || null;
    const guestCard = this.data?.data.guest_card_code || "";
    const departmentsId = this.data?.departments_id || null;
    const accesslevelsId = this.data?.accesslevels_id || null;
    const isOuterZone = this.data?.data?.is_outer_zone || false;

    return {
      valid: true,

      name,
      parentId,
      guestCard,
      departmentsId,
      accesslevelsId,
      isOuterZone,

      nameRules: [(v) => !!v || this.$t("message.NameIsRequired")],
      guestCardRules: [
        (v) =>
          v.length <= 16 || this.$t("message.lengthIsLarge", { length: 16 }),
        (v) => !v || isHexadecimal(v) || this.$t("message.isHex"),
      ],
    };
  },

  computed: {
    ...mapGetters({
      zones: "zones/getListExcludeById",
    }),
    ...mapState({
      departments: (state) => state.departments.list,
      accesslevels: (state) => state.accessLevels.list,
    }),
  },

  methods: {
    ...mapActions({
      getZones: "zones/getList",
      getDepartments: "departments/getList",
      getAccessLevels: "accessLevels/getList",
    }),

    cancel() {
      this.$router.push({ name: this.$const.ROUTES.ZONES });
    },

    async save() {
      let valid = false;
      await this.$refs.form.validate().then((result) => (valid = result.valid));
      if (!valid) return;

      this.$emit("store", {
        name: this.name,
        parent_id: this.parentId,
        data: {
          guest_card_code: this.guestCard,
          is_outer_zone: this.isOuterZone,
        },
        accesslevels_id: this.accesslevelsId,
        departments_id: this.departmentsId,
      });
    },
  },

  created() {
    const params = {};

    if (this.id) {
      params.exclude_id_nested = this.id;
    }

    this.getZones(params);
    this.getDepartments();
    this.getAccessLevels();
  },
};
</script>
