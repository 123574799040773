<template>
  <v-dialog
    :model-value="isOpen"
    max-width="700px"
    @click:outside="$emit('close')"
  >
    <v-card :title="$t('users.errorLogs')">
      <v-card-text>
        <v-table density="comfortable">
          <template #default>
            <thead>
              <tr>
                <th class="text-left" />
                <th class="text-left">{{ $t("message.errorText") }}</th>
                <th class="text-left">{{ $t("devices.deviceType") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(error, index) in errors" :key="index">
                <td>
                  <v-icon v-if="error.type === 'error'" color="error"
                    >mdi-alert-circle-outline</v-icon
                  >
                  <v-icon v-else-if="error.type === 'warning'" color="warning"
                    >mdi-alert-outline</v-icon
                  >
                  <v-icon v-else color="info">mdi-information-outline</v-icon>
                </td>
                <td>
                  {{ error.message }}
                </td>
                <td>
                  {{ error.device }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-card-text>

      <v-card-actions class="mx-6">
        <v-spacer />
        <v-btn variant="text" @click="$emit('close')">{{
          $t("button.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ObjectErrorsDialog",

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
