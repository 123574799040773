import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  data() {
    return {
      v$: useVuelidate(),
      modes: [],
    };
  },

  validations() {
    const data = {};

    data.modes = { required };

    return data;
  },

  computed: {
    modesErrors() {
      const errors = [];
      if (!this.v$.modes.$dirty) return errors;

      if (this.v$.modes.required.$invalid)
        errors.push(this.$t("message.noOneTimezone"));

      return errors;
    },
  },
};
