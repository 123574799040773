import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  isPendingVisits: false,
  isSuccessVisits: false,
  isFailureVisits: false,
  errorMessageVisits: "",

  isPendingStore: false,
  isSuccessStore: false,
  isFailureStore: false,
  errorMessageStore: "",
  errorDataStore: null,

  isPendingPassBio: false,
  isSuccessPassBio: false,
  isFailurePassBio: false,
  errorMessagePassBio: "",

  bioLink: "",

  isPendingSendSms: false,
  isSuccessSendSms: false,
  isFailureSendSms: false,
  errorMessageSendSms: "",

  isPendingSendEmail: false,
  isSuccessSendEmail: false,
  isFailureSendEmail: false,
  errorMessageSendEmail: "",

  isPendingSendQrd: false,
  isSuccessSendQrd: false,
  isFailureSendQrd: false,
  errorMessageSendQrd: "",

  isPendingOffQrd: false,
  isSuccessOffQrd: false,
  isFailureOffQrd: false,
  errorMessageOffQrd: "",

  isPendingRefeature: false,
  isSuccessRefeature: false,
  isFailureRefeature: false,
  errorMessageRefeature: "",

  isPendingValidateFace: false,
  isSuccessValidateFace: false,
  isFailureValidateFace: false,
  errorMessageValidateFace: "",

  data: {},
  visits: null,

  isPendingGetProfilesTypes: false,
  isSuccessGetProfilesTypes: false,
  isFailureGetProfilesTypes: false,
  errorMessageGetProfilesTypes: "",

  profilesTypes: [],
};

export default {
  state,
  mutations,
  actions,
};
