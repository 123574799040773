import actions from "./actions";
import mutations from "./mutations";

const localMonitoring = localStorage.getItem("monitoringState")
  ? JSON.parse(localStorage.getItem("monitoringState"))
  : null;

const localIndoorsTable = localStorage.getItem("indoorsTableState")
  ? JSON.parse(localStorage.getItem("indoorsTableState"))
  : null;

const localEventLogsTable = localStorage.getItem("eventLogsTableState")
  ? JSON.parse(localStorage.getItem("eventLogsTableState"))
  : null;

const state = {
  monitoring: {
    filter: {
      profileType: localMonitoring ? localMonitoring.filter.profileType : null,
      zoneId: localMonitoring ? localMonitoring.filter.zoneId : null,
      temp_mode: localMonitoring ? localMonitoring.filter.temp_mode : false,
    },
    content: {
      currTab: localMonitoring
        ? localMonitoring.content.currTab
        : "event-log-table",
    },
  },
  indoorsTable: {
    filter: {},
    pagination: {
      page: localIndoorsTable?.pagination.page || 1,
      total: localIndoorsTable?.pagination.total || 1,
      limit: localIndoorsTable?.pagination.limit || 10,
      totalItems: localIndoorsTable?.pagination.totalItems || 0,
    },
  },
  eventLogsTable: {
    filter: {},
    pagination: {
      page: localEventLogsTable?.pagination.page || 1,
      total: localEventLogsTable?.pagination.total || 0,
      limit: localEventLogsTable?.pagination.limit || 10,
      totalItems: localEventLogsTable?.pagination.totalItems || 0,
    },
  },
};

export default {
  state,
  mutations,
  actions,
};
