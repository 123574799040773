import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
import { moment as $moment } from "@/plugins/vue-moment";
const $toast = useToast();

export default {
  get({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios("devices", { params })
      .then((response) => {
        const {
          data,
          meta: { filter, pagination },
        } = response.data;

        commit("set", data);
        commit("setPagination", pagination);
        commit("setLoading", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },

  syncDevice({ commit }, id) {
    return $axios
      .post(`device/${id}/sync`, {
        timestamp: $moment().unix(),
      })
      .then(() => {
        $toast.success(i18n.global.t("devices.syncDeviceSuccess"));
      })
      .catch(() => {
        $toast.error(i18n.global.t("devices.syncDeviceError"));
      });
  },

  autoSyncDevice({ commit }, { id, status }) {
    return $axios
      .put(`device/${id}/synclock`, {
        lock: status,
      })
      .catch((error) => {
        $toast.error(i18n.global.t("devices.syncDeviceError"));
      });
  },

  sort({ commit }, { id, idPlace, isBeforeIdPlace }) {
    commit("setLoadingSort", { type: "IS_PENDING" });
    return $axios
      .post("devices/sort", {
        id,
        order_id: idPlace,
        is_before: isBeforeIdPlace,
      })
      .then(() => {
        commit("setLoadingSort", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingSort", {
          type: "IS_FAILURE",
          payload: error.response?.data?.status?.message || "",
        });
        $toast.error(i18n.global.t("devices.sortDeviceError"));
      });
  },

  scan({ commit }) {
    commit("setLoadingScan", { type: "IS_PENDING" });

    const urls = ["device/scan", "devices/ip"];

    const requests = urls.map((url) => $axios(url));

    return Promise.all(requests)
      .then((responses) => {
        responses.forEach((response, index) => {
          const { data } = response.data;

          switch (index) {
            case 0:
              commit("setScannedDevices", data);
              break;
            case 1:
              commit("setAllDevicesIp", data);
              break;
            default:
              break;
          }
        });

        commit("setLoadingScan", { type: "IS_SUCCESS" });
      })
      .catch((errors) => {
        commit("setLoadingScan", {
          type: "IS_FAILURE",
          payload: errors[0]?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  getMonitoring({ commit }) {
    commit("setLoadingMonitoring", { type: "IS_PENDING" });

    return $axios
      .get("devices/monitoring")
      .then((response) => {
        commit("setLoadingMonitoring", { type: "IS_SUCCESS" });
        commit("setMonitoring", response.data.data);
      })
      .catch((errors) => {
        commit("setLoadingMonitoring", {
          type: "IS_FAILURE",
          payload: errors[0]?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  remove({ commit }, ids) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return $axios
      .post("devices/delete", { ids })
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        $toast.success(
          ids.length > 1
            ? i18n.global.t("message.devicesDeleteSuccess")
            : i18n.global.t("message.deviceDeleteSuccess")
        );
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.deleteError"));
      });
  },

  getStatistics({ commit }, params) {
    commit("setLoadingStatistics", { type: "IS_PENDING" });

    return $axios
      .get("devices/statistics", { params })
      .then((response) => {
        commit("setLoadingStatistics", { type: "IS_SUCCESS" });
        commit("setStatistics", response.data.data);
      })
      .catch((error) => {
        commit("setLoadingStatistics", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.deleteError"));
      });
  },
};
