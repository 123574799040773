import actions from "./actions";
import mutations from "./mutations";
import { jsonParse } from "../../helpers";

//По умолчанию
const defaultPageData = {};
const defaultFilter = {};

//Получение данных
const pageData =
  jsonParse(localStorage.getItem("monitoringStatePageData")) || defaultPageData;
const filterData =
  jsonParse(localStorage.getItem("monitoringStateFilter")) || defaultFilter;

const state = {
  data: pageData,
  filter: filterData,
};

export default {
  state,
  mutations,
  actions,
};
