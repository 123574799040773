import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as labsComponents from "vuetify/labs/components";
import * as directives from "vuetify/directives";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import { ru, en } from "vuetify/locale";
import "vuetify/styles";

//Themes
const defaultTheme = {
  dark: false,
  colors: {
    primary: "#7165FF",
    secondary: "#473AD0",
    accent: "#7165FF",
    info: "#22A8D7",
    success: "#27AE60",
    warning: "#f6993f",
    orange: "#f6993f",
    error: "#F06B86",
    blue: "#22A8D7",
    green: "#27AE60",
    red: "#F06B86",
  },
};

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  locale: {
    locale: "en",
    fallback: "en",
    messages: { ru, en },
  },
  theme: {
    defaultTheme: "defaultTheme",
    themes: {
      defaultTheme,
    },
  },
});

export default {
  install: (app) => {
    app.use(vuetify);
  },
};
