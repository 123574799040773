<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="2" :loading="isPendingStore">
      <template v-slot:progress>
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <v-row>
          <v-col>
            <v-switch
              v-model="isGuest"
              :label="$t('access-levels.isGuest')"
              color="primary"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="name"
              :label="`${$t('access-levels.name')} *`"
              :rules="nameRules"
              variant="outlined"
              density="comfortable"
              color="primary"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model.number="parentId"
              :items="accessLevels(id)"
              :label="$t('access-levels.parent')"
              item-value="id"
              item-title="name"
              variant="outlined"
              density="comfortable"
              color="primary"
              clearable
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model="zonesId"
              :items="zones"
              :label="$t('access-levels.zone')"
              multiple
              item-value="id"
              item-title="name"
              variant="outlined"
              density="comfortable"
              color="primary"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-switch
              color="primary"
              v-model="isAccessByTime"
              density="comfortable"
              hide-details
              :label="$t('access-levels.isAccessByTime')"
            />
          </v-col>
        </v-row>
        <v-row v-if="isAccessByTime">
          <v-col>
            <v-autocomplete
              v-model="accessModes"
              multiple
              return-object
              chips
              :label="$t('access-levels.accessModes')"
              item-title="name"
              item-value="id"
              color="primary"
              density="comfortable"
              variant="outlined"
              hide-details
              :loading="isPendingGetAccessModesState"
              :items="dataAccessModesState"
            >
              <template #append-inner>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      color="grey-lighten-1"
                      variant="text"
                      density="compact"
                      icon
                      @click="getAccessModes"
                    >
                      <v-icon>mdi-autorenew</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("setting.dataUpdate") }}</span>
                </v-tooltip>
              </template>
              <template #append>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      variant="text"
                      density="compact"
                      icon
                      color="primary"
                      @click="goToAddAccessMode"
                    >
                      <v-icon>mdi-calendar-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("access-levels.addAccessMode") }}</span>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="isPendingStore" @click="cancel">
          <v-icon start>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" :loading="isPendingStore" @click="save">
          <v-icon start>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "AccessLevelForm",

  props: {
    id: {
      type: String,
      default: "0",
    },
    data: {
      type: Object,
      default: null,
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const name = this.data?.name || "";
    const parentId = this.data?.parent?.id || null;
    const isGuest = this.data?.is_guest || false;
    const zonesId = this.data?.zones_id?.length ? this.data.zones_id : [];
    const accessModes = this.data?.access_modes?.length
      ? this.data.access_modes
      : [];
    const isAccessByTime = accessModes.length ? true : false;

    return {
      valid: true,

      workTimeData: {},
      name,
      parentId,
      isGuest,
      zonesId,
      accessModes,
      isAccessByTime,

      nameRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => v.length < 255 || this.$t("user.nameSizeAccessLevelsValidate"),
      ],
    };
  },

  computed: {
    ...mapGetters({
      accessLevels: "accessLevels/getListExcludeById",
    }),

    ...mapState({
      zones: (state) => state.zones.list,
      isPendingGetAccessModesState: (state) =>
        state.accessModes.isPendingGetAccessModes,
      dataAccessModesState: (state) => state.accessModes.dataAccessModes,
    }),
  },

  methods: {
    ...mapActions({
      getAccessLevels: "accessLevels/getList",
      getZones: "zones/getList",

      getAccessModes: "accessModes/getAccessModes",
    }),

    cancel() {
      this.$emit("cancel");
    },

    async save() {
      let valid = false;
      await this.$refs.form.validate().then((result) => {
        if (result.valid) valid = true;
      });
      if (!valid) return;

      const data = {
        name: this.name,
        parent_id: this.parentId,
        is_guest: this.isGuest,
        zones_id: this.zonesId,
      };

      if (this.isAccessByTime) {
        data.access_modes = this.accessModes;
      } else {
        data.access_modes = [];
      }

      this.$emit("store", data);
    },

    onChangeAccess(data) {
      this.access = data;
    },

    goToAddAccessMode() {
      let route = this.$router.resolve({
        name: this.$const.ROUTES.ADD_ACCESS_MODES,
      });

      window.open(route.href, "_blank");
    },
  },

  created() {
    const params = {};

    if (this.id) {
      params.exclude_id_nested = this.id;
    }

    this.getAccessLevels(params);
    this.getZones();
    this.getAccessModes();
  },
};
</script>
