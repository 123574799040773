<template>
  <v-img
    v-if="item.isStaticAvatar"
    :src="item.avatar"
    width="100%"
    aspect-ratio="1"
    :max-width="maxWidth"
  />
  <v-img
    v-else-if="isPhotoBase64(item.avatar)"
    :src="item.avatar"
    width="100%"
    aspect-ratio="1"
    :max-width="maxWidth"
  />

  <ImgProtected
    v-else-if="item.avatar"
    :src="item.avatarMax"
    :alt="item.name"
    width="100%"
    aspect-ratio="1"
    :maxWidth="maxWidth"
  />
  <v-responsive
    v-else
    class="w-100 bg-primary"
    content-class="d-flex justify-center align-center"
    aspect-ratio="1"
    :max-width="maxWidth"
  >
    <v-icon class="flex-shrink-0" size="150"> mdi-account-circle </v-icon>
  </v-responsive>
</template>

<script>
import ImgProtected from "@/components/ImgProtected";
import hoverImage from "@/mixins/hoverImage";

export default {
  mixins: [hoverImage],
  components: { ImgProtected },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    maxWidth: {
      type: [Number, String],
      default: undefined,
    },
  },
};
</script>
