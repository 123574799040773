<template>
  <Alert v-if="isFailureGetAccessModeState" type="error">
    {{ errorMessageGetAccessModeState }}
  </Alert>

  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card
      elevation="2"
      :disabled="isPendingGetAccessModeState || disabled"
      :loading="isPendingGetAccessModeState"
    >
      <template #loader="{ isActive }">
        <v-progress-linear
          v-if="isActive"
          color="primary"
          height="10"
          indeterminate
        />
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="name"
              :label="`${$t('access-levels.name')} *`"
              :rules="nameRules"
              variant="outlined"
              density="comfortable"
              color="primary"
              hide-details="auto"
            />
          </v-col>
          <v-spacer />
          <!-- <v-col cols="auto">
            <MultiAdd :maxDatesCount="maxModesLeft" @saveDates="addModes" />
          </v-col> -->
        </v-row>
        <v-row v-if="modesErrors.length">
          <v-col>
            <Alert type="error">
              <div v-for="(error, index) in modesErrors" :key="index">
                {{ error }}
              </div>
            </Alert>
          </v-col>
        </v-row>
        <v-slide-x-transition :group="true">
          <v-row v-for="mode in modes" :key="mode.key">
            <v-col>
              <TimeZone v-model="mode.value">
                <template #actions>
                  <v-row>
                    <v-col>
                      <v-btn
                        variant="text"
                        density="comfortable"
                        color="red"
                        :ripple="false"
                        @click="deleteMode(mode.key)"
                      >
                        <v-icon start>mdi-calendar-remove-outline</v-icon>
                        {{ $t("access-modes.deleteSchedule") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </TimeZone>
            </v-col>
          </v-row>
        </v-slide-x-transition>
        <v-row>
          <v-col cols="auto" v-if="modes.length < maxModes">
            <v-btn :ripple="false" variant="text" color="green" @click="addMode"
              ><v-icon start>mdi-calendar-plus-outline</v-icon>
              {{ $t("access-modes.addSchedule") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-btn @click="cancel">
          <v-icon start>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn variant="flat" color="green" @click="setData">
          <v-icon start>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { mapActions, mapState } from "vuex";

import TimeZone from "@/components/TimeZone/index.vue";
//import MultiAdd from "../MultiAdd.vue";
import Alert from "@/components/Alert";

import validator from "./validator";

export default {
  emits: ["cancel", "setData"],

  mixins: [validator],

  components: { TimeZone, Alert /*, MultiAdd*/ },

  props: {
    id: {
      type: [String, Number],
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: true,
      maxModes: 30,

      name: "",

      defaultMode: {
        key: 1,
        value: { timeZoneType: "calendarDay", timeWindows: [], dates: [] },
      },

      nameRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => v.length < 255 || this.$t("user.nameSizeAccessLevelsValidate"),
      ],
    };
  },

  methods: {
    ...mapActions({
      resetGetAccessModeAction: "accessModes/resetGetAccessModeAction",
      getAccessModeAction: "accessModes/getAccessMode",
    }),

    genKey() {
      const activeKeys = this.modes.map((mode) => mode.key);

      for (let i = 1; i <= this.maxModes; i++) {
        if (!activeKeys.includes(i)) {
          return i;
        }
      }

      return 0;
    },

    addMode(mode) {
      const params = {
        key: this.genKey(),
        value: mode || {},
      };
      if (params.key) {
        this.modes.push(params);
      }
    },
    addModes(modes) {
      modes.forEach((mode) => this.addMode(mode));
    },

    deleteMode(key) {
      this.modes = this.modes.filter((mode) => mode.key != key);
      this.v$.modes.$touch();
    },

    cancel() {
      this.$emit("cancel");
    },

    async getData() {
      if (this.id == undefined) {
        this.modes.push(this.defaultMode);
        return;
      }

      await this.getAccessModeAction(this.id).then(() => {
        this.name = this.dataAccessModeState.name;
        this.modes = structuredClone(this.dataAccessModeState.modes) || [];
      });
    },
    async setData() {
      this.v$.$touch();
      const valid = !this.modesErrors.length;

      let vValid = false;
      await this.$refs.form
        .validate()
        .then((result) => (vValid = result.valid));

      if (!valid || !vValid) return;

      const params = {
        name: this.name || "",
        modes: this.modes || [],
      };

      this.$emit("setData", params);
    },
  },

  computed: {
    ...mapState({
      dataAccessModeState: (state) => state.accessModes.dataAccessMode,
      isPendingGetAccessModeState: (state) =>
        state.accessModes.isPendingGetAccessMode,
      isFailureGetAccessModeState: (state) =>
        state.accessModes.isFailureGetAccessMode,
      errorMessageGetAccessModeState: (state) =>
        state.accessModes.errorMessageGetAccessMode,
    }),
    maxModesLeft() {
      return this.maxModes - this.modes.length;
    },
  },

  async created() {
    this.getData();
  },

  unmounted() {
    this.resetGetAccessModeAction();
  },
};
</script>
