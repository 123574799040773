<template>
  <v-card elevation="2">
    <v-tabs show-arrows color="primary">
      <v-tab v-for="(tab, i) in tabByPermission" :key="i" :to="tab.to">
        <v-icon start>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>

    <v-window class="pa-4">
      <router-view />
    </v-window>
  </v-card>
</template>

<script>
import permissions from "@/mixins/permissions";

export default {
  name: "ShowObject",
  mixins: [permissions],
  data() {
    return {
      tabs: [
        {
          name: this.$t("object.object"),
          to: {
            name: this.$const.ROUTES.OBJECT_DATA,
            params: {
              id: this.$route.params.id,
            },
          },
          icon: "mdi-archive-outline",
        },
        {
          name: this.$t("object.statistics"),
          to: {
            name: this.$const.ROUTES.OBJECT_STATISTICS,
            params: {
              id: this.$route.params.id,
            },
          },
          icon: "mdi-file-chart-outline",
        },
        {
          name: this.$t("menu.object-history"),
          to: {
            name: this.$const.ROUTES.OBJECT_HISTORY,
            params: {
              id: this.$route.params.id,
            },
          },
          icon: "mdi-history",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.tabByPermission.length) {
          this.redirect();
        }
      },
      immediate: true,
    },
  },
  methods: {
    redirect() {
      if (this.$route.name === this.$const.ROUTES.OBJECT_SHOW) {
        this.$router.replace(this.tabByPermission[0].to);
      }
    },
  },
  computed: {
    tabByPermission() {
      return this.tabs.filter((tab) => this.can(tab.permission));
    },
  },
};
</script>
