<template>
  <v-row
    v-if="modelValue?.total > 1 || modelValue?.total_items > 10"
    class="d-flex align-center flex-wrap custom-footer"
    style="min-height: 70px"
  >
    <v-col v-if="modelValue?.total > 1" cols="auto">
      <v-pagination
        v-model="paginationValue"
        color="primary"
        :length="this.modelValue.total"
        :total-visible="6"
        density="comfortable"
      />
    </v-col>
    <v-spacer />
    <v-col v-if="modelValue?.total_items > 10" cols="auto">
      <v-select
        class="flex-grow-0 w-25"
        style="min-width: 130px"
        v-model="limitValue"
        :items="[10, 30, 50, 100]"
        :label="$t('datatable.per_page')"
        density="compact"
        color="primary"
        variant="underlined"
        hide-details
      />
    </v-col>
  </v-row>
  <v-row v-if="modelValue?.total_items" class="my-2">
    <v-spacer />
    <v-col cols="auto" class="py-2">
      <small class="blue-grey--text text--lighten-1 text-no-wrap">
        {{ `${totalText || $t("datatable.total")}: ${modelValue.total_items}` }}
      </small>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DataTableFooter",

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    totalText: {
      type: String,
      default: "",
    },
    pagination: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    limitValue: {
      get() {
        return this.modelValue.limit;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          page: 1,
          limit: newValue,
        };
        this.$emit("update:modelValue", params);
      },
    },
    paginationValue: {
      get() {
        if (
          this.modelValue.total &&
          this.modelValue.page > this.modelValue.total
        ) {
          const params = {
            ...this.modelValue,
            page: 1,
          };
          this.$emit("update:modelValue", params);
          return;
        }
        return this.modelValue.page;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          page: newValue,
        };
        this.$emit("update:modelValue", params);
      },
    },
  },
};
</script>
