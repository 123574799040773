<template>
  <div>
    <h1 class="mb-3">{{ $t("operators.roles") }}</h1>

    <Alert v-if="isFailureRoles" type="error">
      <template v-if="errorMessageRoles">
        {{ errorMessageRoles }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <v-skeleton-loader v-if="isLoadingRoles" type="list-item-three-line" />

    <v-row v-else-if="isSuccessRoles">
      <v-col v-for="role in roles" :key="role.loopId" cols="12">
        <Role
          :ref="`role-${role.loopId}`"
          :data="role"
          :templates="rolesTemplate"
          :items="allRolesTemplate"
          @updateData="onChange"
          @cancel="onCancel"
          :isDelete="roles.length > 1"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" @click="add">
          <v-icon start> mdi-plus </v-icon>
          {{ $t("operators.addRole") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import Role from "./Role";

export default {
  name: "OperatorRoles",

  components: { Role, Alert },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      roles: [],
    };
  },

  watch: {
    data() {
      this.getData();
    },
  },

  computed: {
    ...mapState({
      allRolesTemplate: (state) => state.operator.allRoles,
      rolesTemplate: (state) => state.operator.roles,
      isLoadingRoles: (state) => state.operator.isPendingRoles,
      isSuccessRoles: (state) => state.operator.isSuccessRoles,
      isFailureRoles: (state) => state.operator.isFailureRoles,
      errorMessageRoles: (state) => state.operator.errorMessageRoles,
    }),
  },

  methods: {
    ...mapActions({
      getDepartments: "departments/getTree",
      getAccessLevels: "accessLevels/getTree",
      getZones: "zones/getList",
      getRoles: "operator/roles",
    }),

    add() {
      let loopId = 1;
      if (this.roles.length > 0) {
        loopId = this.roles[this.roles.length - 1].loopId + 1;
      }

      this.roles.push({
        loopId,
        type: this.$const.ROLES.OPERATOR,
        accesslevels: [],
        departments: [],
        allowed_zones: [],
        permissions: [],
      });

      this.$emit("updateRoles", this.roles);
    },

    onChange(role) {
      const index = this.roles.findIndex((r) => r.loopId === role.loopId);
      this.roles.splice(index, 1, role);
      this.$emit("updateRoles", this.roles);
    },

    onCancel(id) {
      this.roles = this.roles.filter((role) => role.loopId !== id);
      this.$emit("updateRoles", this.roles);
    },

    getData() {
      this.roles = this.data.map((role, index) => ({
        ...role,
        loopId: index,
      }));
    },
  },

  created() {
    this.getData();
    this.getDepartments();
    this.getAccessLevels();
    this.getZones();
    this.getRoles();
  },
};
</script>
