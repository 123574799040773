<template>
  <v-row v-if="isFailureGetProfilesTypes">
    <v-col>
      <Alert type="error">
        {{ errorMessageGetProfilesTypes }}
      </Alert>
    </v-col>
  </v-row>
  <v-row v-if="isFailureDepartments">
    <v-col>
      <Alert type="error">
        {{ errorMessageDepartments }}
      </Alert>
    </v-col>
  </v-row>

  <v-row>
    <v-col>
      <EditCard
        v-if="isEdit"
        v-model="modelValueComputed"
        :objectTypeId="objectTypeId"
        :showNameFields="showNameFields"
        :objectTypes="objectTypesList"
        :profileTypes="profileTypesList"
        :departments="departmentsList"
      >
        <template v-if="showProfileList" #EndOfRightContainer>
          <v-row class="mt-0">
            <v-col>
              <ProfileList
                v-model="profilesComputed"
                :showDeleteProfile="showDeleteProfile"
                :showAddProfile="showAddProfile"
                :showPermitList="showPermitList"
                :showAddPermit="showAddPermit"
                :showEditPermit="showEditPermit"
                :showDeletePermit="showDeletePermit"
                :isEdit="isEditProfileList"
                :isEditProfile="isEditProfile"
                :isEditPermits="isEditPermits"
                :getProfileTypesAuto="false"
                :profileTypes="profileTypesList"
                :getDeparmentsAuto="false"
                :departments="departmentsList"
                @changePermitsEdited="onChangePermitsEdited"
              />
            </v-col>
          </v-row> </template
      ></EditCard>

      <ShowCard
        v-else
        v-model="modelValueComputed"
        :isEditProfileList="isEditProfileList"
        :isEditProfile="isEditProfile"
        :objectTypes="objectTypesList"
        :profileTypes="profileTypesList"
        :departments="departmentsList"
        :avatarClass="avatarClass"
        :avatarCardMd="avatarCardMd"
        :contentCardMd="contentCardMd"
      >
        <template v-if="showProfileList" #EndOfRightContainer>
          <v-row class="mt-0">
            <v-col>
              <ProfileList
                v-model="profilesComputed"
                :showDeleteProfile="showDeleteProfile"
                :showAddProfile="showAddProfile"
                :showPermitList="showPermitList"
                :showAddPermit="showAddPermit"
                :showEditPermit="showEditPermit"
                :showDeletePermit="showDeletePermit"
                :isEdit="isEditProfileList"
                :isEditProfile="isEditProfile"
                :isEditPermits="isEditPermits"
                :getProfileTypesAuto="false"
                :profileTypes="profileTypesList"
                :getDeparmentsAuto="false"
                :departments="departmentsList"
                @changePermitsEdited="onChangePermitsEdited"
              />
            </v-col>
          </v-row>
        </template>
      </ShowCard>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Alert from "@/components/Alert";
import { ProfileList } from "@/components/ProfileTools";

import EditCard from "./Edit";
import ShowCard from "./Show";

export default {
  emits: ["update:modelValue", "changePermitsEdited"],

  components: { EditCard, Alert, ShowCard, ProfileList },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    objectTypeId: {
      type: [String, Number],
      default: undefined,
    },

    isEdit: {
      type: Boolean,
      default: true,
    },

    isEditProfileList: {
      type: Boolean,
      default: true,
    },

    isEditProfile: {
      type: Boolean,
      default: true,
    },

    isEditPermits: {
      type: Boolean,
      default: true,
    },

    showNameFields: {
      type: Boolean,
      default: true,
    },

    avatarClass: {
      type: [Array, Object, String],
      default: undefined,
    },

    avatarCardMd: {
      type: [String, Number],
      default: 4,
    },

    contentCardMd: {
      type: [String, Number],
      default: 8,
    },

    //Настройки типов объекта
    getObjectTypesAuto: {
      type: Boolean,
      default: true,
    },
    objectTypes: {
      type: Array,
      default: () => [],
    },

    // <-- ПРОФИЛИ -->

    //Настройки типов профиля
    showProfileList: {
      type: Boolean,
      default: true,
    },
    getProfileTypesAuto: {
      type: Boolean,
      default: true,
    },
    profileTypes: {
      type: Array,
      default: () => [],
    },

    //Настройки департаментов
    getDeparmentsAuto: {
      type: Boolean,
      default: true,
    },
    departments: {
      type: Array,
      default: () => [],
    },

    /* <-- Profile --> */

    showDeleteProfile: {
      type: Boolean,
      default: true,
    },

    showAddProfile: {
      type: Boolean,
      default: true,
    },

    /* <-- Permits --> */

    showPermitList: {
      type: Boolean,
      default: true,
    },

    showAddPermit: {
      type: Boolean,
      default: true,
    },

    showEditPermit: {
      type: Boolean,
      default: true,
    },

    showDeletePermit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      objectTypesList: [],
      profileTypesList: [],
      departmentsList: [],
    };
  },

  watch: {
    objectTypes() {
      this.getObjectTypes();
    },

    profileTypes() {
      this.getProfilesTypes();
    },

    departments() {
      this.getDepartments();
    },
  },

  methods: {
    ...mapActions({
      getProfilesTypesAction: "profiles/getProfilesTypes",
      resetGetProfilesTypesAction: "profiles/resetGetProfilesTypes",

      getDepartmentsAction: "departments/getTree",

      getObjectTypesAction: "object/getObjectTypes",
    }),

    onChangePermitsEdited(event) {
      this.$emit("changePermitsEdited", event);
    },

    getObjectTypes() {
      if (this.getProfileTypesAuto) {
        this.getObjectTypesAction().then(() => {
          this.objectTypesList = structuredClone(this.objectTypesState);
        });
      } else {
        this.objectTypesList = this.objectTypes;
      }
    },

    getProfilesTypes() {
      if (this.getProfileTypesAuto) {
        this.getProfilesTypesAction().then(() => {
          this.profileTypesList = structuredClone(this.profileTypesState);
        });
      } else {
        this.profileTypesList = this.profileTypes;
      }
    },

    getDepartments() {
      if (this.getDeparmentsAuto) {
        this.getDepartmentsAction().then(() => {
          this.departmentsList = structuredClone(this.departmentsState);
        });
      } else {
        this.departmentsList = this.departments;
      }
    },
  },

  computed: {
    ...mapState({
      objectTypesState: (state) => state.object.objectTypes,

      isLoadingDepartments: (state) => state.departments.isLoading,
      isFailureDepartments: (state) => state.departments.isFailure,
      errorMessageDepartments: (state) => state.departments.errorMessage,

      departmentsState: (state) => state.departments.tree,

      isPendingGetProfilesTypes: (state) =>
        state.profiles.isPendingGetProfilesTypes,
      isFailureGetProfilesTypes: (state) =>
        state.profiles.isFailureGetProfilesTypes,
      errorMessageGetProfilesTypes: (state) =>
        state.profiles.errorMessageGetProfilesTypes,

      profileTypesState: (state) => state.profiles.profilesTypes,
    }),

    modelValueComputed: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },

    profilesComputed: {
      get() {
        return this.modelValue.profiles;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          profiles: newValue,
        };

        this.$emit("update:modelValue", params);
      },
    },
  },

  created() {
    this.getObjectTypes();
    this.getProfilesTypes();
    this.getDepartments();
  },
};
</script>
