export default {
  setFilter({ commit }, data = {}) {
    localStorage.setItem("indoorTableStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },
  setPagination({ commit }, data = {}) {
    localStorage.setItem("indoorTableStatePagination", JSON.stringify(data));
    commit("setPagination", data);
  },
};
