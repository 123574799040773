<template>
  <v-form ref="form">
    <v-card elevation="2" :loading="isPendingStore">
      <template #progress>
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-btn
              v-if="!isChangeAuth && id"
              class="mt-2"
              @click="isChangeAuth = true"
            >
              {{ $t("operators.changeAuth") }}
            </v-btn>

            <v-row v-if="!id || isChangeAuth">
              <v-col v-if="!id">
                <v-text-field
                  v-model="username"
                  :label="`${$t('auth.username')} *`"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  :readonly="id > 0"
                  :error-messages="usernameErrors"
                  @input="v$.username.$touch()"
                  @blur="v$.username.$touch()"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="password"
                  :label="`${$t('auth.password')} *`"
                  type="password"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  :error-messages="passwordErrors"
                  @input="v$.password.$touch()"
                  @blur="v$.password.$touch()"
                />
              </v-col>
              <v-col>
                <v-text-field
                  ref="passwordConfirmation"
                  v-model="passwordConfirm"
                  :label="`${$t('auth.passwordConfirm')} *`"
                  type="password"
                  variant="outlined"
                  density="comfortable"
                  color="primary"
                  :error-messages="passwordConfirmErrors"
                  @input="v$.passwordConfirm.$touch()"
                  @blur="v$.passwordConfirm.$touch()"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="!isEdit || (formData && !formData.is_admin)" cols="12">
            <Roles ref="roles" :data="roles" @updateRoles="onChangeRoles" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="isPendingStore" @click="cancel">
          <v-icon start>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" dark :loading="isPendingStore" @click="save">
          <v-icon start>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Roles from "../Roles";

import validator from "./validator";

export default {
  name: "OperatorForm",

  mixins: [validator],

  components: { Roles },

  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: true,

      isChangeAuth: false,

      roles: [],
      username: [],
      password: "",
      passwordConfirm: "",
    };
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },

    async save() {
      const data = {};

      if (!this.formData?.is_admin) {
        data.roles = this.roles;
      }

      if (this.isChangeAuth || !this.id) {
        data.username = this.username;
        data.password = this.password;
      }

      const valid = await this.v$.$validate();
      if (!valid) return;

      this.$emit("store", data);
    },

    onChangeRoles(roles) {
      this.roles = roles;
    },
    getData() {
      this.roles = this.formData?.roles || [
        {
          type: this.$const.ROLES.OPERATOR,
          accesslevels: [],
          departments: [],
          allowed_zones: [],
          permissions: [],
        },
      ];
      this.username = this.formData?.username || "";
    },
  },

  created() {
    this.getData();
  },
};
</script>
