export default {
  methods: {
    makeRange(start, end) {
      const result = [];
      for (let i = start; i <= end; i++) {
        result.push(i);
      }
      return result;
    },

    hoursBeforeDate(startDate, endDate, includeLastHour = false) {
      let result = [];
      const curStartDate = startDate
        ? parseInt(this.$moment(startDate).format("H"))
        : 0;

      const curEndDate = startDate
        ? parseInt(this.$moment(endDate).format("H"))
        : this.$moment().format("H");

      result = this.makeRange(curStartDate, curEndDate);

      if (!includeLastHour) result.pop();

      return result;
    },

    minutesBeforeDate(startDate, endDate, includeLastHour = false) {
      let result = [];
      const curStartDate = startDate
        ? parseInt(this.$moment(startDate).format("mm"))
        : 0;

      const curEndDate = startDate
        ? parseInt(this.$moment(endDate).format("mm"))
        : this.$moment().format("mm");

      result = this.makeRange(curStartDate, curEndDate);

      if (!includeLastHour) result.pop();

      return result;
    },
  },
};
