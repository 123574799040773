export default {
  setPageData({ commit }, data = {}) {
    localStorage.setItem("objectTypesStatePageData", JSON.stringify(data));
    commit("setPageData", data);
  },
  setFilter({ commit }, data = {}) {
    localStorage.setItem("objectTypesStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },
  setPagination({ commit }, data = {}) {
    localStorage.setItem("objectTypesStatePagination", JSON.stringify(data));
    commit("setPagination", data);
  },
};
