<template>
  <v-checkbox
    :validation-value="fieldComputed"
    class="v-checkbox-phone-custom-fields--hidden"
    hide-details="auto"
    :rules="phoneRules"
  >
    <template #append="{ isValid }">
      <MazPhoneNumberInput
        v-model="fieldComputed"
        v-model:country-code="countryCode"
        :translations="{
          countrySelector: {
            placeholder: $t('telInput.selectorPlaceholder'),
            error: $t('telInput.error'),
            searchPlaceholder: $t('telInput.searchPlaceholder'),
          },
          phoneInput: {
            placeholder: field.label || '',
          },
        }"
        :noExample="true"
        :error="isValid.value != null && !isValid.value"
        :noFormattingAsYouType="false"
        :preferredCountries="[
          'RU',
          'BY',
          'KZ',
          'MD',
          'AM',
          'AZ',
          'GE',
          'KG',
          'TJ',
          'TM',
          'UZ',
        ]"
        :countryLocale="$i18n.locale"
        :noValidationSuccess="true"
        :noValidationError="true"
      />
    </template>
    <template #message="{ message }">
      <div v-html="message" />
    </template>
  </v-checkbox>
</template>
<script>
import parsePhoneNumber from "libphonenumber-js";

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    settings: {
      type: Object,
      default: () => {},
    },

    field: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      countryCode: "",
    };
  },

  computed: {
    fieldComputed: {
      get() {
        const curValue = this.modelValue.find(
          (value) => value.name == this.field.name
        );

        return curValue?.value;
      },
      set(newValue) {
        let isEdit = false;

        const curValues = this.modelValue.map((value) => {
          const curValue = { ...value };

          if (value.name == this.field.name) {
            curValue.value = newValue;
            isEdit = true;
          }

          return curValue;
        });

        if (!isEdit) {
          curValues.push({
            type: this.field.type,
            name: this.field.name,
            value: newValue,
          });
        }

        this.$emit("update:modelValue", curValues);
      },
    },

    phoneRules() {
      return [
        (v) => {
          if (this.field.required && !v) {
            return this.$t("object-type.errSelectItemRequire");
          }

          const parsePhone = parsePhoneNumber(v, this.countryCode);

          if (v && !parsePhone.isValid()) {
            return this.$t("user.notPhone");
          }

          return true;
        },
      ];
    },
  },
};
</script>
