<template>
  <slot
    :isFull="isFull"
    :items="curItems.curItems"
    :splicedItems="curItems.splicedItems"
  />
  <slot
    v-if="isMoreThanMin"
    name="button"
    :isFull="isFull"
    :items="curItems"
    :splicedItems="curItems.splicedItems"
    :toggleFull="toggleFull"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => [],
    },

    minLength: {
      type: [Number, String],
      default: 3,
    },
  },

  data() {
    return {
      isFull: false,
    };
  },

  methods: {
    init() {
      this.isFull = this.value;
    },

    toggleFull() {
      this.isFull = !this.isFull;
    },
  },

  computed: {
    curItems() {
      if (!this.items?.length) return [];

      const curItems = [...this.items];
      let splicedItems = [];

      if (this.isMoreThanMin && !this.isFull) {
        splicedItems = curItems.splice(this.minLength);
      }

      return { curItems, splicedItems };
    },

    isMoreThanMin() {
      return this.items?.length && this.items?.length > this.minLength;
    },
  },

  created() {
    this.init();
  },
};
</script>
