export default {
  setPageData({ commit }, data = {}) {
    localStorage.setItem("objectStatisticsStatePageData", JSON.stringify(data));
    commit("setPageData", data);
  },
  setFilter({ commit }, data = {}) {
    localStorage.setItem("objectStatisticsStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },
  setPagination({ commit }, data = {}) {
    localStorage.setItem(
      "objectStatisticsStatePagination",
      JSON.stringify(data)
    );
    commit("setPagination", data);
  },
};
