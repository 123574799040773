import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  get({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios(`device/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("set", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  store({ commit }, data) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return $axios
      .post("devices/add", data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.saveError"));
      });
  },

  edit({ commit }, { id, data }) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return $axios
      .put(`device/${id}`, data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.saveError"));
      });
  },

  remove({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return $axios
      .delete(`device/${id}`)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        $toast.success(i18n.global.t("message.deleteSuccess"));
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.deleteError"));
      });
  },

  emergency({ commit }, { id, deviceName, command }) {
    const data = {
      command,
    };

    return $axios
      .put(`device/${id}/control`, data)
      .then(() => {
        $toast.success(
          i18n.global.t("devices.setNewControlMode", { deviceName })
        );
      })
      .catch((error) => {
        $toast.error(`${error.response.data.status.message} - ${deviceName}`);
      });
  },

  blocked({ commit }, { id, deviceName, state }) {
    const data = {
      state,
    };

    return $axios
      .put(`device/${id}/state`, data)
      .then(() => {
        $toast.success(
          i18n.global.t("devices.setNewOgateStatus", { deviceName })
        );
      })
      .catch((error) => {
        $toast.error(
          i18n.global.t(`${error.response.data.status.message} - ${deviceName}`)
        );
      });
  },

  disabledBiometricStatus({ commit }, { id, deviceName, disable }) {
    const data = {
      disable,
    };

    return $axios
      .put(`device/${id}/facestate`, data)
      .then(() => {
        $toast.success(
          i18n.global.t("devices.setBiometricStatus", { deviceName })
        );
      })
      .catch((error) => {
        $toast.error(`${error.response.data.status.message} - ${deviceName}`);
      });
  },

  reboot({ commit }, { id, deviceName }) {
    return $axios
      .put(`device/${id}/reboot`)
      .then(() => {
        $toast.success(
          i18n.global.t("devices.setNewOgateReboot", { deviceName })
        );
      })
      .catch((error) => {
        $toast.error(`${error.response.data.status.message} - ${deviceName}`);
      });
  },
};
