export default {
  setLoadingFilter(state, { type, payload = "" }) {
    state.isPendingFilter = type === "IS_PENDING";
    state.isSuccessFilter = type === "IS_SUCCESS";
    state.isFailureFilter = type === "IS_FAILURE";
    state.errorMessageFilter = payload;
  },
  setFilterTemplate(state, payload) {
    state.filterTemplate = payload || {};
  },
};
