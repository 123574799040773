import { createApp } from "vue";
import App from "./App.vue";

// Plugins
import Vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import Apexcharts from "@/plugins/apexcharts";
import ClipBoard from "./plugins/vue3-clipboard";
import Print from "./plugins/vue3-print-nb";
import QRCodeVue3 from "./plugins/qrcode-vue3.js";
import Toast from "@/plugins/vue-toastification";
import Moment from "@/plugins/vue-moment";
import Maska from "@/plugins/maska";
import PortalVue from "portal-vue";
import Auth from "@/plugins/auth";
import constPlugin from "@/constants";
import Vue3ConfirmDialog from "@/plugins/vue3-confirm-dialog";
import MazUi from "@/plugins/maz-ui.js";
import Axios from "@/plugins/axios";
import Mitt from "@/plugins/mitt";
import Countdown from "@/plugins/vue3-countdown";
import ElementPlus from "@/plugins/element-plus";
import VueDraggablePlus from "@/plugins/vue-draggable-plus";
import VueCameraLib from "@/plugins/vue-camera-lib";
import VCalendar from "./plugins/v-calendar";

//Store
import Vuex from "./store";

// Router
import router from "./router";

// PWA
import "@/registerServiceWorker";

//Global components
import VueTelinputVutify from "@/components/vue-tel-input-vuetify";

const app = createApp(App);

//Регистрация модулей
app
  .use(Vuetify)
  .use(Vuex)
  .use(i18n)
  .use(PortalVue)
  .use(Axios)
  .use(router)
  .use(Moment)
  .use(Auth)
  .use(Toast)
  .use(constPlugin)
  .use(Vue3ConfirmDialog)
  .use(Apexcharts)
  .use(VueTelinputVutify)
  .use(Print)
  .use(QRCodeVue3)
  .use(ClipBoard)
  .use(Countdown)
  .use(Mitt)
  .use(ElementPlus)
  .use(MazUi)
  .use(Maska)
  .use(VueDraggablePlus)
  .use(VueCameraLib)
  .use(VCalendar);

app.mount("#app");
