import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPendingGetAccessModes: false,
  isSuccessGetAccessModes: false,
  isFailureGetAccessModes: false,
  errorMessageGetAccessModes: "",

  dataAccessModes: [],

  isPendingAddAccessMode: false,
  isSuccessAddAccessMode: false,
  isFailureAddAccessMode: false,
  errorMessageAddAccessMode: "",

  isPendingGetAccessMode: false,
  isSuccessGetAccessMode: false,
  isFailureGetAccessMode: false,
  errorMessageGetAccessMode: "",

  dataAccessMode: {},

  isPendingUpdateAccessMode: false,
  isSuccessUpdateAccessMode: false,
  isFailureUpdateAccessMode: false,
  errorMessageUpdateAccessMode: "",

  isPendingDeleteAccessMode: false,
  isSuccessDeleteAccessMode: false,
  isFailureDeleteAccessMode: false,
  errorMessageDeleteAccessMode: "",
};

export default {
  state,
  mutations,
  actions,
};
