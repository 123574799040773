export default {
  setCurPage({ commit }, data) {
    localStorage.setItem("systemCurPage", JSON.stringify(data));
    commit("setCurPage", data);
  },

  setCurVersion({ commit }, data) {
    localStorage.setItem("systemCurVersion", JSON.stringify(data));
    commit("setCurVersion", data);
  },
};
