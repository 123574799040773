import i18n from "@/plugins/i18n";
import { instance as $axios } from "@/plugins/axios";
import { useToast } from "vue-toastification";
const $toast = useToast();

export default {
  get({ commit }, { id }) {
    commit("setLoading", { type: "IS_RESET_STORE" });
    commit("setLoading", { type: "IS_PENDING" });

    return $axios(`object/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("set", data);
        commit("setLoading", { type: "IS_SUCCESS" });
        return response;
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
        throw error;
      });
  },

  getVisits({ commit }, { id, month }) {
    const params = {};

    if (month) params.month = month;

    commit("setLoadingVisits", { type: "IS_PENDING" });

    return $axios(`object/${id}/passeds`, { params })
      .then((response) => {
        const { data } = response.data;

        commit("setVisits", data);
        commit("setLoadingVisits", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingVisits", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  store({ commit }, data) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return $axios
      .post("objects/add", data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        commit("setStoreErrorData", error?.response?.data?.data || null);

        $toast.error(i18n.global.t("message.saveError"));
      });
  },

  edit({ commit }, { id, data }) {
    commit("setLoading", { type: "IS_PENDING" });

    return $axios
      .put(`object/${id}`, data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        commit("setStoreErrorData", error?.response?.data?.data || null);

        $toast.error(i18n.global.t("message.saveError"));
      });
  },

  refeature({ commit }, { id }) {
    commit("setLoadingRefeature", { type: "IS_PENDING" });

    return $axios
      .put(`object/${id}/refeature`)
      .then(() => {
        commit("setLoadingRefeature", { type: "IS_SUCCESS" });

        $toast.success(i18n.global.t("message.refeature_success"));
      })
      .catch((error) => {
        commit("setLoadingRefeature", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.saveError"));
      });
  },

  getBioLink({ commit }, { id, clear }) {
    if (clear) {
      commit("clearBioLink");
      return;
    }
    commit("setLoadingPassBio", { type: "IS_PENDING" });

    return $axios(`object/${id}/generate_bio_invite`)
      .then((response) => {
        commit("setBioLink", response.data.data);
        commit("setLoadingPassBio", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingPassBio", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        $toast.error(i18n.global.t("message.errorLoading"));
      });
  },

  sendOnTerminal({ commit, rootState }, text) {
    commit("setLoadingSendQrd", { type: "IS_PENDING" });

    return $axios
      .get(`${rootState.settings.data.qr_terminal}/qr?text=${text}`)
      .then(() => {
        commit("setLoadingSendQrd", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingSendQrd", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
      });
  },

  offTerminal({ commit, rootState }) {
    commit("setLoadingOffQrd", { type: "IS_PENDING" });

    return $axios
      .get(`${rootState.settings.data.qr_terminal}/off`)
      .then(() => {
        commit("setLoadingOffQrd", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingOffQrd", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
      });
  },

  sendSms({ commit }, { id, params }) {
    commit("setLoadingSendSms", { type: "IS_PENDING" });

    return $axios
      .post(`object/${id}/invite`, params)
      .then(() => {
        commit("setLoadingSendSms", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingSendSms", {
          type: "IS_FAILURE",
          payload: error?.response?.data || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );
      });
  },

  sendEmail({ commit }, { id, params }) {
    commit("setLoadingSendEmail", { type: "IS_PENDING" });

    return $axios
      .post(`object/${id}/invite`, params)
      .then(() => {
        commit("setLoadingSendEmail", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingSendEmail", {
          type: "IS_FAILURE",
          payload: error?.response?.data || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );
      });
  },

  validateFace({ commit }, params) {
    commit("setLoadingValidateFace", { type: "IS_PENDING" });

    return $axios
      .post("validate/face", params)
      .then(() => {
        commit("setLoadingValidateFace", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingValidateFace", {
          type: "IS_FAILURE",
          payload: error?.response?.data || "",
        });

        $toast.error(
          error?.response?.data?.status?.message ||
            i18n.global.t("message.errorLoading")
        );
      });
  },
};
