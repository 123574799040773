import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const validValue = helpers.regex(/^[a-zA-Z0-9\\-]+$/);

export default {
  data() {
    return {
      v$: useVuelidate({ $scope: false }),
      name: "",
      value: "",
    };
  },

  validations() {
    const data = {
      name: { required },
      value: {
        required,
        validValue,
        noRepeat: helpers.withParams({ type: "noRepeat" }, (value) => {
          if (!value) return true;

          if (
            this.isNew &&
            this.allSelectItems.find((field) => field.key == value)
          ) {
            return false;
          }

          if (
            this.allSelectItems?.filter(
              (selectField) => selectField.key == value
            ).length > 1
          ) {
            return false;
          }

          const findField = this.allSelectItems.find(
            (selectField) => selectField.key == value
          );

          if (findField && findField.key != this.fieldValue.value) {
            return false;
          }

          return true;
        }),
      },
    };

    return data;
  },

  computed: {
    valueErrors() {
      const errors = [];

      if (this.v$.value.$dirty && this.v$.value.noRepeat.$invalid) {
        errors.push(this.$t("object-type.errDublicateSelectItem"));
      }

      if (this.v$.value.$dirty && this.v$.value.validValue.$invalid) {
        errors.push(this.$t("object-type.errTypeRegex"));
      }

      if (this.v$.value.$dirty && this.v$.value.required.$invalid) {
        errors.push(this.$t("object-type.errSelectItemRequire"));
      }

      return errors;
    },

    nameErrors() {
      const errors = [];

      if (this.v$.name.$dirty && this.v$.name.required.$invalid) {
        errors.push(this.$t("object-type.errSelectItemRequire"));
      }

      return errors;
    },
  },
};
