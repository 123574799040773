import { createI18n } from "vue-i18n";
const ru = require("../lang/ru.json");
const en = require("../lang/en.json");

const i18n = createI18n({
  locale: "en",
  globalInjection: true,
  messages: {
    en,
    ru,
  },
});
export default i18n;
