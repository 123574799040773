<template>
  <v-row v-if="isFailureGetProfilesTypes">
    <v-col>
      <Alert type="error">
        {{ errorMessageGetProfilesTypes }}
      </Alert>
    </v-col>
  </v-row>
  <v-row v-if="isFailureDepartments">
    <v-col>
      <Alert type="error">
        {{ errorMessageDepartments }}
      </Alert>
    </v-col>
  </v-row>

  <v-row v-for="profile in modelValueGet" :key="profile.key">
    <v-col>
      <ProfileCard
        v-model="profile.value"
        :profileKey="profile.key"
        :showDeleteProfile="showDeleteProfile"
        :showPermitList="showPermitList"
        :showAddPermit="showAddPermit"
        :showEditPermit="showEditPermit"
        :showDeletePermit="showDeletePermit"
        :isEdit="isEditProfile"
        :isEditPermits="isEditPermits"
        :profileTypes="profileTypesList"
        :getProfileTypesAuto="false"
        :getDeparmentsAuto="false"
        :departments="departmentsList"
        @removeProfile="onRemoveProfile"
        @changePermitsEdited="onChangePermitsEdited"
        @update:modelValue="modelValueUpdate(profile.key, $event)"
      />
    </v-col>
  </v-row>

  <v-row v-if="isEdit && showAddProfile">
    <v-col>
      <v-menu v-if="curProfileTypes.length" offset-y start>
        <template #activator="{ props }">
          <v-btn v-bind="props" block variant="tonal" color="success">
            <v-icon start>mdi-account-plus-outline</v-icon>
            {{ $t("profile.add_profile") }}
          </v-btn>
        </template>
        <v-list density="comfortable">
          <v-list-item
            v-for="type in curProfileTypes"
            :key="type.id"
            @click="modelValueAdd(type.id)"
          >
            <v-list-item-title>
              {{ type.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Alert from "@/components/Alert";

import ProfileCard from "../ProfileCard";

export default {
  emits: ["update:modelValue", "changePermitsEdited"],

  components: { ProfileCard, Alert },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    isEdit: {
      type: Boolean,
      default: true,
    },

    isEditProfile: {
      type: Boolean,
      default: true,
    },

    isEditPermits: {
      type: Boolean,
      default: true,
    },

    //Настройки типов профиля
    getProfileTypesAuto: {
      type: Boolean,
      default: true,
    },
    profileTypes: {
      type: Array,
      default: () => [],
    },

    //Настройки департаментов
    getDeparmentsAuto: {
      type: Boolean,
      default: true,
    },
    departments: {
      type: Array,
      default: () => [],
    },

    /* <-- Profile --> */

    showDeleteProfile: {
      type: Boolean,
      default: true,
    },

    showAddProfile: {
      type: Boolean,
      default: true,
    },

    /* <-- Permits --> */

    showPermitList: {
      type: Boolean,
      default: true,
    },

    showAddPermit: {
      type: Boolean,
      default: true,
    },

    showEditPermit: {
      type: Boolean,
      default: true,
    },

    showDeletePermit: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      profileTypesList: [],
      departmentsList: [],

      permitsEdited: {},
    };
  },

  watch: {
    profileTypes() {
      this.getProfilesTypes();
    },

    departments() {
      this.getDepartments();
    },

    permitsEditedValue(newValue) {
      this.$emit("changePermitsEdited", newValue);
    },
  },

  methods: {
    ...mapActions({
      getProfilesTypesAction: "profiles/getProfilesTypes",
      resetGetProfilesTypesAction: "profiles/resetGetProfilesTypes",

      getDepartmentsAction: "departments/getTree",
    }),

    onChangePermitsEdited(event) {
      this.permitsEdited[event.id] = event.value;
    },

    getProfilesTypes() {
      if (this.getProfileTypesAuto) {
        this.getProfilesTypesAction().then(() => {
          this.profileTypesList = structuredClone(this.profileTypesState);
        });
      } else {
        this.profileTypesList = this.profileTypes;
      }
    },

    getDepartments() {
      if (this.getDeparmentsAuto) {
        this.getDepartmentsAction().then(() => {
          this.departmentsList = structuredClone(this.departmentsState);
        });
      } else {
        this.departmentsList = this.departments;
      }
    },

    modelValueUpdate(key, value) {
      let curModelValue = [...this.modelValue];

      curModelValue = curModelValue.map((profile) => {
        if (profile.profile_types_id == key) {
          return value;
        }

        return profile;
      });

      this.$emit("update:modelValue", curModelValue);
    },

    modelValueAdd(key) {
      const newProfile = {
        profile_types_id: key,
      };

      const curModelValue = [...this.modelValue, newProfile];

      this.$emit("update:modelValue", curModelValue);
    },

    onRemoveProfile(profileTypeId) {
      let curModelValue = [...this.modelValue];

      curModelValue = curModelValue.filter(
        (profile) => profile.profile_types_id != profileTypeId
      );

      this.$emit("update:modelValue", curModelValue);
    },
  },

  computed: {
    ...mapState({
      isPendingGetProfilesTypes: (state) =>
        state.profiles.isPendingGetProfilesTypes,
      isFailureGetProfilesTypes: (state) =>
        state.profiles.isFailureGetProfilesTypes,
      errorMessageGetProfilesTypes: (state) =>
        state.profiles.errorMessageGetProfilesTypes,

      profileTypesState: (state) => state.profiles.profilesTypes,

      isLoadingDepartments: (state) => state.departments.isLoading,
      isFailureDepartments: (state) => state.departments.isFailure,
      errorMessageDepartments: (state) => state.departments.errorMessage,

      departmentsState: (state) => state.departments.tree,
    }),

    permitsEditedValue() {
      let curActiveEdit = false;

      for (let key in this.permitsEdited) {
        if (this.permitsEdited[key]) {
          curActiveEdit = true;
        }
      }

      return curActiveEdit;
    },

    modelValueGet() {
      return this.modelValue.map((profile) => {
        const curProfile = {
          key: profile.profile_types_id,
          value: profile,
        };

        return curProfile;
      });
    },

    curProfileTypes() {
      const usedIdes = this.modelValue?.map(
        (profile) => profile.profile_types_id
      );

      return this.profileTypesList.filter(
        (type) => !usedIdes.includes(type.id)
      );
    },
  },

  created() {
    this.getProfilesTypes();
    this.getDepartments();
  },

  unmounted() {
    this.resetGetProfilesTypesAction();
  },
};
</script>
