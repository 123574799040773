<template>
  <Alert v-if="isFailureUpdateObjectTypeState" type="error">
    {{ errorMessageUpdateObjectTypeState }}
  </Alert>

  <Form
    :disabled="isPendingUpdateObjectTypeState"
    :id="id"
    @setData="setData"
    @cancelForm="goBack"
  />
</template>
<script>
import { mapActions, mapState } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  components: { Form, Alert },

  data() {
    return {
      id: undefined,
    };
  },

  methods: {
    ...mapActions({
      resetUpdateObjectTypeAction: "objectTypes/resetUpdateObjectType",
      updateObjectTypeAction: "objectTypes/updateObjectType",
    }),

    getData() {
      this.id = this.$route.params.id;
    },

    goBack() {
      this.$router.go(-1);
    },

    setData(data) {
      const params = {
        id: this.id,
        data: data,
      };

      this.updateObjectTypeAction(params).then(this.goBack);
    },
  },

  computed: {
    ...mapState({
      isPendingUpdateObjectTypeState: (state) =>
        state.objectTypes.isPendingUpdateObjectType,
      isFailureUpdateObjectTypeState: (state) =>
        state.objectTypes.isFailureUpdateObjectType,
      errorMessageUpdateObjectTypeState: (state) =>
        state.objectTypes.errorMessageUpdateObjectType,
    }),
  },

  created() {
    this.getData();
  },

  unmounted() {
    this.resetUpdateObjectTypeAction();
  },
};
</script>
