<template>
  <span v-for="type in item.identityTypes" :key="type">
    <v-chip
      v-if="type === 'face'"
      prepend-icon="mdi-emoticon-happy-outline"
      color="primary"
    >
      {{ $t("users.face") }}
    </v-chip>

    <v-chip v-if="type === 'qr'" prepend-icon="mdi-qrcode-scan" color="primary">
      {{ $t("users.qr") }}
    </v-chip>

    <v-chip
      v-if="type === 'card'"
      v-clipboard:copy="getIdentityValue(item.event?.identity_value || '')"
      v-clipboard:success="onCopy"
      prepend-icon="mdi-card-bulleted-outline"
      color="primary"
    >
      {{ $t("users.card") }} <br />
      HEX - {{ getIdentityValue(item.event.identity_value) }} <br />
      DEC -
      {{ parseInt(getIdentityValue(item.event.identity_value), 16) }}
    </v-chip>
  </span>

  <v-chip
    v-if="item.event?.message"
    prepend-icon="mdi-message-reply-text-outline"
    color="primary"
  >
    {{ item.event.message }}
  </v-chip>
</template>
<script>
import identityValue from "@/mixins/identityValue";

export default {
  mixins: [identityValue],

  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    onCopy() {
      this.$toast.success(this.$t("message.copied"));
    },
  },
};
</script>
