import actions from "./actions";
import mutations from "./mutations";

const limit = window.localStorage.getItem("personsLimit") || 30;

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  isPendingRemove: false,
  isSuccessRemove: false,
  isFailureRemove: false,
  errorMessageRemove: "",

  data: [],
  limit: parseInt(limit),
  pagination: {
    current_page: 1,
    per_page: 30,
    total: 0,
    total_items: 0,
  },

  // Check phone
  isPendingCheckPhone: false,
  isSuccessCheckPhone: false,
  isFailureCheckPhone: false,
  errorMessageCheckPhone: "",
  checkPhoneData: {
    result: false,
  },

  // Check email
  isPendingCheckEmail: false,
  isSuccessCheckEmail: false,
  isFailureCheckEmail: false,
  errorMessageCheckEmail: "",
  checkEmailData: {
    result: false,
  },

  // Multi set department
  isPendingMultiSetDepartment: false,
  isSuccessMultiSetDepartment: false,
  isFailureMultiSetDepartment: false,
  errorMessageMultiSetDepartment: "",

  // Multi set AccessLevel
  isPendingMultiSetAccessLevel: false,
  isSuccessMultiSetAccessLevel: false,
  isFailureMultiSetAccessLevel: false,
  errorMessageMultiSetAccessLevel: "",

  // Delete All Persons
  isPendingDelAllPersons: false,
  isSuccessDelAllPersons: false,
  isFailureDelAllPersons: false,
  errorMessageDelAllPersons: "",

  // Multi set Photos
  isPendingPersonPhoto: false,
  isSuccessPersonPhoto: false,
  isFailurePersonPhoto: false,
  errorMessagePersonPhoto: "",
};

export default {
  state,
  mutations,
  actions,
};
