<template>
  <div
    class="radial-progress"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
      background: backgroundColor,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "RadialProgress",

  props: {
    progress: {
      type: Number,
      default: 0,
    },

    size: {
      type: Number,
      default: 100,
    },

    progressColor: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    backgroundColor() {
      switch (this.progressColor) {
        case 2:
          return `conic-gradient(#7165FF ${this.progress}%, 0, white ${
            100 - this.progress
          }%)`;
        default:
          return `conic-gradient(#38c172 ${this.progress}%, 0, white ${
            100 - this.progress
          }%)`;
      }
    },
  },
};
</script>
