<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="objecttypes"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col cols="12" :lg="!pageData.isMini ? 9 : 12">
      <v-row v-if="isFailureGetObjectTypesState">
        <v-col cols="12">
          <Alert type="error">
            {{ errorMessageGetObjectTypesState }}
          </Alert>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <!-- <v-btn color="primary" @click="goToAdd">
            <v-icon start> mdi-plus </v-icon>
            {{ $t("departments.addNew") }}
          </v-btn> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :loading="isPendingGetObjectTypesState"
            :headers="headers"
            :items="objectTypes"
            :items-per-page="pagination.limit"
            disable-sort
          >
            <template #loader="{ isActive }">
              <v-progress-linear
                v-if="isActive"
                color="primary"
                height="10"
                indeterminate
              />
            </template>

            <template #[`item.name`]="{ item }">
              <router-link
                class="text-primary"
                :to="{
                  name: $const.ROUTES.EDIT_OBJECT_TYPE,
                  params: {
                    id: item.id,
                  },
                }"
              >
                {{ item.name }}
              </router-link>
            </template>

            <template #[`item.fields`]="{ item }">
              <v-row class="ma-n1">
                <v-col
                  v-for="field in item.fields"
                  :key="field.param"
                  cols="auto"
                  class="pa-1"
                >
                  <v-chip size="small">
                    {{ field.label || field.name }}
                  </v-chip>
                </v-col>
              </v-row>
            </template>

            <template #bottom
              ><DataTableFooterV2
                v-model="pagination"
                @update:modelValue="onPagination"
            /></template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
import Filter from "@/components/Filter";

export default {
  components: { Alert, DataTableFooterV2, Filter },

  data() {
    return {
      objectTypes: [],

      headers: [
        {
          title: "ID",
          value: "id",
          width: 100,
        },
        {
          title: this.$t("object-type.name"),
          value: "name",
          align: "start",
        },
        {
          title: this.$t("object-type.fields"),
          value: "fields",
          align: "center",
        },
      ],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      getObjectTypesAction: "object/getObjectTypes",
      resetGetObjectTypesAction: "object/resetGetObjectTypes",

      setStatePageDataAction: "stateObjectTypes/setPageData",
      setStateFilterAction: "stateObjectTypes/setFilter",
      setStatePaginationAction: "stateObjectTypes/setPagination",
    }),

    getObjectTypes() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getObjectTypesAction(params).then((response) => {
        this.objectTypes = this.objectTypesState;

        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;

          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };

          this.setPaginationState();
        }
      });
    },

    goToAdd() {
      this.$router.push({ name: this.$const.ROUTES.ADD_OBJECT_TYPE });
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния таблицы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },

    //Запись состояния таблицы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },

    setFilterState() {
      this.setStateFilterAction(this.filter);
    },

    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };

      this.setStatePaginationAction(this.pagination);
    },

    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },

    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getObjectTypes();
    },
  },

  computed: {
    ...mapState({
      objectTypesState: (state) => state.object.objectTypes,

      isPendingGetObjectTypesState: (state) =>
        state.object.isPendingGetObjectTypes,
      isFailureGetObjectTypesState: (state) =>
        state.object.isFailureGetObjectTypes,
      errorMessageGetObjectTypesState: (state) =>
        state.object.errorMessageGetObjectTypes,

      statePageData: (state) => state.stateObjectTypes.data,
      stateFilter: (state) => state.stateObjectTypes.filter,
      statePagination: (state) => state.stateObjectTypes.pagination,
    }),
  },

  created() {
    this.getState();
    this.getObjectTypes();
  },

  unmounted() {
    this.resetGetObjectTypesAction();
  },
};
</script>
