import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

export const options = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: "button",
  icon: false,
  rtl: false,
};

export default {
  install: (app) => {
    app.use(Toast, options);
    app.config.globalProperties.$toast = useToast();
  },
};
