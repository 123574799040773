export default {
  setPageData({ commit, state }, data = {}) {
    const curData = {
      ...state.data,
      ...data,
    };

    localStorage.setItem("monitoringStatePageData", JSON.stringify(curData));
    commit("setPageData", curData);
  },
  setFilter({ commit }, data = {}) {
    localStorage.setItem("monitoringStateFilter", JSON.stringify(data));
    commit("setFilter", data);
  },
};
