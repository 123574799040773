<template>
  <v-row>
    <v-col cols="12" md="4">
      <AvatarCard v-model="photoComputed" />
    </v-col>

    <v-col style="z-index: 1" cols="12" md="8">
      <v-card class="overflow-visible" elevation="2">
        <template #loader="{ isActive }">
          <v-progress-linear
            v-if="isActive"
            color="primary"
            height="10"
            indeterminate
          />
        </template>

        <v-card-text>
          <v-row v-if="showNameFields">
            <v-col cols="12">
              <FullName
                v-model:surname="lastNameComputed"
                v-model:name="firstNameComputed"
                v-model:patronymic="middleNameComputed"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CustomFields
                v-model="customFieldsComputed"
                :settings="{ object_id: modelValue.id, isObject: true }"
                :fields="curObjectType?.fields"
                :show="false"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <slot name="EndOfRightContainer" />
    </v-col>
  </v-row>
</template>

<script>
import AvatarCard from "../components/AvatarCard";

import CustomFields from "../../CustomFields";
import FullName from "@/components/fullname";

export default {
  name: "PersonForm",

  emits: ["update:modelValue"],

  components: {
    FullName,
    AvatarCard,
    CustomFields,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },

    objectTypeId: {
      type: [String, Number],
      default: undefined,
    },

    showNameFields: {
      type: Boolean,
      default: true,
    },

    //Настройки типов объекта
    objectTypes: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      BREAK_STRING_CHAR: "&*|",
    };
  },

  methods: {
    updateModelValue(params) {
      this.$emit("update:modelValue", params);
    },
  },

  computed: {
    firstNameComputed: {
      get() {
        return this.modelValue.name?.split(this.BREAK_STRING_CHAR)[1] ?? "";
      },
      set(newValue) {
        let parseName = this.modelValue.name?.split(this.BREAK_STRING_CHAR);

        if (parseName?.length) {
          parseName[1] = newValue;
        } else {
          parseName = ["", newValue, ""];
        }

        parseName = parseName.join(this.BREAK_STRING_CHAR);

        const params = {
          ...this.modelValue,
          name: parseName,
        };

        this.updateModelValue(params);
      },
    },

    lastNameComputed: {
      get() {
        return this.modelValue.name?.split(this.BREAK_STRING_CHAR)[0] ?? "";
      },
      set(newValue) {
        let parseName = this.modelValue.name?.split(this.BREAK_STRING_CHAR);

        if (parseName?.length) {
          parseName[0] = newValue;
        } else {
          parseName = [newValue, "", ""];
        }

        parseName = parseName.join(this.BREAK_STRING_CHAR);

        const params = {
          ...this.modelValue,
          name: parseName,
        };

        this.updateModelValue(params);
      },
    },

    middleNameComputed: {
      get() {
        return this.modelValue.name?.split(this.BREAK_STRING_CHAR)[2] ?? "";
      },
      set(newValue) {
        let parseName = this.modelValue.name?.split(this.BREAK_STRING_CHAR);

        if (parseName?.length) {
          parseName[2] = newValue;
        } else {
          parseName = ["", "", newValue];
        }

        parseName = parseName.join(this.BREAK_STRING_CHAR);

        const params = {
          ...this.modelValue,
          name: parseName,
        };

        this.updateModelValue(params);
      },
    },

    photoComputed: {
      get() {
        return this.modelValue.photo;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          photo: newValue,
          photo_encrypted: newValue,
        };

        this.updateModelValue(params);
      },
    },

    customFieldsComputed: {
      get() {
        return this.modelValue.values;
      },
      set(newValue) {
        const params = {
          ...this.modelValue,
          values: newValue,
        };

        this.$emit("update:modelValue", params);
      },
    },

    curObjectType() {
      return this.objectTypes?.find((type) => type.id == this.objectTypeId);
    },
  },
};
</script>
